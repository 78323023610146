import { action, observable } from 'mobx'
import { ArtistFormResponseForm } from '../../models/ArtistModels/artistForm.model'
import { ApiRequestStore } from '../common/apiRequest.store'

class FinalistFormGetStore extends ApiRequestStore {
  @observable formData?: ArtistFormResponseForm;

  @action
  setFormData(data: ArtistFormResponseForm): void {
    this.formData = data
  }

  @action
  clearData = (): void => {
    this.formData = undefined
  }
}

class ConfirmFinalistStore extends ApiRequestStore {}

export const finalistFormGetStore: FinalistFormGetStore = new FinalistFormGetStore()

export const confirmFinalistStore: ConfirmFinalistStore = new ConfirmFinalistStore()
