import { autoserialize, autoserializeAs } from "cerialize"

export class ListExpertModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize photo: string | null;

  constructor(
    id: number,
    name: string,
    photo: string
  ) {
    this.id = id
    this.name = name
    this.photo = photo
  }
}

export class ExpertListGetRequest {
  @autoserialize limit?: number;
  @autoserialize offset?: number;
  @autoserialize search?: string;

  constructor(
    limit?: number,
    offset?: number,
    search?: string
  ) {
    this.limit = limit
    this.offset = offset
    this.search = search
  }
}

export class ExpertListGetResponse {
  @autoserializeAs(ListExpertModel) items?: ListExpertModel[];
  @autoserialize count?: number;
}
