import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import FinalistsListComponent from '../../../components/CRM/AdminCRM/FinalistsList/FinalistsList.component'
import {
  PATH_FINALISTS, 
  PATH_FINALISTS_CONFIRMED, 
  PATH_FINALISTS_UNCONFIRMED, 
  PATH_FINALISTS_UNPROCESSED, 
  PATH_FINALIST_MODERATE
} from '../../../const/routes.const'
import { FinalistsListArtistType } from '../../../models/AdminModels/FinalistsList.model'
import { getFinalistsList } from '../../../services/admin/finalistsList.service'
import { finalistsListStore } from '../../../store/AdminStore/finalistsList.store'

const currentCategory = `${PATH_FINALISTS}/`
const tabPathnames = [
  PATH_FINALISTS_UNPROCESSED.replace(currentCategory, ''),
  PATH_FINALISTS_CONFIRMED.replace(currentCategory, ''),
  PATH_FINALISTS_UNCONFIRMED.replace(currentCategory, '')
]

const routeCaregories: Map<string, FinalistsListArtistType> = new Map([
  [PATH_FINALISTS_UNPROCESSED, FinalistsListArtistType.Unprocessed],
  [PATH_FINALISTS_CONFIRMED, FinalistsListArtistType.Confirmed],
  [PATH_FINALISTS_UNCONFIRMED, FinalistsListArtistType.Unconfirmed]
])

const FinalistsListContainer = (): JSX.Element => {
  const location = useLocation()
  const history = useHistory()

  const [tabIndex, setTabIndex] = useState(0)

  const getActiveTab = (path: string): number => {
    const pathnameIndex = tabPathnames.indexOf(path)

    return pathnameIndex !== -1 ? pathnameIndex : 0
  }

  const getFinalists = (pathname: string, search?: string, limit?: number, offset?: number): void => {
    const requestParams = {
      type: routeCaregories.get(pathname) || FinalistsListArtistType.Unprocessed,
      limit,
      offset,
      search
    }

    getFinalistsList(requestParams)
  }

  const onEffect = (): () => void => {
    const pathSuffix = location.pathname.split('/').pop()
    
    if (tabPathnames.indexOf(pathSuffix || '') < 0) {
      history.replace(PATH_FINALISTS_UNPROCESSED)
    }

    const searchString = new URLSearchParams(location.search).get("name") || undefined

    getFinalists(
      location.pathname,
      searchString
    )

    setTabIndex(getActiveTab(pathSuffix || ''))

    return (): void => {
      finalistsListStore.clearData()
    }
  }

  useEffect(onEffect, [])

  const onSelect = (tabIndex: number): void => {
    setTabIndex(tabIndex)
    const pureNewPathname = `${currentCategory}${tabPathnames[tabIndex]}`
    const newPathname = `${pureNewPathname}${location.search}`
    const searchString = new URLSearchParams(location.search).get("name") || undefined

    history.replace(newPathname)
    finalistsListStore.clearData()

    getFinalists(
      pureNewPathname,
      searchString
    )
  }

  const onSearchChange = (searchString: string): void => {
    finalistsListStore.clearData()

    getFinalists(
      searchString || ''
    )
  }

  const {
    isLoading,
    finalists,
    currentOffset,
    count,
    totalCount
  } = finalistsListStore

  const getShouldLoadMore = (): boolean => {
    if (count && finalists) {
      return count > finalists.length
    } else return true
  }

  const onLoadMore = (): void => {
    getFinalists(
      location.pathname,
      new URLSearchParams(location.search).get("name") || undefined,
      20,
      currentOffset ? currentOffset + 20 : 20
    )

    finalistsListStore.setOffset(currentOffset ? currentOffset + 20 : 20)
  }

  const goToArtist = (artistId: number): void => {
    history.push(
      `${PATH_FINALIST_MODERATE}/${artistId}`,
      {category: location.pathname}
    )
  }

  return (
    <FinalistsListComponent 
      tabIndex={tabIndex}
      finalists={finalists}
      isLoading={isLoading}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onLoadMore={onLoadMore}
      shouldLoadMore={getShouldLoadMore()}
      finalistsCount={totalCount}
      onArtistClick={goToArtist}
    />
  )
}

export default observer(FinalistsListContainer)