/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import {
  PATH_ARTIST_EVALUATION,
  PATH_ARTIST_REQUEST,
  PATH_ARTIST_REQUEST_READ,
  PATH_ARTIST_REPORT
} from "../../../const/routes.const"
import { artistFormNotificationsStore } from "../../../store/ArtistStore/artistFormNotifications.store"
import ArtistNotificationsComponent from "../../../components/CRM/ArtistCRM/ArtistNotifications/ArtistNotifications.component"
import {
  setStatusEditingForm,
  getArtistForm
} from "../../../services/artist/artistForm.service"
import { formatDateForClient } from "../../../utils/formatDate"
import {
  MemberModel,
  ArtistFormFields
} from "../../../models/ArtistModels/artistForm.model"
import { artistFormGetStore } from "../../../store/ArtistStore/artistForm.store"
import { authContext } from "../../../context/auth.context"
import moment from "moment"

const ArtistNotificationsContainer = (): JSX.Element => {
  const history = useHistory()

  const { user } = useContext(authContext)
  const artistId = parseInt(useParams<{ artistId: string }>().artistId)

  const getFormInitialValues = (): ArtistFormFields | undefined => {
    const formData = artistFormGetStore.formData

    if (formData) {
      return {
        ...formData?.form,
        members: formData.form.members.length
          ? formData.form.members.map(
              (member): MemberModel => ({
                ...member,
                birthday: formatDateForClient(member.birthday)
              })
            )
          : [
              {
                name: null,
                email: null,
                birthday: null,
                role: null,
                tShirtSize: null,
                phone: null,
                musicExperience: null,
                facebookUrl: null,
                instagramUrl: null,
                vkontakteUrl: null,
                tiktokUrl: null,
                youtubeUrl: null,
                twitterUrl: null,
                isLeader: true
              }
            ],
        photoSocial: [
          formData.form.photoSocial[0] || null,
          formData.form.photoSocial[1] || null,
          formData.form.photoSocial[2] || null,
          formData.form.photoSocial[3] || null
        ],
        musicLinks: [
          formData.form.musicLinks[0] || "",
          formData.form.musicLinks[1] || "",
          formData.form.musicLinks[2] || ""
        ],
        budgetPlanJson: formData.form.budgetPlanJson?.length
          ? formData.form.budgetPlanJson?.map(item => ({
              name: item?.name || null,
              price: item?.price || 0,
              count: item?.count || 0,
              currentBudget: item?.currentBudget || 0
            }))
          : [
              {
                name: null,
                price: null,
                count: null,
                currentBudget: null
              }
            ],
        projectStart: formatDateForClient(formData?.form.projectStart),
        projectEnd: formatDateForClient(formData?.form.projectEnd),
        projectBudget: formData.form.projectBudget || null
      }
    }
  }
  const [formDataValues, setFormDataValues] = useState(getFormInitialValues())

  const onEffect = (): (() => void) => {
    user.role &&
      getArtistForm(user.role, artistId).then(() => {
        setFormDataValues(getFormInitialValues())
      })

    return (): void => {
      artistFormGetStore.clearData()
    }
  }

  useEffect(onEffect, [])

  const onFormEditClick = (): void => {
    history.push(PATH_ARTIST_REQUEST)
  }

  const onFormReadClick = (): void => {
    history.push(PATH_ARTIST_REQUEST_READ)
  }

  const onRatingButtonClick = (): void => {
    history.push(PATH_ARTIST_EVALUATION)
  }

  const onReportButtonClick = (): void => {
    const stage = moment(new Date()).isBefore("2022-04-15")
      ? "stage1"
      : "stage2"
    history.push(`${PATH_ARTIST_REPORT}/${stage}`)
  }

  const onFormRemoderationClick = (): void => {
    setStatusEditingForm().then(() => history.push(PATH_ARTIST_REQUEST))
  }

  return (
    <div>
      <ArtistNotificationsComponent
        notificationsData={artistFormNotificationsStore.notificationsData}
        onFormEditClick={onFormEditClick}
        onFormReadClick={onFormReadClick}
        onRatingButtonClick={onRatingButtonClick}
        onFormRemoderationClick={onFormRemoderationClick}
        onReportButtonClick={onReportButtonClick}
        formData={formDataValues}
      />
    </div>
  )
}

export default observer(ArtistNotificationsContainer)
