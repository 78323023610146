import React from "react"

import "./CrmReportListItem.style.scss"

interface Props {
  onClick?: () => void;
  name: string;
  photo?: string | null;
  statusStage1: string;
  statusStage2: string;
}

export const statuses: {
  [key in string]: {
    label: string;
    color: string;
  };
} = {
  NEW: {
    label: "Новая заявка",
    color: "red"
  },
  EDITING: {
    label: "Редактируется артистом",
    color: "grey"
  },
  MODERATION: {
    label: "Требуется модерация",
    color: "yellow"
  },
  CHECKED: {
    label: "Одобрена",
    color: "green"
  }
}

const block = "crm-report-list-item"

const CRMReportListItemComponent = (props: Props): JSX.Element => {
  const { name, photo, onClick,statusStage1, statusStage2 } = props

  return (
    <div className={`${block}`} onClick={onClick}>
      <div
        className={`${block}__avatar`}
        style={photo ? { backgroundImage: `url(${photo})` } : {}}
      />
      <div className={`${block}__name`}>{name}</div>

      <div className={`${block}__status`}>
        <div className={`${block}__status-header`}>
          Статусы анкет (первой и второй):
        </div>
        <div className={`${block}__status-footer`}>
          <div className={`${block}--${statuses[statusStage1].color}`}>
            {statuses[statusStage1].label}
          </div>
          <div className={`${block}__divider`}>/</div>
          <div className={`${block}--${statuses[statusStage2].color}`}>
            {statuses[statusStage2].label}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CRMReportListItemComponent
