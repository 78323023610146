import { action, makeObservable, observable } from "mobx"
import { WinnerFormStatus } from "../../models/ArtistModels/winnerForm.model"

class WinnerDataStore {
  @observable formStatus?: WinnerFormStatus;

  constructor() {
    makeObservable(this)
  }

  @action
  setFormStatus(status: WinnerFormStatus): void {
    this.formStatus = status
  }
}

export const winnerDataStore: WinnerDataStore = new WinnerDataStore()
