import classNames from "classnames"
import React from "react"
import { ReactComponent as Bell } from "../../../../assets/icons/bell.svg"
import {
  ArtistFormStatus,
  ArtistFormFields
} from "../../../../models/ArtistModels/artistForm.model"
import {
  ArtistFormNotificationsResponse,
  FormNotification,
  NotificationsType
} from "../../../../models/ArtistModels/artistNotifications.model"
import Button from "../../../common/Button/Button.component"

import "./ArtistNotifications.style.sass"
import { artistFormSetEditStatusStore } from "../../../../store/ArtistStore/artistForm.store"
import DownloadFormComponent from "../../AdminCRM/AdminApproveSidebar/DownloadForm/DownloadForm.component"
import GenerateCertificate from "../GenerateCertificate/GenerateCertificate.component"

interface PropTypes {
  notificationsData?: ArtistFormNotificationsResponse;
  onFormEditClick: () => void;
  onFormReadClick: () => void;
  onFormRemoderationClick: () => void;
  onRatingButtonClick: () => void;
  formData?: ArtistFormFields;
  onReportButtonClick: () => void;
}

const block = "artist-notifications"

export default class ArtistNotificationsComponent extends React.Component<PropTypes> {
  private needNotificationHighlight = true;

  render(): JSX.Element {
    const {
      notificationsData,
      onFormEditClick,
      onRatingButtonClick,
      onFormReadClick,
      onFormRemoderationClick,
      onReportButtonClick,
      formData
    } = this.props

    const notifications = notificationsData?.notifications
    this.needNotificationHighlight = true

    return (
      <div className={block}>
        <div className="content-container">
          <div className={`${block}__body`}>
            <div className={`${block}__title`}>Уведомления</div>

            {notifications && !!notifications.length ? (
              <div className="row row--space-between">
                <div className={`${block}__notifications`}>
                  {notifications.map(
                    (notification: FormNotification, index: number) => (
                      <div
                        className={classNames(`${block}__notifications-item`)}
                        key={`notification-${index}`}
                      >
                        <div className={`${block}__notifications-data`}>
                          <div className={`${block}__notifications-image`}>
                            <Bell />
                          </div>
                          <div className={`${block}__notifications-text`}>
                            {notification.text}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div>
                  {notifications.find(
                    notification =>
                      notification.type === NotificationsType.Rated
                  ) && (
                    <div
                      className={`${block}__rating-button margin margin--bottom-s`}
                    >
                      <Button
                        text={"Оценки экспертов"}
                        onClick={onRatingButtonClick}
                        background="turquoise"
                      />
                    </div>
                  )}
                  {formData && (
                    <div className={`${block}__rating-button margin--bottom-s`}>
                      <DownloadFormComponent formData={formData} />
                    </div>
                  )}
                  {formData && formData.name && formData.isFinalist && (
                    <div className={`${block}__rating-button`}>
                      <GenerateCertificate name={formData.name} />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row row--space-between">
                <div className={`${block}__notifications`}>
                  <div className={`${block}__no-notifications-text`}>
                    Нет новых уведомлений
                  </div>
                </div>
                {formData && (
                  <div className={`${block}__rating-button`}>
                    <DownloadFormComponent formData={formData} />
                  </div>
                )}
              </div>
            )}

            <div className="margin margin--top-m">
              <Button
                text="Просмотреть анкету"
                onClick={onFormReadClick}
                background="turquoise"
              />
            </div>

            {(notificationsData?.form.status === ArtistFormStatus.EDITING ||
              notificationsData?.form.status === ArtistFormStatus.NEW) && (
              <div className="margin margin--top-m">
                <Button
                  text={"Редактировать анкету"}
                  onClick={onFormEditClick}
                  background="turquoise"
                />
              </div>
            )}

            {notificationsData?.form.status === ArtistFormStatus.CHECKED && (
              <div className="margin margin--top-m">
                <Button
                  text={"Oтправить на повторную модерацию"}
                  onClick={onFormRemoderationClick}
                  background="turquoise"
                  isLoading={artistFormSetEditStatusStore.isLoading}
                />
              </div>
            )}

            {formData && formData.isWinner && (
              <div className="margin margin--top-m">
                <Button
                  text={"Отчет о проекте"}
                  onClick={onReportButtonClick}
                  background="turquoise"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
