import React from "react"
import "react-tabs/style/react-tabs.css"
import LoaderComponent from "../../../common/Loader/Loader.component"
import LazyLoad from "react-lazyload"
import LazyLoadComponent from "../../common/LazyLoad/LazyLoad.component"
import SearchInputContainer from "../../../../container/CRM/AdminCRM/SearchInput.container"
import CRMListItemComponent from "../CrmListItem/CrmListItem.component"
import { ListExpertModel } from "../../../../models/AdminModels/ExpertList.model"
import classNames from "classnames"

import './ExpertList.style.scss'

const block = "expert-list"

interface Props {
  isLoading: boolean;
  expertsList?: ListExpertModel[];
  onSearchChange: (value: string) => void;
  onLoadMore: () => void;
  shouldLoadMore: boolean;
}

const ExpertListComponent = (props: Props): JSX.Element => {
  const {
    isLoading,
    expertsList,
    onSearchChange,
    shouldLoadMore,
    onLoadMore
  } = props

  const onLoadMoreHandle = (): void => {
    !isLoading && onLoadMore()
  }

  return (
    <div className={`${block} admin-layout-content`}>
      <div className="admin-layout-content__header">
        <div className="page-title page-title--black admin-layout-content__title">
          ЭКСПЕРТЫ
        </div>

        <div className="admin-layout-content__controls">
          <SearchInputContainer onSearchChange={onSearchChange} />
        </div>
      </div>
      {isLoading && !expertsList?.length && (
        <div className="admin-layout-content__loading">
          <LoaderComponent width={100} height={100} />
        </div>
      )}
      <div 
        className={classNames(
          "admin-layout-content__body",
          `${block}__body`
        )}
      >
        {expertsList && expertsList.length ? (
          <>
          {expertsList.map(expert => (
            <CRMListItemComponent 
              key={expert.id}
              title={expert.name}
              image={expert.photo || undefined}
            />
          ))}
          {shouldLoadMore && (
            <LazyLoad height={10} unmountIfInvisible={true}>
              <LazyLoadComponent isLoading={isLoading} onMount={onLoadMoreHandle} />
            </LazyLoad>
          )}
          </>
        ) : !isLoading && (
          <div className="admin-layout-content__empty">
            Список пуст
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpertListComponent