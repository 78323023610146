import { Deserialize } from "cerialize"
import { ArtistFormNotificationsResponse } from "../../models/ArtistModels/artistNotifications.model"
import { api } from "../../utils/api"
import { RequestErrorResponse } from "../../models/common/error.model"
import { artistDataStore } from "../../store/ArtistStore/artistData.store"
import { artistFormNotificationsStore } from "../../store/ArtistStore/artistFormNotifications.store"

export function getArtistFormNotifications(): Promise<ArtistFormNotificationsResponse> {
  artistFormNotificationsStore.setIsLoading(true)

  return api.get(
    '/artist/notifications'
  ).then((res) => {
    const data: ArtistFormNotificationsResponse = Deserialize(res.data, ArtistFormNotificationsResponse)

    artistDataStore.setFormStatus(data.form?.status)
    artistFormNotificationsStore.setNotificationsData(data)
    artistFormNotificationsStore.setIsLoading(false)

    return data
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response?.data
    artistFormNotificationsStore.setIsLoading(false)
    artistFormNotificationsStore.setErrors(errorResponseData?.errors)
    throw error
  })
}