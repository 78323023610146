import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ArtistListComponent from '../../../components/CRM/AdminCRM/ArtistList/ArtistList.component'
import { 
  PATH_ARTISTS, 
  PATH_ARTISTS_APPROVED, 
  PATH_ARTISTS_CHECKED, 
  PATH_ARTISTS_NEW, 
  PATH_ARTISTS_REMODERATION 
} from '../../../const/routes.const'
import { ArtistListCategory } from '../../../models/AdminModels/ArtistList.model'
import { getArtistList } from '../../../services/admin/artistList.service'
import { artistListStore } from '../../../store/AdminStore/artistList.store'

const currentCategory = `${PATH_ARTISTS}/`
const tabPathnames = [
  PATH_ARTISTS_NEW.replace(currentCategory, ''),
  PATH_ARTISTS_REMODERATION.replace(currentCategory, ''),
  PATH_ARTISTS_APPROVED.replace(currentCategory, ''),
  PATH_ARTISTS_CHECKED.replace(currentCategory, '')
]

const routeCaregories: Map<string, ArtistListCategory> = new Map([
  [PATH_ARTISTS_NEW, ArtistListCategory.NEW],
  [PATH_ARTISTS_REMODERATION, ArtistListCategory.REMODERATION],
  [PATH_ARTISTS_APPROVED, ArtistListCategory.APPROVED],
  [PATH_ARTISTS_CHECKED, ArtistListCategory.CHECKED]
])

const ArtistListContainer = (props: RouteComponentProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0)

  const getActiveTab = (path: string): number => {
    const pathnameIndex = tabPathnames.indexOf(path)

    return pathnameIndex !== -1 ? pathnameIndex : 0
  }

  const getArtists = (pathname: string, search?: string, limit?: number, offset?: number): void => {
    const requestParams = {
      type: routeCaregories.get(pathname) || ArtistListCategory.NEW,
      limit,
      offset,
      search
    }

    getArtistList(requestParams)
  }

  const onEffect = (): () => void => {
    const pathSuffix = props.location.pathname.split('/').pop()
    
    if (tabPathnames.indexOf(pathSuffix || '') < 0) {
      props.history.replace(PATH_ARTISTS_NEW)
    }

    const searchString = new URLSearchParams(props.location.search).get("name") || undefined

    getArtists(
      props.location.pathname, 
      searchString
    )

    setTabIndex(getActiveTab(pathSuffix || ''))

    return (): void => {
      artistListStore.clearData()
    }
  }

  useEffect(onEffect, [])

  const onSelect = (tabIndex: number): void => {
    setTabIndex(tabIndex)
    const pureNewPathname = `${currentCategory}${tabPathnames[tabIndex]}`
    const newPathname = `${pureNewPathname}${props.location.search}`
    const searchString = new URLSearchParams(props.location.search).get("name") || undefined

    props.history.replace(newPathname)
    artistListStore.clearData()

    getArtists(
      pureNewPathname,
      searchString
    )
  }

  const getShouldLoadMore = (): boolean => {
    const { count, artists } = artistListStore

    if (count && artists) {
      return count > artists.length
    } else return true
  }

  const {
    isLoading,
    artists,
    currentOffset,
    totalCount
  } = artistListStore

  return (
    <ArtistListComponent
      tabIndex={tabIndex}
      artistsData={artists}
      isLoading={isLoading}
      onSelect={onSelect}
      onSearchChange={(value: string): void => {
        artistListStore.clearData()

        getArtists(
          props.location.pathname,
          value || ''
        )
      }}
      onLoadMore={(): void => {
        getArtists(
          props.location.pathname,
          new URLSearchParams(props.location.search).get("name") || undefined,
          20,
          currentOffset ? currentOffset + 20 : 20
        )

        artistListStore.setOffset(currentOffset ? currentOffset + 20 : 20)
      }}
      shouldLoadMore={getShouldLoadMore()}
      artistsCount={totalCount}
    />
  )
}

export default withRouter(observer(ArtistListContainer))