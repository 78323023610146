import { action, observable } from "mobx"
import { FinalistsListGetResponse, FinalistsListItemModel } from "../../models/AdminModels/FinalistsList.model"
import { ApiRequestStore } from "../common/apiRequest.store"

class FinalistsListStore extends ApiRequestStore {
  @observable finalists?: FinalistsListItemModel[];
  @observable count?: number;
  @observable totalCount?: number;
  @observable currentOffset?: number;

  @action
  setFinalistsListData(data: FinalistsListGetResponse): void {
    if (this.finalists && data.items) {
      this.finalists = [...this.finalists, ...data.items]
    } else {
      this.finalists = data.items
    }
    this.count = data.count
    this.totalCount = data.totalCount
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  }

  @action
  clearData = (): void => {
    this.finalists = undefined
    this.count = undefined
    this.totalCount = undefined
    this.currentOffset = undefined
  }
}

export const finalistsListStore: FinalistsListStore = new FinalistsListStore()
