import React from 'react'
import classNames from 'classnames'

import './Textarea.style.scss'

const block = 'textarea-component'

interface Props {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: string;
  value: string;
  placeholder: string;
  error?: string;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  disabled?: boolean;
  maxLength?: number;
}

const TextareaComponent = (props: Props): JSX.Element => {
  const { name, onChange, value, placeholder, error, onBlur, onFocus, disabled, maxLength } = props

  return (
    <div className={block}>
      <div className={classNames(
        `${block}__textarea-block`,
        disabled && `${block}__textarea-block--disabled`,
        error && `${block}__textarea-block--error`
      )}>
        <textarea
          className={`${block}__textarea`}
          name={name}
          onChange={onChange}
          value={value || ''}
          placeholder={placeholder}
          maxLength={maxLength || 10000}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        />
      </div>
      <div className={`${block}__error`}>
        {error}
      </div>
    </div>
  )
}

export default TextareaComponent