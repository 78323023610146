import { BudgetRowModel } from "../models/ArtistModels/artistForm.model"

export const getFullPriceBudget = (item: BudgetRowModel) =>
  Number(item?.price) * Number(item?.count)

export const getRequestedBudget = (item: BudgetRowModel) => {
  const result =
    getFullPriceBudget(item) - Number(item?.currentBudget) > 0
      ? getFullPriceBudget(item) - Number(item?.currentBudget)
      : 0
  return result
}

export const getFullPriceBudgetTotal = (items: BudgetRowModel[]) => {
  const result = items?.reduce(
    (acc, curValue) => acc + getFullPriceBudget(curValue),
    0
  )

  return result
}

export const getRequestedBudgetTotal = (items: BudgetRowModel[]) =>
  items?.reduce((acc, curValue) => acc + getRequestedBudget(curValue), 0)

export const getCurrentBudgetTotal = (items: BudgetRowModel[]) =>
  items?.reduce((acc, curValue) => acc + Number(curValue?.currentBudget), 0)
