import React from "react"
import EvaluationFormContainer from "../../container/CRM/ExpertCRM/EvaluationForm.container"
import EvaluationInfoContainer from "../../container/CRM/ExpertCRM/EvaluationInfo.container"

import "./style.scss"
import HeaderComponent from "../../components/common/Header/Header.component"

const block = "evaluation-page"

const EvaluationPage = (): JSX.Element => (
  <div className={block}>
    <HeaderComponent />
    <EvaluationInfoContainer />

    <div className={`${block}__separator`} />

    <div className={`${block}__evaluation-form`}>
      <div className="content-container">
        <EvaluationFormContainer />
      </div>
    </div>
  </div>
)

export default EvaluationPage
