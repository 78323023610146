import { autoserialize, autoserializeAs } from "cerialize"

export class FestExpertFinalistsListGetRequest {
  @autoserialize evaluated?: boolean;
  @autoserialize limit?: number;
  @autoserialize offset?: number;
  @autoserialize search?: string;

  constructor(
    evaluated?: boolean,
    limit?: number,
    offset?: number,
    search?: string
  ) {
    this.limit = limit
    this.offset = offset
    this.search = search
    this.evaluated = evaluated
  }
}


export class FestExpertArtistModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize photo?: string;

  constructor(
    id: number,
    name: string,
    photo: string
  ) {
    this.id = id
    this.name = name
    this.photo = photo
  }
}

export class FestExpertFinalistsListItemModel {
  @autoserialize valuation: number;
  @autoserializeAs(FestExpertArtistModel) artist: FestExpertArtistModel;

  constructor(
    valuation: number,
    artist: FestExpertArtistModel
  ) {
    this.valuation = valuation
    this.artist = artist
  }
}

export class FestExpertFinalistsListGetResponse {
  @autoserialize count: number;
  @autoserializeAs ('total_count') totalCount: number;
  @autoserializeAs(FestExpertFinalistsListItemModel) items: FestExpertFinalistsListItemModel[];

  constructor(
    items: FestExpertFinalistsListItemModel[],
    count: number,
    totalCount: number
  ) {
    this.items = items
    this.count = count
    this.totalCount = totalCount
  }
}
