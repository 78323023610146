import { createContext } from "react"
import { UserRole } from "../const/permissionsRules.const"

interface ContextType {
  user: {
    role?: UserRole;
    isAuthenticated?: boolean;
  };
  setUserRole: (value: UserRole) => void;
  logout: () => void;
}

export const authContext = createContext<ContextType>({
  user: {},
  setUserRole: () => null,
  logout: () => null
})

export const AuthProvider = authContext.Provider