import React from "react"
import moment from "moment"
import { ReactComponent as Save } from "../../../assets/icons/save.svg"
import { ReactComponent as Star } from "../../../assets/icons/star-text.svg"

import { WinnerFormStatus } from "../../../models/ArtistModels/winnerForm.model"
import { formStatusLabels } from "../ArtistCRM/ArtistForm/data.const"

import "./WinnerCRMHeader.style.scss"
import { statuses } from "../AdminCRM/CrmReportListItem/CrmReportListItem.component"
import { tabNames } from "../ArtistCRM/WinnerForm/WinnerForm.component"

interface Props {
  title: string;
  stage: string;
  formStatus: WinnerFormStatus;
  onSave?: () => void;
  saveTime?: Date;
}

const block = "winner-crm-header"

const WinnerCRMHeader = (props: Props): JSX.Element => {
  const { title, onSave, formStatus, saveTime, stage } = props

  return (
    <div className={block}>
      <div className="content-container row row--space-between">
        <div className={`${block}__content`}>
          <div className={`${block}__title`}>{title}</div>
          <div className={`${block}__text margin--top-m`}>
            Выбранный период: <span>{tabNames[stage]}</span>
          </div>

          <div className={`${block}__text`}>
            Статус: <span>{statuses[formStatus].label}</span>
          </div>

          <div className={`${block}__label margin--top-xs`}>
            <div className={`${block}__label-eye`}>
              <Star width="20px" height="15px" fill="#ffffff" />
            </div>
            <div className={`${block}__label-text`}>
              Поля с данной пометкой обязательны для заполнения.
            </div>
          </div>
        </div>
        {onSave && (
          <div className={`${block}__right-side`}>
            <div className={`${block}__save-button`} onClick={onSave}>
              <Save /> Сохранить
            </div>

            {saveTime && (
              <div className={`${block}__save-time`}>
                Последнее сохранение: {moment(saveTime).format("HH:mm:ss")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default WinnerCRMHeader
