import { EvaluationArtistModel, EvaluationLeaderModel } from "../../../../models/ExpertModels/Evaluation.model"
import { ReactComponent as FB } from '../../../../assets/icons/facebook.svg'
import { ReactComponent as VK } from '../../../../assets/icons/vk.svg'
import { ReactComponent as Instagram } from '../../../../assets/icons/instagram.svg'
import { ReactComponent as Twitter } from '../../../../assets/icons/twitter.svg'
import { ReactComponent as Tiktok } from '../../../../assets/icons/tiktok.svg'
import { ReactComponent as Youtube } from '../../../../assets/icons/youtube.svg'
import { ReactComponent as Spotify } from '../../../../assets/icons/spotify.svg'
import { ReactComponent as Soundcloud } from '../../../../assets/icons/soundcloud.svg'
import { ReactComponent as YandexMusic } from '../../../../assets/icons/yandex-music.svg'

interface ProjectInSocialDataItem {
  field: keyof EvaluationArtistModel;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const projectInSocialData: ProjectInSocialDataItem[] = [
  {
    field: 'facebookUrl',
    icon: FB
  },
  {
    field: 'instagramUrl',
    icon: Instagram
  },
  {
    field: 'vkontakteUrl',
    icon: VK
  },
  {
    field: 'twitterUrl',
    icon: Twitter
  },
  {
    field: 'tiktokUrl',
    icon: Tiktok
  },
  {
    field: 'youtubeUrl',
    icon: Youtube
  }
]

// export const musicSocialData: ProjectInSocialDataItem[] = [
//   {
//     field: 'musicVkontakteUrl',
//     icon: VK
//   },
//   {
//     field: 'musicSpotifyUrl',
//     icon: Spotify
//   },
//   {
//     field: 'musicSoundcloudUrl',
//     icon: Soundcloud
//   },
//   {
//     field: 'musicYandexUrl',
//     icon: YandexMusic
//   }
// ]

interface LeaderSocialDataItem {
  field: keyof EvaluationLeaderModel;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const leaderSocial: LeaderSocialDataItem[] = [
  {
    field: 'facebookUrl',
    icon: FB
  },
  {
    field: 'instagramUrl',
    icon: Instagram
  },
  {
    field: 'vkontakteUrl',
    icon: VK
  },
  {
    field: 'twitterUrl',
    icon: Twitter
  },
  {
    field: 'tiktokUrl',
    icon: Tiktok
  },
  {
    field: 'youtubeUrl',
    icon: Youtube
  }
]