import React, { Fragment, ReactNode, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface Props extends RouteComponentProps {
  children: ReactNode;
}

const ScrollToTopWrapper = (props: Props): JSX.Element => {
  const { history } = props
  
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return (): void => {
      unlisten()
    }
  }, [history])

  return (
    <Fragment>{props.children}</Fragment>
  )
}

export default withRouter(ScrollToTopWrapper)