import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import LoginFormComponent from '../../components/CRM/LoginForm/LoginForm.component'
import PassRecoveryFormComponent from '../../components/CRM/PassRecoveryForm/PassRecoveryForm.component'
import 
  PassRecoverySuccessComponent 
from 
  '../../components/CRM/PassRecoveryForm/PassRecoverySuccess/PassRecoverySuccess.component'
import { PATH_CRM } from '../../const/routes.const'
import { authContext } from '../../context/auth.context'
import { LoginRequest, PassRecoveryRequest } from '../../models/AuthModels/login.model'
import { logIn, recoverUserPassword } from '../../services/auth.service'
import { loginStore, recoverPasswordStore } from '../../store/AuthStore/auth.store'

const LoginFormContainer = (props: RouteComponentProps): JSX.Element => {
  const { setUserRole } = useContext(authContext)
  const [visibleForm, setVisibleForm] = 
    useState<'loginForm' | 'passwordRecovery' | 'passRecoveryEmailSent'>('loginForm')

  const login = (data: LoginRequest): void => {
    logIn(data).then((data) => {
      setUserRole(data.user.role)
      props.history.push(PATH_CRM)
    })
  }

  const recoverPassword = (data: PassRecoveryRequest): void => {
    recoverUserPassword(data).then(() => {
      setVisibleForm('passRecoveryEmailSent')
    })
  }

  const renderForm = (): JSX.Element => {
    switch(visibleForm) {
      case 'loginForm':
        return (
          <LoginFormComponent
            onSubmit={login}
            error={loginStore.requestErrors && loginStore.requestErrors[0].message}
            isLoading={loginStore.isLoading}
            onPassRecoveryClick={(): void => setVisibleForm('passwordRecovery')}
          />
        )

      case 'passwordRecovery':
        return (
          <PassRecoveryFormComponent
            onSubmit={recoverPassword}
            error={recoverPasswordStore.requestErrors && recoverPasswordStore.requestErrors[0].message}
            isLoading={recoverPasswordStore.isLoading}
            onLoginClick={(): void => setVisibleForm('loginForm')}
          />
        )
      
      case 'passRecoveryEmailSent':
        return (
          <PassRecoverySuccessComponent onLoginClick={(): void => setVisibleForm('loginForm')} />
        )
    }
  }
  
  return (
    renderForm()
  )
}

export default withRouter(observer(LoginFormContainer))