import { Deserialize, Serialize } from "cerialize"
import moment from "moment"
import { UserRole } from "../../const/permissionsRules.const"
import {
  ArtistFormGetResponse,
  ArtistFormSaveRequest,
  ArtistFormStatus,
  MemberModel
} from "../../models/ArtistModels/artistForm.model"
import { RequestErrorResponse } from "../../models/common/error.model"
import { artistDataStore } from "../../store/ArtistStore/artistData.store"
import {
  artistFormApplyStore,
  artistFormApproveStore,
  artistFormGetStore,
  artistFormModerateStore,
  artistFormReadStore,
  artistFormSaveStore,
  artistSaveMembersStore,
  artistFormSetEditStatusStore
} from "../../store/ArtistStore/artistForm.store"
import { artistFormNotificationsStore } from "../../store/ArtistStore/artistFormNotifications.store"
import { api } from "../../utils/api"

const getArtistFormUrl = (role: UserRole, id?: number): string => {
  switch (role) {
    case UserRole.ARTIST:
      return "/artist/form"
    case UserRole.ADMIN:
      return `/admin/artists/${id}`
    case UserRole.EXPERT:
      return `/expert/artists/${id}`
    default:
      return `/admin/artists/${id}`
  }
}

const getSaveMembersUrl = (role: UserRole, id?: number): string => {
  switch (role) {
    case UserRole.ARTIST:
      return "/artist/form/members"
    case UserRole.ADMIN:
      return `/admin/artists/${id}/members`
    case UserRole.EXPERT:
      return `/expert/artists/${id}`
    default:
      return `/admin/artists/${id}`
  }
}

export function getArtistForm(role: UserRole, id?: number): Promise<void> {
  const url = getArtistFormUrl(role, id)
  artistFormGetStore.setIsLoading(true)

  return api
    .get(url)
    .then((res) => {
      const data: ArtistFormGetResponse = Deserialize(
        res.data,
        ArtistFormGetResponse
      )

      artistFormGetStore.setIsLoading(false)
      artistFormGetStore.setFormData(data)
      artistDataStore.setFormStatus(data.meta.status)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormGetStore.setIsLoading(false)
      artistFormGetStore.setErrors(errorResponseData?.errors)
    })
}

export function saveArtistForm(
  request: ArtistFormSaveRequest,
  role: UserRole,
  id?: number
): Promise<void> {
  const url = getArtistFormUrl(role, id)
  const serializedData = Serialize(request, ArtistFormSaveRequest)

  artistFormSaveStore.setIsLoading(true)

  return api
    .put(url, serializedData)
    .then(() => {
      artistFormSaveStore.setIsLoading(false)
      artistFormSaveStore.setSaveTime(moment().toDate())
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormSaveStore.setIsLoading(false)
      artistFormSaveStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function saveArtistMembers(
  members: MemberModel[],
  role: UserRole,
  id?: number
): Promise<void> {
  const url = getSaveMembersUrl(role, id)
  const serializedData = Serialize(members, MemberModel)
  artistSaveMembersStore.setIsLoading(true)

  return api
    .post(url, { members: serializedData })
    .then(() => {
      artistSaveMembersStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistSaveMembersStore.setIsLoading(false)
      artistSaveMembersStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function applyForm(): Promise<void> {
  artistFormApplyStore.setIsLoading(true)

  return api
    .post("/artist/form/apply")
    .then(() => {
      artistFormApplyStore.setIsLoading(false)
      artistDataStore.setFormStatus(ArtistFormStatus.MODERATION)
      artistFormNotificationsStore.addNotificationOnApply()
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormApplyStore.setIsLoading(false)
      artistFormApplyStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function setStatusEditingForm(): Promise<void> {
  artistFormSetEditStatusStore.setIsLoading(true)

  return api
    .post("/artist/form/edit")
    .then(() => {
      artistFormSetEditStatusStore.setIsLoading(false)
      artistDataStore.setFormStatus(ArtistFormStatus.EDITING)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormSetEditStatusStore.setIsLoading(false)
      artistFormSetEditStatusStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function moderateArtist(comment: string, id?: number): Promise<void> {
  artistFormModerateStore.setIsLoading(true)

  return api
    .post(`/admin/artists/${id}/reject`, { comment })
    .then(() => {
      artistFormModerateStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormModerateStore.setIsLoading(false)
      artistFormModerateStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function commentByAdmin(comment: string, id?: number): Promise<void> {
  artistFormModerateStore.setIsLoading(true)

  return api
    .post(`/admin/artists/${id}/comment`, { comment })
    .then(() => {
      artistFormModerateStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormModerateStore.setIsLoading(false)
      artistFormModerateStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function commentByArtist(comment: string): Promise<void> {
  artistFormModerateStore.setIsLoading(true)

  return api
    .post("/artist/comment", { comment })
    .then(() => {
      artistFormModerateStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormModerateStore.setIsLoading(false)
      artistFormModerateStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function approveArtist(id: number): Promise<void> {
  artistFormApproveStore.setIsLoading(true)

  return api
    .post(`/admin/artists/${id}/approve`)
    .then(() => {
      artistFormApproveStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormApproveStore.setIsLoading(false)
      artistFormApproveStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function checkForm(id: number): Promise<void> {
  artistFormReadStore.setIsLoading(true)

  return api
    .post(`/admin/artists/${id}/check`)
    .then(() => {
      artistFormReadStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistFormReadStore.setIsLoading(false)
      artistFormReadStore.setErrors(errorResponseData?.errors)
      throw error
    })
}
