import React, { FocusEvent } from "react"
import classNames from "classnames"
import MaskedInput from "react-text-mask"

import "./Input.style.scss"

const block = "input-component"

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  placeholder?: string;
  error?: string;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  mask:
    | Array<string | RegExp>
    | false
    | ((value: string) => Array<string | RegExp> | false);
  guide?: boolean;
  disabled?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  type?: string;
  isErrorStatic?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isLabelCentered?: boolean;
  shouldHideErrorMessage?: boolean;
  pipe?: (
    conformedValue: string,
    config: any
  ) =>
    | string
    | false
    | {
        value: string;
        indexesOfPipedChars: number[];
      };
}

const InputComponent = (props: Props): JSX.Element => {
  const {
    onChange,
    name,
    value,
    placeholder,
    error,
    onBlur,
    mask,
    guide,
    disabled,
    onFocus,
    type,
    isErrorStatic,
    icon,
    isLabelCentered,
    shouldHideErrorMessage,
    pipe
  } = props

  const maxSymbolLength = 255

  const hackyMask = !mask
    ? Array.from(Array(maxSymbolLength)).map(() => /./)
    : mask

  return (
    <div className={block}>
      <div
        className={classNames(
          `${block}__input-block`,
          disabled && `${block}__input-block--disabled`,
          error && `${block}__input-block--error`,
          icon && `${block}__input-block--with-icon`,
          isLabelCentered && `${block}__input-block--centered`
        )}
      >
        {icon && (
          <div className={`${block}__icon`}>{React.createElement(icon)}</div>
        )}
        <MaskedInput
          name={name}
          onChange={onChange}
          onBlur={e => onBlur && onBlur(e as React.FocusEvent<HTMLInputElement>)}
          onFocus={e => onFocus && onFocus(e as React.FocusEvent<HTMLInputElement>)}
          mask={hackyMask}
          guide={mask ? guide : false}
          disabled={disabled}
          placeholder={placeholder}
          value={value || ""}
          className={`${block}__input`}
          type={type || "text"}
          pipe={pipe}
        />
      </div>
      {error && !shouldHideErrorMessage && (
        <div
          className={classNames(
            `${block}__error`,
            isErrorStatic && `${block}__error--static`
          )}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export default InputComponent
