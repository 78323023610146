import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { createNumberMask } from "text-mask-addons"
import Button from "../../../common/Button/Button.component"
import InputComponent from "../../../common/Input/Input.component"

import "./EvaluationProjectDefenseForm.style.scss"
import { EvaluateProjectDefenseForm } from "../../../../models/FestExpertModels/Evaluation.model"
import Modal from "../Modal/Modal.component"

interface Props {
  error?: string;
  isLoading?: boolean;
  onSubmit: (data: EvaluateProjectDefenseForm) => void;
  isDisabled?: boolean;
  defaultValues?: EvaluateProjectDefenseForm;
}

const block = "evaluation-project-defense-form"

const EvaluationProjectDefenseFormComponent = (props: Props): JSX.Element => {
  const { error, isLoading, onSubmit, isDisabled, defaultValues } = props
  const {
    handleSubmit,
    errors,
    control,
    formState: { isDirty, isValid },
    setValue
  } = useForm<EvaluateProjectDefenseForm>({ defaultValues, mode: "onChange" })

  const [isOpenModal, setIsOpenModal] = React.useState(false)

  const getError = (): string | boolean => {
    const errorFieldType =
      errors.reality?.type ||
      errors.relevance?.type ||
      errors.realizability?.type ||
      errors.scale?.type

    if (errorFieldType === "inRange") {
      return "В некоторых полях оценка не вписывается в допустимый диапазон."
    }

    if (errorFieldType === "required") {
      return "Заполнены не все обязательные поля."
    }

    return false
  }

  useEffect(() => {
    setValue("reality", defaultValues?.reality)
    setValue("realizability", defaultValues?.realizability)
    setValue("relevance", defaultValues?.relevance)
    setValue("scale", defaultValues?.scale)
  }, [defaultValues])

  return (
    <div className={block}>
      <div className={`${block}__title`}>
        2. Очная защита проектов – презентации проекта
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Актуальность заявленного проекта для музыкального коллектива на
            данный момент
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="relevance"
              defaultValue={null}
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={"0"}
                  error={errors.relevance?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Логическая связность всех разделов проекта, уровень проработки
            заявки, реализуемость проекта
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="realizability"
              defaultValue={null}
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={"0"}
                  error={errors.realizability?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Реалистичность бюджета и календарного плана проекта, наличие
            партнеров и софинансирования
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="reality"
              defaultValue={null}
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={"0"}
                  error={errors.reality?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Масштабность и соответствие запланированных результатов разделам
            заявки
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="scale"
              defaultValue={null}
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    maxValue: 10,
                    minValue: 0
                  })}
                  placeholder={"0"}
                  error={errors.scale?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>

      {!isDisabled && (
        <div className={`${block}__submit`}>
          <Button
            onClick={() => setIsOpenModal(true)}
            text="Отправить"
            background="turquoise"
            isLoading={isLoading}
            isDisabled={isDisabled || !isDirty || !isValid}
          />
        </div>
      )}

      {!isValid && <div className={`${block}__error`}>{getError()}</div>}

      {!!error && <div className={`${block}__error`}>{error}</div>}

      <Modal
        onCloseModal={() => setIsOpenModal(false)}
        isOpenModal={isOpenModal}
        onSubmit={handleSubmit(onSubmit)}
      />
    </div>
  )
}

export default EvaluationProjectDefenseFormComponent
