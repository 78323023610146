import { ReactComponent as RadioIcon } from "../../../assets/icons/radio.svg"
import { ReactComponent as Disk } from "../../../assets/icons/disk.svg"
import { ReactComponent as Mic } from "../../../assets/icons/mic.svg"
import { ReactComponent as Piano } from "../../../assets/icons/piano.svg"
import { ReactComponent as Drums } from "../../../assets/icons/drums.svg"
import { ReactComponent as Guitar } from "../../../assets/icons/guitar.svg"
import { ReactComponent as Sax } from "../../../assets/icons/sax.svg"
import { ReactComponent as Metal } from "../../../assets/icons/metal.svg"

export interface GenreRadioButtonsData {
  value: string;
  caption: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const genreRadioButtonsData: GenreRadioButtonsData[] = [
  {
    value: "hiphop",
    caption: "Хип-Хоп",
    icon: RadioIcon
  },
  {
    value: "electro",
    caption: "Электроника",
    icon: Disk
  },
  {
    value: "pop",
    caption: "Поп",
    icon: Mic
  },
  {
    value: "classic",
    caption: "Классика",
    icon: Piano
  },
  {
    value: "folk",
    caption: "Фолк",
    icon: Drums
  },
  {
    value: "rock",
    caption: "Рок",
    icon: Guitar
  },
  {
    value: "jazz",
    caption: "Джаз",
    icon: Sax
  },
  {
    value: "metal",
    caption: "Метал",
    icon: Metal
  }
]
