/* eslint-disable no-console */
import React from "react"
import fontkit from "@pdf-lib/fontkit"
import Button from "../../../common/Button/Button.component"
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow-down.svg"
import { PDFDocument, rgb } from "pdf-lib"
import download from "downloadjs"
import { formates } from '../../../../const/fileFormates'

interface PropTypes {
  name: string;
}

const GenerateCertificate = (props: PropTypes): JSX.Element => {
  const { name } = props

  const getFontSize = () => {
    if (name.length > 24) {
      return 15
    } else {
      return 24
    }
  }

  const modifyPdf = async () => {
    const openSansUrls = {
      full: "https://storage.yandexcloud.net/prod.newopen/us-east-1/mus-tech_rider-ieKzVdbt.ttf"
    }

    const url = openSansUrls.full
    const openSansBytes = await fetch(url).then(res => res.arrayBuffer())

    const urlPdf =
      "https://storage.yandexcloud.net/prod.newopen/us-east-1/mus-presentation-D2GYpXkT.pdf"
    const existingPdfBytes = await fetch(urlPdf).then(res =>
      res.arrayBuffer()
    )

    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    pdfDoc.registerFontkit(fontkit)
    const openSansFont = await pdfDoc.embedFont(openSansBytes)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    const { width, height } = firstPage.getSize()

    firstPage.drawText(name, {
      x: width / 7,
      y: height / 2 + 60,
      size: getFontSize(),
      font: openSansFont,
      color: rgb(1, 1, 1)
    })

    const pdfBytes = await pdfDoc.save()

    download(pdfBytes, "NewOpenCertificate2024.pdf", formates.pdf)
  }

  return (
    <Button
      onClick={modifyPdf}
      background="transparent"
      text="Сертификат о участии"
      icon={<ArrowDown width="18.5" height="20" />}
    />
  )
}

export default GenerateCertificate
