import { autoserialize, autoserializeAs } from "cerialize"
import { ArtistFormStatus } from "../ArtistModels/artistForm.model"

export enum ArtistListCategory {
  NEW = 'new',
  REMODERATION = 'remoderation',
  APPROVED = 'approved',
  CHECKED = 'checked'
}

export class ListArtistAvatar {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize url: string;
  @autoserializeAs('url_s') urlSizeS?: string;
  @autoserializeAs('url_m') urlSizeM?: string;
  @autoserializeAs('url_l') urlSizeL?: string;
  
  constructor(id: number, name: string, url: string) {
    this.id = id
    this.name = name
    this.url = url
  }
}

export class ListArtistModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize photo: string | null;
  @autoserialize status: ArtistFormStatus;

  constructor(
    id: number,
    name: string,
    photo: string,
    status: ArtistFormStatus
  ) {
    this.id = id
    this.name = name
    this.photo = photo
    this.status = status
  }
}

export class ArtistListGetRequest {
  @autoserialize type: ArtistListCategory;
  @autoserialize limit?: number;
  @autoserialize offset?: number;
  @autoserialize search?: string;

  constructor(
    type: ArtistListCategory,
    limit?: number,
    offset?: number,
    search?: string
  ) {
    this.type = type
    this.limit = limit
    this.offset = offset
    this.search = search
  }
}

export class ArtistListGetResponse {
  @autoserializeAs(ListArtistModel) items: ListArtistModel[] | [];
  @autoserialize count: number;
  @autoserializeAs('total_count') totalCount: number;

  constructor(
    items: ListArtistModel[] | [],
    count: number,
    totalCount: number
  ) {
    this.items = items
    this.count = count
    this.totalCount = totalCount
  }
}
