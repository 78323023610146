import { action, observable } from "mobx"

import { ApiRequestStore } from "../common/apiRequest.store"
import {
  WinnerListGetResponse,
  WinnerListItemModel
} from "../../models/AdminModels/WinnerList.model"

class WinnerListStore extends ApiRequestStore {
  @observable winners?: WinnerListItemModel[];
  @observable totalCount?: number;
  @observable currentOffset?: number;

  @action
  setWinnersListData(data: WinnerListGetResponse): void {
    if (this.winners && data.items) {
      this.winners = [...this.winners, ...data.items]
    } else {
      this.winners = data.items
    }
    this.totalCount = data.totalCount
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  };

  @action
  clearData = (): void => {
    this.winners = undefined
    this.totalCount = undefined
    this.currentOffset = undefined
  };
}

export const winnerListStore: WinnerListStore = new WinnerListStore()
