import React, { useEffect, useState } from "react"

import { Dialog } from "@material-ui/core"
import "./Modal.style.scss"
import Button from "../../../common/Button/Button.component"

interface Props {
  onCloseModal: () => void;
  isOpenModal: boolean;
  onSubmit: () => void;
}
const block = "evaluation-modal"

const Modal = (props: Props): JSX.Element => {
  const { isOpenModal, onCloseModal, onSubmit } = props

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={isOpenModal}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
    >
      <div className={block}>
        <div className={`${block}__title`}>Точно отправляем?</div>
        <div className={`${block}__subtitle`}>
          После отправки оценку нельзя будет изменить.
        </div>
        <div className={`${block}__submit`}>
          <Button onClick={onSubmit} text="Отправить" background="yellow" />
        </div>
        <div className={`${block}__cancel`}>
          <Button onClick={onCloseModal} text="Отмена" background="text" />
        </div>
      </div>
    </Dialog>
  )
}

export default Modal
