/* eslint-disable no-console */
import React from "react"

import {
  Page,
  Text,
  View,
  Document,
  Font,
  Image,
  pdf,
  Link
} from "@react-pdf/renderer"
import { ReactComponent as ArrowDown } from "../../../../../assets/icons/arrow-down.svg"
import geometriaMedium from "../../../../../assets/fonts/Geometria-Medium.woff"
import geometriaRegular from "../../../../../assets/fonts/Geometria.woff"
import document from "../../../../../assets/images/pdf-renderer/doc.png"
import { saveAs } from "file-saver"
import { pdfFormStyles } from "./styles"

import Button from "../../../../common/Button/Button.component"
import { WinnerFormFields } from "../../../../../models/ArtistModels/winnerForm.model"

Font.register({ family: "GeometriaRegular", src: geometriaRegular })
Font.register({ family: "GeometriaMedium", src: geometriaMedium })

interface PropTypes {
  formData: WinnerFormFields
}

const isXsArea = (textLength: number) => textLength < 500
const isSmArea = (textLength: number) => textLength > 500 && textLength <= 1500
const isMdArea = (textLength: number) => textLength > 1500 && textLength <= 3000

const getTextareaSize = (textLength: number | undefined) => {
  if (!textLength) return pdfFormStyles.textareaEmpty

  if (isXsArea(textLength)) {
    return pdfFormStyles.textareaXs
  } else if (isSmArea(textLength)) {
    return pdfFormStyles.textareaSm
  } else if (isMdArea(textLength)) {
    return pdfFormStyles.textareaMd
  } else {
    return pdfFormStyles.textareaLg
  }
}

const getTextAreaFontSize = (textLength: number | undefined) => {
  if (!textLength) return pdfFormStyles.textareaEmpty

  if (textLength < 500) {
    return pdfFormStyles.textAreaFontSizeSm
  } else if (isSmArea(textLength)) {
    return pdfFormStyles.textAreaFontSizeSm
  } else if (isMdArea(textLength)) {
    return pdfFormStyles.textAreaFontSizeMd
  } else {
    return pdfFormStyles.textAreaFontSizeLg
  }
}

export default class DownloadReportingComponent extends React.PureComponent<PropTypes> {
  private _isMounted = false

  componentDidMount(): void {
    this._isMounted = true
  }

  componentWillUnmount(): void {
    this._isMounted = false
  }

  // hack to prevent lag on props change, that fires rerender and document recreation
  downLoadPdf = async (): Promise<void> => {
    const blob = await pdf(this.createDocument()).toBlob()
    saveAs(blob, `Отчетность.pdf`)
  }

  createDocument = (): JSX.Element => {
    const {
      projectLeaderName,
      projectName,
      contractNumber,
      contractDate,
      projectStart,
      projectEnd,
      checkpointCompletionDetails,
      pastEventOverview,
      projectQuantitativeQualitativeResults,
      projectInMediaUrls,
      mediaFilesUrl,
      electronicMaterialsVersions,
      artistProjectContribution,
      detectedDefects,
      generalProjectResults,
      primaryReportingDocuments,
      financialStatementForm,
      consentProvideData
    } = this.props.formData

    const styles = pdfFormStyles

    return (
      <Document
        onRender={(): void => {
          if (this._isMounted) {
            this.setState({ isLoading: false })
          }
        }}
      >
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
          <Text style={styles.title}>Отчётность</Text>
            <View style={styles.formBlock}>
              <View wrap={false} style={[styles.inputBlock, styles.noMargin]}>
                <Text style={styles.inputTitle}>
                  * ФИО руководителя проекта
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{projectLeaderName}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Название коллектива/имя музыканта (для сольных проектов)
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{projectName}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>* Наименование проекта</Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{projectName}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Номер Договора предоставления гранта проекта
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{contractNumber}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Дата заключения Договора предоставления гранта
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{contractDate}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.row}>
                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>
                    * Дата начала реализации проекта
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>{projectStart}</Text>
                  </View>
                </View>

                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>
                    * Дата окончания реализации
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>{projectEnd}</Text>
                  </View>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Сведения о выполнении ключевых контрольных точек и описание
                  содержания деятельности
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(
                      checkpointCompletionDetails?.replace(
                        /\r?\n|\r/g,
                        "; "
                      )?.length
                    )
                  ]}
                >
                  <Text
                    style={[
                      getTextAreaFontSize(
                        checkpointCompletionDetails?.replace(
                          /\r?\n|\r/g,
                          "; "
                        )?.length
                      )
                    ]}
                  >
                    {checkpointCompletionDetails?.replace(
                      /\r?\n|\r/g,
                      "; "
                    )}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Проведенные мероприятия с указанием сроков реализации
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(
                      pastEventOverview?.replace(/\r?\n|\r/g, "; ")?.length
                    )
                  ]}
                >
                  <Text
                    style={[
                      getTextAreaFontSize(
                        pastEventOverview?.replace(/\r?\n|\r/g, "; ")
                          ?.length
                      )
                    ]}
                  >
                    {pastEventOverview?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Количественные и качественные результаты проекта, социальный
                  и творческий эффект
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(
                      projectQuantitativeQualitativeResults?.replace(
                        /\r?\n|\r/g,
                        "; "
                      )?.length
                    )
                  ]}
                >
                  <Text
                    style={[
                      getTextAreaFontSize(
                        projectQuantitativeQualitativeResults?.replace(
                          /\r?\n|\r/g,
                          "; "
                        )?.length
                      )
                    ]}
                  >
                    {projectQuantitativeQualitativeResults?.replace(
                      /\r?\n|\r/g,
                      "; "
                    )}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  Упоминания проекта в СМИ, соцсетях
                </Text>
                {projectInMediaUrls?.map((item, index) => (
                  <View style={styles.input} key={`${item}-${index}`}>
                    <Link src={item || ""} style={styles.techRiderText}>
                      {item || ""}
                    </Link>
                  </View>
                ))}
              </View>

              <View wrap={false} style={[styles.inputBlock]}>
                <Text style={styles.inputTitle}>
                  * Фотографии, видео- и аудиозаписи с мероприятий
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{mediaFilesUrl}</Text>
                </View>
              </View>

              <View wrap={false} style={[styles.inputBlock]}>
                <Text style={styles.inputTitle}>
                  * Электронные версии материалов, созданных с использованием
                  гранта
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>
                    {electronicMaterialsVersions}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Информация о собственном вкладе грантополучателя и
                  использованном на реализацию проекта за отчетный период
                  софинансирования
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(
                      artistProjectContribution?.replace(/\r?\n|\r/g, "; ")
                        ?.length
                    )
                  ]}
                >
                  <Text
                    style={[
                      getTextAreaFontSize(
                        artistProjectContribution?.replace(
                          /\r?\n|\r/g,
                          "; "
                        )?.length
                      )
                    ]}
                  >
                    {artistProjectContribution?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Недостатки, выявленные в ходе реализации проекта
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(
                      detectedDefects?.replace(/\r?\n|\r/g, "; ")?.length
                    )
                  ]}
                >
                  <Text
                    style={[
                      getTextAreaFontSize(
                        detectedDefects?.replace(/\r?\n|\r/g, "; ")?.length
                      )
                    ]}
                  >
                    {detectedDefects?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Общие выводы по результатам реализации проекта
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(
                      generalProjectResults?.replace(/\r?\n|\r/g, "; ")
                        ?.length
                    )
                  ]}
                >
                  <Text
                    style={[
                      getTextAreaFontSize(
                        generalProjectResults?.replace(/\r?\n|\r/g, "; ")
                          ?.length
                      )
                    ]}
                  >
                    {generalProjectResults?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Форма финансового отчета для заполнения
                </Text>
                <View style={styles.techRider}>
                  <Image style={styles.techRiderImage} source={document} />
                  <Link
                    src={financialStatementForm?.url || ""}
                    style={styles.techRiderText}
                  >
                    {financialStatementForm?.originalName}
                  </Link>
                </View>
              </View>

              <View wrap={false} style={[styles.inputBlock]}>
                <Text style={styles.inputTitle}>
                  * Копии первичных отчетных документов
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>
                    {primaryReportingDocuments}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Согласие о предоставлении достоверных данных в отчете
                </Text>
                <View style={styles.techRider}>
                  <Image style={styles.techRiderImage} source={document} />
                  <Link
                    src={consentProvideData?.url || ""}
                    style={styles.techRiderText}
                  >
                    {consentProvideData?.originalName}
                  </Link>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  render(): JSX.Element {
    return (
      <Button
        onClick={this.downLoadPdf}
        background="transparent"
        text="Скачать Отчет"
        icon={<ArrowDown width="18.5" height="20" />}
      />
    )
  }
}
