import React from 'react'
import AdminCRMLayout from '../../components/CRM/AdminCRM/AdminCRMLayout/AdminCRMLayout.component'
import ArtistListContainer from '../../container/CRM/AdminCRM/ArtistList.container'

const ArtistsPage = (): JSX.Element => (
    <AdminCRMLayout>
      <ArtistListContainer />
    </AdminCRMLayout>
  )

export default ArtistsPage