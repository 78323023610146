import React from "react"
import LazyLoad from "react-lazyload"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import SearchInputContainer from "../../../../container/CRM/AdminCRM/SearchInput.container"
import {
  FinalistsListArtistType,
  FinalistsListItemModel
} from "../../../../models/AdminModels/FinalistsList.model"
import LoaderComponent from "../../../common/Loader/Loader.component"
import LazyLoadComponent from "../../common/LazyLoad/LazyLoad.component"
import CRMListItemComponent from "../CrmListItem/CrmListItem.component"

interface Props {
  tabIndex: number;
  isLoading: boolean;
  finalists?: FinalistsListItemModel[];
  onSelect: (tabIndex: number) => void;
  onSearchChange: (value: string) => void;
  onLoadMore: () => void;
  onArtistClick: (id: number) => void;
  finalistsCount?: number;
  shouldLoadMore: boolean;
}

const block = "finalists-list"

const tabNames: { [key in FinalistsListArtistType]: string } = {
  unprocessed: "Необработанные",
  confirmed: "Приедут",
  unconfirmed: "Не приедут"
}

const FinalistsListComponent = (props: Props): JSX.Element => {
  const {
    tabIndex,
    isLoading,
    finalists,
    onSelect,
    onSearchChange,
    shouldLoadMore,
    finalistsCount,
    onLoadMore,
    onArtistClick
  } = props

  const onLoadMoreHandle = (): void => {
    !isLoading && onLoadMore()
  }

  return (
    <div className={`${block} admin-layout-content`}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex: number): void => onSelect(tabIndex)}
      >
        <div className="admin-layout-content__header">
          <div className="page-title page-title--black admin-layout-content__title">
            ФИНАЛИСТЫ
          </div>
          <TabList>
            {Object.keys(tabNames).map((category, index) => (
              <Tab key={category}>
                {tabIndex === index && (
                  <div className="admin-layout-content__counter">
                    {finalistsCount || 0}
                  </div>
                )}
                {tabNames[category as FinalistsListArtistType]}
              </Tab>
            ))}
          </TabList>

          <div className="admin-layout-content__controls">
            <SearchInputContainer onSearchChange={onSearchChange} />
          </div>
        </div>

        {isLoading && !finalists?.length && (
          <div className="admin-layout-content__loading">
            <LoaderComponent width={100} height={100} />
          </div>
        )}

        <div className="admin-layout-content__body">
          {Object.keys(tabNames).map(category => (
            <TabPanel key={category}>
              {finalists && finalists.length ? (
                <>
                  {finalists.map((finalist, index) => (
                    <CRMListItemComponent
                      key={finalist.artist.id}
                      title={finalist.artist.name}
                      image={finalist.artist.photo || undefined}
                      onClick={(): void => onArtistClick(finalist.artist.id)}
                      rating={finalist.valuation}
                      number={index + 1}
                      isBgColored={index < 100}
                    />
                  ))}
                  {shouldLoadMore && (
                    <LazyLoad height={10} unmountIfInvisible={true}>
                      <LazyLoadComponent
                        isLoading={isLoading}
                        onMount={onLoadMoreHandle}
                      />
                    </LazyLoad>
                  )}
                </>
              ) : (
                !isLoading && (
                  <div className="admin-layout-content__empty">Список пуст</div>
                )
              )}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  )
}

export default FinalistsListComponent
