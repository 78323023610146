import { observer } from "mobx-react"
import React, { useContext } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { UserRole } from "../../../const/permissionsRules.const"
import {
  PATH_ARTIST_EVALUATION,
  PATH_ARTIST_NOTIFICATIONS,
  PATH_AUTH,
  PATH_ARTIST_REQUEST_READ
} from "../../../const/routes.const"
import { authContext } from "../../../context/auth.context"
import WinnerCRMHeaderComponent from "../../../components/CRM/WinnerCRMHeader/WinnerCRMHeader.component"
import { WinnerFormStatus } from "../../../models/ArtistModels/winnerForm.model"
import { winnerDataStore } from "../../../store/ArtistStore/winnerData.store"
import {
  winnerFormGetStore,
  winnerFormSaveStore,
  winnerFormApplyStore
} from "../../../store/ArtistStore/winnerForm.store"
import { tabNames } from "../../../components/CRM/ArtistCRM/WinnerForm/WinnerForm.component"

const headerData = {
  title: "ОТЧЁТ О РЕАЛИЗУЕМОМ ПРОЕКТЕ"
}

const WinnerCRMHeaderContainer = (): JSX.Element => {
  const { user } = useContext(authContext)
  const stage = useRouteMatch<{ stage: string }>().params.stage

  const onSave = (): void => {
    const indexForm = Object.keys(tabNames).indexOf(stage) || 0

    const loading =
      winnerFormSaveStore.isLoading || winnerFormApplyStore.isLoading
    const form = document.getElementById(`winner-form-${indexForm}`)
    form &&
      !loading &&
      form.dispatchEvent(new Event("submit", { cancelable: true }))
  }

  return (
    <WinnerCRMHeaderComponent
      {...headerData}
      stage={stage}
      onSave={
        winnerDataStore.formStatus === WinnerFormStatus.EDITING &&
        user.role === UserRole.ARTIST
          ? onSave
          : undefined
      }
      formStatus={
        winnerFormGetStore.formData?.meta?.status || WinnerFormStatus.EDITING
      }
      saveTime={
        winnerDataStore.formStatus === WinnerFormStatus.EDITING
          ? winnerFormSaveStore.saveTime
          : undefined
      }
    />
  )
}

export default observer(WinnerCRMHeaderContainer)
