/* eslint-disable */
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import EvaluationInfoComponent from '../../../components/CRM/FestExpertCRM/EvaluationInfo/EvaluationInfo.component'
import { PATH_FINALISTS } from '../../../const/routes.const'
import { RequestErrorResponse } from '../../../models/common/error.model'
import { getArtistInfo } from '../../../services/festExpert/festExpertArtist.service'
import { artistInfoGetStore } from '../../../store/FestExpertStore/festExpertArtistInfo.store'
import { FestExpertEvaluationArtistModel } from '../../../models/FestExpertModels/ArtistInfo.model'

const FestExpertArtistInfoContainer = (): JSX.Element => {
  const [artistInfo, setArtistInfo] =
    useState<FestExpertEvaluationArtistModel>()
  const [isLoading, setIsLoading] = useState<boolean>()

  const history = useHistory()
  const artistId = parseInt(
    useRouteMatch<{ artistId: string }>().params.artistId
  )

  const onEffect = (): (() => void) => {
    if (isNaN(artistId)) {
      history.replace(PATH_FINALISTS)
    }

    getArtistInfo(artistId).catch((errorResponseData: RequestErrorResponse) => {
      const noArtistError = errorResponseData?.errors.find(
        (error) => error.code === 'notfound'
      )

      if (!!noArtistError) {
        history.replace(PATH_FINALISTS)
      }
    })

    return (): void => {
      artistInfoGetStore.clearInfo()
    }
  }

  useEffect(onEffect, [])

  useEffect(() => {
    setArtistInfo(artistInfoGetStore.getArtistInfo())
    setIsLoading(artistInfoGetStore.getIsLoading())
  }, [artistInfoGetStore.getIsLoading()])

  return <EvaluationInfoComponent artist={artistInfo} isLoading={isLoading} />
}

export default observer(FestExpertArtistInfoContainer)
