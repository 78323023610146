import { autoserialize, autoserializeAs } from "cerialize"
import { ArtistFormStatus } from "./artistForm.model"

export enum NotificationsType {
  SentToModeration = "SENT_TO_MODERATION",
  NeedEditing = "NEED_EDITING",
  Approved = "APPROVED",
  SentToRemoderation = "SENT_TO_REMODERATION",
  Rated = "RATED",
  NeedEditingReport = "NEED_EDITING_REPORT",
  ApprovedIntermediateReport = "APPROVED_INTERMEDIATE_REPORT",
  ApprovedFinalReport = "APPROVED_FINAL_REPORT",
}

export class NotificationFormInfo {
  @autoserialize id: number;
  @autoserialize status: ArtistFormStatus;
  @autoserializeAs("updated_at") updatedAt: string | null;
  @autoserializeAs("applied_at") appliedAt: string | null;
  @autoserializeAs("rejected_at") rejectedAt: string | null;
  @autoserializeAs("approved_at") approvedAt: string | null;
  @autoserializeAs("checked_at") checkedAt: string | null;

  constructor(
    id: number,
    status: ArtistFormStatus,
    updatedAt: string | null,
    appliedAt: string | null,
    rejectedAt: string | null,
    approvedAt: string | null,
    checkedAt: string | null
  ) {
    this.id = id
    this.status = status
    this.updatedAt = updatedAt
    this.appliedAt = appliedAt
    this.rejectedAt = rejectedAt
    this.approvedAt = approvedAt
    this.checkedAt = checkedAt
  }
}

export class FormNotification {
  @autoserialize id?: number;
  @autoserialize type: NotificationsType;
  @autoserialize text: string;
  @autoserializeAs("created_at") createdAt: string;

  constructor(
    id: number,
    type: NotificationsType,
    text: string,
    createdAt: string
  ) {
    this.id = id
    this.type = type
    this.text = text
    this.createdAt = createdAt
  }
}

export class ArtistFormNotificationsResponse {
  @autoserializeAs(NotificationFormInfo) form: NotificationFormInfo;
  @autoserializeAs(FormNotification) notifications: FormNotification[];

  constructor(form: NotificationFormInfo, notifications: FormNotification[]) {
    this.form = form
    this.notifications = notifications
  }
}
