import { action, observable } from "mobx"
import { ApiRequestStore } from "../common/apiRequest.store"
import { WinnerFormGetResponse } from "../../models/ArtistModels/winnerForm.model"

class WinnerFormSaveStore extends ApiRequestStore {
  @observable saveTime?: Date;

  @action
  setSaveTime(date: Date): void {
    this.saveTime = date
  }
}

class WinnerFormGetStore extends ApiRequestStore {
  @observable formData?: WinnerFormGetResponse;

  @action
  setFormData(data: WinnerFormGetResponse): void {
    this.formData = data
  }

  @action
  clearData = (): void => {
    this.formData = undefined
  };
}


class WinnerFormApplyStore extends ApiRequestStore {}

class WinnerFormModerateStore extends ApiRequestStore {}

class WinnerFormReadStore extends ApiRequestStore {}

export const winnerFormSaveStore: WinnerFormSaveStore =
  new WinnerFormSaveStore()

export const winnerFormGetStore: WinnerFormGetStore = new WinnerFormGetStore()

export const winnerFormApplyStore: WinnerFormApplyStore =
  new WinnerFormApplyStore()

export const winnerFormModerateStore: WinnerFormModerateStore =
  new WinnerFormModerateStore()

export const winnerFormReadStore: WinnerFormReadStore =
  new WinnerFormReadStore()
