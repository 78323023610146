import React from "react"

import "./IconWithText.style.scss"

interface PropTypes {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
}

const block = "icon-with-text"

export default class IconWithTextComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { icon, text } = this.props

    return (
      <div className={block}>
        <div className={`${block}__icon`}>{React.createElement(icon)}</div>
        <div className={`${block}__text`}>{text}</div>
      </div>
    )
  }
}
