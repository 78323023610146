import { autoserialize, autoserializeAs } from "cerialize"

export enum WinnerListType {
  New = "new",
  Checked = "checked",
}

export enum WinnerFormStatus {
  NEW = "NEW",
  EDITING = "EDITING",
  MODERATION = "MODERATION",
  CHECKED = "CHECKED",
}

export class WinnerListGetRequest {
  @autoserialize type: WinnerListType;
  @autoserialize limit?: number;
  @autoserialize offset?: number;
  @autoserialize search?: string;

  constructor(
    type: WinnerListType,
    limit?: number,
    offset?: number,
    search?: string
  ) {
    this.type = type
    this.limit = limit
    this.offset = offset
    this.search = search
  }
}

export class WinnerListItemModel {
  @autoserialize name: string;
  @autoserialize photo: string | null;
  @autoserializeAs("status_stage_1") statusStage1: string;
  @autoserializeAs("status_stage_2") statusStage2: string;
  @autoserializeAs("artist_id") artistId: number;

  constructor(
    name: string,
    photo: string,
    artistId: number,
    statusStage1: string,
    statusStage2: string
  ) {
    this.name = name
    this.photo = photo
    this.artistId = artistId
    this.statusStage1 = statusStage1
    this.statusStage2 = statusStage2
  }
}

export class WinnerListGetResponse {
  @autoserializeAs("total_count") totalCount: number;
  @autoserializeAs(WinnerListItemModel) items: WinnerListItemModel[];

  constructor(items: WinnerListItemModel[], totalCount: number) {
    this.items = items
    this.totalCount = totalCount
  }
}
