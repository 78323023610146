/* eslint-disable no-console */
import React from "react"

import {
  Page,
  Text,
  View,
  Document,
  Font,
  Image,
  pdf,
  Link,
  StyleSheet
} from "@react-pdf/renderer"
import { ReactComponent as ArrowDown } from "../../../../../assets/icons/arrow-down.svg"
import geometriaMedium from "../../../../../assets/fonts/Geometria-Medium.woff"
import geometriaRegular from "../../../../../assets/fonts/Geometria.woff"
import hiphop from "../../../../../assets/images/pdf-renderer/hip-hop.png"
import hiphopActive from "../../../../../assets/images/pdf-renderer/hip-hop-active.png"
import electro from "../../../../../assets/images/pdf-renderer/electro.png"
import electroActive from "../../../../../assets/images/pdf-renderer/electro-active.png"
import pop from "../../../../../assets/images/pdf-renderer/pop.png"
import popActive from "../../../../../assets/images/pdf-renderer/pop-active.png"
import classic from "../../../../../assets/images/pdf-renderer/classic.png"
import classicActive from "../../../../../assets/images/pdf-renderer/classic-active.png"
import folk from "../../../../../assets/images/pdf-renderer/folk.png"
import folkActive from "../../../../../assets/images/pdf-renderer/folk-active.png"
import rock from "../../../../../assets/images/pdf-renderer/rock.png"
import rockActive from "../../../../../assets/images/pdf-renderer/rock-active.png"
import jazz from "../../../../../assets/images/pdf-renderer/jazz.png"
import jazzActive from "../../../../../assets/images/pdf-renderer/jazz-active.png"
import metal from "../../../../../assets/images/pdf-renderer/metal.png"
import metalActive from "../../../../../assets/images/pdf-renderer/metal-active.png"
import facebook from "../../../../../assets/images/pdf-renderer/facebook.png"
import instagram from "../../../../../assets/images/pdf-renderer/instagram.png"
import vkontakte from "../../../../../assets/images/pdf-renderer/vk.png"
import tiktok from "../../../../../assets/images/pdf-renderer/tiktok.png"
import youtube from "../../../../../assets/images/pdf-renderer/youtube.png"
import twitter from "../../../../../assets/images/pdf-renderer/twitter.png"
import document from "../../../../../assets/images/pdf-renderer/doc.png"
import { saveAs } from "file-saver"
import { pdfFormStyles } from "./styles"
import {
  ArtistFormFields,
  BudgetRowModel
} from "../../../../../models/ArtistModels/artistForm.model"
import Button from "../../../../common/Button/Button.component"

Font.register({ family: "GeometriaRegular", src: geometriaRegular })
Font.register({ family: "GeometriaMedium", src: geometriaMedium })

interface PropTypes {
  formData: ArtistFormFields;
}

const makeBreaks = (input: string, maxLength: number): string => {
  const regex = new RegExp(`.{1,${maxLength}}`, "g")
  const splittedString = input.match(regex) || [""]

  const stringWithBreaks = splittedString?.map(
    (partOfString: string): string => `${partOfString}\u000a`
  )

  return stringWithBreaks.join("")
}

const genreData = [
  {
    value: "hiphop",
    caption: "Хип-Хоп",
    icon: hiphop,
    activeIcon: hiphopActive
  },
  {
    value: "electro",
    caption: "Электроника",
    icon: electro,
    activeIcon: electroActive
  },
  {
    value: "pop",
    caption: "Поп",
    icon: pop,
    activeIcon: popActive
  },
  {
    value: "classic",
    caption: "Классика",
    icon: classic,
    activeIcon: classicActive
  },
  {
    value: "folk",
    caption: "Фолк",
    icon: folk,
    activeIcon: folkActive
  },
  {
    value: "rock",
    caption: "Рок",
    icon: rock,
    activeIcon: rockActive
  },
  {
    value: "jazz",
    caption: "Джаз",
    icon: jazz,
    activeIcon: jazzActive
  },
  {
    value: "metal",
    caption: "Метал",
    icon: metal,
    activeIcon: metalActive
  }
]

const Headerstyles = StyleSheet.create({
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid black",
    backgroundColor: "#67DBCA",
    color: "#000000",
    fontWeight: "bold"
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid black"
  },
  rowNumber: {
    textAlign: "left",
    borderRight: "1px solid black",
    fontSize: 4,
    width: "20px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  rowName: {
    textAlign: "left",
    borderRight: "1px solid black",
    fontSize: 4,
    width: "120px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  rowCostPerItem: {
    textAlign: "left",
    borderRight: "1px solid black",
    fontSize: 4,
    width: "70px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  rowCount: {
    textAlign: "left",
    borderRight: "1px solid black",
    fontSize: 4,
    width: "50px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  rowPrice: {
    textAlign: "left",
    borderRight: "1px solid black",
    fontSize: 4,
    width: "80px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  rowAskedPrice: {
    textAlign: "left",
    fontSize: 4,
    width: "120px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  footerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px"
  },
  footerTotalText: {
    marginLeft: "200px",
    textAlign: "left",
    fontSize: 8,
    width: "50px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  footerRowPrice: {
    textAlign: "left",
    fontSize: 8,
    width: "80px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  },
  footerRowAskedPrice: {
    textAlign: "left",
    fontSize: 8,
    width: "120px",
    letterSpacing: 1,
    textTransform: "uppercase",
    paddingLeft: "4px",
    paddingTop: "5px",
    height: "100%"
  }
})

const getFullPriceBudget = (item: BudgetRowModel) =>
  Number(item?.price) * Number(item?.count)

const getRequestedBudget = (item: BudgetRowModel) => {
  const result =
    getFullPriceBudget(item) - Number(item?.currentBudget) > 0
      ? getFullPriceBudget(item) - Number(item?.currentBudget)
      : 0
  return result
}

const getFullPriceBudgetTotal = (items: BudgetRowModel[]) => {
  const result = items?.reduce(
    (acc, curValue) => acc + getFullPriceBudget(curValue),
    0
  )

  return result
}

const getTextareaSize = (textLength: number | undefined) => {
  if (!textLength) return pdfFormStyles.textareaEmpty
  if (textLength < 500) {
    return pdfFormStyles.textareaXs
  } else if (textLength > 500 && textLength <= 1500) {
    return pdfFormStyles.textareaSm
  } else if (textLength > 1500 && textLength <= 3000) {
    return pdfFormStyles.textareaMd
  } else {
    return pdfFormStyles.textareaLg
  }
}

const getTextAreaFontSize = (textLength: number | undefined) => {
  if (!textLength) return pdfFormStyles.textareaEmpty
  if (textLength < 500) {
    return pdfFormStyles.textAreaFontSizeSm
  } else if (textLength > 500 && textLength <= 1500) {
    return pdfFormStyles.textAreaFontSizeSm
  } else if (textLength > 1500 && textLength <= 3000) {
    return pdfFormStyles.textAreaFontSizeMd
  } else {
    return pdfFormStyles.textAreaFontSizeLg
  }
}

const getRequestedBudgetTotal = (items: BudgetRowModel[]) =>
  items?.reduce((acc, curValue) => acc + getRequestedBudget(curValue), 0)

const getCurrentBudgetTotal = (items: BudgetRowModel[]) =>
  items?.reduce((acc, curValue) => acc + Number(curValue?.currentBudget), 0)

const TableHeader = () => (
  <View style={Headerstyles.tableHeader}>
    <Text style={Headerstyles.rowNumber}>№</Text>
    <Text style={Headerstyles.rowName}>Наименование</Text>
    <Text style={Headerstyles.rowCostPerItem}>Цена (за 1 шт.)</Text>
    <Text style={Headerstyles.rowCount}>Кол-во</Text>
    <Text style={Headerstyles.rowPrice}>Стоимость</Text>
    <Text style={Headerstyles.rowPrice}>Cофинансирование</Text>
    <Text style={Headerstyles.rowAskedPrice}>Запрашиваемый бюджет</Text>
  </View>
)
const TableRows = (props: { data: BudgetRowModel[] }) => {
  const { data } = props
  return (
    <>
      {data.map((item: BudgetRowModel, index: number) => (
        <View style={Headerstyles.tableRow} key={`${item.name}_${index}`}>
          <Text style={Headerstyles.rowNumber}>{++index}</Text>
          <Text style={Headerstyles.rowName}>{item.name}</Text>
          <Text style={Headerstyles.rowCostPerItem}>{item.price}</Text>
          <Text style={Headerstyles.rowCount}>{item.count}</Text>
          <Text style={Headerstyles.rowPrice}>
            {getFullPriceBudget(item) || 0}
          </Text>
          <Text style={Headerstyles.rowPrice}>{item.currentBudget}</Text>
          <Text style={Headerstyles.rowAskedPrice}>
            {getRequestedBudget(item)}
          </Text>
        </View>
      ))}
    </>
  )
}

const TableFooter = (props: { data: BudgetRowModel[] }) => {
  const { data } = props
  return (
    <>
      <View style={Headerstyles.footerRow}>
        <Text style={Headerstyles.footerTotalText}>Итого:</Text>
        <Text style={Headerstyles.footerRowPrice}>
          {`${getFullPriceBudgetTotal(data) || 0} ₽`}
        </Text>
        <Text style={Headerstyles.footerRowPrice}>
          {`${getCurrentBudgetTotal(data) || 0} ₽`}
        </Text>
        <Text style={Headerstyles.footerRowAskedPrice}>
          {`${getRequestedBudgetTotal(data) || 0} ₽`}
        </Text>
      </View>
    </>
  )
}

const Table = (props: { data: BudgetRowModel[] }) => {
  const { data } = props
  return (
    <View>
      <TableHeader />
      <TableRows data={data} />
      <TableFooter data={data} />
    </View>
  )
}

export default class DownloadFormComponent extends React.PureComponent<PropTypes> {
  private _isMounted = false;

  componentDidMount(): void {
    this._isMounted = true
  }

  componentWillUnmount(): void {
    this._isMounted = false
  }

  // hack to prevent lag on props change, that fires rerender and document recreation
  downLoadPdf = async (): Promise<void> => {
    const blob = await pdf(this.createDocument()).toBlob()
    saveAs(blob, `${this.props.formData.name}.pdf`)
  };

  createDocument = (): JSX.Element => {
    const data = this.props.formData
    const styles = pdfFormStyles

    return (
      <Document
        onRender={(): void => {
          if (this._isMounted) {
            this.setState({ isLoading: false })
          }
        }}
      >
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>1. ОСНОВНАЯ ИНФОРМАЦИЯ</Text>
            <View style={styles.formBlock}>
              <View wrap={false} style={[styles.inputBlock, styles.noMargin]}>
                <Text style={styles.inputTitle}>
                  * Название коллектива/имя музыканта (для сольных проектов)
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{data.name}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>* Музыкальный жанр</Text>
                <View style={styles.row}>
                  {genreData.map((genre, index: number) => (
                    <View style={styles.genre} key={`pdf-genre-${index}`}>
                      <View style={styles.genreIcon}>
                        <Image
                          source={
                            data.genre === genre.value
                              ? genre.activeIcon
                              : genre.icon
                          }
                        />
                      </View>
                      <View style={styles.genreCaptionContainer}>
                        <Text style={styles.genreCaption}>{genre.caption}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>

              <View wrap={false} style={styles.row}>
                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>* Страна</Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>{data.country}</Text>
                  </View>
                </View>

                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>* Город</Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>{data.city}</Text>
                  </View>
                </View>
              </View>

              <View wrap={false} style={styles.row}>
                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>* Email</Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>
                      {makeBreaks(data.contactEmail || "", 29)}
                    </Text>
                  </View>
                </View>

                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>* Телефон</Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>{`${
                      data.contactPhone ? data.contactPhone : ""
                    }`}</Text>
                  </View>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>* Контактное лицо</Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{data.contactName}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Описание музыкального коллектива
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.description?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text style={[getTextAreaFontSize(data.description?.replace(/\r?\n|\r/g, "; ")?.length)]}>
                    {data.description?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Музыкальный проект в социальных сетях
                </Text>
                <View style={styles.row}>
                  <View
                    style={[
                      styles.input,
                      styles.rowInput,
                      styles.inputWithImage
                    ]}
                  >
                    <View style={styles.inputImage}>
                      <Image source={facebook} />
                    </View>
                    <Link
                      src={data.facebookUrl || ""}
                      style={styles.techRiderText}
                    >
                      {makeBreaks(data.facebookUrl || "", 40)}
                    </Link>
                  </View>
                  <View
                    style={[
                      styles.input,
                      styles.rowInput,
                      styles.inputWithImage
                    ]}
                  >
                    <View style={styles.inputImage}>
                      <Image source={vkontakte} />
                    </View>
                    <Link
                      src={data.facebookUrl || ""}
                      style={styles.techRiderText}
                    >
                      {makeBreaks(data.vkontakteUrl || "", 40)}
                    </Link>
                  </View>
                </View>
                <View style={styles.row}>
                  <View
                    style={[
                      styles.input,
                      styles.rowInput,
                      styles.inputWithImage
                    ]}
                  >
                    <View style={styles.inputImage}>
                      <Image source={instagram} />
                    </View>
                    <Link
                      src={data.instagramUrl || ""}
                      style={styles.techRiderText}
                    >
                      {makeBreaks(data.instagramUrl || "", 40)}
                    </Link>
                    <Text style={styles.inputText}></Text>
                  </View>
                  <View
                    style={[
                      styles.input,
                      styles.rowInput,
                      styles.inputWithImage
                    ]}
                  >
                    <View style={styles.inputImage}>
                      <Image source={tiktok} />
                    </View>
                    <Link
                      src={data.tiktokUrl || ""}
                      style={styles.techRiderText}
                    >
                      {makeBreaks(data.tiktokUrl || "", 40)}
                    </Link>
                    <Text style={styles.inputText}></Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View
                    style={[
                      styles.input,
                      styles.rowInput,
                      styles.inputWithImage
                    ]}
                  >
                    <View style={styles.inputImage}>
                      <Image source={youtube} />
                    </View>
                    <Link
                      src={data.youtubeUrl || ""}
                      style={styles.techRiderText}
                    >
                      {makeBreaks(data.youtubeUrl || "", 40)}
                    </Link>
                  </View>
                  <View
                    style={[
                      styles.input,
                      styles.rowInput,
                      styles.inputWithImage
                    ]}
                  >
                    <View style={styles.inputImage}>
                      <Image source={twitter} />
                    </View>
                    <Link
                      src={data.twitterUrl || ""}
                      style={styles.techRiderText}
                    >
                      {makeBreaks(data.twitterUrl || "", 40)}
                    </Link>
                  </View>
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Прикрепите ссылки на конкретные треки, которые услышат
                    эксперты
                  </Text>
                  {data.musicLinks?.map((item, index) => (
                    <View style={styles.input} key={`${item}-${index}`}>
                      <Link src={item || ""} style={styles.techRiderText}>
                        {item || ""}
                      </Link>
                    </View>
                  ))}
                </View>

                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                    * Код плейлиста с вашей музыкой
                  </Text>

                  <View style={[styles.input, styles.iFrameArea]}>
                    <Text style={styles.inputText}>
                      {data.playlistCode || ""}
                    </Text>
                  </View>
                </View>
                <View wrap={false} style={styles.inputBlock}>
                  <Text style={styles.inputTitle}>
                  Вы можете выиграть специальный приз фестиваля от наших партнеров — Клипов ВКонтакте. 
                  Выложите вертикальный клип с хэштегом #newopen2024 и 
                  прикрепите ссылку на видео в этом разделе заявки, 
                  чтобы принять участие в конкурсе.
                  </Text>
                  <View style={styles.input}>
                    <Link
                      src={data.vkClipsUrl || ""}
                      style={styles.techRiderText}
                    >
                      {data.vkClipsUrl || ""}
                    </Link>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>2. ИНФОРМАЦИЯ О ПРОЕКТЕ</Text>
            <View style={styles.formBlock}>
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Название проекта, на реализацию которого запрашивается грант
                </Text>
                <View style={styles.input}>
                  <Text style={styles.inputText}>{data.projectName}</Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Краткое описание проекта
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.projectDescription?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text
                    style={[
                      getTextAreaFontSize(data.projectDescription?.replace(/\r?\n|\r/g, "; ")?.length)
                    ]}
                  >
                    {data.projectDescription?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Актуальность проекта для музыкального коллектива
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.projectRelevance?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text style={styles.inputText}>{data.projectRelevance?.replace(/\r?\n|\r/g, "; ")}</Text>
                </View>
              </View>
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>* Цель проекта</Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.projectPoint?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text style={styles.inputText}>{data.projectPoint?.replace(/\r?\n|\r/g, "; ")}</Text>
                </View>
              </View>
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Целевая аудитория проекта
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.projectTargetAudience?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text style={styles.inputText}>
                    {data.projectTargetAudience?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Каналы коммуникации с целевыми группами проекта
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.projectPrStrategy?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text style={styles.inputText}>{data.projectPrStrategy?.replace(/\r?\n|\r/g, "; ")}</Text>
                </View>
              </View>
              <View wrap={false} style={styles.row}>
                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>
                    * Дата начала реализации проекта
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>{data.projectStart}</Text>
                  </View>
                </View>

                <View style={[styles.inputBlock, styles.rowInput]}>
                  <Text style={styles.inputTitle}>
                    * Дата окончания реализации
                  </Text>
                  <View style={styles.input}>
                    <Text style={styles.inputText}>{data.projectEnd}</Text>
                  </View>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Этапы реализации проекта с указанием сроков
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.calendarPlanText?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text
                    style={[getTextAreaFontSize(data.calendarPlanText?.replace(/\r?\n|\r/g, "; ")?.length)]}
                  >
                    {data.calendarPlanText?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  <b>Ожидаемые количественные результаты проекта</b>
                </Text>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Количество участников мероприятий проекта
                </Text>
                <View style={[styles.input]}>
                  <Text style={styles.inputText}>
                    {data.projectOffilneAudienceExpectations}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Охват аудитории творческим продуктом проекта
                </Text>
                <View style={[styles.input]}>
                  <Text style={styles.inputText}>
                    {data.projectOnlineAudienceExpectations}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>* Медиа-охват проекта</Text>
                <View style={[styles.input]}>
                  <Text style={styles.inputText}>
                    {data.projectMediaCoverageExpectations}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Ожидаемые качественные результаты проекта
                </Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.projectExpectations?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text style={styles.inputText}>
                    {data.projectExpectations?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>* Бюджет проекта</Text>
                <Table data={data.budgetPlanJson} />
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Источники софинансирования
                </Text>
                <View style={[styles.input, styles.iFrameArea]}>
                  <Text style={styles.inputText}>
                    {data.projectBudgetSources?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>3. СОСТАВ КОЛЛЕКТИВА</Text>
            <View style={styles.formBlock}>
              {data.members &&
                data.members.map((member, index) => {
                  if (!member) return <></>

                  return (
                    <View key={`member--${index}`}>
                      {index !== 0 && <View style={styles.separator} />}

                      <View wrap={false} style={styles.row}>
                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>* ФИО</Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].name}
                            </Text>
                          </View>
                        </View>

                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>* Дата рождения</Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].birthday}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View wrap={false} style={styles.row}>
                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>* Email</Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].email}
                            </Text>
                          </View>
                        </View>

                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>* Телефон</Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].phone}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View wrap={false} style={styles.row}>
                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>* Роль в группе</Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].role}
                            </Text>
                          </View>
                        </View>

                        <View style={[styles.inputBlock, styles.rowInput]}>
                          <Text style={styles.inputTitle}>
                            * Размер футболки
                          </Text>
                          <View style={styles.input}>
                            <Text style={styles.inputText}>
                              {data.members && data.members[index].tShirtSize}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View wrap={false} style={styles.inputBlock}>
                        <Text style={styles.inputTitle}>
                          * Опыт музыкальной деятельности
                        </Text>
                        <View
                          style={[
                            styles.input,
                            getTextareaSize(
                              data.members[index].musicExperience?.replace(/\r?\n|\r/g, "; ")?.length
                            )
                          ]}
                        >
                          <Text
                            style={[
                              getTextAreaFontSize(
                                data.members[index].musicExperience?.replace(/\r?\n|\r/g, "; ")?.length
                              )
                            ]}
                          >
                            {data.members &&
                              data.members[index]?.musicExperience?.replace(/\r?\n|\r/g, "; ")}
                          </Text>
                        </View>
                      </View>

                      <View wrap={false} style={styles.inputBlock}>
                        <Text style={styles.inputTitle}>* Социальные сети</Text>

                        <View wrap={false} style={styles.row}>
                          <View
                            style={[
                              styles.input,
                              styles.rowInput,
                              styles.inputWithImage,
                              styles.marginedInput
                            ]}
                          >
                            <View style={styles.inputImage}>
                              <Image source={facebook} />
                            </View>
                            <Link
                              src={data.members[index].facebookUrl || ""}
                              style={styles.techRiderText}
                            >
                              {makeBreaks(
                                (data.members &&
                                  data.members[index].facebookUrl) ||
                                  "",
                                40
                              )}
                            </Link>
                          </View>
                          <View
                            style={[
                              styles.input,
                              styles.rowInput,
                              styles.inputWithImage,
                              styles.marginedInput
                            ]}
                          >
                            <View style={styles.inputImage}>
                              <Image source={vkontakte} />
                            </View>
                            <Link
                              src={data.members[index].vkontakteUrl || ""}
                              style={styles.techRiderText}
                            >
                              {makeBreaks(
                                (data.members &&
                                  data.members[index].vkontakteUrl) ||
                                  "",
                                40
                              )}
                            </Link>
                          </View>
                        </View>

                        <View wrap={false} style={styles.row}>
                          <View
                            style={[
                              styles.input,
                              styles.rowInput,
                              styles.inputWithImage,
                              styles.marginedInput
                            ]}
                          >
                            <View style={styles.inputImage}>
                              <Image source={instagram} />
                            </View>
                            <Link
                              src={data.members[index].instagramUrl || ""}
                              style={styles.techRiderText}
                            >
                              {makeBreaks(
                                (data.members &&
                                  data.members[index].instagramUrl) ||
                                  "",
                                40
                              )}
                            </Link>
                          </View>
                          <View
                            style={[
                              styles.input,
                              styles.rowInput,
                              styles.inputWithImage,
                              styles.marginedInput
                            ]}
                          >
                            <View style={styles.inputImage}>
                              <Image source={tiktok} />
                            </View>
                            <Link
                              src={data.members[index].tiktokUrl || ""}
                              style={styles.techRiderText}
                            >
                              {makeBreaks(
                                (data.members &&
                                  data.members[index].tiktokUrl) ||
                                  "",
                                40
                              )}
                            </Link>
                          </View>
                        </View>

                        <View wrap={false} style={styles.row}>
                          <View
                            style={[
                              styles.input,
                              styles.rowInput,
                              styles.inputWithImage,
                              styles.marginedInput
                            ]}
                          >
                            <View style={styles.inputImage}>
                              <Image source={youtube} />
                            </View>
                            <Link
                              src={data.members[index].youtubeUrl || ""}
                              style={styles.techRiderText}
                            >
                              {makeBreaks(
                                (data.members &&
                                  data.members[index].youtubeUrl) ||
                                  "",
                                40
                              )}
                            </Link>
                          </View>
                          <View
                            style={[
                              styles.input,
                              styles.rowInput,
                              styles.inputWithImage,
                              styles.marginedInput
                            ]}
                          >
                            <View style={styles.inputImage}>
                              <Image source={twitter} />
                            </View>
                            <Link
                              src={data.members[index].twitterUrl || ""}
                              style={styles.techRiderText}
                            >
                              {makeBreaks(
                                (data.members &&
                                  data.members[index].twitterUrl) ||
                                  "",
                                40
                              )}
                            </Link>
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                })}
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>Приглашенные специалисты</Text>
                <View
                  style={[
                    styles.input,
                    getTextareaSize(data.invitedSpecialists?.replace(/\r?\n|\r/g, "; ")?.length)
                  ]}
                >
                  <Text style={styles.inputText}>
                    {data.invitedSpecialists?.replace(/\r?\n|\r/g, "; ")}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>4. МЕДИАМАТЕРИАЛЫ</Text>
            <View style={styles.formBlock}>
              <View wrap={false} style={[styles.inputBlock, styles.noMargin]}>
                <Text style={styles.inputTitle}>
                  * Фотографии музыкального коллектива
                </Text>
                <View style={styles.photoInput}>
                  <Text style={styles.photoInputTitle}>На сайт</Text>
                  <View style={styles.photoInputImageWrapper}>
                    <Image
                      style={styles.photoInputImage}
                      source={{
                        uri: data.photoSite?.url || "",
                        method: "GET",
                        headers: {},
                        body: ""
                      }}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.photoInput}>
                <Text style={styles.photoInputTitle}>
                  Для социальных сетей и экранов
                </Text>
                <View style={styles.row}>
                  {data.photoSocial?.map((item, index) => (
                    <View style={styles.photoInputImageWrapper} key={index}>
                      <Image
                        style={styles.photoInputImage}
                        source={{
                          uri: item?.url || "",
                          method: "GET",
                          headers: {},
                          body: ""
                        }}
                      />
                    </View>
                  ))}
                </View>
              </View>

              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Отсканированная копия паспорта руководителя проекта
                </Text>
                <View style={styles.techRider}>
                  <Image style={styles.techRiderImage} source={document} />
                  <Link
                    src={data.leaderPassport?.url || ""}
                    style={styles.techRiderText}
                  >
                    {data.leaderPassport?.originalName}
                  </Link>
                </View>
              </View>
              {data.membersAgreements &&
                data.membersAgreements.map((member, index) => {
                  if (!member) return <></>

                  return (
                    <View
                      wrap={false}
                      style={[styles.inputBlock, styles.section]}
                      key={`membersAgreements--${index}`}
                    >
                      <Text style={styles.inputTitle}>
                        * Согласия всех членов музыкального коллектива об
                        участии в конкурсе
                      </Text>
                      <View style={styles.techRider}>
                        <Image
                          style={styles.techRiderImage}
                          source={document}
                        />
                        <Link
                          src={member.url || ""}
                          style={styles.techRiderText}
                        >
                          {member.originalName}
                        </Link>
                      </View>
                    </View>
                  )
                })}
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>* Технический райдер</Text>
                <View style={styles.techRider}>
                  <Image style={styles.techRiderImage} source={document} />
                  <Link
                    src={data.techRider?.url || ""}
                    style={styles.techRiderText}
                  >
                    {data.techRider?.originalName}
                  </Link>
                </View>
              </View>
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>Презентация коллектива</Text>
                <View style={styles.techRider}>
                  <Image style={styles.techRiderImage} source={document} />
                  <Link
                    src={data.presentation?.url || ""}
                    style={styles.techRiderText}
                  >
                    {data.presentation?.originalName}
                  </Link>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>5. ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</Text>
            <View style={styles.formBlock}>
              <View wrap={false} style={styles.inputBlock}>
                <Text style={styles.inputTitle}>
                  * Ссылка на промо-пост с анонсом участия в фестивале New /
                  Open
                </Text>
                <View style={styles.input}>
                  <Link
                    src={data.promoPostUrl || ""}
                    style={styles.techRiderText}
                  >
                    {makeBreaks(data.promoPostUrl || "", 70)}
                  </Link>
                  <Text style={styles.inputText}></Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  };

  render(): JSX.Element {
    return (
      <Button
        onClick={this.downLoadPdf}
        background="transparent"
        text="Скачать анкету"
        icon={<ArrowDown width="18.5" height="20" />}
      />
    )
  }
}
