import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import LoaderComponent from "../../../common/Loader/Loader.component"
import LazyLoad from "react-lazyload"
import { ListArtistModel, ArtistListCategory } from "../../../../models/AdminModels/ArtistList.model"
import ArtistListItemContainer from "../../../../container/CRM/AdminCRM/ArtistListItem.container"
import LazyLoadComponent from "../../common/LazyLoad/LazyLoad.component"
import SearchInputContainer from "../../../../container/CRM/AdminCRM/SearchInput.container"

const block = "artist-list"

interface Props {
  tabIndex: number;
  isLoading: boolean;
  artistsData?: ListArtistModel[];
  onSelect: (tabIndex: number) => void;
  onSearchChange: (value: string) => void;
  onLoadMore: () => void;
  artistsCount?: number;
  shouldLoadMore: boolean;
}

const tabNames: { [key in ArtistListCategory]: string } = {
  new: "Новые",
  remoderation: "На повторной модерации",
  approved: "Утвержденные",
  checked: "Вычитанные"
}

const ArtistListComponent = (props: Props): JSX.Element => {
  const {
    tabIndex,
    isLoading,
    artistsData,
    onSelect,
    onSearchChange,
    shouldLoadMore,
    artistsCount,
    onLoadMore
  } = props

  const onLoadMoreHandle = (): void => {
    !isLoading && onLoadMore()
  }

  return (
    <div className={`${block} admin-layout-content`}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex: number): void => onSelect(tabIndex)}
      >
        <div className="admin-layout-content__header">
          <div className="page-title page-title--black admin-layout-content__title">
            АРТИСТЫ
          </div>
          <TabList>
            {Object.keys(tabNames).map((category, index) => (
              <Tab key={category}>
                {tabIndex === index && (
                  <div className="admin-layout-content__counter">
                    {artistsCount || 0}
                  </div>
                )}
                {tabNames[category as ArtistListCategory]}
              </Tab>
            ))}
          </TabList>

          <div className="admin-layout-content__controls">
            <SearchInputContainer onSearchChange={onSearchChange} />
          </div>
        </div>
        {isLoading && !artistsData?.length && (
          <div className="admin-layout-content__loading">
            <LoaderComponent width={100} height={100} />
          </div>
        )}
        <div className="admin-layout-content__body">
          {Object.keys(tabNames).map(category => (
            <TabPanel key={category}>
              {artistsData && artistsData.length ? (
                <>
                {artistsData.map((artist: ListArtistModel) => (
                  <ArtistListItemContainer artistData={artist} key={artist.id} />
                ))}
                {shouldLoadMore && (
                  <LazyLoad height={10} unmountIfInvisible={true}>
                    <LazyLoadComponent isLoading={isLoading} onMount={onLoadMoreHandle} />
                  </LazyLoad>
                )}
                </>
              ) : !isLoading && (
                <div className="admin-layout-content__empty">
                  Список пуст
                </div>
              )}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  )
}

export default ArtistListComponent