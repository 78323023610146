/* eslint-disable max-len */
import React, { useRef, useEffect } from "react"
import { DeepMap, FieldError, FormProvider, useForm } from "react-hook-form"
import { scroller } from "react-scroll"
import classNames from "classnames"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import isEqual from "lodash.isequal"

import { CommentModel } from "../../../../models/ArtistModels/artistForm.model"
import LoaderComponent from "../../../common/Loader/Loader.component"
import Button from "../../../common/Button/Button.component"
import { RequestError } from "../../../../models/common/error.model"
import ErrorLabelComponent from "../../common/ErrorLabel/ErrorLabel.component"
import { UserRole } from "../../../../const/permissionsRules.const"
import "./WinnerForm.style.scss"
import WinnerFormInputFields from "./WinnerFormFields/WinnerFormFields.component"
import ArtistSidebarComponent from "../ArtistSidebar/ArtistSidebar.component"

import {
  WinnerFormFields,
  WinnerFormResponseMeta,
  WinnerFormStatus
} from "../../../../models/ArtistModels/winnerForm.model"
import AdminApproveSidebarReportComponent from "../../AdminCRM/AdminApproveSidebarReport/AdminApproveSidebarReport.component"
import { statuses } from "../../AdminCRM/CrmReportListItem/CrmReportListItem.component"
import DownloadReportingComponent from "../../AdminCRM/AdminApproveSidebar/DownloadReporting/DownloadReporting.component"

export const tabNames: { [key in string]: string } = {
  stage1: " С 15.02 по 15.04",
  stage2: "С 15.04 по 21.07"
}

interface Props {
  isLoading?: boolean;
  isSaving?: boolean;
  formInitialValues?: WinnerFormFields;
  formStatus: WinnerFormStatus;
  showErrors: () => void;
  userRole?: UserRole;
  showValidationErrors?: boolean;
  isFormReadonly?: boolean;
  formId?: number;
  submittionErrors?: RequestError[];
  activeSidebarTab: "comments" | "hints";
  setActiveSidebarTab: (name: "comments" | "hints") => void;
  winnerFormMeta?: WinnerFormResponseMeta;
  isFirstEdit: boolean;
  comments?: CommentModel[];
  tabIndex: number;
  onSelect: (tabIndex: number) => void;
  onSubmit: (data: WinnerFormFields, isApply?: boolean) => void;
  onCheckForm: (values: WinnerFormFields) => void;
  onModerate: (values: WinnerFormFields) => void;
  onComment: () => void;
  isModerateLoading: boolean;
  isModerateActive: boolean;
  isCheckLoading: boolean;
}

const block = "winner-form"

const WinnerFormComponent = (props: Props): JSX.Element => {
  const {
    isLoading,
    isSaving,
    formInitialValues,
    showValidationErrors,
    isFormReadonly,
    showErrors,
    formStatus,
    userRole,
    activeSidebarTab,
    setActiveSidebarTab,
    tabIndex,
    onSelect,
    onSubmit,
    formId,
    submittionErrors,
    isFirstEdit,
    winnerFormMeta,
    comments,
    onComment,
    isModerateLoading,
    isModerateActive,
    onCheckForm,
    onModerate,
    isCheckLoading
  } = props

  const form1 = useForm<WinnerFormFields>({
    shouldFocusError: false
  })

  const form2 = useForm<WinnerFormFields>({
    shouldFocusError: false
  })

  const lastSubmitValues = useRef<WinnerFormFields>()
  const autosaveTimeout = useRef<number>()

  const getForm = (tabIndex: number) => {
    if (tabIndex === 0) return form1
    if (tabIndex === 1) return form2
    return form1
  }

  const autoSaveInterval = (): void => {
    if (
      !isEqual(getForm(tabIndex).getValues(), lastSubmitValues.current) &&
      getForm(tabIndex).getValues() &&
      !isSaving
    ) {
      onSubmit(getForm(tabIndex).getValues(), false)
      lastSubmitValues.current = getForm(tabIndex).getValues()
    }
    autosaveTimeout.current = window.setTimeout(autoSaveInterval, 1000 * 30)
  }

  const scrollToFirstError = (
    errors: DeepMap<WinnerFormFields, FieldError>
  ): void => {
    const errorFields = Object.keys(errors)

    if (errorFields.length) {
      scroller.scrollTo(errorFields[0], {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -230
      })
    }
  }

  useEffect(() => {
    form1.reset(formInitialValues)
    form2.reset(formInitialValues)
    lastSubmitValues.current = formInitialValues

    if (isFirstEdit && userRole === UserRole.ARTIST) {
      autosaveTimeout.current = window.setTimeout(autoSaveInterval, 1000 * 30)
    }

    return (): void => {
      clearInterval(autosaveTimeout.current)
      autosaveTimeout.current = undefined
    }
  }, [formInitialValues])

  useEffect(() => {
    if (!winnerFormMeta?.appliedAt) {
      setActiveSidebarTab("hints")
    } else {
      setActiveSidebarTab("comments")
    }
  }, [winnerFormMeta?.appliedAt])

  const onFormSubmit = (
    submitFunction: (data: WinnerFormFields) => void
  ): void => {
    showErrors()
    getForm(tabIndex).handleSubmit(
      data => submitFunction(data),
      errors => scrollToFirstError(errors)
    )()
  }

  return (
    <div
      className={classNames(
        block,
        userRole !== UserRole.ARTIST &&
          formStatus !== WinnerFormStatus.EDITING &&
          `${block}--admin`,
        activeSidebarTab === "comments" && `${block}--comments`
      )}
    >
      <div className="content-container">
        <>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(tabIndex: number): void => onSelect(tabIndex)}
          >
            <TabList className="tab-list">
              {Object.keys(tabNames).map((category, index) => (
                <Tab
                  className={`react-tabs__tab ${
                    tabIndex === index ? "react-tabs__tab--selected" : ""
                  }`}
                  key={category}
                >
                  {tabNames[category]}
                </Tab>
              ))}
            </TabList>

            {Object.keys(tabNames).map(category => (
              <TabPanel key={category}>
                {isLoading || !formInitialValues ? (
                  <LoaderComponent width={100} height={100} />
                ) : (
                  <>
                    <form
                      id={`winner-form-${tabIndex}`}
                      onSubmit={(): void =>
                        onSubmit(getForm(tabIndex).getValues(), false)
                      }
                    />
                    <div className="row row--space-between margin--top-m ">
                      <div className={`${block}__status`}>
                        Статус:{" "}
                        <span
                          className={`${block}__status--${statuses[formStatus].color}`}
                        >
                          {statuses[formStatus].label}
                        </span>
                      </div>
                    </div>

                    <div
                      className={`${block}__status ${block}__status--red margin--top-xs`}
                    >
                      Заполните все необходимые поля
                    </div>

                    <div className="row row--space-between">
                      <div className={`${block}__form`}>
                        <FormProvider {...getForm(tabIndex)}>
                          <WinnerFormInputFields
                            formInitialValues={formInitialValues}
                            showValidationErrors={showValidationErrors}
                            formId={formId}
                            isFormReadonly={isFormReadonly}
                            isVisibleHints={activeSidebarTab === "hints"}
                          />
                        </FormProvider>

                        {userRole === UserRole.ARTIST &&
                          formStatus === WinnerFormStatus.EDITING &&
                          !isFormReadonly && (
                            <div className={`${block}__submit-button`}>
                              <Button
                                background="turquoise"
                                onClick={(): void => {
                                  showErrors()
                                  getForm(tabIndex).handleSubmit(
                                    data => onSubmit(data, true),
                                    errors => scrollToFirstError(errors)
                                  )()
                                }}
                                text={"Подать заявку"}
                                isLoading={isSaving}
                              />
                            </div>
                          )}

                        {userRole === UserRole.ADMIN &&
                          formStatus === WinnerFormStatus.CHECKED && (
                            <div className={`${block}__submit-area`}>
                              <div className={`${block}__submit-button`}>
                                <Button
                                  background="turquoise"
                                  onClick={(): void => {
                                    showErrors()
                                    getForm(tabIndex).handleSubmit(
                                      data => onSubmit(data, false),
                                      errors => scrollToFirstError(errors)
                                    )()
                                  }}
                                  text="Сохранить"
                                  isLoading={isSaving}
                                />
                              </div>
                            </div>
                          )}
                        {submittionErrors &&
                          submittionErrors.length &&
                          submittionErrors.map((error, index: number) => (
                            <ErrorLabelComponent
                              text={error.message}
                              key={`${error.code}-${index}`}
                            />
                          ))}
                      </div>
                      {userRole === UserRole.ADMIN && 
                        !isFormReadonly && (
                        <div className="margin margin--top-xl">
                          <AdminApproveSidebarReportComponent
                            onModerate={(): void =>
                              onModerate(getForm(tabIndex).getValues())
                            }
                            isModerateActive={isModerateActive}
                            comments={comments}
                            onCheckForm={(): void => onFormSubmit(onCheckForm)}
                            formStatus={formStatus}
                            commentInputTitle={
                              "Оставьте комментарий для участника фестиваля"
                            }
                            isModerateLoading={isModerateLoading}
                            isCheckLoading={isCheckLoading}
                            submittionErrors={submittionErrors}
                          />
                          
                          {category === 'stage2' && 
                          <div className="margin--top-xl">
                                {/* TODO: formInitialValues - Данные с сервера */}
                            <DownloadReportingComponent formData={formInitialValues}/>
                          </div>
                          }
                        </div>
                      )}

                      {userRole === UserRole.ARTIST && (
                        <ArtistSidebarComponent
                          comments={comments}
                          commentInputTitle={
                            "Оставьте комментарий для модератора"
                          }
                          activeSidebarTab={activeSidebarTab}
                          setActiveSidebarTab={setActiveSidebarTab}
                          onComment={onComment}
                          isModerateLoading={isModerateLoading}
                          isModerateActive={isModerateActive}
                          isCanComment={
                            formStatus === WinnerFormStatus.EDITING &&
                            !!winnerFormMeta?.appliedAt
                          }
                        />
                      )}
                    </div>
                  </>
                )}
              </TabPanel>
            ))}
          </Tabs>
        </>
      </div>
    </div>
  )
}

export default WinnerFormComponent
