import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ExpertListComponent from '../../../components/CRM/AdminCRM/ExpertList/ExpertList.component'
import { getExpertList } from '../../../services/admin/expertList.service'
import { expertListStore } from '../../../store/AdminStore/expertList.store'

const ExpertListContainer = (props: RouteComponentProps): JSX.Element => {
  const getExperts = (search?: string, limit?: number, offset?: number): void => {
    getExpertList({search, limit, offset})
  }

  const onEffect = (): () => void => {
    const searchString = new URLSearchParams(props.location.search).get("name") || undefined

    getExperts(searchString)

    return (): void => {
      expertListStore.clearData()
    }
  }

  useEffect(onEffect, [])

  const onSearchChange = (searchString: string): void => {
    expertListStore.clearData()

    getExperts(
      searchString || ''
    )
  }

  const {
    isLoading,
    experts,
    currentOffset,
    count,
    setOffset
  } = expertListStore

  const getShouldLoadMore = (): boolean => {
    if (count && experts) {
      return count > experts.length
    } else return true
  }

  const onLoadMore = (): void => {
    getExperts(
      new URLSearchParams(props.location.search).get("name") || undefined,
      20,
      currentOffset ? currentOffset + 20 : 20
    )

    setOffset(currentOffset ? currentOffset + 20 : 20)
  }

  return (
    <ExpertListComponent
      isLoading={isLoading}
      expertsList={experts}
      onSearchChange={onSearchChange}
      onLoadMore={onLoadMore}
      shouldLoadMore={getShouldLoadMore()}
    />
  )
}

export default withRouter(observer(ExpertListContainer))