import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import FinalistFormComponent from "../../../components/CRM/AdminCRM/FinalistForm/FinalistForm.component"
import { PATH_FINALISTS_UNPROCESSED } from "../../../const/routes.const"
import { FinalistsListArtistType } from "../../../models/AdminModels/FinalistsList.model"
import {
  ArtistFormFields,
  MemberModel
} from "../../../models/ArtistModels/artistForm.model"
import {
  confirmFinalist,
  getFinalistForm
} from "../../../services/admin/finalistForm.service"
import {
  confirmFinalistStore,
  finalistFormGetStore
} from "../../../store/AdminStore/finalistForm.store"
import { formatDateForClient } from "../../../utils/formatDate"

const FinalistFormContainer = (): JSX.Element => {
  const artistId = parseInt(
    useRouteMatch<{ artistId: string }>().params.artistId
  )
  const history = useHistory()
  const location = useLocation<{ category: FinalistsListArtistType }>()

  const getFormInitialValues = (): ArtistFormFields | undefined => {
    const formData = finalistFormGetStore.formData

    if (formData) {
      return {
        ...formData,
        members: formData.members.length
          ? formData.members.map(
              (member): MemberModel => ({
                ...member,
                birthday: formatDateForClient(member.birthday)
              })
            )
          : [
              {
                name: null,
                email: null,
                birthday: null,
                role: null,
                phone: null,
                tShirtSize: null,
                musicExperience: null,
                facebookUrl: null,
                instagramUrl: null,
                vkontakteUrl: null,
                tiktokUrl: null,
                youtubeUrl: null,
                twitterUrl: null,
                isLeader: true
              }
            ],
        photoSocial: [
          formData.photoSocial[0] || null,
          formData.photoSocial[1] || null,
          formData.photoSocial[2] || null,
          formData.photoSocial[3] || null
        ],
        projectStart: formatDateForClient(formData?.projectStart),
        projectEnd: formatDateForClient(formData?.projectEnd),
        projectBudget: formData.projectBudget || null
      }
    }
  }

  const [formInitialValues, setFormInitialValues] = useState(
    getFormInitialValues()
  )

  const onEffect = (): (() => void) => {
    if (isNaN(artistId)) {
      history.replace(PATH_FINALISTS_UNPROCESSED)
    }

    getFinalistForm(artistId).then(() => {
      setFormInitialValues(getFormInitialValues())
    })

    return (): void => {
      finalistFormGetStore.clearData()
    }
  }

  useEffect(onEffect, [])

  const goBack = (): void => {
    if (location.state?.category) {
      history.push(location.state.category || PATH_FINALISTS_UNPROCESSED)
    } else {
      history.push(PATH_FINALISTS_UNPROCESSED)
    }
  }

  const confirmArtist = (isConfirmed: boolean): void => {
    confirmFinalist(artistId, isConfirmed).then(() => {
      goBack()
    })
  }

  return (
    <FinalistFormComponent
      isLoading={finalistFormGetStore.isLoading}
      formInitialValues={formInitialValues}
      setFormInitialValues={setFormInitialValues}
      artistId={artistId}
      onConfirm={confirmArtist}
      isConfirmLoading={confirmFinalistStore.isLoading}
    />
  )
}

export default observer(FinalistFormContainer)
