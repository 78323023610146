import { autoserializeAs, deserialize, deserializeAs } from "cerialize"

export class FileUploadResponse {
  @deserialize id: number;
  @autoserializeAs('original_name') originalName: string;
  @deserialize url: string;
  @deserializeAs('url_s') urlSizeS?: string;
  @deserializeAs('url_m') urlSizeM?: string;
  @deserializeAs('url_l') urlSizeL?: string;

  constructor(name: string, url: string, id: number) {
    this.originalName = name
    this.url = url
    this.id = id
  }
}
export enum FileUploadType {
  IMAGE = 'image',
  DOCUMENT = 'document'
} 
