import { Deserialize, Serialize } from "cerialize"
import { RequestErrorResponse } from "../../models/common/error.model"
import {
  FestExpertFinalistsListGetRequest,
  FestExpertFinalistsListGetResponse
} from "../../models/FestExpertModels/FinalistsList.model"
import { festExpertFinalistsListStore } from "../../store/FestExpertStore/festExpertFinalistsList.store"
import { api } from "../../utils/api"

export function getFestExpertFinalists(
  request: FestExpertFinalistsListGetRequest
): Promise<void> {
  const serializedData = Serialize(request, FestExpertFinalistsListGetRequest)

  festExpertFinalistsListStore.setIsLoading(true)

  return api
    .get("/festexpert/artists", {
      params: serializedData
    })
    .then((res) => {
      const data: FestExpertFinalistsListGetResponse = Deserialize(
        res.data,
        FestExpertFinalistsListGetResponse
      )

      festExpertFinalistsListStore.setIsLoading(false)
      festExpertFinalistsListStore.setFinalistsListData(data)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      festExpertFinalistsListStore.setIsLoading(false)
      festExpertFinalistsListStore.setErrors(errorResponseData?.errors)

      throw error
    })
}
