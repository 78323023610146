import { Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../models/common/error.model"
import { artistInfoGetStore } from "../../store/FestExpertStore/festExpertArtistInfo.store"
import { api } from "../../utils/api"
import { FestExpertArtistGetResponse } from "../../models/FestExpertModels/ArtistInfo.model"

export function getArtistInfo(id: number): Promise<void> {
  artistInfoGetStore.setIsLoading(true)

  return api
    .get(`/festexpert/artists/${id}`)
    .then((res) => {
      const data: FestExpertArtistGetResponse = Deserialize(
        res.data,
        FestExpertArtistGetResponse
      )

      artistInfoGetStore.setIsLoading(false)
      artistInfoGetStore.setArtistInfo(data?.artist)
      artistInfoGetStore.setValuationInfo(data?.valuation)
      artistInfoGetStore.setAvailableToEvaluate(data?.availableToEvaluate)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data

      artistInfoGetStore.setIsLoading(false)
      artistInfoGetStore.setErrors(errorResponseData?.errors)

      throw errorResponseData
    })
}
