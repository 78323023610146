import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import ArtistRatingComponent from '../../../components/CRM/ArtistCRM/ArtistRating/ArtistRating.component'
import { artistRatingStore } from '../../../store/ArtistStore/artistRating.store'
import { getArtistRating } from '../../../services/artist/artistRating.service'

const ArtistNotificationsContainer = (): JSX.Element => {
  const onEffect = (): () => void => {
    getArtistRating()

    return (): void => {
      artistRatingStore.clearData()
    }
  }

  useEffect(onEffect, [])

  return (
    <ArtistRatingComponent
      evaluationData={artistRatingStore.ratingData}
      isLoading={artistRatingStore.isLoading}
    />
  )
}

export default observer(ArtistNotificationsContainer)