export enum UserRole {
  VISITOR = 'VISITOR',
  ARTIST = 'ARTIST',
  ADMIN = 'ADMIN',
  EXPERT = 'EXPERT',
  FESTIVAL_EXPERT = 'FESTIVAL_EXPERT'
}

export type RulesType = {
  [key in UserRole]: {
    static: string[];
    //dynamic?: Record<string, (data: unknown) => unknown>;
  };
};

const rules: RulesType = {
  VISITOR: {
    static: []
    // dynamic: {
    //   "category:action": (data): boolean => {
    //     if (!data.test || !data.test2) return false
    //     return data.test === data.test2
    //   }
    // }
  },
  ARTIST: {
    static: []
  },
  ADMIN: {
    static: []
  },
  EXPERT: {
    static: []
  },
  FESTIVAL_EXPERT: {
    static: []
  }
}

export default rules