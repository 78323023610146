import React from 'react'
import { ReactComponent as Loader } from "../../../assets/icons/loading.svg"

import './Loader.style.scss'

const block = "loader"

interface Props {
  width: number;
  height: number;
}

const LoaderComponent = (props: Props): JSX.Element => {
  const { width, height } = props

  return (
    <div className={block}>
      <Loader fill="#21CEB4" width={width} height={height} />
    </div>
  )
}

export default LoaderComponent