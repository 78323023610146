import React from 'react'
import AdminCRMLayout from '../../components/CRM/AdminCRM/AdminCRMLayout/AdminCRMLayout.component'
import FinalistsListContainer from '../../container/CRM/AdminCRM/FinalistsList.container'

const FinalistsPage = (): JSX.Element => (
  <AdminCRMLayout>
    <FinalistsListContainer />
  </AdminCRMLayout>
)

export default FinalistsPage