import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import {
  PATH_ARTISTS,
  PATH_ARTIST_MODERATE,
  PATH_EXPERTS,
  PATH_FINALISTS,
  PATH_FINALIST_MODERATE,
  PATH_WINNERS,
  PATH_WINNER_MODERATE
} from "../../../const/routes.const"
import FinalistFormContainer from "../../../container/CRM/AdminCRM/FinalistForm.container"
import ArtistFormContainer from "../../../container/CRM/ArtistCRM/ArtistForm.container"
import ArtistsPage from "../../../pages/ArtistsPage/Artists.page"
import ExpertsPage from "../../../pages/ExpertsPage/Experts.page"
import FinalistsPage from "../../../pages/FinalistsPage/Finalists.page"
import WinnersPage from "../../../pages/WinnersPage/Winners.page"
import ArtistCRMLayout from "../ArtistCRM/ArtistCRMLayout/ArtistCRMLayout.component"
import WinnerFormContainer from "../../../container/CRM/ArtistCRM/WinnerForm.container"
import WinnerCRMLayout from "../ArtistCRM/WinnerCRMLayout/WinnerCRMLayout.component"

const AdminCRM = (): JSX.Element => (
  <Switch>
    <Route path={PATH_ARTISTS}>
      <ArtistsPage />
    </Route>

    <Route exact path={PATH_EXPERTS}>
      <ExpertsPage />
    </Route>

    <Route path={PATH_FINALISTS}>
      <FinalistsPage />
    </Route>

    <Route path={PATH_WINNERS}>
      <WinnersPage />
    </Route>

    <Route exact path={`${PATH_ARTIST_MODERATE}/:artistId`}>
      <ArtistCRMLayout>
        <ArtistFormContainer />
      </ArtistCRMLayout>
    </Route>

    <Route exact path={`${PATH_FINALIST_MODERATE}/:artistId`}>
      <ArtistCRMLayout>
        <FinalistFormContainer />
      </ArtistCRMLayout>
    </Route>

    <Route exact path={`${PATH_WINNER_MODERATE}/:artistId/:stage`}>
      <WinnerCRMLayout>
        <WinnerFormContainer />
      </WinnerCRMLayout>
    </Route>

    <Redirect to={PATH_ARTISTS} />
  </Switch>
)

export default AdminCRM
