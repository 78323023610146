import { action, observable } from "mobx"
import { ExpertListGetResponse, ListExpertModel } from "../../models/AdminModels/ExpertList.model"
import { ApiRequestStore } from "../common/apiRequest.store"

class ExpertListStore extends ApiRequestStore {
  @observable experts?: ListExpertModel[];
  @observable count?: number;
  @observable currentOffset?: number;

  @action
  setArtistFormsData(data: ExpertListGetResponse): void {
    if (this.experts && data.items) {
      this.experts = [...this.experts, ...data.items]
    } else {
      this.experts = data.items
    }
    this.count = data.count
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  }

  @action
  clearData = (): void => {
    this.experts = undefined
    this.count = undefined
    this.currentOffset = undefined
  }
}

export const expertListStore: ExpertListStore = new ExpertListStore()
