import React from "react"
import classNames from "classnames"
import { ReactComponent as StartIcon } from "../../../../assets/icons/star.svg"

import "./CrmListItem.style.scss"

interface Props {
  title: string;
  image?: string;
  statusText?: string;
  statusColor?: "red" | "grey" | "yellow" | "green" | "blue";
  onClick?: () => void;
  rating?: number;
  number?: number;
  isBgColored?: boolean;
}

const block = "crm-list-item"

const CRMListItemComponent = (props: Props): JSX.Element => {
  const { title, image, statusText, statusColor, onClick, rating, number, isBgColored } =
    props

  return (
    <div
      className={`${block} ${isBgColored && `${block}--top100`}`}
      onClick={onClick}
    >
      {number && <div className={`${block}__number`}>{number}.</div>}

      <div
        className={`${block}__avatar`}
        style={image ? { backgroundImage: `url(${image})` } : {}}
      />
      <div className={`${block}__name`}>{title}</div>

      {statusText && <div className={`${block}__status`}>{statusText}</div>}
      {statusColor && (
        <div
          className={classNames(
            `${block}__status-indicator ${block}--${statusColor}`
          )}
        />
      )}

      {rating && (
        <div className={`${block}__rating`}>
          <div className={`${block}__rating-number`}>{rating}</div>
          <div className={`${block}__rating-image`}>
            <StartIcon width="100%" height="100%" />
          </div>
        </div>
      )}
    </div>
  )
}

export default CRMListItemComponent
