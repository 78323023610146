import React from 'react'
import CommentInputComponent from '../../../components/CRM/AdminCRM/AdminApproveSidebar/CommentInput'
import { formCommentStore } from '../../../store/AdminStore/formComment.store'

export default class CommentInputContainer extends React.Component {
  componentWillUnmount(): void {
    formCommentStore.clearComment()
  }

  onChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    formCommentStore.setComment(event.target.value)
  }

  render(): JSX.Element {
    return (
      <CommentInputComponent onChange={this.onChange} value={formCommentStore.comment}/>
    )
  }
}