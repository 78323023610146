import React from 'react'
import classNames from 'classnames'

import './AuthLayout.style.scss'

interface Props {
  children: React.ReactNode;
  hasRegister?: boolean;
}

const block = 'auth-layout'

const AuthLayoutComponent = (props: Props): JSX.Element => {
  const { children, hasRegister } = props

  return (
    <div className={classNames(
      block,
      !hasRegister && `${block}--no-register`
    )}>
      {children}
    </div>
  )
}

export default AuthLayoutComponent