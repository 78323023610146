import React from 'react'
import ArtistCRMLayout from '../../components/CRM/ArtistCRM/ArtistCRMLayout/ArtistCRMLayout.component'
import ArtistFormContainer from '../../container/CRM/ArtistCRM/ArtistForm.container'

const ArtistFormPage = (): JSX.Element => (
    <ArtistCRMLayout>
      <ArtistFormContainer />
    </ArtistCRMLayout>
  )

export default ArtistFormPage