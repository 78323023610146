import React from 'react'
import AdminCRMLayout from '../../components/CRM/AdminCRM/AdminCRMLayout/AdminCRMLayout.component'
import ExpertListContainer from '../../container/CRM/AdminCRM/ExpertList.container'

const ExpertsPage = (): JSX.Element => (
  <AdminCRMLayout>
    <ExpertListContainer />
  </AdminCRMLayout>
)

export default ExpertsPage