import React from 'react'
import classNames from 'classnames'

import './InputLabel.style.scss'

interface Props {
  children: React.ReactNode;
  isFocused?: boolean;
}

const block = 'input-label'

const InputLabelComponent = (props: Props): JSX.Element => {
  const { children, isFocused } = props

  return (
    <div className={classNames(
      block,
      isFocused && `${block}--focused`
    )}>
      {children}
    </div>
  )
}

export default InputLabelComponent