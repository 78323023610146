import React from 'react'
import AdminCRMLayout from '../../components/CRM/AdminCRM/AdminCRMLayout/AdminCRMLayout.component'
import FinalistsListContainer from '../../container/CRM/FestExpertCRM/FinalistsList.container'

const FestExpertFinalistsPage = (): JSX.Element => (
  <AdminCRMLayout>
    <FinalistsListContainer />
  </AdminCRMLayout>
)

export default FestExpertFinalistsPage