/* eslint-disable no-console */
/* eslint-disable max-len */
import React from "react"
import { ReactComponent as Comment } from "../../../../assets/icons/comment.svg"
import { ReactComponent as CommentAvatar } from "../../../../assets/icons/comment-avatar.svg"
import moment from "moment"
import { RequestError } from "../../../../models/common/error.model"
import ErrorLabelComponent from "../../common/ErrorLabel/ErrorLabel.component"
import {
  CommentModel,
  ArtistFormStatus,
  ArtistFormFields
} from "../../../../models/ArtistModels/artistForm.model"
import Button from "../../../common/Button/Button.component"
import IconWithTextComponent from "../../common/IconWithText/IconWithText.component"

import "./AdminApproveSidebar.style.scss"
import CommentInputContainer from "../../../../container/CRM/AdminCRM/CommentInput.container"
import DownloadFormComponent from "./DownloadForm/DownloadForm.component"
import { UserRole } from "../../../../const/permissionsRules.const"
import classNames from "classnames"
import { artistFormReadStore } from "../../../../store/ArtistStore/artistForm.store"

interface Props {
  onModerate: () => void;
  onApprove: () => void;
  onCheckForm: () => void;
  onApproveApproveFormWithoutCheck: () => void
  isModerateActive: boolean;
  isApproveActive: boolean;
  comments?: CommentModel[];
  formStatus: ArtistFormStatus;
  formData?: ArtistFormFields;
  submittionErrors?: RequestError[];
  commentInputTitle: string;
  isModerateLoading: boolean;
  isApproveLoading: boolean;
  isCheckLoading: boolean;
  checkingErrors?: RequestError[];
}

const block = "admin-approve-sidebar"

const AdminApproveSidebarComponent = (props: Props): JSX.Element => {
  const {
    onModerate,
    onApprove,
    isModerateActive,
    isApproveActive,
    comments,
    formStatus,
    onCheckForm,
    onApproveApproveFormWithoutCheck,
    submittionErrors,
    commentInputTitle,
    isModerateLoading,
    isApproveLoading,
    isCheckLoading,
    formData,
    checkingErrors
  } = props

  return (
    <div className={block}>
      <div className={`${block}__comment-input-section`}>
        {formStatus === "APPROVED" || formStatus === "CHECKED" ? (
          <>
            {/* <div>
              <IconWithTextComponent icon={Comment} text={commentInputTitle} />
              <div className="margin margin--top-xs">
                <CommentInputContainer />
              </div>
              <div className="margin margin--top-l">
                <Button
                  onClick={onModerate}
                  text="Отправить на доработку"
                  background="turquoise"
                  isDisabled={!isModerateActive}
                  isLoading={isModerateLoading}
                />
              </div>
            </div> */}
            <div className="margin--top-xs">
              <Button
                text="Вычитано"
                onClick={formStatus === 'CHECKED' ? onApproveApproveFormWithoutCheck : onCheckForm}
                background="turquoise"
                isDisabled={!isApproveActive}
                isLoading={isCheckLoading}
              />
            </div>
            {formData && (
              <div className="margin margin--top-s">
                <DownloadFormComponent formData={formData} />
              </div>
            )}
            {/* Ошибка при вычитке */}
            {checkingErrors &&
              checkingErrors.length &&
              checkingErrors.map((error, index): JSX.Element => {
                if (error.code === "valuations") {
                  return (
                    <ErrorLabelComponent
                      text={`Добавлено меньше 3х экспертов жанра вычитываемого артиста. Пожалуйста, добавьте их в админке и попробуйте снова`}
                    />
                  )
                } else {
                  return (
                    <ErrorLabelComponent
                      text={error.message}
                      key={`${error.code}-${index}`}
                    />
                  )
                }
              })}

            {submittionErrors &&
              submittionErrors.length &&
              submittionErrors.map(
                (error, index): JSX.Element => (
                  <ErrorLabelComponent
                    text={error.message}
                    key={`${error.code}-${index}`}
                  />
                )
              )}
          </>
        ) : (
          <>
            {formStatus !== "EDITING" && (
              <>
                <IconWithTextComponent
                  icon={Comment}
                  text={commentInputTitle}
                />
                <div className="margin margin--top-xs">
                  <CommentInputContainer />
                </div>
                <div
                  className={classNames(
                    "margin margin--top-l"
                    // formStatus !== 'EDITING' && "margin--top-l"
                  )}
                >
                  <Button
                    onClick={onModerate}
                    text="Отправить на доработку"
                    background="turquoise"
                    isDisabled={!isModerateActive}
                    isLoading={isModerateLoading}
                  />
                </div>
                {/* {formStatus !== "EDITING" && ( */}
                <div className="margin margin--top-s">
                  <Button
                    onClick={onApprove}
                    text="Одобрить заявку"
                    background="turquoise"
                    isDisabled={!isApproveActive}
                    isLoading={isApproveLoading}
                  />
                </div>
              </>
            )}

            {formData && (
              <div className="margin margin--top-s">
                <DownloadFormComponent formData={formData} />
              </div>
            )}

            {submittionErrors &&
              submittionErrors.length &&
              submittionErrors.map(
                (error, index): JSX.Element => (
                  <ErrorLabelComponent
                    text={error.message}
                    key={`${error.code}-${index}`}
                  />
                )
              )}
          </>
        )}
        {comments && !!comments.length && (
          <div className={`${block}__comments-section`}>
            <div className={`${block}__comments-title`}>
              Предыдущие комментарии:
            </div>
            <div className={`${block}__comments`}>
              {comments.map((comment: CommentModel, index: number) => (
                <div
                  className={`${block}__comments-item`}
                  key={`${comment.author}-comment-${index}`}
                >
                  {comment.author === UserRole.ARTIST && (
                    <div className={`${block}__comments-item-header`}>
                      <div className={`icon`}>
                        <CommentAvatar />
                      </div>
                      <div className={`name`}>Артист</div>
                    </div>
                  )}

                  {comment.author === UserRole.ADMIN && (
                    <div className={`${block}__comments-item-header`}>
                      <div className={`icon icon--admin`}>
                        <CommentAvatar />
                      </div>
                      <div className={`name`}>Вы</div>
                    </div>
                  )}

                  <div className={`${block}__comments-item-text`}>
                    {comment.comment}
                  </div>

                  <div className={`${block}__comments-item-date`}>
                    {moment
                      .utc(comment.createdAt)
                      .utcOffset(-new Date().getTimezoneOffset())
                      .format("DD.MM.YYYY, HH:mm:ss")}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminApproveSidebarComponent
