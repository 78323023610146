/* eslint-disable no-console */
/* eslint-disable max-len */
import React from "react"
import moment from "moment"
import classNames from "classnames"
import { ReactComponent as Comment } from "../../../../assets/icons/comment.svg"
import { ReactComponent as CommentAvatar } from "../../../../assets/icons/comment-avatar.svg"
import { RequestError } from "../../../../models/common/error.model"
import ErrorLabelComponent from "../../common/ErrorLabel/ErrorLabel.component"
import { CommentModel } from "../../../../models/ArtistModels/artistForm.model"
import Button from "../../../common/Button/Button.component"
import IconWithTextComponent from "../../common/IconWithText/IconWithText.component"

import CommentInputContainer from "../../../../container/CRM/AdminCRM/CommentInput.container"
import { UserRole } from "../../../../const/permissionsRules.const"
import "./AdminApproveSidebarReport.style.scss"
import { WinnerFormStatus } from "../../../../models/ArtistModels/winnerForm.model"

interface Props {
  onModerate: () => void;
  onCheckForm: () => void;
  isModerateActive: boolean;
  comments?: CommentModel[];
  formStatus?: WinnerFormStatus;
  submittionErrors?: RequestError[];
  commentInputTitle: string;
  isModerateLoading: boolean;
  isCheckLoading?: boolean;
}

const block = "admin-approve-sidebar"

const AdminApproveSidebarReportComponent = (props: Props): JSX.Element => {
  const {
    onModerate,
    isModerateActive,
    comments,
    formStatus,
    onCheckForm,
    submittionErrors,
    commentInputTitle,
    isModerateLoading,
    isCheckLoading
  } = props

  return (
    <div className={block}>
      <div className={`${block}__comment-input-section`}>
        {formStatus === WinnerFormStatus.MODERATION && (
          <>
            <IconWithTextComponent icon={Comment} text={commentInputTitle} />
            <div className="margin margin--top-xs">
              <CommentInputContainer />
            </div>
            <div className={classNames("margin margin--top-l")}>
              <Button
                onClick={onModerate}
                text="Отправить на доработку"
                background="turquoise"
                isDisabled={!isModerateActive}
                isLoading={isModerateLoading}
              />
            </div>
            <div className="margin--top-xs">
              <Button
                text="Одобрить заявку"
                onClick={onCheckForm}
                background="turquoise"
                isLoading={isCheckLoading}
              />
            </div>
          </>
        )}

        {submittionErrors &&
          submittionErrors.length &&
          submittionErrors.map(
            (error, index): JSX.Element => (
              <ErrorLabelComponent
                text={error.message}
                key={`${error.code}-${index}`}
              />
            )
          )}

        {comments && !!comments.length && (
          <div className={`${block}__comments-section`}>
            <div className={`${block}__comments-title`}>
              Предыдущие комментарии:
            </div>
            <div className={`${block}__comments`}>
              {comments.map((comment: CommentModel, index: number) => (
                <div
                  className={`${block}__comments-item`}
                  key={`${comment.author}-comment-${index}`}
                >
                  {comment.author === UserRole.ARTIST && (
                    <div className={`${block}__comments-item-header`}>
                      <div className={`icon`}>
                        <CommentAvatar />
                      </div>
                      <div className={`name`}>Артист</div>
                    </div>
                  )}

                  {comment.author === UserRole.ADMIN && (
                    <div className={`${block}__comments-item-header`}>
                      <div className={`icon icon--admin`}>
                        <CommentAvatar />
                      </div>
                      <div className={`name`}>Вы</div>
                    </div>
                  )}

                  <div className={`${block}__comments-item-text`}>
                    {comment.comment}
                  </div>

                  <div className={`${block}__comments-item-date`}>
                    {moment
                      .utc(comment.createdAt)
                      .utcOffset(-new Date().getTimezoneOffset())
                      .format("DD.MM.YYYY, HH:mm:ss")}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminApproveSidebarReportComponent
