import React from 'react'
import { ExpertDashboardListitem } from '../../../../../models/ExpertModels/ExpertDashboard.model'
import CRMListItemComponent from '../../../AdminCRM/CrmListItem/CrmListItem.component'

import './EvaluatedArtists.style.scss'

interface Props {
  artists: ExpertDashboardListitem[];
  onClick: (id?: number) => void
}

const block = 'evaluated-artists'

const EvaluatedArtistsComponent = (props: Props): JSX.Element => {
  const { artists, onClick } = props

  return (
    <div className={block}>
      <div className="content-container">
        <div className={`${block}__title page-text page-text--align-center page-text--bold`}>
          РАНЕЕ ОЦЕНЕННЫЕ
        </div>

        <div className={`${block}__list`}>
          {artists.map(artistData => (
            <CRMListItemComponent 
              onClick={() => onClick(artistData.valuation.id)}
              key={artistData.artist.id} 
              title={artistData.artist.name}
              image={artistData.artist.photo}
              rating={artistData.valuation.sum}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default EvaluatedArtistsComponent