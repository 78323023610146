import React from 'react'
import classNames from 'classnames'

import './iconLink.style.scss'

const block = 'iconLink-component'

interface Props {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  text: string
  href: string
}

const IconLinkComponent = (props: Props): JSX.Element => {
  const { icon, text, href } = props

  return (
    <a className={block} href={href} target="_blank" rel="noopener noreferrer">
      <div className={classNames(`${block}__icon-wrapper`)}>
        {React.createElement(icon)}
      </div>
      <div className={classNames(`${block}__text`)}>{text}</div>
    </a>
  )
}

export default IconLinkComponent
