import { action, observable } from "mobx"
import { ArtistFormGetResponse } from "../../models/ArtistModels/artistForm.model"
import { ApiRequestStore } from "../common/apiRequest.store"

class ArtistFormSaveStore extends ApiRequestStore {
  @observable saveTime?: Date;

  @action
  setSaveTime(date: Date): void {
    this.saveTime = date
  }
}

class ArtistFormGetStore extends ApiRequestStore {
  @observable formData?: ArtistFormGetResponse;

  @action
  setFormData(data: ArtistFormGetResponse): void {
    this.formData = data
  }

  @action
  clearData = (): void => {
    this.formData = undefined
  };
}

class ArtistSaveMembersStore extends ApiRequestStore {}

class ArtistFormApplyStore extends ApiRequestStore {}

class ArtistFormSetEditStatusStore extends ApiRequestStore {}

class ArtistFormModerateStore extends ApiRequestStore {}

class ArtistFormApproveStore extends ApiRequestStore {}

class ArtistFormReadStore extends ApiRequestStore {}

export const artistFormSaveStore: ArtistFormSaveStore =
  new ArtistFormSaveStore()

export const artistFormGetStore: ArtistFormGetStore = new ArtistFormGetStore()

export const artistFormApplyStore: ArtistFormApplyStore =
  new ArtistFormApplyStore()

export const artistFormSetEditStatusStore: ArtistFormSetEditStatusStore =
  new ArtistFormSetEditStatusStore()

export const artistFormModerateStore: ArtistFormModerateStore =
  new ArtistFormModerateStore()

export const artistFormApproveStore: ArtistFormApproveStore =
  new ArtistFormApproveStore()

export const artistFormReadStore: ArtistFormReadStore =
  new ArtistFormReadStore()

export const artistSaveMembersStore: ArtistSaveMembersStore =
  new ArtistSaveMembersStore()
