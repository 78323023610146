import React from "react"
import { ReactComponent as Star } from "../../../../assets/icons/star.svg"
import { ReactComponent as Graph } from "../../../../assets/icons/graph.svg"
import { ReactComponent as Check } from "../../../../assets/icons/white-check.svg"
import { ReactComponent as Users } from "../../../../assets/icons/users.svg"
import { ReactComponent as Heart } from "../../../../assets/icons/heart.svg"
import { ArtistRatingGetResponse } from "../../../../models/ArtistModels/artistRating.model"
import LoaderComponent from "../../../common/Loader/Loader.component"

import "./ArtistRating.style.scss"
import ArtistRatingComment from "./ArtistRatingComment/ArtistRatingComment.component"

interface Props {
  evaluationData?: ArtistRatingGetResponse
  isLoading: boolean
}

const block = "artist-rating"

const ArtistRatingComponent = (props: Props): JSX.Element => {
  const { isLoading, evaluationData } = props

  return (
    <div className={block}>
      <div className="content-container">
        {isLoading ? (
          <LoaderComponent width={100} height={100} />
        ) : (
          <>
            <div className={`${block}__title`}>ВАШ СРЕДНИЙ БАЛЛ</div>
            <div className={`${block}__rating`}>
              <div className={`${block}__rating-quantity`}>
                {evaluationData?.totalRating}
              </div>
              <div className={`${block}__rating-icon`}>
                <Star />
              </div>
            </div>
            <div className={`${block}__subrating-title`}>
              ЗА РАЗНЫЕ КАТЕГОРИИ (ДО 40 БАЛЛОВ) ВЫ ПОЛУЧИЛИ
            </div>
            <div className={`${block}__subrating-container`}>
              <div className={`${block}__subrating`}>
                <div className={`${block}__subrating-block`}>
                  <div className={`${block}__rating-quantity`}>
                    {evaluationData?.reality}
                  </div>
                  <div className={`${block}__rating-icon`}>
                    <Graph />
                  </div>
                </div>

                <p className={`${block}__subrating-description`}>
                  Реалистичность проекта
                </p>
              </div>

              <div className={`${block}__subrating`}>
                <div className={`${block}__subrating-block`}>
                  <div className={`${block}__rating-quantity`}>
                    {evaluationData?.relevance}
                  </div>
                  <div className={`${block}__rating-icon`}>
                    <Users />
                  </div>
                </div>

                <p className={`${block}__subrating-description`}>
                  Актуальность для группы
                </p>
              </div>

              <div className={`${block}__subrating`}>
                <div className={`${block}__subrating-block`}>
                  <div className={`${block}__rating-quantity`}>
                    {evaluationData?.quality}
                  </div>
                  <div className={`${block}__rating-icon`}>
                    <Check />
                  </div>
                </div>

                <p className={`${block}__subrating-description`}>
                  Качество музыкальных произведений
                </p>
              </div>

              <div className={`${block}__subrating`}>
                <div className={`${block}__subrating-block`}>
                  <div className={`${block}__rating-quantity`}>
                    {evaluationData?.emotionalResponse}
                  </div>
                  <div className={`${block}__rating-icon`}>
                    <Heart />
                  </div>
                </div>

                <p className={`${block}__subrating-description`}>
                  Эмоциональный отклик на творчество
                </p>
              </div>
            </div>
            {/* <div className={`${block}__comments`}>
              <div className={`${block}__comments-title`}>
                КОММЕНТАРИИ ЭКСПЕРТОВ
              </div>

              <div className={`${block}__comments-items`}>
                {evaluationData?.comments ? evaluationData.comments.map((comment, index) => (
                  <ArtistRatingComment text={comment} key={index} />
                ))
              :
              <div>Эксперты не оставляли вам комментариев</div>
              }
              </div>
            </div> */}
          </>
        )}
      </div>
    </div>
  )
}

export default ArtistRatingComponent
