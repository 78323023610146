import { Deserialize, Serialize } from "cerialize"
import { RequestErrorResponse } from "../../models/common/error.model"
import { winnerListStore } from "../../store/AdminStore/winnerList.store"
import { api } from "../../utils/api"
import {
  WinnerListGetResponse,
  WinnerListGetRequest
} from "../../models/AdminModels/WinnerList.model"

export function getWinnerList(request: WinnerListGetRequest): Promise<void> {
  const serializedData = Serialize(request, WinnerListGetRequest)

  winnerListStore.setIsLoading(true)

  return api
    .get("/admin/artists/reports", {
      params: serializedData
    })
    .then((res) => {
      const data: WinnerListGetResponse = Deserialize(
        res.data,
        WinnerListGetResponse
      )

      winnerListStore.setWinnersListData({
        ...data
      })
      winnerListStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerListStore.setIsLoading(false)
      winnerListStore.setErrors(errorResponseData?.errors)
    })
}
