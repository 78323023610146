import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { UserRole } from '../../const/permissionsRules.const'
import { PATH_AUTH } from '../../const/routes.const'
import { authContext } from '../../context/auth.context'
import AdminCRM from './AdminCRM/AdminCRM.component'
import ArtistCRM from './ArtistCRM/ArtistCRM.component'
import ExpertCRM from './ExpertCRM/ExpertCRM.component'
import FestExpertCRM from './FestExpertCRM/FestExpertCRM.component'

const CRM = (): JSX.Element => {
  const { user } = useContext(authContext)

  const renderCRMComponent = (): JSX.Element => {
    switch (user.role) {
      case UserRole.ARTIST:
        return <ArtistCRM />
      case UserRole.FESTIVAL_EXPERT:
        return <FestExpertCRM />
      case UserRole.EXPERT:
        return <ExpertCRM />
      case UserRole.ADMIN:
        return <AdminCRM />
      case UserRole.VISITOR:
        return <Redirect to={PATH_AUTH} />
      default:
        return <Redirect to={PATH_AUTH} />
    }
  }

  return (
    renderCRMComponent()
  )
}

export default CRM