import React from "react"
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg"
import { ReactComponent as Save } from "../../../assets/icons/save.svg"
import { ReactComponent as Star } from "../../../assets/icons/star-text.svg"

import { ArtistFormStatus } from "../../../models/ArtistModels/artistForm.model"
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-left.svg"

import "./CRMHeader.style.scss"
import { formStatusLabels } from "../ArtistCRM/ArtistForm/data.const"
import moment from "moment"

interface Props {
  title: string;
  text: string;
  hasEyeLabel?: boolean;
  onSave?: () => void;
  onBack?: () => void;
  artistFormStatus?: ArtistFormStatus;
  shouldShowStatus?: boolean;
  saveTime?: Date;
}

const block = "crm-header"

const CRMHeaderComponent = (props: Props): JSX.Element => {
  const {
    title,
    text,
    hasEyeLabel,
    onSave,
    onBack,
    shouldShowStatus,
    artistFormStatus,
    saveTime
  } = props

  return (
    <div className={block}>
      <div className="content-container row row--space-between">
        <div className={`${block}__content`}>
          {onBack && (
            <div className={`${block}__back-btn`} onClick={onBack}>
              <div className={`${block}__back-btn-arrow`}>
                <Arrow />
              </div>
              <div className={`${block}__back-btn-text`}>Назад</div>
            </div>
          )}
          <div className={`${block}__title`}>{title}</div>
          <div className={`${block}__text`}>{text}</div>
          {hasEyeLabel && (
            <>
              <div className={`${block}__label margin--top-s`}>
                <div className={`${block}__label-eye`}>
                  <Eye fill="#ffffff" />
                </div>
                <div className={`${block}__label-text`}>
                  Информация с данной пометкой будет отражена на сайте.
                </div>
              </div>
              <div className={`${block}__label margin--top-xs`}>
                <div className={`${block}__label-eye`}>
                  <Star width="20px" height="15px" fill="#ffffff" />
                </div>
                <div className={`${block}__label-text`}>
                  Поля с данной пометкой обязательны для заполнения.
                </div>
              </div>
            </>
          )}
        </div>
        {(onSave) && (
          <div className={`${block}__right-side`}>
            {onSave && !shouldShowStatus ? (
              <>
                <div className={`${block}__save-button`} onClick={onSave}>
                  <Save /> Сохранить
                </div>

                {saveTime && (
                  <div className={`${block}__save-time`}>
                    Последнее сохранение: {moment(saveTime).format("HH:mm:ss")}
                  </div>
                )}
              </>
            ) : (
              artistFormStatus && (
                <div className={`${block}__status-label`}>
                  {
                    formStatusLabels.find(
                      statusData => statusData.formStatus === artistFormStatus
                    )?.label
                  }
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CRMHeaderComponent
