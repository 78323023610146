import { action, observable } from 'mobx'
import { EvaluationInfoGetResponse } from '../../models/ExpertModels/Evaluation.model'
import { ApiRequestStore } from '../common/apiRequest.store'

class EvaluationInfoGetStore extends ApiRequestStore {
  @observable evaluationInfo?: EvaluationInfoGetResponse

  @action
  setEvaluationInfo(data: EvaluationInfoGetResponse): void {
    this.evaluationInfo = data
  }

  @action
  clearInfo(): void {
    this.evaluationInfo = undefined
  }
}

class EvaluateRequestStore extends ApiRequestStore {}

export const evaluationInfoGetStore: EvaluationInfoGetStore = new EvaluationInfoGetStore()
export const evaluateRequestStore: EvaluateRequestStore = new EvaluateRequestStore()
