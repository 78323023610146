import React from 'react'
import MainLayout from '../../components/common/MainLayout/MainLayout.component'
import AuthLayoutContainer from '../../container/CRM/AuthLayout.container'

import './Auth.style.scss'

const block = 'auth-page'

const AuthPage = (): JSX.Element => {
  const hasRegister = false
    // process.env.REACT_APP_ENVIRONMENT === 'production' ? false : true

  return (
    <div className={block}>
      <MainLayout>
        <div className={`${block}__content`}>
          <div className="content-container">
            <div className="page-title">
              {hasRegister
                ? 'Введите данные для регистрации или входа в личный кабинет'
                : 'Введите данные для входа в личный кабинет'}
              {/* Хотите стать участником фестиваля <br />
            NEW / OPEN 2021? */}
            </div>
            {hasRegister && (
              <div className={`${block}__subtitle`}>
                Успейте подать заявку до 31 октября 2023
              </div>
            )}
          </div>

          <AuthLayoutContainer hasRegister={hasRegister} />
        </div>
      </MainLayout>
    </div>
  )
}

export default AuthPage
