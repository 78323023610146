import { Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../models/common/error.model"
import { ExpertDashboardGetResponse } from "../../models/ExpertModels/ExpertDashboard.model"
import { expertDashboardGetStore } from "../../store/ExpertStore/ExpertDashboard.store"
import { api } from "../../utils/api"
import { evaluationInfoGetStore } from '../../store/ExpertStore/ExpertEvaluation.store'
import { EvaluationInfoGetResponse } from '../../models/ExpertModels/Evaluation.model'

export function getExpertDashboardData(): Promise<void> {
  expertDashboardGetStore.setIsLoading(true)

  return api.get(
    '/expert/evaluated'
  ).then((res) => {
    const data: ExpertDashboardGetResponse = Deserialize(res.data, ExpertDashboardGetResponse)
    
    expertDashboardGetStore.setDashboardData(data)
    expertDashboardGetStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response?.data
    expertDashboardGetStore.setIsLoading(false)
    expertDashboardGetStore.setErrors(errorResponseData?.errors)

    throw error
  })
}

export async function getEvaluatedArtist(id: number) {
  evaluationInfoGetStore.setIsLoading(true)

  return await api.get(
    `/expert/evaluated/${id}`
  ).then(async (res) => {
    const data: EvaluationInfoGetResponse = Deserialize(res.data, EvaluationInfoGetResponse)
    evaluationInfoGetStore.setIsLoading(false)
    
    return await data
  }).catch(() => evaluationInfoGetStore.setIsLoading(false))
}