export const PATH_ROOT = "/"
export const PATH_AUTH = "/auth"
export const PATH_CRM = "/crm"
export const PATH_ARTIST_REQUEST = "/request"
export const PATH_ARTIST_REPORT = "/report"
export const PATH_ARTIST_NOTIFICATIONS = "/notifications"
export const PATH_ARTIST_EVALUATION = "/evaluation"
export const PATH_ARTIST_REQUEST_READ = `${PATH_ARTIST_REQUEST}/read`
export const PATH_ARTIST_REPORT_READ = `${PATH_ARTIST_REPORT}/read`

// ADMIN
export const PATH_ARTISTS = "/artists"
export const PATH_ARTISTS_NEW = `${PATH_ARTISTS}/new`
export const PATH_ARTISTS_REMODERATION = `${PATH_ARTISTS}/remoderation`
export const PATH_ARTISTS_APPROVED = `${PATH_ARTISTS}/approved`
export const PATH_ARTISTS_CHECKED = `${PATH_ARTISTS}/checked`
export const PATH_ARTIST_MODERATE = "/artist-form"
export const PATH_EXPERTS = "/experts"
export const PATH_FINALISTS = "/finalists"
export const PATH_WINNERS = "/winners"
export const PATH_FINALISTS_UNPROCESSED = `${PATH_FINALISTS}/unprocessed`
export const PATH_FINALISTS_CONFIRMED = `${PATH_FINALISTS}/confirmed`
export const PATH_FINALISTS_UNCONFIRMED = `${PATH_FINALISTS}/unconfirmed`
export const PATH_FINALIST_MODERATE = "/finalist-form"
export const PATH_WINNERS_NEW = `${PATH_WINNERS}/new`
export const PATH_WINNERS_CHECKED = `${PATH_WINNERS}/checked`
export const PATH_WINNER_MODERATE = "/report-form"
export const PATH_WINNER_MODERATE_STAGE_1 = `${PATH_WINNER_MODERATE}/stage1`
export const PATH_WINNER_MODERATE_STAGE_2 = `${PATH_WINNER_MODERATE}/stage2`

// EXPERT
export const PATH_EXPERT_DASHBOARD = "/expert-dashboard"
export const PATH_EVALUATION = "/evaluation"

// FEST EXPERT
export const PATH_FINALISTS_RATED = `${PATH_FINALISTS}/rated`
export const PATH_FINALISTS_UNRATED = `${PATH_FINALISTS}/unrated`
