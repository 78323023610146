import React from 'react'
import classNames from 'classnames'

import './AuthFormLayout.style.scss'

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
  error?: string;
}

const block = 'auth-form-layout'

const AuthFormLayoutComponent = (props: Props): JSX.Element => {
  const { title, children, className, error } = props

  return (
    <div className={classNames(`${block}`, className)}>
      <div className={`${block}__title`}>
        {title}
      </div>
      <div className={`${block}__form`}>
        {children}
      </div>
      {error && (
        <div className={`${block}__error`}>
          {error}
        </div>
      )}
      
    </div>
  )
}

export default AuthFormLayoutComponent