import React, { useEffect } from 'react'
import LoaderComponent from '../../../common/Loader/Loader.component'
import classNames from 'classnames'

import './LazyLoad.style.scss'

interface Props {
  isLoading: boolean;
  onMount: () => void;
}

const block = 'lazy-loader'

const LazyLoadComponent = (props: Props): JSX.Element => {
  const { onMount, isLoading } = props

  const onEffect = (): void => {
    onMount()
  }
  
  useEffect(onEffect, [])

  return (        
    <div className={classNames(
      block,
      isLoading && `${block}--loading`
    )}>
      {isLoading ? (
        <LoaderComponent width={50} height={50} />
      ) : (
        <div />
      )}
    </div> 
  )
}

export default LazyLoadComponent