import { Deserialize } from "cerialize"
import { ArtistFormResponseForm } from "../../models/ArtistModels/artistForm.model"
import { RequestErrorResponse } from "../../models/common/error.model"
import { confirmFinalistStore, finalistFormGetStore } from "../../store/AdminStore/finalistForm.store"
import { api } from "../../utils/api"

export function getFinalistForm(id: number): Promise<void> {
  finalistFormGetStore.setIsLoading(true)

  return api.get(
    `/admin/evaluated/${id}`
  ).then((res) => {
    const data: ArtistFormResponseForm = Deserialize(res.data, ArtistFormResponseForm)
    
    finalistFormGetStore.setIsLoading(false)
    finalistFormGetStore.setFormData(data)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response?.data
    finalistFormGetStore.setIsLoading(false)
    finalistFormGetStore.setErrors(errorResponseData?.errors)

    throw error
  })
}

export function confirmFinalist(id: number, confirmed: boolean): Promise<void> {
  confirmFinalistStore.setIsLoading(true)

  return api.put(
    `/admin/evaluated/${id}/confirmed`,
    {
      confirmed
    }
  ).then(() => {
    confirmFinalistStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response?.data
    confirmFinalistStore.setIsLoading(false)
    confirmFinalistStore.setErrors(errorResponseData?.errors)

    throw errorResponseData
  })
}