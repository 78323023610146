import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  PATH_WINNERS_NEW,
  PATH_WINNERS_CHECKED,
  PATH_WINNERS,
  PATH_WINNER_MODERATE
} from "../../../const/routes.const"
import { winnerListStore } from "../../../store/AdminStore/winnerList.store"
import {
  WinnerListType,
  WinnerFormStatus
} from "../../../models/AdminModels/WinnerList.model"
import WinnerListComponent from "../../../components/CRM/AdminCRM/WinnersList/WinnerList.component"
import { getWinnerList } from "../../../services/admin/winnerList.service"

const currentCategory = `${PATH_WINNERS}/`
const tabPathnames = [
  PATH_WINNERS_NEW.replace(currentCategory, ""),
  PATH_WINNERS_CHECKED.replace(currentCategory, "")
]

const routeCaregories: Map<string, WinnerListType> = new Map([
  [PATH_WINNERS_NEW, WinnerListType.New],
  [PATH_WINNERS_CHECKED, WinnerListType.Checked]
])

const WinnerListContainer = (): JSX.Element => {
  const location = useLocation()
  const history = useHistory()

  const [tabIndex, setTabIndex] = useState(0)

  const getActiveTab = (path: string): number => {
    const pathnameIndex = tabPathnames.indexOf(path)

    return pathnameIndex !== -1 ? pathnameIndex : 0
  }

  const getWinners = (
    pathname: string,
    limit?: number,
    offset?: number
  ): void => {
    const requestParams = {
      type: routeCaregories.get(pathname) || WinnerListType.New,
      limit,
      offset
    }

    getWinnerList(requestParams)
  }

  const onEffect = (): (() => void) => {
    const pathSuffix = location.pathname.split("/").pop()

    if (tabPathnames.indexOf(pathSuffix || "") < 0) {
      history.replace(PATH_WINNERS_NEW)
    }

    getWinners(location.pathname)

    setTabIndex(getActiveTab(pathSuffix || ""))

    return (): void => {
      winnerListStore.clearData()
    }
  }

  useEffect(onEffect, [])

  const onSelect = (tabIndex: number): void => {
    setTabIndex(tabIndex)
    const pureNewPathname = `${currentCategory}${tabPathnames[tabIndex]}`

    history.replace(pureNewPathname)
    winnerListStore.clearData()

    getWinners(pureNewPathname)
  }

  const { isLoading, winners, currentOffset, totalCount } =
    winnerListStore

  const getShouldLoadMore = (): boolean => {
    if (totalCount && winners) {
      return totalCount > winners.length
    } else return true
  }

  const onLoadMore = (): void => {
    getWinners(location.pathname, 20, currentOffset ? currentOffset + 20 : 20)

    winnerListStore.setOffset(currentOffset ? currentOffset + 20 : 20)
  }

  const goToArtist = (artistId: number, statusStage1: string): void => {
    if (statusStage1 === WinnerFormStatus.CHECKED) {
      history.push(`${PATH_WINNER_MODERATE}/${artistId}/stage2`)
    } else {
      history.push(`${PATH_WINNER_MODERATE}/${artistId}/stage1`)
    }
  }

  return (
    <WinnerListComponent
      tabIndex={tabIndex}
      winners={winners}
      isLoading={isLoading}
      onSelect={onSelect}
      onLoadMore={onLoadMore}
      shouldLoadMore={getShouldLoadMore()}
      totalCount={totalCount}
      onArtistClick={goToArtist}
    />
  )
}

export default observer(WinnerListContainer)
