import React from "react"

import "./CircleButton.style.scss"
import Tooltip from "@material-ui/core/Tooltip/Tooltip"

interface Props {
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  children: React.ReactNode;
  tooltip?: string;
}

const block = "circle-button"

const CircleButtonComponent = (props: Props): JSX.Element => {
  const { children, onClick, tooltip } = props

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} placement="bottom" arrow disableFocusListener>
          <div className={block} onClick={onClick}>
            {children}
          </div>
        </Tooltip>
      ) : (
        <div className={block} onClick={onClick}>
          {children}
        </div>
      )}
    </>
  )
}

export default CircleButtonComponent
