import React from 'react'
import { observer } from 'mobx-react'
import UploadFileComponent from '../../../components/CRM/common/UploadFile/UploadFile.component'
import { FileUploadResponse, FileUploadType } from '../../../models/common/fileUpload.model'
import { fileUploadStore } from '../../../store/common/fileUpload.store'
import { deleteFile, uploadFile } from '../../../services/common/uploadFile.service'
import { UserRole } from '../../../const/permissionsRules.const'

interface PropTypes {
  nameForStore: string;
  title?: string;
  hasTitleError?: boolean;
  fileTypes?: string[];
  onFileUpload?: (response: FileUploadResponse) => void;
  onFileDelete?: () => void;
  uploadedFile?: FileUploadResponse;
  showFileName: boolean;
  minWidth?: number;
  minHeight?: number;
  disabled?: boolean;
  type: FileUploadType;
  fileType: string;
  titleIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  artistId?: number;
  isReport?: boolean;
}

@observer
export default class UploadFileContainer extends React.Component<PropTypes> {
  constructor(props: PropTypes) {
    super(props)
    fileUploadStore.registration(this.props.nameForStore)
  }

  state = {
    isImage: false,
    error: ''
  }

  addImageProcess(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = (): void => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }

  onDelete = (event: React.SyntheticEvent<HTMLElement>): void => {
    event.stopPropagation()

    if (!this.props.disabled && this.props.uploadedFile?.id) {
      deleteFile(
        this.props.uploadedFile?.id, 
        this.props.type, 
        this.props.nameForStore, 
        localStorage.getItem('userRole') as UserRole,
        this.props.artistId,
        this.props.isReport
      ).then(() => {
        fileUploadStore.removeUploadedFileData(this.props.nameForStore)
        this.props.onFileDelete && this.props.onFileDelete()
      })
    }
  }

  onDrop = (file: File[]): void => {
    if (file && file[0]) {
      const hasFile = file.length > 0
      const isImage = hasFile && (file[0].type === 'image/jpeg' || file[0].type === 'image/png')
      const isValidType = hasFile && this.props.fileTypes?.includes(file[0].type)      

      if (!isValidType) {
        this.setState({
          error: 'Неверный формат файла'
        })
  
        return
      }

      if (file[0].size > 30 * 1024 * 1024) {
        this.setState({
          error: 'Размер изображения превышает допустимый (30мб)'
        })
  
        return
      }
  
      if (isImage) {
        const url = URL.createObjectURL(file[0])
        this.addImageProcess(url).then((img) => {
          if (this.props.minHeight && this.props.minWidth) {
            if (img.width >= this.props.minWidth && img.height >= this.props.minHeight) {
              uploadFile(
                file, 
                this.props.nameForStore, 
                this.props.type, 
                this.props.fileType,
                localStorage.getItem('userRole') as UserRole,
                this.props.artistId,
                this.props.isReport
              ).then(this.props.onFileUpload)
              this.setState({
                isImage,
                error: ''
              })
            } else {
              this.setState({
                error: 'Разрешение фотографии меньше, чем необходимо'
              })
            }
          }
        })
      } else {
        uploadFile(
          file, 
          this.props.nameForStore,
          this.props.type, 
          this.props.fileType,
          localStorage.getItem('userRole') as UserRole,
          this.props.artistId,
          this.props.isReport
        ).then(this.props.onFileUpload)
        this.setState({
          isImage,
          error: ''
        })
      }
    }
  }

  componentWillUnmount(): void {
    fileUploadStore.unmount(this.props.nameForStore)
  }

  render(): JSX.Element {
    const { nameForStore, title, fileTypes, uploadedFile, showFileName, disabled, hasTitleError } = this.props
    
    return (
      <UploadFileComponent
        onDrop={this.onDrop}
        uploadedFileState={fileUploadStore.getItem(nameForStore)}
        uploadedFile={uploadedFile}
        title={title}
        hasTitleError={hasTitleError}
        fileTypes={fileTypes || ['']}
        isImage={this.state.isImage}
        showFileName={showFileName}
        error={this.state.error}
        onDelete={this.onDelete}
        disabled={disabled}
        titleIcon={this.props.titleIcon}
      />
    )
  }
}
