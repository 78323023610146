import { autoserialize } from "cerialize"
import { UserModel } from "../common/user.model"

export class LoginRequest {
  @autoserialize email: string;
  @autoserialize password: string;
  
  constructor(
    email: string,
    password: string
  ) {
    this.email = email
    this.password = password
  }
}

export class LoginResponse {
  @autoserialize token: string;
  @autoserialize user: UserModel;

  constructor(
    token: string,
    user: UserModel
  ) {
    this.token = token
    this.user = user
  }
}

export class PassRecoveryRequest {
  @autoserialize email: string;
  
  constructor(
    email: string
  ) {
    this.email = email
  }
}