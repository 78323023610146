import React from 'react'
import AdminCRMLayout from '../../components/CRM/AdminCRM/AdminCRMLayout/AdminCRMLayout.component'
import WinnerListContainer from '../../container/CRM/AdminCRM/WinnerList.container'

const WinnersPage = (): JSX.Element => (
  <AdminCRMLayout>
    <WinnerListContainer />
  </AdminCRMLayout>
)

export default WinnersPage