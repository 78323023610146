import { Deserialize } from "cerialize"
import { api } from "../../utils/api"
import { RequestErrorResponse } from "../../models/common/error.model"
import { artistRatingStore } from "../../store/ArtistStore/artistRating.store"
import { ArtistRatingGetResponse } from "../../models/ArtistModels/artistRating.model"

export function getArtistRating(): Promise<ArtistRatingGetResponse> {
  artistRatingStore.setIsLoading(true)

  return api.get(
    '/artist/rating'
  ).then((res) => {
    const data: ArtistRatingGetResponse = Deserialize(res.data, ArtistRatingGetResponse)

    artistRatingStore.setNotificationsData(data)
    artistRatingStore.setIsLoading(false)

    return data
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response?.data
    artistRatingStore.setIsLoading(false)
    artistRatingStore.setErrors(errorResponseData?.errors)
    throw error
  })
}