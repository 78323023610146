import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import ScrollToTopWrapper from "./components/common/ScrollToTop/ScrollToTop.wrapper"
import CRM from "./components/CRM/CRM.component"
import { PATH_AUTH, PATH_ROOT } from "./const/routes.const"
import AuthContextProvider from "./container/Auth/Auth.provider"
import AuthPage from "./pages/Auth/Auth.page"
import { Provider } from 'mobx-react'

const Router = (): JSX.Element => (
  <AuthContextProvider>
    <Provider>
      <BrowserRouter>
        <Switch>
          <Route exact path={PATH_AUTH}>
            <ScrollToTopWrapper>
              <AuthPage />
            </ScrollToTopWrapper>
          </Route>
          <Route path={PATH_ROOT}>
            <ScrollToTopWrapper>
              <CRM />
            </ScrollToTopWrapper>
          </Route>

          <Redirect to={PATH_AUTH} />
        </Switch>
      </BrowserRouter>
    </Provider>
  </AuthContextProvider>
)

export default Router
