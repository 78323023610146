import { autoserialize, autoserializeAs } from "cerialize"

export class ExpertListArtistModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize photo: string;

  constructor(
    id: number,
    name: string,
    photo: string
  ) {
    this.id = id
    this.name = name
    this.photo = photo
  }
}

export class ExpertDashboardListitem {
  @autoserializeAs(ExpertListArtistModel) artist: ExpertListArtistModel;
  @autoserialize valuation: {
    id: number
    sum: number
  };

  constructor(
    artist: ExpertListArtistModel,
    valuation: {
      id: number
      sum: number
    }
  ) {
    this.artist = artist
    this.valuation = valuation
  }
}

export class ExpertDashboardGetResponse {
  @autoserializeAs(ExpertDashboardListitem) items: ExpertDashboardListitem[] | [];
  @autoserialize count: number;
  @autoserializeAs('left_to_evaluate') leftToEvaluate: number;

  constructor(
    items: ExpertDashboardListitem[] | [],
    count: number,
    leftToEvaluate: number
  ) {
    this.items = items
    this.count = count
    this.leftToEvaluate = leftToEvaluate
  }
}