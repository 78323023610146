import { action, observable } from "mobx"
import { ApiRequestStore } from "../common/apiRequest.store"
import {
  AvailableToEvaluateModel,
  EvaluationValuationModel
} from "../../models/FestExpertModels/Evaluation.model"
import { FestExpertEvaluationArtistModel } from "../../models/FestExpertModels/ArtistInfo.model"

class ArtistInfoGetStore extends ApiRequestStore {
  @observable artistInfo?: FestExpertEvaluationArtistModel;
  @observable valuation?: EvaluationValuationModel;
  @observable availableToEvaluate?: AvailableToEvaluateModel;

  @action
  setArtistInfo(data: FestExpertEvaluationArtistModel): void {    
    this.artistInfo = data
  }

  @action
  setValuationInfo(data: EvaluationValuationModel): void {    
    this.valuation = data
  }

  @action
  setAvailableToEvaluate(data: AvailableToEvaluateModel): void {        
    this.availableToEvaluate = data
  }

  @action
  clearInfo(): void {
    this.artistInfo = undefined
    this.valuation = undefined
    this.availableToEvaluate = undefined
  }

  @action
  getArtistInfo = () => this.artistInfo
  
  @action
  getIsLoading = () => this.isLoading
  
  @action
  getValuation = () => this.valuation

  @action
  getAvailableToEvaluate = () => this.availableToEvaluate
}

export const artistInfoGetStore: ArtistInfoGetStore = new ArtistInfoGetStore()

class EvaluateMusicPerfomanceRequestStore extends ApiRequestStore {}
class EvaluateProjectDefenseRequestStore extends ApiRequestStore {}

export const evaluateMusicPerfomanceRequestStore: EvaluateMusicPerfomanceRequestStore =
  new EvaluateMusicPerfomanceRequestStore()
export const evaluateProjectDefenseRequestStore: EvaluateProjectDefenseRequestStore =
  new EvaluateProjectDefenseRequestStore()
