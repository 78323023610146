import { api } from "../../utils/api"
import { RequestErrorResponse } from "../../models/common/error.model"
import {
  FileUploadResponse,
  FileUploadType
} from "../../models/common/fileUpload.model"
import { Deserialize } from "cerialize"
import { fileUploadStore } from "../../store/common/fileUpload.store"
import { UserRole } from "../../const/permissionsRules.const"

const getArtistFileUploadUrl = (
  type: FileUploadType,
  formId?: number
): string => {
  if (formId) {
    return `/artist/reports/${formId}/documents`
  }
  switch (type) {
    case FileUploadType.IMAGE:
      return "/artist/form/images"
    case FileUploadType.DOCUMENT:
      return "/artist/form/documents"
  }
}

const getAdminFileUploadUrl = (
  type: FileUploadType,
  id?: number,
  isReport?: boolean
): string => {
  if (isReport) {
    return `/admin/artists/reports/${id}/documents`
  } else {
    switch (type) {
      case FileUploadType.IMAGE:
        return `/admin/artists/${id}/images`
      case FileUploadType.DOCUMENT:
        return `/admin/artists/${id}/documents`
    }
  }
}

export const getFileUploadUrl = (
  role: UserRole,
  type: FileUploadType,
  id?: number,
  isReport?: boolean
): string => {
  switch (role) {
    case UserRole.ARTIST:
      return getArtistFileUploadUrl(type, id)
    case UserRole.ADMIN:
      return getAdminFileUploadUrl(type, id, isReport)
    default:
      return getAdminFileUploadUrl(type, id, isReport)
  }
}

export function uploadFile(
  file: File[],
  stateName: string,
  type: FileUploadType,
  fileType: string,
  role: UserRole,
  artistId?: number,
  isReport?: boolean
): Promise<FileUploadResponse> {
  const formData = new FormData()
  formData.append("file", file[0], file[0].name)
  formData.append("file_type", fileType)
  fileUploadStore.setIsLoading(true, stateName)

  return api
    .post(getFileUploadUrl(role, type, artistId, isReport), formData, {
      headers: { "content-type": "multipart/form-data" }
    })
    .then((res) => {
      const responseData: FileUploadResponse = Deserialize(
        res.data,
        FileUploadResponse
      )

      fileUploadStore.setUploadedFileData(responseData, stateName)

      return responseData
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      fileUploadStore.setIsLoading(false, stateName)
      fileUploadStore.setSubmittionErrors(errorResponseData?.errors, stateName)
      throw error
    })
}

export function deleteFile(
  id: number,
  type: FileUploadType,
  stateName: string,
  role: UserRole,
  artistId?: number,
  isReport?: boolean
): Promise<void> {
  fileUploadStore.setIsLoading(true, stateName)

  return api
    .delete(`${getFileUploadUrl(role, type, artistId, isReport)}/${id}`)
    .then(() => {
      fileUploadStore.setIsLoading(false, stateName)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      fileUploadStore.setIsLoading(false, stateName)
      fileUploadStore.setSubmittionErrors(errorResponseData?.errors, stateName)
      throw error
    })
}

export function deleteFileWithUnmount(
  id: number,
  type: FileUploadType,
  role: UserRole,
  artistId?: number
): Promise<void> {
  return api.delete(
    `${getFileUploadUrl(role, type, artistId)}/${id}`
  )
}
