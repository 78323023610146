import React from "react"
import WinnerFormContainer from "../../container/CRM/ArtistCRM/WinnerForm.container"
import WinnerCRMLayout from "../../components/CRM/ArtistCRM/WinnerCRMLayout/WinnerCRMLayout.component"

const WinnerFormPage = (): JSX.Element => (
  <WinnerCRMLayout>
    <WinnerFormContainer />
  </WinnerCRMLayout>
)

export default WinnerFormPage
