import React, { useState, useEffect } from "react"
import "./EmbedContent.style.scss"
interface Props {
  content: string | null;
}

const vkRegex =
  /VK\.Widgets\.Playlist\("(.+)",\s?(-?\d+)\s?,\s?(\d+)\s?,\s?'(.*)'\);/i
const block = "embed-content"

const EmbedContent = (props: Props): JSX.Element => {
  const { content } = props
  const [currentFrame, setCurrentFrame] = useState<any>()

  const getFrame = () => {
    if (!content) {
      return
    }
    const vkMatch = content.match(vkRegex)
    if (vkMatch) {
      const [, elementId, ownerId, playlistId, hash] = vkMatch

      setCurrentFrame(<div className={`${block}__vk`} id={elementId}></div>)
      window.VK.Widgets.Playlist(
        elementId,
        Number(ownerId),
        Number(playlistId),
        hash
      )
    } else if (content.match(/<iframe/i)) {
      setCurrentFrame(
        <div
          dangerouslySetInnerHTML={{
            __html: `${content}`
          }}
        />
      )
    } else {
      setCurrentFrame(
        <div className={`${block}__error`}>
          {"Некорректное значение, посмотрите подсказку"}
        </div>
      )
    }
  }

  useEffect(() => {
    getFrame()
  }, [])

  useEffect(() => {
    getFrame()
  }, [content])

  return <>{currentFrame}</>
}

export default EmbedContent
