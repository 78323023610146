import moment from "moment"
import React, { useEffect, useState } from "react"
import { EvaluationInfoGetResponse } from "../../../../models/ExpertModels/Evaluation.model"
import LoaderComponent from "../../../common/Loader/Loader.component"
import { projectInSocialData } from "./data.const"
import { ReactComponent as Document } from "../../../../assets/icons/doc.svg"

import "./EvaluationInfo.style.scss"
import BudgetTable from "../BudgetTable/BudgetTable.component"
import { getFullPlace, getRusGenre } from "../../../../utils/viewUtils"
import { getEvaluatedArtist } from '../../../../services/expert/expertDashboard.service'
import { evaluationInfoGetStore } from '../../../../store/ExpertStore/ExpertEvaluation.store'

interface Props {
  data?: EvaluationInfoGetResponse;
  isLoading?: boolean;
}

const block = "evaluation-info"

const EvaluationInfoComponent = (props: Props): JSX.Element => {
  const { data, isLoading } = props
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const [formData, setFormData] = useState(data)
  const [artist, setArtist] = useState(data?.artist)
  const [members, setMembers] = useState(artist?.members)

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  useEffect(() => {    
    if (formData) {
      setArtist(formData.artist)
      setMembers(formData.artist.members)
    }    
  }, [formData])  

  useEffect(() => {
    const getData = async (id: number) => await getEvaluatedArtist(id)  

    if (data && !formData) {
      setFormData(data)
    } else {
      const id = Number(window.location.search.split('=')[1])
      if (id) {
        getData(id).then(res => res && setFormData(res))
      }
    }    
  }, [data])

  useEffect(() => {    
    if (evaluationInfoGetStore.evaluationInfo) setFormData(evaluationInfoGetStore.evaluationInfo)
  }, [evaluationInfoGetStore.evaluationInfo])

  return (
    <div className={block}>
      {isLoading ? (
        <LoaderComponent width={100} height={100} />
      ) : (
        <>
          <div className={`${block}__header`}>
            <div className={`${block}__header-image`}>
              <svg
                className="desktop"
                width="100vw"
                height="54.166vw"
                viewBox="0 0 1440 780"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M161.094 743H141L217.906 574H238L161.094 743Z"
                  fill="#E63C24"
                />
                {/* <path
                  d="M47 0H1440V780L1014.5 724.5L986.5 774L404.5 706L375 644L78.8672 611.394L47 0Z"
                  fill="#67DBCA"
                /> */}
                <mask
                  id="mask-cover"
                  mask-type="alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="1440"
                  height="704"
                >
                  <path d="M0 0H1440V479L1315 704L0 553V0Z" fill="black" />
                </mask>
                <g mask="url(#mask-cover)">
                  <image href={"ExpertCover.png"} />
                </g>
                <path
                  d="M113.094 695H93L169.906 526H190L113.094 695Z"
                  fill="#E63C24"
                />
              </svg>
              <div className={`${block}__image-container`}>
                <img src={artist?.photoSite} alt={artist?.name} />
              </div>
              <div className={`${block}__header-substrate`} />
            </div>
          </div>

          <div className={`${block}__info-section`}>
            <div className="content-container">
              <div className="page-title page-title--black">{artist?.name}</div>

              <div className={`${block}__genre`}>
                {getRusGenre(artist?.genre)}
              </div>

              <div className={`${block}__country`}>
                {getFullPlace(artist?.country, artist?.city)}
              </div>

              <div className={`${block}__description`}>
                <div className={`${block}__block-title`}>
                  Описание музыкального коллектива
                </div>
                <div className={`${block}__info-block`}>
                  {artist?.description}
                </div>
              </div>

              <div className={`${block}__social-links-wrapper`}>
                <div className={`${block}__block-title`}>
                  Музыкальный проект в социальных сетях
                </div>
                <div className={`${block}__social-links`}>
                  {artist &&
                    projectInSocialData.map(
                      item =>
                        artist[item.field] && (
                          <div
                            className={`${block}__social-links-item`}
                            key={item.field}
                          >
                            <a
                              href={artist[item.field] as string}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {React.createElement(item.icon)}
                            </a>
                          </div>
                        )
                    )}
                </div>
              </div>

              <div className={`${block}__social-links-wrapper`}>
                <div className={`${block}__block-title`}>
                  Треки, которые исполнитель предложил вам послушать
                </div>
                <div className={`${block}__music-links`}>
                  {artist &&
                    artist.musicLinks?.map((item, index) => (
                      <div
                        className={`${block}__music-links-item`}
                        key={`${item}-${index}`}
                      >
                        <a
                          href={item || ""}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item || ""}
                        </a>
                      </div>
                    ))}
                </div>
              </div>

              <div className={`${block}__section`}>
                <div className={`${block}__section-title`}>ПРОЕКТ</div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Название проекта, на реализацию которого запрашивается грант
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectName}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Краткое описание проекта
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectDescription}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Актуальность проекта для музыкального коллектива
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectRelevance}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>Цель проекта</div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectPoint}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Целевая аудитория проекта
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectTargetAudience}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Каналы коммуникации с целевыми группами проекта
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectPrStrategy}
                  </div>
                </div>

                <div className={`${block}__info-block-row`}>
                  <div className={`${block}__info-block-wrapper`}>
                    <div className={`${block}__block-title`}>
                      Дата начала реализации проекта
                    </div>
                    <div className={`${block}__info-block`}>
                      {moment(artist?.projectStart, "YYYY-MM-DD").format(
                        "DD.MM.YYYY"
                      )}
                    </div>
                  </div>

                  <div className={`${block}__info-block-wrapper`}>
                    <div className={`${block}__block-title`}>
                      Дата окончания реализации
                    </div>
                    <div className={`${block}__info-block`}>
                      {moment(artist?.projectEnd, "YYYY-MM-DD").format(
                        "DD.MM.YYYY"
                      )}
                    </div>
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Этапы реализации проекта с указанием сроков
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.calendarPlanText}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Количество участников мероприятий проекта
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectOffilneAudienceExpectations}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Охват аудитории творческим продуктом проекта
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectOnlineAudienceExpectations}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Медиа-охват проекта
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectMediaCoverageExpectations}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Ожидаемые качественные результаты проекта
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectExpectations}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>Бюджет проекта</div>
                  <div
                    className={`${block}__budget-block`}
                    onClick={() => setIsOpenModal(true)}
                  >
                    <div className={`${block}__document-block-img`}>
                      <Document />
                    </div>
                    <p className={`${block}__document-block-preview`}>
                      Смета проекта (<span>нажмите, чтобы посмотреть</span>)
                    </p>
                  </div>
                </div>
                <BudgetTable
                  formValues={artist?.budgetPlanJson || []}
                  onCloseModal={onCloseModal}
                  isOpenModal={isOpenModal}
                />

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Источники софинансирования
                  </div>
                  <div className={`${block}__info-block`}>
                    {artist?.projectBudgetSources}
                  </div>
                </div>
              </div>

              <div className={`${block}__section`}>
                <div
                  className={`${block}__section-title ${block}__info-block-wrapper`}
                >
                  РУКОВОДИТЕЛЬ КОЛЛЕКТИВА
                </div>
                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>ФИО</div>
                  <div className={`${block}__info-block`}>
                    {members && members[0]?.name}
                  </div>
                </div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Опыт музыкальной деятельности
                  </div>
                  <div className={`${block}__info-block`}>
                    {members && members[0]?.musicExperience}
                  </div>
                </div>

                {members && members.length > 1 && (
                  <div
                    className={`${block}__section-title ${block}__info-block-wrapper`}
                  >
                    УЧАСТНИКИ КОЛЛЕКТИВА
                  </div>
                )}

                {members &&
                  members
                    ?.filter(item => item.isLeader == false)
                    .map((item, index: number) => (
                      <div key={`${item}-${index}`}>
                        <div className={`${block}__info-block-wrapper`}>
                          <div className={`${block}__block-title`}>ФИО</div>
                          <div className={`${block}__info-block`}>
                            {item?.name}
                          </div>
                        </div>
                        <div className={`${block}__info-block-wrapper`}>
                          <div className={`${block}__block-title`}>
                            Опыт музыкальной деятельности
                          </div>
                          <div className={`${block}__info-block`}>
                            {item?.musicExperience}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>

              <div className={`${block}__section`}>
                <div className={`${block}__section-title`}>ДОКУМЕНТЫ</div>

                <div className={`${block}__info-block-wrapper`}>
                  <div className={`${block}__block-title`}>
                    Презентация коллектива
                  </div>
                  <div className={`${block}__document-block`}>
                    <div className={`${block}__document-block-img`}>
                      <Document />
                    </div>
                    <a
                      href={artist?.presentation?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {artist?.presentation?.name}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EvaluationInfoComponent
