import { ReactComponent as Artist } from "../../../../assets/icons/artist.svg"
import { ReactComponent as Expert } from "../../../../assets/icons/user2.svg"
import { ReactComponent as Cup } from "../../../../assets/icons/cup.svg"
import { ReactComponent as Reports } from "../../../../assets/icons/reports.svg"
import { PATH_ARTISTS, PATH_EXPERTS, PATH_FINALISTS, PATH_WINNERS } from "../../../../const/routes.const"
import { UserRole } from "../../../../const/permissionsRules.const"

export const nav = [
  { title: "Артисты", icon: Artist, pathnameCategory: PATH_ARTISTS.replace('/', ''), roles: [UserRole.ADMIN] },
  { title: "Эксперты", icon: Expert, pathnameCategory: PATH_EXPERTS.replace('/', ''), roles: [UserRole.ADMIN] },
  { 
    title: "Финалисты", 
    icon: Cup, 
    pathnameCategory: PATH_FINALISTS.replace('/', ''), 
    roles: [UserRole.ADMIN] 
  },
  { 
    title: "Отчеты", 
    icon: Reports, 
    pathnameCategory: PATH_WINNERS.replace('/', ''), 
    roles: [UserRole.ADMIN] 
  },
  { 
    title: "Оценки", 
    icon: Cup, 
    pathnameCategory: PATH_FINALISTS.replace('/', ''), 
    roles: [UserRole.FESTIVAL_EXPERT] 
  }
]
