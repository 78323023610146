import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import EvaluationFormComponent from '../../../components/CRM/ExpertCRM/EvaluationForm/EvaluationForm.component'
import { PATH_EXPERT_DASHBOARD } from '../../../const/routes.const'
import { EvaluateForm, EvaluateRequest, EvaluationValuationModel } from '../../../models/ExpertModels/Evaluation.model'
import { evaluateArtist } from '../../../services/expert/expertEvaluation.service'
import { evaluateRequestStore, evaluationInfoGetStore } from '../../../store/ExpertStore/ExpertEvaluation.store'
import { getEvaluatedArtist } from '../../../services/expert/expertDashboard.service'

const EvaluationFormContainer = (props: RouteComponentProps): JSX.Element => {
  const [id, setId] = useState<number>()
  const [formData, setFormData] = useState<EvaluationValuationModel>()

  const evaluate = (data: EvaluateForm): void => {
    const preparedRequest: EvaluateRequest = {
      reality: parseInt(data.reality),
      relevance: parseInt(data.relevance),
      quality: parseInt(data.quality),
      emotionalResponse: parseInt(data.emotionalResponse),
      comment: data.comment || undefined
    }
    
    evaluateArtist(preparedRequest, id ?? evaluationInfoGetStore.evaluationInfo?.valuation.id ?? 0).then(() => {
      props.history.push(PATH_EXPERT_DASHBOARD)
    })
  }

  useEffect(() => {    
    const getData = async (id: number) => await getEvaluatedArtist(id)  
    const id = Number(window.location.search.split('=')[1])
    
    if (id) {
      getData(id).then(res => res && setFormData(res.valuation))
      setId(id)
    }
  }, [])

  return (
    <EvaluationFormComponent
      onSubmit={evaluate}
      formData={formData}
      isLoading={evaluateRequestStore.isLoading}
      error={evaluateRequestStore.requestErrors && evaluateRequestStore.requestErrors[0].message}
    />
  )
}

export default withRouter(observer(EvaluationFormContainer))