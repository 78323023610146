import { Deserialize, Serialize } from "cerialize"
import { LoginRequest, LoginResponse, PassRecoveryRequest } from "../models/AuthModels/login.model"
import { RegisterRequest, RegisterResponse } from "../models/AuthModels/register.model"
import { RequestErrorResponse } from "../models/common/error.model"
import { loginStore, recoverPasswordStore, registrationStore } from "../store/AuthStore/auth.store"
import { api, setToken } from "../utils/api"

export function logIn(request: LoginRequest): Promise<LoginResponse> {
  const serializedData = Serialize(request, LoginRequest)
  loginStore.setIsLoading(true)

  return api
    .post("/auth/login", serializedData)
    .then((res) => {
      const data: LoginResponse = Deserialize(res.data, LoginResponse)

      loginStore.setIsLoading(false)
      setToken(data.token)

      return data
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data

      loginStore.setIsLoading(false)
      loginStore.setErrors(errorResponseData?.errors)
      
      throw error
    })
}

export function registerUser(request: RegisterRequest): Promise<RegisterResponse> {
  const serializedData = Serialize(request, RegisterRequest)
  registrationStore.setIsLoading(true)

  return api
    .post("/auth/signup", serializedData)
    .then((res) => {
      const data: RegisterResponse = Deserialize(res.data, RegisterResponse)

      registrationStore.setIsLoading(false)
      setToken(data.token)

      return data
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      registrationStore.setIsLoading(false)
      registrationStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function recoverUserPassword(request: PassRecoveryRequest): Promise<void> {
  const serializedData = Serialize(request, PassRecoveryRequest)
  recoverPasswordStore.setIsLoading(true)

  return api
    .post("/auth/send_reset_password_email", serializedData)
    .then(() => {
      recoverPasswordStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      recoverPasswordStore.setIsLoading(false)
      recoverPasswordStore.setErrors(errorResponseData?.errors)
      throw error
    })
}