import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import FinalistsListComponent from '../../../components/CRM/FestExpertCRM/FinalistsList/FinalistsList.component'
import {
  PATH_ARTISTS,
  PATH_FINALISTS,
  PATH_FINALISTS_RATED,
  PATH_FINALISTS_UNRATED
} from '../../../const/routes.const'
import { getFestExpertFinalists } from '../../../services/festExpert/festExpertFinalists.service'
import { festExpertFinalistsListStore } from '../../../store/FestExpertStore/festExpertFinalistsList.store'
import { FestExpertFinalistsListItemModel } from '../../../models/FestExpertModels/FinalistsList.model'

const currentCategory = `${PATH_FINALISTS}/`

const tabPathnames = [
  PATH_FINALISTS_UNRATED.replace(currentCategory, ''),
  PATH_FINALISTS_RATED.replace(currentCategory, '')
]

const routeCaregories: Map<string, boolean> = new Map([
  [PATH_FINALISTS_UNRATED, false],
  [PATH_FINALISTS_RATED, true]
])

interface FinalistData {
  finalists: FestExpertFinalistsListItemModel[] | undefined
  count: number | undefined
  totalCount: number | undefined
  currentOffset: number | undefined
  isLoading: boolean
}

const FinalistsListContainer = (): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const [tabIndex, setTabIndex] = useState(0)

  const [finalistData, setFinalistData] = useState<FinalistData>()

  const getFinalists = (
    pathname: string,
    search?: string,
    limit?: number,
    offset?: number
  ): void => {
    // TODO: fix infinite loading
    const requestParams = {
      evaluated: routeCaregories.get(pathname) || false,
      limit: 100,
      offset,
      search
    }

    getFestExpertFinalists(requestParams)
  }

  const getActiveTab = (path: string): number => {
    const pathnameIndex = tabPathnames.indexOf(path)

    return pathnameIndex !== -1 ? pathnameIndex : 0
  }

  const onSelect = (tabIndex: number): void => {
    setTabIndex(tabIndex)

    const pureNewPathname = `${currentCategory}${tabPathnames[tabIndex]}`
    const newPathname = `${pureNewPathname}${location.search}`
    const searchString =
      new URLSearchParams(location.search).get('name') || undefined

    history.replace(newPathname)
    festExpertFinalistsListStore.clearData()

    getFinalists(pureNewPathname, searchString)
  }

  const onEffect = (): (() => void) => {
    const pathSuffix = location.pathname.split('/').pop()

    if (tabPathnames.indexOf(pathSuffix || '') < 0) {
      history.replace(PATH_FINALISTS_UNRATED)
    }

    const searchString =
      new URLSearchParams(location.search).get('name') || undefined

    getFinalists(location.pathname, searchString)
    setTabIndex(getActiveTab(pathSuffix || ''))

    return (): void => {
      festExpertFinalistsListStore.clearData()
    }
  }

  const onSearchChange = (searchString: string): void => {
    festExpertFinalistsListStore.clearData()

    getFinalists(location.pathname, searchString || '')
  }

  const getShouldLoadMore = (): boolean => {
    if (finalistData?.count && finalistData?.finalists) {
      return finalistData.count > finalistData.finalists.length
    } else return true
  }

  const onLoadMore = (): void => {
    getFinalists(
      location.pathname,
      new URLSearchParams(location.search).get('name') || undefined,
      20,
      finalistData?.currentOffset ? finalistData.currentOffset + 20 : 20
    )

    festExpertFinalistsListStore.setOffset(
      finalistData?.currentOffset ? finalistData.currentOffset + 20 : 20
    )
  }

  const goToArtist = (artistId: number): void => {
    history.push(`${PATH_ARTISTS}/${artistId}`, {
      category: location.pathname
    })
  }

  useEffect(onEffect, [])

  useEffect(() => {
    setFinalistData(festExpertFinalistsListStore.getData())
  }, [festExpertFinalistsListStore.getFinalists()])

  return (
    <FinalistsListComponent
      tabIndex={tabIndex}
      finalistsCount={finalistData?.totalCount}
      onSelect={onSelect}
      finalists={finalistData?.finalists}
      isLoading={!!finalistData?.isLoading}
      onSearchChange={onSearchChange}
      onLoadMore={onLoadMore}
      onArtistClick={goToArtist}
      shouldLoadMore={getShouldLoadMore()}
    />
  )
}

export default observer(FinalistsListContainer)
