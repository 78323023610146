import classNames from 'classnames'
import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import LazyLoad from 'react-lazyload'
import SearchInputContainer from '../../../../container/CRM/AdminCRM/SearchInput.container'
import { FestExpertFinalistsListItemModel } from '../../../../models/FestExpertModels/FinalistsList.model'
import LoaderComponent from '../../../common/Loader/Loader.component'
import CRMListItemComponent from '../../AdminCRM/CrmListItem/CrmListItem.component'
import LazyLoadComponent from '../../common/LazyLoad/LazyLoad.component'

import './FinalistsList.style.scss'

interface Props {
  isLoading: boolean
  finalists?: FestExpertFinalistsListItemModel[]
  onSearchChange: (value: string) => void
  onLoadMore: () => void
  onArtistClick: (id: number) => void
  shouldLoadMore: boolean
  tabIndex: number
  onSelect: (tabIndex: number) => void
  finalistsCount?: number
}

const tabNames: { [key in any]: string } = {
  unrated: 'Неоцененные',
  rated: 'Оцененные'
}

const block = 'fest-expert-finalists-list'

const FinalistsListComponent = (props: Props): JSX.Element => {
  const {
    tabIndex,
    onSelect,
    finalistsCount,
    isLoading,
    finalists,
    onSearchChange,
    shouldLoadMore,
    onLoadMore,
    onArtistClick
  } = props

  const onLoadMoreHandle = (): void => {
    !isLoading && onLoadMore()
  }

  return (
    <div className={`${block} admin-layout-content`}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex: number): void => onSelect(tabIndex)}
      >
        <div className="admin-layout-content__header">
          <div className="page-title page-title--black admin-layout-content__title">
            ОЦЕНКИ
          </div>
          <TabList>
            {Object.keys(tabNames).map((category, index) => (
              <Tab key={category}>
                {tabIndex === index && (
                  <div className="admin-layout-content__counter">
                    {finalistsCount || 0}
                  </div>
                )}
                {tabNames[category as any]}
              </Tab>
            ))}
          </TabList>

          <div className="admin-layout-content__controls">
            <SearchInputContainer onSearchChange={onSearchChange} />
          </div>
        </div>

        {isLoading && !finalists?.length && (
          <div className="admin-layout-content__loading">
            <LoaderComponent width={100} height={100} />
          </div>
        )}

        <div
          className={classNames('admin-layout-content__body', `${block}__body`)}
        >
          {finalists && finalists.length ? (
            <>
              {finalists.map((finalist, index) => (
                <CRMListItemComponent
                  key={finalist.artist.id}
                  title={finalist.artist.name}
                  image={finalist.artist.photo || undefined}
                  onClick={(): void => onArtistClick(finalist.artist.id)}
                  rating={finalist.valuation}
                  number={index + 1}
                />
              ))}
              {shouldLoadMore && (
                <LazyLoad height={10} unmountIfInvisible={true}>
                  <LazyLoadComponent
                    isLoading={isLoading}
                    onMount={onLoadMoreHandle}
                  />
                </LazyLoad>
              )}
            </>
          ) : (
            !isLoading && (
              <div className="admin-layout-content__empty">Список пуст</div>
            )
          )}
        </div>
      </Tabs>
    </div>
  )
}

export default FinalistsListComponent
