import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import {
  PATH_EVALUATION,
  PATH_EXPERT_DASHBOARD
} from "../../../const/routes.const"
import EvaluationPage from "../../../pages/EvaluationPage/Evaluation.page"
import ExpertDashboardPage from "../../../pages/ExpertDashboardPage/ExpertDashboard.page"

const ExpertCRM = (): JSX.Element => (
  <Switch>
    <Route exact path={PATH_EXPERT_DASHBOARD}>
      <ExpertDashboardPage />
    </Route>

    <Route exact path={PATH_EVALUATION}>
      <EvaluationPage />
    </Route>

    <Redirect to={PATH_EXPERT_DASHBOARD} />
  </Switch>
)

export default ExpertCRM
