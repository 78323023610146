import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { PATH_FINALISTS, PATH_ARTISTS } from "../../../const/routes.const"
import FestExpertFinalistsPage from "../../../pages/FestExpertFinalistsPage/FestExpertFinalists.page"
import FestExpertEvaluationPage from "../../../pages/FestExpertEvaluationPage/FestExpertEvaluation.page"

const FestExpertCRM = (): JSX.Element => (
  <Switch>
    <Route path={PATH_FINALISTS}>
      <FestExpertFinalistsPage />
    </Route>

    <Route exact path={`${PATH_ARTISTS}/:artistId`}>
      <FestExpertEvaluationPage />
    </Route>

    <Redirect to={PATH_FINALISTS} />
  </Switch>
)

export default FestExpertCRM
