import { Deserialize, Serialize } from "cerialize"
import { ArtistListGetRequest, ArtistListGetResponse } from "../../models/AdminModels/ArtistList.model"
import { RequestErrorResponse } from "../../models/common/error.model"
import { artistListStore } from "../../store/AdminStore/artistList.store"
import { api } from "../../utils/api"

export function getArtistList(request: ArtistListGetRequest): Promise<void> {
  const serializedData = Serialize(request, ArtistListGetRequest) 

  artistListStore.setIsLoading(true)

  return api
    .get(
      "/admin/artists",
      {
        params: serializedData
      }
    )
    .then((res) => {
      const data: ArtistListGetResponse = Deserialize(
        res.data,
        ArtistListGetResponse
      )

      artistListStore.setArtistFormsData({
        ...data
      })
      artistListStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      artistListStore.setIsLoading(false)
      artistListStore.setErrors(errorResponseData?.errors)
    })
}
