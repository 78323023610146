import React from 'react'

import './MainLayout.style.scss'

interface Props {
  children: React.ReactNode;
}

const block = 'main-layout'

const MainLayout = (props: Props): JSX.Element => {
  const { children } = props

  return (
    <div className={block}>
      <div className={`${block}__content`}>
        {children}
      </div>
    </div>
  )
}

export default MainLayout