import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import DashboardComponent from '../../../components/CRM/ExpertCRM/Dashboard/Dashboard.component'
import { PATH_EVALUATION } from '../../../const/routes.const'
import { getExpertDashboardData } from '../../../services/expert/expertDashboard.service'
import { expertDashboardGetStore } from '../../../store/ExpertStore/ExpertDashboard.store'

const DashboardContainer = (props: RouteComponentProps): JSX.Element => {  
  const goToEvaluation = (id?: number): void => {
    props.history.push({pathname: PATH_EVALUATION, search: id ? `id=${id}`: ''})
  }

  useEffect(() => {
    getExpertDashboardData()
  }, [])

  return (
    <DashboardComponent 
      onGoToEvaluationClick={goToEvaluation}
      isDataLoading={expertDashboardGetStore.isLoading}
      dashboardData={expertDashboardGetStore.dashboardData}
    />
  )
}

export default withRouter(observer(DashboardContainer))