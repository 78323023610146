import { Deserialize, Serialize } from "cerialize"
import { FinalistsListGetRequest, FinalistsListGetResponse } from "../../models/AdminModels/FinalistsList.model"
import { RequestErrorResponse } from "../../models/common/error.model"
import { finalistsListStore } from "../../store/AdminStore/finalistsList.store"
import { api } from "../../utils/api"

export function getFinalistsList(request: FinalistsListGetRequest): Promise<void> {
  const serializedData = Serialize(request, FinalistsListGetRequest) 

  finalistsListStore.setIsLoading(true)

  return api
    .get(
      "/admin/evaluated",
      {
        params: serializedData
      }
    )
    .then((res) => {
      const data: FinalistsListGetResponse = Deserialize(
        res.data,
        FinalistsListGetResponse
      )

      finalistsListStore.setFinalistsListData({
        ...data
      })
      finalistsListStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      finalistsListStore.setIsLoading(false)
      finalistsListStore.setErrors(errorResponseData?.errors)
    })
}
