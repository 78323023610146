import { action, observable } from 'mobx'
import { ExpertDashboardGetResponse } from '../../models/ExpertModels/ExpertDashboard.model'
import { ApiRequestStore } from '../common/apiRequest.store'

class ExpertDashboardGetStore extends ApiRequestStore {
  @observable dashboardData?: ExpertDashboardGetResponse

  @action
  setDashboardData(data: ExpertDashboardGetResponse): void {
    this.dashboardData = data
  }
}

export const expertDashboardGetStore: ExpertDashboardGetStore = new ExpertDashboardGetStore()
