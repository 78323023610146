import { observer } from "mobx-react"
import React, { useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import CRMHeaderComponent from "../../../components/CRM/CRMHeader/CRMHeader.component"
import { UserRole } from "../../../const/permissionsRules.const"
import {
  PATH_ARTIST_EVALUATION,
  PATH_ARTIST_NOTIFICATIONS,
  PATH_ARTIST_REQUEST_READ
} from "../../../const/routes.const"
import { authContext } from "../../../context/auth.context"
import { ArtistFormStatus } from "../../../models/ArtistModels/artistForm.model"
import { artistDataStore } from "../../../store/ArtistStore/artistData.store"
import {
  artistFormApplyStore,
  artistFormGetStore,
  artistFormSaveStore,
  artistSaveMembersStore
} from "../../../store/ArtistStore/artistForm.store"
import { artistFormNotificationsStore } from "../../../store/ArtistStore/artistFormNotifications.store"

const headerData = {
  title: "ЗАЯВКА НА УЧАСТИЕ В КОНКУРСЕ",
  text: `Внимательно заполняйте все поля и получите шанс стать участником New/Open. 
  Необходимо заполнить все обязательные пункты формы и нажать кнопку "Подать заявку", 
  чтобы модераторы смогли проверить правильность заполнения заявки. 
  После проверки организаторами в ваш личный кабинет придет уведомление об успешной модерации, 
  это означает, что заявка будет оценена экспертами.`
}

const ArtistCRMHeaderContainer = (): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const { user } = useContext(authContext)

  const onSave = (): void => {
    const loading =
      artistFormSaveStore.isLoading ||
      artistFormApplyStore.isLoading ||
      artistSaveMembersStore.isLoading
    const form = document.getElementById("artist-form")
    form &&
      !loading &&
      form.dispatchEvent(new Event("submit", { cancelable: true }))
  }

  const onBack = (): void => {
    history.length > 0 && history.goBack()
  }

  const isRatingPage = location.pathname === PATH_ARTIST_EVALUATION

  const shouldShowStatus = (): boolean =>
    user.role === UserRole.ARTIST &&
    (location.pathname === PATH_ARTIST_NOTIFICATIONS ||
      location.pathname === PATH_ARTIST_REQUEST_READ)

  return (
    <CRMHeaderComponent
      {...headerData}
      hasEyeLabel={shouldShowStatus() && !isRatingPage}
      onSave={
        artistDataStore.formStatus === ArtistFormStatus.EDITING &&
        user.role === UserRole.ARTIST
          ? onSave
          : undefined
      }
      artistFormStatus={
        artistFormGetStore.formData?.meta?.status ||
        artistFormNotificationsStore.notificationsData?.form?.status
      }
      shouldShowStatus={shouldShowStatus()}
      saveTime={
        artistDataStore.formStatus === ArtistFormStatus.EDITING
          ? artistFormSaveStore.saveTime
          : undefined
      }
      onBack={isRatingPage ? onBack : undefined}
    />
  )
}

export default observer(ArtistCRMHeaderContainer)
