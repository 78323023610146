import { action, makeObservable, observable } from "mobx"
import { ArtistFormStatus } from "../../models/ArtistModels/artistForm.model"

class ArtistDataStore {
  @observable formStatus?: ArtistFormStatus;

  constructor() {
    makeObservable(this)
  }

  @action
  setFormStatus(status: ArtistFormStatus): void {
    this.formStatus = status
  }
}

export const artistDataStore: ArtistDataStore = new ArtistDataStore()
