export const getRusGenre = (genre : string | undefined): string => {
  switch (genre) {
    case "metal":
      return "Металл"
    case "classic":
      return "Классика"
    case "rock":
      return "Рок"
    case "pop":
      return "Поп"
    case "electro":
      return "Электро"
    case "hiphop":
      return "Хип-Хоп"
    case "folk":
      return "Фолк"
    case "jazz":
      return "Джаз"

    default:
      return ''
  }
}

export const getFullPlace = (country: string | undefined, city: string | undefined): string => {
  if (country && city) {
    return `${country} / ${city}`
  }
  if (country) {
    return country
  }
  if (city) {
    return city
  }

  return ''
}
