import { observer } from "mobx-react"
import React, { useEffect } from "react"
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  Redirect
} from "react-router-dom"
import {
  PATH_ARTIST_REQUEST,
  PATH_ARTIST_NOTIFICATIONS,
  PATH_ARTIST_EVALUATION,
  PATH_ARTIST_REQUEST_READ,
  PATH_ARTIST_REPORT,
  PATH_ARTIST_REPORT_READ
} from "../../../const/routes.const"
import { getArtistFormNotifications } from "../../../services/artist/artistFormNotifications.services"
import { artistFormNotificationsStore } from "../../../store/ArtistStore/artistFormNotifications.store"
import ArtistCRMLayout from "./ArtistCRMLayout/ArtistCRMLayout.component"
import LoaderComponent from "../../common/Loader/Loader.component"
import ArtistNotificationsContainer from "../../../container/CRM/ArtistCRM/ArtistNotifications.container"
import ArtistFormPage from "../../../pages/ArtistFormPage/ArtistForm.page"
import WinnerFormPage from "../../../pages/WinnerFormPage/WinnerForm.page"
import ArtistRatingPage from "../../../pages/ArtistRatingPage/ArtistRating.page"

const ArtistCRM = (props: RouteComponentProps): JSX.Element => {
  const onEffect = (): void => {
    getArtistFormNotifications().then((notificationsData) => {
      !!notificationsData?.form?.appliedAt
        ? // notificationsData.notifications.length
          props.history.replace(PATH_ARTIST_NOTIFICATIONS)
        : props.history.replace(PATH_ARTIST_REQUEST)
    })
  }

  useEffect(onEffect, [])

  return artistFormNotificationsStore.notificationsData ? (
    <Switch>
      <Route path={PATH_ARTIST_NOTIFICATIONS}>
        <ArtistCRMLayout>
          <ArtistNotificationsContainer />
        </ArtistCRMLayout>
      </Route>
      <Route path={PATH_ARTIST_REQUEST_READ}>
        <ArtistFormPage />
      </Route>
      <Route path={PATH_ARTIST_REQUEST}>
        <ArtistFormPage />
      </Route>
      <Route path={`${PATH_ARTIST_REPORT_READ}/:stage`}>
        <WinnerFormPage />
      </Route>
      <Route path={`${PATH_ARTIST_REPORT}/:stage`}>
        <WinnerFormPage />
      </Route>
      <Route path={PATH_ARTIST_EVALUATION}>
        <ArtistRatingPage />
      </Route>

      <Redirect to={PATH_ARTIST_NOTIFICATIONS} />
    </Switch>
  ) : (
    <LoaderComponent width={100} height={100} />
  )
}

export default withRouter(observer(ArtistCRM))
