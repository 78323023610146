import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: "https://b1b1bed8af0546408958d6e1c30c3489@o464965.ingest.sentry.io/5476409",
    integrations: [
      new Integrations.BrowserTracing()
    ],

    tracesSampleRate: 1.0
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
