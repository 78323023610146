import React from 'react'
import ArtistCRMLayout from '../../components/CRM/ArtistCRM/ArtistCRMLayout/ArtistCRMLayout.component'
import ArtistRatingContainer from '../../container/CRM/ArtistCRM/ArtistRating.container'

const ArtistRatingPage = (): JSX.Element => (
  <ArtistCRMLayout>
    <ArtistRatingContainer />
  </ArtistCRMLayout>
)

export default ArtistRatingPage