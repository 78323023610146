import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { LoginRequest } from '../../../models/AuthModels/login.model'
import InputComponent from '../../common/Input/Input.component'
import AuthFormLayoutComponent from '../AuthFormLayout/AuthFormLayout.component'
import { isEmailValid } from '../../../const/validators.const'
import Button from '../../common/Button/Button.component'

interface Props {
  error?: string;
  isLoading?: boolean;
  onSubmit: (data: LoginRequest) => void;
  onPassRecoveryClick: () => void;
}

const block = 'login-form'

const LoginFormComponent = (props: Props): JSX.Element => {
  const { error, isLoading, onSubmit, onPassRecoveryClick } = props
  const {
    handleSubmit,
    errors,
    control
  } = useForm<LoginRequest>()

  return (
    <AuthFormLayoutComponent 
      title="АВТОРИЗАЦИЯ" 
      className={block}
      error={error}
    >
      <div className={'auth-form-layout__input'}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            },
            validate: {
              validEmail: (value): boolean | string => isEmailValid(value) || 'Введите корректный Email'
            }
          }}
          defaultValue=""
          control={control} 
          name="email"
          render={({ onChange, value, name }): JSX.Element => (
            <InputComponent
              onChange={(event): void => onChange(event.target.value.trim())}
              value={value.trim()}
              name={name}
              mask={false}
              placeholder={'E-mail'}
              error={errors.email?.message}
              isErrorStatic={true}
            />
          )}
        />
      </div>
      <div className={'auth-form-layout__input'}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            }
          }}
          defaultValue=""
          control={control} 
          name="password" 
          render={({ onChange, value, name }): JSX.Element => (
            <InputComponent
              onChange={onChange}
              value={value}
              name={name}
              mask={false}
              placeholder={'Пароль'}
              error={errors.password?.message}
              isErrorStatic={true}
              type={'password'}
            />
          )}
        />
      </div>
      <div className={`auth-form-layout__pass-recovery`} onClick={onPassRecoveryClick}>
        Забыли пароль?
      </div>
      <div className={`auth-form-layout__button`}>
        <Button 
          background="turquoise" 
          onClick={handleSubmit(onSubmit)}
          isDisabled={isLoading}
          text={'Войти'}
        />
      </div>
    </AuthFormLayoutComponent>
  )
}

export default LoginFormComponent