/* eslint-disable max-len */
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PATH_FINALISTS_UNRATED } from '../../../const/routes.const'

import {
  EvaluateProjectDefenseForm,
  EvaluateProjectDefenseRequest,
  EvaluateMusicPerfomanceRequest,
  EvaluateMusicPerfomanceForm
} from '../../../models/FestExpertModels/Evaluation.model'
import {
  evaluateArtistMusicPerfomance,
  evaluateArtistProjectDefense
} from '../../../services/festExpert/festExpertEvaluation.service'
import EvaluationMusicPerfomanceFormComponent from '../../../components/CRM/FestExpertCRM/EvaluationMusicPerfomanceForm/EvaluationMusicPerfomanceForm.component'
import EvaluationProjectDefenseFormComponent from '../../../components/CRM/FestExpertCRM/EvaluationProjectDefenseForm/EvaluationProjectDefenseForm.component'
import {
  evaluateProjectDefenseRequestStore,
  evaluateMusicPerfomanceRequestStore,
  artistInfoGetStore
} from '../../../store/FestExpertStore/festExpertArtistInfo.store'

const FestExpertEvaluationFormContainer = (
  props: RouteComponentProps
): JSX.Element => {
  const [musicPerfomance, setMusicPerfomance] = useState<
    EvaluateMusicPerfomanceForm | undefined
  >()
  const [projectDefense, setProjectDefense] = useState<
    EvaluateProjectDefenseForm | undefined
  >()

  const [availableToEvaluate, setAvailableToEvaluate] = useState<{
    musicPerfomance: boolean | undefined
    projectDefense: boolean | undefined
  }>()

  const evaluateProjectDefense = (data: EvaluateProjectDefenseForm): void => {
    const preparedRequest: EvaluateProjectDefenseRequest = {
      reality: parseInt(data.reality),
      relevance: parseInt(data.relevance),
      realizability: parseInt(data.realizability),
      scale: parseInt(data.scale)
    }

    evaluateArtistProjectDefense(
      preparedRequest,
      artistInfoGetStore.artistInfo?.id || 0
    ).then(() => {
      props.history.goBack()
    })
  }

  const evaluateMusicPerfomance = (data: EvaluateMusicPerfomanceForm): void => {
    const preparedRequest: EvaluateMusicPerfomanceRequest = {
      quality: parseInt(data.quality),
      artistry: parseInt(data.artistry),
      emotionalResponse: parseInt(data.emotionalResponse)
    }

    evaluateArtistMusicPerfomance(
      preparedRequest,
      artistInfoGetStore.artistInfo?.id || 0
    ).then(() => {
      props.history.goBack()
    })
  }

  useEffect(() => {
    const val = artistInfoGetStore.getValuation()
    const isAvailable = artistInfoGetStore.getAvailableToEvaluate()

    setAvailableToEvaluate({
      musicPerfomance: isAvailable?.musicPerfomance,
      projectDefense: isAvailable?.projectDefense
    })
    setMusicPerfomance(val?.musicPerfomance)
    setProjectDefense(val?.projectDefense)
  }, [artistInfoGetStore.getIsLoading()])

  return (
    <>
      <EvaluationMusicPerfomanceFormComponent
        onSubmit={evaluateMusicPerfomance}
        isLoading={evaluateProjectDefenseRequestStore.isLoading}
        error={
          evaluateProjectDefenseRequestStore.requestErrors &&
          evaluateProjectDefenseRequestStore.requestErrors[0].message
        }
        isDisabled={!availableToEvaluate?.musicPerfomance}
        defaultValues={musicPerfomance}
      />
      <EvaluationProjectDefenseFormComponent
        onSubmit={evaluateProjectDefense}
        isLoading={evaluateMusicPerfomanceRequestStore.isLoading}
        error={
          evaluateMusicPerfomanceRequestStore.requestErrors &&
          evaluateMusicPerfomanceRequestStore.requestErrors[0].message
        }
        isDisabled={!availableToEvaluate?.projectDefense}
        defaultValues={projectDefense}
      />
    </>
  )
}

export default withRouter(observer(FestExpertEvaluationFormContainer))
