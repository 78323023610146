import { autoserialize, autoserializeAs } from "cerialize"
import { BudgetRowModel, MemberModel } from "../ArtistModels/artistForm.model"

export class EvaluationLeaderModel {
  @autoserialize name: string;
  @autoserialize role: string;
  @autoserializeAs('music_experience') musicExperience: string;
  @autoserializeAs('facebook_url') facebookUrl: string | null;
  @autoserializeAs('instagram_url') instagramUrl: string | null;
  @autoserializeAs('youtube_url') youtubeUrl: string | null;
  @autoserializeAs('vkontakte_url') vkontakteUrl: string | null;
  @autoserializeAs('tiktok_url') tiktokUrl: string | null;
  @autoserializeAs('twitter_url') twitterUrl: string | null;

  constructor(
    name: string,
    role: string,
    musicExperience: string,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    tiktokUrl: string | null,
    twitterUrl: string | null
  ) {
    this.name = name
    this.role = role
    this.musicExperience = musicExperience
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.youtubeUrl = youtubeUrl
    this.vkontakteUrl = vkontakteUrl
    this.tiktokUrl = tiktokUrl
    this.twitterUrl = twitterUrl
  }
}

export class EvaluationFileModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize url: string;

  constructor(
    id: number, 
    name: string,
    url: string
  ) {
    this.id = id
    this.name = name
    this.url = url
  }
}

export class EvaluationArtistModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize description: string;
  @autoserialize country: string;
  @autoserialize city: string;
  @autoserialize genre: string;
  @autoserializeAs('facebook_url') facebookUrl: string | null;
  @autoserializeAs('instagram_url') instagramUrl: string | null;
  @autoserializeAs('youtube_url') youtubeUrl: string | null;
  @autoserializeAs('vkontakte_url') vkontakteUrl: string | null;
  @autoserializeAs('twitter_url') twitterUrl: string | null;
  @autoserializeAs('tiktok_url') tiktokUrl: string | null;
  @autoserializeAs("music_links") musicLinks: Array<string | null>;
  @autoserializeAs(MemberModel) members: MemberModel[];
  @autoserializeAs('project_name') projectName: string;
  @autoserializeAs('project_description') projectDescription: string;
  @autoserializeAs('project_start') projectStart: string;
  @autoserializeAs('project_end') projectEnd: string;
  @autoserializeAs('project_point') projectPoint: string;
  @autoserializeAs("project_target_audience") projectTargetAudience: string| null;
  @autoserializeAs("project_online_audience_expectations")
  projectOnlineAudienceExpectations: number | null;
  @autoserializeAs("project_offilne_audience_expectations")
  projectOffilneAudienceExpectations: number | null;
  @autoserializeAs("project_media_coverage_expectations")
  projectMediaCoverageExpectations: number | null;
  @autoserializeAs("calendar_plan_text") calendarPlanText: string | null;
  @autoserializeAs('project_relevance') projectRelevance: string;
  @autoserializeAs('project_pr_strategy') projectPrStrategy: string;
  @autoserializeAs('project_implementation_if_epidemic') projectImplementationIfEpidemic: string;
  @autoserializeAs('project_expectations') projectExpectations: string;
  @autoserializeAs('project_audience_expectations') projectAudienceExpectations: string;
  @autoserializeAs('project_budget') projectBudget: number;
  @autoserializeAs('project_budget_sources') projectBudgetSources: string;
  @autoserializeAs(EvaluationLeaderModel) leader: EvaluationLeaderModel;
  @autoserializeAs('photo_site') photoSite: string;
  @autoserializeAs('calendar_plan') calendarPlan: EvaluationFileModel;
  @autoserializeAs('budget_plan') budgetPlan: EvaluationFileModel;
  @autoserializeAs(BudgetRowModel, "budget_plan_json")
  budgetPlanJson: BudgetRowModel[];
  @autoserialize presentation: EvaluationFileModel | null;

  constructor(
    id: number,
    name: string,
    description: string,
    country: string,
    city: string,
    genre: string ,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    twitterUrl: string | null,
    tiktokUrl: string | null,
    musicLinks: Array<string | null>,
    members: MemberModel[],
    projectName: string,
    projectDescription: string,
    projectStart: string,
    projectEnd: string,
    projectPoint: string,
    projectTargetAudience: string| null,
    projectOnlineAudienceExpectations: number | null,
    projectOffilneAudienceExpectations: number | null,
    projectMediaCoverageExpectations: number | null,
    calendarPlanText: string | null,
    projectRelevance: string,
    projectPrStrategy: string,
    projectImplementationIfEpidemic: string,
    projectExpectations: string,
    projectAudienceExpectations: string,
    projectBudget: number,
    projectBudgetSources: string,
    leader: EvaluationLeaderModel,
    photoSite: string,
    calendarPlan: EvaluationFileModel,
    budgetPlan: EvaluationFileModel,
    budgetPlanJson: BudgetRowModel[],
    presentation: EvaluationFileModel | null
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.country = country
    this.city = city
    this.genre = genre
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.youtubeUrl = youtubeUrl
    this.vkontakteUrl = vkontakteUrl
    this.twitterUrl = twitterUrl
    this.tiktokUrl = tiktokUrl
    this.musicLinks = musicLinks
    this.members = members
    this.projectName = projectName
    this.projectDescription = projectDescription
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.projectPoint = projectPoint
    this.projectTargetAudience = projectTargetAudience
    this.projectRelevance = projectRelevance
    this.projectPrStrategy = projectPrStrategy
    this.projectImplementationIfEpidemic = projectImplementationIfEpidemic
    this.projectExpectations = projectExpectations
    this.projectAudienceExpectations = projectAudienceExpectations
    this.projectBudget = projectBudget
    this.projectBudgetSources = projectBudgetSources
    this.projectOnlineAudienceExpectations = projectOnlineAudienceExpectations
    this.projectOffilneAudienceExpectations = projectOffilneAudienceExpectations
    this.projectMediaCoverageExpectations = projectMediaCoverageExpectations
    this.calendarPlanText = calendarPlanText
    this.leader = leader
    this.photoSite = photoSite
    this.calendarPlan = calendarPlan
    this.budgetPlan = budgetPlan
    this.budgetPlanJson = budgetPlanJson
    this.presentation = presentation
  }
}

export class EvaluationValuationModel {
  @autoserialize id: number;
  @autoserialize reality: number;
  @autoserialize relevance: number;
  @autoserialize quality: number;
  @autoserializeAs('emotional_response') emotionalResponse: number
  @autoserialize comment: string;
  @autoserializeAs('Asreceived_at') AsreceivedAt: string;
  @autoserializeAs('sent_at') senAt: string;

    constructor(
      id: number,
      reality: number,
  relevance:number,
  quality:number,
  emotionalResponse: number,
  comment:string,
  AsreceivedAt: string,
  senAt: string
    ) {
      this.id = id
      this.reality = reality
      this.relevance = relevance
      this.quality = quality
      this.emotionalResponse = emotionalResponse
      this.comment = comment
      this.AsreceivedAt = AsreceivedAt
      this.senAt = senAt
    }
}

export class EvaluationInfoGetResponse {
  @autoserializeAs(EvaluationArtistModel) artist: EvaluationArtistModel;
  @autoserializeAs(EvaluationValuationModel) valuation: EvaluationValuationModel;

  constructor(
    artist: EvaluationArtistModel,
    valuation: EvaluationValuationModel
  ) {
    this.artist = artist
    this.valuation = valuation
  }
}

export class EvaluateRequest {
  @autoserialize reality: number;
  @autoserialize relevance: number;
  @autoserialize quality: number;
  @autoserializeAs('emotional_response') emotionalResponse: number;
  @autoserialize comment?: string;

  constructor(
    reality: number,
    relevance: number,
    quality: number,
    emotionalResponse: number
  ) {
    this.reality = reality
    this.relevance = relevance
    this.quality = quality
    this.emotionalResponse = emotionalResponse
  }
}

export class EvaluateForm {
  @autoserialize reality: string;
  @autoserialize relevance: string;
  @autoserialize quality: string;
  @autoserializeAs('emotional_response') emotionalResponse: string;
  @autoserialize comment: string | null;

  constructor(
    reality: string,
    relevance: string,
    quality: string,
    emotionalResponse: string,
    comment: string | null
  ) {
    this.reality = reality
    this.relevance = relevance
    this.quality = quality
    this.emotionalResponse = emotionalResponse
    this.comment = comment
  }
}