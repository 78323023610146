import React from 'react'
import classNames from 'classnames'

import './AuthInfo.style.scss'
import Button from '../../common/Button/Button.component'

interface Props {
  data: {
    title: string;
    text: string;
    buttonText: string;
  };
  onClick: () => void;
}

const block = "auth-info"

const AuthInfoComponent = (props: Props): JSX.Element => {
  const { data, onClick } = props

  return(
    <div className={block}>
      <div className={`${block}__title`}>
        {data.title}
      </div>
      <div className={classNames(`${block}__text`, 'margin', 'margin--top-m')}>
        {data.text}
      </div>
      <div className={`${block}__button margin margin--top-m`}>
        <Button text={data.buttonText} background="white" onClick={onClick} />
      </div>
    </div>
  )
}

export default AuthInfoComponent