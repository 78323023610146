import { observer } from "mobx-react"
import React, { useContext, useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { UserRole } from "../../../const/permissionsRules.const"
import {
  PATH_ARTIST_NOTIFICATIONS,
  PATH_WINNERS,
  PATH_ARTIST_REPORT_READ,
  PATH_WINNER_MODERATE_STAGE_1,
  PATH_WINNER_MODERATE_STAGE_2,
  PATH_WINNER_MODERATE,
  PATH_ARTIST_REPORT
} from "../../../const/routes.const"
import { authContext } from "../../../context/auth.context"

import { formCommentStore } from "../../../store/AdminStore/formComment.store"
import { artistDataStore } from "../../../store/ArtistStore/artistData.store"
import {
  formatDateForClient,
  formatDateForServer
} from "../../../utils/formatDate"
import WinnerFormComponent from "../../../components/CRM/ArtistCRM/WinnerForm/WinnerForm.component"
import {
  WinnerFormFields,
  WinnerFormStatus,
  WinnerFormSaveRequest
} from "../../../models/ArtistModels/winnerForm.model"
import {
  winnerFormGetStore,
  winnerFormSaveStore,
  winnerFormApplyStore,
  winnerFormReadStore,
  winnerFormModerateStore
} from "../../../store/ArtistStore/winnerForm.store"
import {
  getWinnerForm,
  saveWinnerForm,
  applyForm,
  commentByAdmin,
  commentByArtist,
  moderateWinner,
  checkForm
} from "../../../services/artist/winnerForm.service"
import { winnerDataStore } from "../../../store/ArtistStore/winnerData.store"

const currentCategory = `${PATH_WINNER_MODERATE}/`

const tabPathnames = [
  PATH_WINNER_MODERATE_STAGE_1.replace(currentCategory, ""),
  PATH_WINNER_MODERATE_STAGE_2.replace(currentCategory, "")
]

const WinnerFormContainer = (): JSX.Element => {
  const { user } = useContext(authContext)
  const artistId = parseInt(useParams<{ artistId: string }>().artistId)
  const history = useHistory()
  const location = useLocation<{ category: string }>()
  const [activeSidebarTab, setActiveSidebarTab] = useState<
    "comments" | "hints"
  >("comments")

  const [tabIndex, setTabIndex] = useState<number>(0)

  const [isFormReadonlyState, setIsFormReadonlyState] =
    useState<boolean>(false)

  const getFormInitialValues = (): WinnerFormFields | undefined => {
    const formData = winnerFormGetStore.formData

    if (formData) {
      return {
        ...formData?.report,
        projectInMediaUrls: formData?.report.projectInMediaUrls
          ? [
              formData?.report.projectInMediaUrls[0] || "",
              formData?.report.projectInMediaUrls[1] || "",
              formData?.report.projectInMediaUrls[2] || ""
            ]
          : ["", "", ""],
        projectStart: formatDateForClient(formData?.report.projectStart),
        projectEnd: formatDateForClient(formData?.report.projectEnd),
        contractDate: formatDateForClient(formData?.report.contractDate)
      }
    }
  }

  const [formInitialValues, setFormInitialValues] = useState(
    getFormInitialValues()
  )
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const formId = winnerFormGetStore.formData?.report.id

  const getActiveTab = (path: string): number => {
    const pathnameIndex = tabPathnames.indexOf(path)
    return pathnameIndex !== -1 ? pathnameIndex : 0
  }

  const onEffect = (): (() => void) => {
    if (isNaN(artistId) && user.role === UserRole.ADMIN) {
      history.replace(PATH_WINNERS)
    }

    const pathSuffix = location.pathname.split("/").pop()
    setTabIndex(getActiveTab(pathSuffix || ""))

    user.role &&
      getWinnerForm(user.role, getActiveTab(pathSuffix || ""), artistId).then(
        () => {
          setFormInitialValues(getFormInitialValues())
        }
      )

    return (): void => {
      winnerFormGetStore.clearData()
    }
  }

  useEffect(() => {
    if (location.pathname === PATH_ARTIST_REPORT_READ) {
      setIsFormReadonlyState(true)
    }
    onEffect()
  }, [location.pathname])

  const showErrors = (): void => {
    setShowValidationErrors(true)
  }

  const getSavePreparedRequest = (
    data: WinnerFormFields
  ): {
    saveRequest: WinnerFormSaveRequest;
  } => {
    const { ...formValues } = data

    const preparedRequest = {
      ...formValues,
      projectStart: formatDateForServer(formValues.projectStart),
      projectEnd: formatDateForServer(formValues.projectEnd),
      contractDate: formatDateForServer(formValues.contractDate),
      projectInMediaUrls:
        formValues.projectInMediaUrls?.filter(el => !!el) || null
    }

    return {
      saveRequest: preparedRequest
    }
  }

  const goBack = (): void => {
    if (location.state?.category) {
      history.push(location.state.category || PATH_WINNERS)
    } else {
      history.push(PATH_WINNERS)
    }
  }

  const moderate = (data: WinnerFormFields): void => {
    const preparedRequest = getSavePreparedRequest(data)

    user.role &&
      saveWinnerForm(preparedRequest.saveRequest, user.role, formId)
        .then(() => {
          moderateWinner(formCommentStore.comment, formId)
        })
        .then(() => {
          commentByAdmin(formCommentStore.comment, artistId).then(() => {
            goBack()
          })
        })
  }

  const comment = (): void => {
    if (user.role && user.role === UserRole.ARTIST) {
      commentByArtist(formCommentStore.comment).then(() => {
        formCommentStore.clearComment()
      })
    }
  }

  const onCheckForm = (data: WinnerFormFields): void => {
    winnerFormReadStore.clearErrors()
    const preparedRequest = getSavePreparedRequest(data)

    user.role &&
      saveWinnerForm(preparedRequest.saveRequest, user.role, formId).then(
        () => {
          formId &&
            checkForm(formId).then(() => {
              goBack()
            })
        }
      )
  }

  const onSubmit = (data: WinnerFormFields, isApply?: boolean): void => {
    const preparedRequest = getSavePreparedRequest(data)
    user.role &&
      saveWinnerForm(preparedRequest.saveRequest, user.role, formId).then(
        () => {
          isApply &&
            formId &&
            applyForm(formId).then(() =>
              history.push(PATH_ARTIST_NOTIFICATIONS)
            )
        }
      )
  }

  const onSelect = (tabIndex: number): void => {
    setTabIndex(tabIndex)
    let newPathname
    if (user.role === UserRole.ARTIST) {
      newPathname = `${PATH_ARTIST_REPORT}/${tabPathnames[tabIndex]}`
    } else {
      newPathname = `${currentCategory}${artistId}/${tabPathnames[tabIndex]}`
    }

    history.replace(newPathname)
    winnerFormGetStore.clearData()
  }

  return (
    <WinnerFormComponent
      isLoading={winnerFormGetStore.isLoading || !formInitialValues}
      formInitialValues={formInitialValues}
      activeSidebarTab={activeSidebarTab}
      setActiveSidebarTab={setActiveSidebarTab}
      formStatus={winnerDataStore.formStatus || WinnerFormStatus.EDITING}
      userRole={user.role}
      tabIndex={tabIndex}
      onSelect={onSelect}
      onSubmit={onSubmit}
      isSaving={winnerFormSaveStore.isLoading}
      showValidationErrors={showValidationErrors}
      showErrors={showErrors}
      submittionErrors={
        winnerFormApplyStore.requestErrors || winnerFormSaveStore.requestErrors
      }
      isFirstEdit={!winnerFormGetStore.formData?.meta.appliedAt}
      isFormReadonly={
        isFormReadonlyState ||
        (user.role === UserRole.ARTIST &&
          winnerFormGetStore.formData?.meta.status ===
            WinnerFormStatus.CHECKED) ||
        (user.role === UserRole.ARTIST &&
          winnerFormGetStore.formData?.meta.status ===
            WinnerFormStatus.MODERATION) ||
        (user.role === UserRole.ADMIN &&
          winnerFormGetStore.formData?.meta.status === WinnerFormStatus.EDITING)
      }
      formId={formInitialValues?.id}
      winnerFormMeta={winnerFormGetStore.formData?.meta}
      isModerateLoading={winnerFormModerateStore.isLoading}
      isModerateActive={!!formCommentStore.comment.length}
      comments={winnerFormGetStore.formData?.comments}
      onComment={comment}
      onCheckForm={onCheckForm}
      onModerate={moderate}
      isCheckLoading={winnerFormReadStore.isLoading}
    />
  )
}

export default observer(WinnerFormContainer)
