import React from 'react'
import { ExpertDashboardGetResponse } from '../../../../models/ExpertModels/ExpertDashboard.model'
import Button from '../../../common/Button/Button.component'
import LoaderComponent from '../../../common/Loader/Loader.component'

import './Dashboard.style.scss'
import EvaluatedArtistsComponent from './EvaluatedArtists/EvaluatedArtists.component'

interface Props {
  onGoToEvaluationClick: (id?: number) => void;
  dashboardData?: ExpertDashboardGetResponse;
  isDataLoading?: boolean;
}

const block = 'dashboard'

const DashboardComponent = (props: Props): JSX.Element => {
  const {
    dashboardData,
    onGoToEvaluationClick,
    isDataLoading
  } = props

  return (
    <div className={block}>
      {isDataLoading ? (
        <LoaderComponent width={100} height={100} />
      ) : (
        dashboardData && (
          <>
          {dashboardData.leftToEvaluate > 0 ? (
            <div className="row row--centered">
              <Button
                text="Приступить к оценке"
                background="turquoise"
                onClick={() => onGoToEvaluationClick()}
              />
            </div>
          ) : (
            <div className={`${block}__artists-left`}>
              Все исполнители оценены
            </div>
          )}

          {!!dashboardData.count ?  (
            <>
            {dashboardData.leftToEvaluate > 0 && (
              <div className={`${block}__artists-left`}>
                Осталось оценить еще {dashboardData?.leftToEvaluate} артистов
              </div>
            )}
            
            <div className={`${block}__evaluated-artists`}>
              <EvaluatedArtistsComponent artists={dashboardData?.items} onClick={onGoToEvaluationClick} />
            </div>
            </>
          ) : (
            dashboardData.leftToEvaluate > 0 && (
              <div className={`${block}__artists-left`}>
                Вы пока не оценили ни одного музыканта
              </div>
            )
          )}
          </>
        )
      )}
    </div>
  )
}

export default DashboardComponent