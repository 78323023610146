import { ApiRequestStore } from './apiRequest.store'
import { observable, action, get, set, makeObservable } from 'mobx'
import { RequestError } from '../../models/common/error.model'
import { FileUploadResponse } from '../../models/common/fileUpload.model'

export class FileUploadLocalState extends ApiRequestStore {
  @observable uploadedFileData?: FileUploadResponse;
}

export class FileUploadStore {
  @observable items = new Map([])

  constructor() {
    makeObservable(this)
  }

  @action
  registration = (name: string): void => {
    const nameExists = get(this.items, name)
    if (nameExists) return

    const value = new FileUploadLocalState()
    set(this.items, name, value)
  }

  @action
  unmount = (name: string): void => {
    this.items.delete(name)
  };

  @action
  setUploadedFileData(data: FileUploadResponse, name: string): void {
    const item = get(this.items, name)
    item.uploadedFileData = data
    item.isLoading = false
  }

  @action
  setIsLoading(isLoading: boolean, name: string): void {
    get(this.items, name).isLoading = isLoading
  }

  @action
  setSubmittionErrors(errors: RequestError[], name: string): void {
    get(this.items, name).setErrors(errors)
  }

  getIsLoading = (name: string): boolean => get(this.items, name).isLoading

  getItem = (name: string): FileUploadLocalState => get(this.items, name)

  @action
  removeUploadedFileData = (name: string): void => {
    const item = get(this.items, name)
    item.uploadedFileData = {}
  }
}

export const fileUploadStore: FileUploadStore = new FileUploadStore()
