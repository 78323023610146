import React from "react"

import HeaderComponent from "../../../common/Header/Header.component"
import WinnerCRMHeaderContainer from "../../../../container/CRM/ArtistCRM/WinnerCRMHeader.container"
import "./WinnerCRMLayout.style.scss"

interface Props {
  children: React.ReactNode;
}

const block = "winner-crm-layout"

export default class WinnerCRMLayout extends React.Component<Props> {
  render(): JSX.Element {
    return (
      <div className={block}>
        <HeaderComponent/>
        <WinnerCRMHeaderContainer />
        <div className={`${block}__body`}>
          <div className="container">{this.props.children}</div>
        </div>
      </div>
    )
  }
}
