import { observable, action, makeObservable } from 'mobx'

export class FormCommentStore {
  @observable comment = ''

  constructor() {
    makeObservable(this)
  }

  @action
  setComment = (comment: string): void => {
    this.comment = comment
  }

  @action
  clearComment = (): void => {
    this.comment = ''
  };
}

export const formCommentStore: FormCommentStore = new FormCommentStore()
