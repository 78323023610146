import { autoserialize, autoserializeAs } from "cerialize"
import { ListArtistModel } from "./ArtistList.model"

export enum FinalistsListArtistType {
  Unprocessed = 'unprocessed',
  Confirmed = 'confirmed',
  Unconfirmed = 'unconfirmed'
}

export class FinalistsListGetRequest {
  @autoserialize type: FinalistsListArtistType;
  @autoserialize limit?: number;
  @autoserialize offset?: number;
  @autoserialize search?: string;

  constructor(
    type: FinalistsListArtistType,
    limit?: number,
    offset?: number,
    search?: string
  ) {
    this.type = type
    this.limit = limit
    this.offset = offset
    this.search = search
  }
}

export class FinalistsListItemModel {
  @autoserialize valuation: number;
  @autoserialize artist: ListArtistModel;

  constructor(
    valuation: number,
    artist: ListArtistModel
  ) {
    this.valuation = valuation
    this.artist = artist
  }
}

export class FinalistsListGetResponse {
  @autoserialize count: number;
  @autoserializeAs ('total_count') totalCount: number;
  @autoserializeAs(FinalistsListItemModel) items: FinalistsListItemModel[];

  constructor(
    items: FinalistsListItemModel[],
    count: number,
    totalCount: number
  ) {
    this.items = items
    this.count = count
    this.totalCount = totalCount
  }
}
