/* eslint-disable max-len */
import moment from "moment"
import React from "react"
import classNames from "classnames"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { useLocation } from "react-router-dom"

import InputComponent from "../../../../common/Input/Input.component"
import InputLabelComponent from "../InputLabel/InputLabel.component"
import { Element } from "react-scroll"
import TextareaComponent from "../../../../common/Textarea/Textarea.component"
import { isValidURL } from "../../../../../const/validators.const"
import { FileUploadType } from "../../../../../models/common/fileUpload.model"
import UploadFileContainer from "../../../../../container/CRM/common/UploadFile.container"

import { WinnerFormFields } from "../../../../../models/ArtistModels/winnerForm.model"

import "./WinnerFormFields.style.scss"
import { formates } from '../../../../../const/fileFormates'

interface Props {
  formInitialValues: WinnerFormFields;
  showValidationErrors?: boolean;
  formId?: number;
  isFormReadonly?: boolean;
  isVisibleHints?: boolean;
}

const block = "winner-form-fields"

const WinnerFormInputFields = (props: Props): JSX.Element => {
  const { watch, getValues, control, setValue, errors } = useFormContext()
  const location = useLocation()

  const {
    formInitialValues,
    showValidationErrors,
    formId,
    isFormReadonly,
    isVisibleHints = false
  } = props

  const [focusedInput, setFocusedInput] = React.useState("")

  const projectInMediaUrlsFieldArray = useFieldArray({
    control,
    name: "projectInMediaUrls"
  })

  const dateStartField = watch("projectStart")
  const dateEndField = watch("projectEnd")

  const isStartDateBeforeEnd = (date: string): boolean =>
    moment(date, "DD.MM.YYYY").isBefore(moment(dateEndField, "DD.MM.YYYY"))
  const isStartDateAftereEnd = (date: string): boolean =>
    moment(date, "DD.MM.YYYY").isAfter(moment(dateStartField, "DD.MM.YYYY"))

  const isStartDateValid = (date: string): boolean | string => {
    const dateToCompareStart = moment("14.02.2024", "DD.MM.YYYY")
    const dateToCompareEnd = moment("16.06.2024", "DD.MM.YYYY")
    
  
      if (moment(date, "DD.MM.YYYY", true).isValid()) {
        if (
          moment(date, "DD.MM.YYYY").isBetween(
            dateToCompareStart,
            dateToCompareEnd
          )
        ) {
          return isStartDateBeforeEnd(date)
            ? true
            : "Дата начала не может быть позже даты окончания или совпадать с ней"
        } else {
          if (moment(date, "DD.MM.YYYY").isSameOrAfter(dateToCompareEnd)) {
            return "Дата начала не позднее 15.06.2024"
          } else {
            return "Дата начала не ранее 15.02.2024"
          }
        }
      } else {
        return "Введите корректную дату"
      }
    }
  
    const isEndDateValid = (date: string): boolean | string => {
      const dateToCompareStart = moment("14.02.2024", "DD.MM.YYYY")
    const dateToCompareEnd = moment("16.06.2024", "DD.MM.YYYY")
  
      if (moment(date, "DD.MM.YYYY", true).isValid()) {
        if (
          moment(date, "DD.MM.YYYY").isBetween(
            dateToCompareStart,
            dateToCompareEnd
          )
        ) {
          return isStartDateAftereEnd(date)
            ? true
            : "Дата окончания не может быть раньше даты начала или совпадать с ней"
        } else {
          if (moment(date, "DD.MM.YYYY").isSameOrAfter(dateToCompareEnd)) {
            return "Дата окончания не позднее 15.06.2024"
          } else {
            return "Дата окончания не ранее 15.02.2024"
          }
        }
      } else {
        return "Введите корректную дату"
      }
    }
  
  const checkValueByLength = (name: string, length: number) => {
    const value = getValues(`${name}`)

    if (value.length < length - 1) {
      setValue(name, value)
    } else {
      setValue(name, value.substring(0, value.length - 1))
    }
  }

  return (
    <>
      <div className="form__section">
        <div className="form__input-block">
          <div className="form__input-title">* ФИО руководителя проекта</div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues?.projectLeaderName}
              control={control}
              name="projectLeaderName"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"ФИО полностью"}
                    error={errors.projectLeaderName?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Скопируйте текст из договора на получение гранта
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Название коллектива/имя музыканта (для сольных проектов)
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.artistName}
              control={control}
              name="artistName"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите текст"}
                    error={errors.artistName?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Скопируйте текст из договора на получение гранта
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">* Наименование проекта</div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.projectName}
              control={control}
              name="projectName"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите текст"}
                    error={errors.projectName?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Скопируйте текст из договора на получение гранта
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Номер Договора предоставления гранта проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name="contractNumber"
              defaultValue={formInitialValues.contractNumber}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите текст"}
                    error={errors.contractNumber?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Скопируйте текст из договора на получение гранта
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Дата заключения Договора предоставления гранта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  validDate: (value): boolean | string =>
                    isStartDateValid(value)
                }
              }}
              control={control}
              name={"contractDate"}
              defaultValue={formInitialValues.contractDate}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={[
                      /[0-3]/,
                      /[0-9]/,
                      ".",
                      /[0-1]/,
                      /[0-9]/,
                      ".",
                      /[1-2]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/
                    ]}
                    placeholder={"__.__.____"}
                    error={errors.contractDate?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Скопируйте текст из договора на получение гранта
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-row">
          <div className="form__input-block">
            <div className="form__input-title">
              * Дата начала реализации проекта
            </div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  },
                  validate: {
                    validDate: (value): boolean | string =>
                      isStartDateValid(value)
                  }
                }}
                control={control}
                name={"projectStart"}
                defaultValue={formInitialValues.projectStart}
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={[
                        /[0-3]/,
                        /[0-9]/,
                        ".",
                        /[0-1]/,
                        /[0-9]/,
                        ".",
                        /[1-2]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                      placeholder={"__.__.____"}
                      error={errors.projectStart?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={isFormReadonly}
                    />
                  </Element>
                )}
              />
            </div>
          </div>
          <div className="form__input-block">
            <div className="form__input-title">* Дата окончания реализации</div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  },
                  validate: {
                    validDate: (value): boolean | string =>
                      isEndDateValid(value)
                  }
                }}
                control={control}
                name={"projectEnd"}
                defaultValue={formInitialValues.projectEnd}
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={[
                        /[0-3]/,
                        /[0-9]/,
                        ".",
                        /[0-1]/,
                        /[0-9]/,
                        ".",
                        /[1-2]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                      placeholder={"__.__.____"}
                      error={errors.projectEnd?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={isFormReadonly}
                    />
                    <div className="form__input-label">
                      <InputLabelComponent
                        isFocused={
                          focusedInput === name ||
                          focusedInput === "projectStart"
                        }
                      >
                        Дата начала не ранее 15.02.2024;
                        <br />
                        Дата окончания не поздее 15.06.2024.
                      </InputLabelComponent>
                    </div>
                  </Element>
                )}
              />
            </div>
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Сведения о выполнении ключевых контрольных точек и описание
            содержания деятельности
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 5000,
                  message: "Не более 5000 символов"
                }
              }}
              defaultValue={formInitialValues.checkpointCompletionDetails}
              control={control}
              name="checkpointCompletionDetails"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 5000)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.checkpointCompletionDetails?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 5000 символов. <br />
                      Контрольные точки указаны в приложении №1 к договору
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Проведенные мероприятия с указанием сроков реализации
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 3000,
                  message: "Не более 3000 символов"
                }
              }}
              defaultValue={formInitialValues.pastEventOverview}
              control={control}
              name="pastEventOverview"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 3000)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.pastEventOverview?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 3000 символов.
                      <br /> Опишите проведённые мероприятия за данный период по
                      приведённому в заявке календарному плану
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Количественные и качественные результаты проекта, социальный и
            творческий эффект
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 2000,
                  message: "Не более 2000 символов"
                }
              }}
              defaultValue={
                formInitialValues.projectQuantitativeQualitativeResults
              }
              control={control}
              name="projectQuantitativeQualitativeResults"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 2000)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={
                      errors.projectQuantitativeQualitativeResults?.message
                    }
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 2000 символов.
                      <br /> Укажите основные достигнутые количественные и
                      качественные результаты реализации проекта за отчетный
                      период
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            Упоминания проекта в СМИ, соцсетях
          </div>
          {projectInMediaUrlsFieldArray.fields.map((item, index) => (
            <div className="form__input" key={item.id}>
              <Controller
                rules={{
                  validate: {
                    validUrl: (value): boolean | string =>
                      !!value?.length
                        ? isValidURL(value.trim()) || "Введите корректный URL"
                        : true
                  }
                }}
                control={control}
                defaultValue={
                  formInitialValues.projectInMediaUrls
                    ? formInitialValues.projectInMediaUrls[index]
                    : ""
                }
                name={`projectInMediaUrls.${index}`}
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={(event): void => {
                        onChange(event)
                      }}
                      value={value}
                      name={name}
                      mask={false}
                      placeholder={"Введите ссылку"}
                      error={
                        errors?.projectInMediaUrls &&
                        errors?.projectInMediaUrls[index] &&
                        errors?.projectInMediaUrls[index].message
                      }
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      isErrorStatic={true}
                      disabled={isFormReadonly}
                    />
                    {index === 0 && (
                      <div className="form__input-label">
                        <InputLabelComponent
                          isFocused={
                            focusedInput === "projectInMediaUrls.0" ||
                            focusedInput === "projectInMediaUrls.1" ||
                            focusedInput === "projectInMediaUrls.2"
                          }
                        >
                          Электронные ссылки на публикации и (или) материалы,
                          содержащие информацию о реализации проекта за отчетный
                          период, в средствах массовой информации и сети
                          «Интернет» (включая социальные сети)
                        </InputLabelComponent>
                      </div>
                    )}
                  </Element>
                )}
              />
            </div>
          ))}
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Фотографии, видео- и аудиозаписи с мероприятий
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  validUrl: (value): boolean | string =>
                    !!value?.length
                      ? isValidURL(value.trim()) || "Введите корректный URL"
                      : true
                }
              }}
              defaultValue={formInitialValues.mediaFilesUrl}
              control={control}
              name="mediaFilesUrl"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Введите ссылку"}
                    error={errors.mediaFilesUrl?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                    mask={false}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Предоставьте ссылку на облачное хранилище
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Электронные версии материалов, созданных с использованием гранта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  validUrl: (value): boolean | string =>
                    !!value?.length
                      ? isValidURL(value.trim()) || "Введите корректный URL"
                      : true
                }
              }}
              defaultValue={formInitialValues.electronicMaterialsVersions}
              control={control}
              name="electronicMaterialsVersions"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Введите ссылку"}
                    error={errors.electronicMaterialsVersions?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                    mask={false}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Укажите ссылку на облачное хранилище. Афишы, брошюры,
                      буклеты, газеты, доклады, журналы, книги, презентации,
                      сборники и иные материалы, созданные с использованием
                      гранта в отчетном периоде.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Информация о собственном вкладе грантополучателя и использованном
            на реализацию проекта за отчетный период софинансирования
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.artistProjectContribution}
              control={control}
              name="artistProjectContribution"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.artistProjectContribution?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Недостатки, выявленные в ходе реализации проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.detectedDefects}
              control={control}
              name="detectedDefects"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.detectedDefects?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Общие выводы по результатам реализации проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.generalProjectResults}
              control={control}
              name="generalProjectResults"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.generalProjectResults?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                  />
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div
            className={classNames(
              "form__input-title",
              showValidationErrors &&
                errors.financialStatementForm &&
                "form__input-title--error"
            )}
          >
            * Форма финансового отчета для заполнения
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name={"financialStatementForm"}
              defaultValue={formInitialValues.financialStatementForm}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <UploadFileContainer
                    isReport
                    artistId={formId}
                    nameForStore={name}
                    fileTypes={[formates.pdf, formates.jpeg, formates.jpg, formates.png]}
                    uploadedFile={value || undefined}
                    onFileUpload={(fileData): void => onChange(fileData)}
                    onFileDelete={(): void => {
                      setValue(name, null)
                    }}
                    showFileName={false}
                    disabled={isFormReadonly}
                    type={FileUploadType.DOCUMENT}
                    fileType={"financial_statement_form"}
                  />

                  <div className="form__input-label">
                    <InputLabelComponent>
                      Скачайте{" "}
                      <span>
                        <a
                          href="https://docs.google.com/document/d/1arRPgwkfolwS8s2FIIPLIHVUy4hPvb6F/edit"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          форму
                        </a>
                      </span>{" "}
                      для отчёта, заполните и прикрепите заполненный отчёт в
                      поле для файла
                    </InputLabelComponent> 
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Копии первичных отчетных документов
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  validUrl: (value): boolean | string =>
                    !!value?.length
                      ? isValidURL(value.trim()) || "Введите корректный URL"
                      : true
                }
              }}
              defaultValue={formInitialValues.primaryReportingDocuments}
              control={control}
              name="primaryReportingDocuments"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Введите ссылку"}
                    error={errors.primaryReportingDocuments?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={isFormReadonly}
                    mask={false}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Предоставьте ссылку на облачное хранилище
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div
            className={classNames(
              "form__input-title",
              showValidationErrors &&
                errors.consentProvideData &&
                "form__input-title--error"
            )}
          >
            * Согласие о предоставлении достоверных данных в отчете
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name={"consentProvideData"}
              defaultValue={formInitialValues.consentProvideData}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <UploadFileContainer
                    isReport
                    artistId={formId}
                    nameForStore={name}
                    fileTypes={[formates.pdf, formates.jpeg, formates.jpg, formates.png]}
                    uploadedFile={value || undefined}
                    onFileUpload={(fileData): void => onChange(fileData)}
                    onFileDelete={(): void => {
                      setValue(name, null)
                    }}
                    showFileName={false}
                    disabled={isFormReadonly}
                    type={FileUploadType.DOCUMENT}
                    fileType={"consent_provide_data"}
                  />

                  <div className="form__input-label">
                    <InputLabelComponent>
                      Скачайте{" "}
                      <span>
                        <a
                          href="https://docs.google.com/document/d/1Ztzc1Sacrvdg-JHURkAou4hDKzRWO1_I/edit"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          форму
                        </a>
                      </span>{" "}
                      для отчёта, заполните и прикрепите заполненный отчёт в
                      поле для файла
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WinnerFormInputFields
