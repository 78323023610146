/* eslint-disable max-len */
import { ReactComponent as Facebook } from "../../../../assets/icons/facebook.svg"
import { ReactComponent as VK } from "../../../../assets/icons/vk.svg"
import { ReactComponent as Instagram } from "../../../../assets/icons/instagram.svg"
import { ReactComponent as TikTok } from "../../../../assets/icons/tiktok.svg"
import { ReactComponent as Youtube } from "../../../../assets/icons/youtube.svg"
import { ReactComponent as Twitter } from "../../../../assets/icons/twitter.svg"
import { ReactComponent as SoundCloud } from "../../../../assets/icons/soundcloud.svg"
import { ReactComponent as Spotify } from "../../../../assets/icons/spotify.svg"
import { ReactComponent as YM } from "../../../../assets/icons/yandex-music.svg"
import { ArtistFormStatus } from "../../../../models/ArtistModels/artistForm.model"

export interface SocialNetworksField {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: 'facebookUrl' | 'vkontakteUrl' | 'instagramUrl' | 'tiktokUrl' | 'youtubeUrl' | 'twitterUrl';
  placeholder: string;
}

export const projectInSocial: SocialNetworksField[] = [
  {
    name: 'facebookUrl',
    icon: Facebook,
    placeholder: 'Facebook'
  },
  {
    name: 'vkontakteUrl',
    icon: VK,
    placeholder: 'Vkontakte'
  },
  {
    name: 'instagramUrl',
    icon: Instagram,
    placeholder: 'Instagram'
  },
  {
    name: 'tiktokUrl',
    icon: TikTok,
    placeholder: 'Tiktok'
  },
  {
    name: 'youtubeUrl',
    icon: Youtube,
    placeholder: 'Youtube'
  },
  {
    name: 'twitterUrl',
    icon: Twitter,
    placeholder: 'Twitter'
  }
]

// interface MusicProjectLinksField {
//   icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
//   name: 'musicVkontakteUrl' | 'musicSoundcloudUrl' | 'musicSpotifyUrl' | 'musicYandexUrl';
//   placeholder: string;
// }

// export const musicProjectLinks: MusicProjectLinksField[] = [
//   {
//     name: 'musicVkontakteUrl',
//     icon: VK,
//     placeholder: 'VK'
//   },
//   {
//     name: 'musicSoundcloudUrl',
//     icon: SoundCloud,
//     placeholder: 'Soundcloud'
//   },
//   {
//     name: 'musicSpotifyUrl',
//     icon: Spotify,
//     placeholder: 'Spotify'
//   },
//   {
//     name: 'musicYandexUrl',
//     icon: YM,
//     placeholder: 'Yandex.music'
//   }
// ]

interface MemberSocialField {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: 'facebookUrl' | 'vkontakteUrl' | 'instagramUrl' | 'tiktokUrl' | 'youtubeUrl' | 'twitterUrl';
  placeholder: string;
}

export const memberSocial: MemberSocialField[] = [
  {
    name: 'facebookUrl',
    icon: Facebook,
    placeholder: 'Facebook'
  },
  {
    name: 'vkontakteUrl',
    icon: VK,
    placeholder: 'Vkontakte'
  },
  {
    name: 'instagramUrl',
    icon: Instagram,
    placeholder: 'Instagram'
  },
  {
    name: 'tiktokUrl',
    icon: TikTok,
    placeholder: 'Tiktok'
  },
  {
    name: 'youtubeUrl',
    icon: Youtube,
    placeholder: 'Youtube'
  },
  {
    name: 'twitterUrl',
    icon: Twitter,
    placeholder: 'Twitter'
  }
]

export const formStatusLabels: {
  formStatus: ArtistFormStatus;
  label: string;
}[] = [
  {
    formStatus: ArtistFormStatus.EDITING,
    label: 'Редактируется артистом'
  },
  {
    formStatus: ArtistFormStatus.MODERATION,
    label: 'На модерации'
  },
  {
    formStatus: ArtistFormStatus.APPROVED,
    label: 'Заявка утверждена'
  },
  {
    formStatus: ArtistFormStatus.CHECKED,
    label: 'На рассмотрении экспертов'
  }
]