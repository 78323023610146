import { Deserialize, Serialize } from "cerialize"
import { RequestErrorResponse } from "../../models/common/error.model"
import { EvaluateRequest, EvaluationInfoGetResponse } from "../../models/ExpertModels/Evaluation.model"
import { evaluateRequestStore, evaluationInfoGetStore } from "../../store/ExpertStore/ExpertEvaluation.store"
import { api } from "../../utils/api"

export function getEvaluationInfo(): Promise<void> {
  evaluationInfoGetStore.setIsLoading(true)

  return api.get(
    '/expert/valuation'
  ).then((res) => {
    const data: EvaluationInfoGetResponse = Deserialize(res.data, EvaluationInfoGetResponse)
    
    evaluationInfoGetStore.setEvaluationInfo(data)
    evaluationInfoGetStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response?.data
    
    evaluationInfoGetStore.setErrors(errorResponseData?.errors)
    evaluationInfoGetStore.setIsLoading(false)

    throw errorResponseData
  })
}

export function evaluateArtist(data: EvaluateRequest, id: number): Promise<void> {
  const serializedData = Serialize(data, EvaluateRequest)
  
  evaluateRequestStore.setIsLoading(true)

  return api.put(
    `/expert/valuations/${id}`,
    serializedData
  ).then(() => {
    evaluateRequestStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response?.data
    
    evaluateRequestStore.setIsLoading(false)
    evaluateRequestStore.setErrors(errorResponseData?.errors)

    throw error
  })
}