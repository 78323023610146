import { Deserialize, Serialize } from "cerialize"
import { ExpertListGetRequest, ExpertListGetResponse } from "../../models/AdminModels/ExpertList.model"
import { RequestErrorResponse } from "../../models/common/error.model"
import { expertListStore } from "../../store/AdminStore/expertList.store"
import { api } from "../../utils/api"

export function getExpertList(request: ExpertListGetRequest): Promise<void> {
  const serializedData = Serialize(request, ExpertListGetRequest) 

  expertListStore.setIsLoading(true)

  return api
    .get(
      "/admin/experts",
      {
        params: serializedData
      }
    )
    .then((res) => {
      const data: ExpertListGetResponse = Deserialize(
        res.data,
        ExpertListGetResponse
      )

      expertListStore.setArtistFormsData({
        ...data
      })
      expertListStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      expertListStore.setIsLoading(false)
      expertListStore.setErrors(errorResponseData?.errors)
    })
}