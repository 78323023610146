import React from "react"

import "./GenreRadioList.style.scss"
import { genreRadioButtonsData } from "../../../container/CRM/ArtistCRM/data.const"

const block = "genre-radio-button"

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  disabled?: boolean;
  error?: string;
}

const GenreRadioList = (props: Props): JSX.Element => {
  const { onChange, name, value, disabled, error } = props

  return (
    <div>
      <div className={`genre-radio-list`}>
        {genreRadioButtonsData.map((genre, index) => (
          <label key={index} className={block}>
            <input
              disabled={disabled}
              name={`${name}${index}`}
              type="radio"
              value={genre.value}
              checked={value === genre.value}
              onChange={onChange}
            />
            <div className={`${block}__icon`}>
              {React.createElement(genre.icon)}
            </div>
            <div className={`${block}__caption`}>{genre.caption}</div>
          </label>
        ))}
      </div>
      {error && <div className={`genre-radio-list__error`}>{error}</div>}
    </div>
  )
}

export default GenreRadioList
