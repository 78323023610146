import React from 'react'

import './CommentInput.style.scss'

interface Props {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string
}

const block = 'comment-input'

const CommentInputComponent = (props: Props): JSX.Element => (
  <div className={block}>
    <textarea
      onChange={props.onChange}
      className={`${block}__input`}
      placeholder="Введите комментарий"
      value={props.value}
    />
  </div>
)

export default CommentInputComponent