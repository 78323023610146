import React, { useContext } from "react"

import "./Header.style.scss"
import { ReactComponent as Exit } from "../../../assets/icons/exit.svg"
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-left.svg"

import CircleButtonComponent from "../../CRM/common/CircleButton/CircleButton.component"
import { useHistory, Route } from "react-router-dom"
import { authContext } from "../../../context/auth.context"
import {
  PATH_AUTH,
  PATH_ROOT,
  PATH_ARTIST_NOTIFICATIONS,
  PATH_ARTISTS,
  PATH_EXPERT_DASHBOARD,
  PATH_FINALISTS
} from "../../../const/routes.const"

const block = "header"

const HeaderComponent = (): JSX.Element => {
  const history = useHistory()
  const { logout } = useContext(authContext)

  const onLogout = (): void => {
    logout()
    history.push(PATH_AUTH)
  }

  const goToHome = (): void => {
    history.push(PATH_ROOT)
  }

  const goBack = (): void => {
    history.goBack()
  }

  const isStartPage = (): boolean => {
    switch (history.location.pathname) {
      case PATH_ARTIST_NOTIFICATIONS:
        return true
      case PATH_ARTISTS:
        return true
      case PATH_EXPERT_DASHBOARD:
        return true
      case PATH_FINALISTS:
        return true
      default:
        return false
    }
  }

  return (
    <div className={block}>
      <div className="content-container row row--space-between row--align-center">
        {history.length > 0 && !isStartPage() ? (
          <div className={`${block}__back-btn`} onClick={goBack}>
            <div className={`${block}__back-btn-arrow`}>
              <Arrow />
            </div>
            <div className={`${block}__back-btn-text`}>Назад</div>
          </div>
        ) : (
          <div />
        )}
        <div className={`${block}__logo`} onClick={goToHome} />
        <div className="row row--end">
          <CircleButtonComponent onClick={onLogout} tooltip="Выход">
            <Exit />
          </CircleButtonComponent>
        </div>
      </div>
    </div>
  )
}

export default HeaderComponent
