import React, { useContext } from "react"
import {
  Link,
  RouteComponentProps,
  withRouter,
  useHistory
} from "react-router-dom"
import classNames from "classnames"
import { ReactComponent as LiActiveCurve } from "../../../../assets/icons/curve.svg"
import { authContext } from "../../../../context/auth.context"
import { ReactComponent as Exit } from "../../../../assets/icons/exit.svg"
import { ReactComponent as Logo } from "../../../../assets/icons/logo.svg"

import { nav } from "./nav.const"

import "./SideBar.style.scss"
import { PATH_AUTH } from "../../../../const/routes.const"

const block = "nav-sidebar"

const SideBar = (props: RouteComponentProps): JSX.Element => {
  const {
    location: { pathname }
  } = props
  const history = useHistory()

  const { user, logout } = useContext(authContext)

  const onLogout = (): void => {
    logout()
    history.push(PATH_AUTH)
  }

  const currentPathnameCategory = pathname.split("/")[1]

  return (
    <div className={block}>
      <div className={`${block}__logo`}>
        <Logo />
      </div>

      <nav>
        {nav.map(
          item =>
            user.role &&
            item.roles.indexOf(user.role) >= 0 && (
              <Link
                to={`/${item.pathnameCategory}`}
                key={item.pathnameCategory}
              >
                <div
                  className={classNames(
                    `${block}__item`,
                    currentPathnameCategory === item.pathnameCategory &&
                      `${block}__item--active`
                  )}
                >
                  <div className={`${block}__item-icon`}>
                    {React.createElement(item.icon)}
                  </div>
                  <div className={`${block}__item-title`}>{item.title}</div>

                  {currentPathnameCategory === item.pathnameCategory && (
                    <LiActiveCurve className={`${block}__item--decorative`} />
                  )}
                </div>
              </Link>
            )
        )}
      </nav>

      <div className={classNames(`${block}__item`)} onClick={onLogout}>
        <div className={`${block}__item-icon`}>
          <Exit />
        </div>
        <div className={`${block}__item-title`}>Выход</div>
      </div>
    </div>
  )
}

export default withRouter(SideBar)
