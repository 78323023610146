import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputComponent from '../../common/Input/Input.component'
import AuthFormLayoutComponent from '../AuthFormLayout/AuthFormLayout.component'
import { isEmailValid } from '../../../const/validators.const'
import Button from '../../common/Button/Button.component'
import { RegisterFormFields } from '../../../models/AuthModels/register.model'

interface Props {
  error?: string;
  isLoading?: boolean;
  onSubmit: (data: RegisterFormFields) => void;
  onInputChange: () => void;
}

const block = 'register-form'

const RegisterFormComponent = (props: Props): JSX.Element => {
  const { error, isLoading, onSubmit, onInputChange } = props
  const {
    handleSubmit,
    errors,
    control,
    getValues,
    clearErrors
  } = useForm<RegisterFormFields>()

  return (
    <AuthFormLayoutComponent 
      title="ПОДАЧА ЗАЯВКИ"
      className={block}
      error={error}
    >
      <div className={'auth-form-layout__input'}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            }
          }}
          defaultValue=""
          control={control} 
          name="name" 
          render={({ onChange, value, name }): JSX.Element => (
            <InputComponent
              onChange={onChange}
              value={value}
              name={name}
              mask={false}
              placeholder={'Название коллектива'}
              error={errors.name?.message}
              isErrorStatic={true}
            />
          )}
        />
      </div>
      <div className={'auth-form-layout__input'}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            },
            validate: {
              validEmail: (value): boolean | string => isEmailValid(value) || 'Введите корректный Email'
            }
          }}
          defaultValue=""
          control={control} 
          name="email" 
          render={({ onChange, value, name }): JSX.Element => (
            <InputComponent
              onChange={(event): void => {
                onChange(event.target.value.trim())
                onInputChange()
              }}
              value={value.trim()}
              name={name}
              mask={false}
              placeholder={'E-mail'}
              error={errors.email?.message}
              isErrorStatic={true}
            />
          )}
        />
      </div>
      <div className={'auth-form-layout__input'}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            }
          }}
          defaultValue=""
          control={control} 
          name="password" 
          render={({ onChange, value, name }): JSX.Element => (
            <InputComponent
              onChange={(event): void => {
                onChange(event)
                getValues().repeatPassword === event.target.value && clearErrors('repeatPassword')
              }}
              value={value}
              name={name}
              mask={false}
              placeholder={'Пароль'}
              error={errors.password?.message}
              type={'password'}
              isErrorStatic={true}
            />
          )}
        />
      </div>
      <div className={'auth-form-layout__input'}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            },
            validate: {
              samePassword: (value): boolean | string => getValues().password === value || 'Пароли не совпадают'
            }
          }}
          defaultValue=""
          control={control}
          name="repeatPassword"
          render={({ onChange, value, name }): JSX.Element => (
            <InputComponent
              onChange={onChange}
              value={value}
              name={name}
              mask={false}
              placeholder={'Подтверждение пароля'}
              error={errors.repeatPassword?.message}
              type={'password'}
              isErrorStatic={true}
            />
          )}
        />
      </div>
      <div className={`auth-form-layout__button`}>
        <Button 
          background="turquoise" 
          onClick={handleSubmit(onSubmit)}
          isDisabled={isLoading}
          text={'Зарегистрироваться'}
        />
      </div>
    </AuthFormLayoutComponent>
  )
}

export default RegisterFormComponent