import React from "react"
import CRMHeaderComponent from "../../components/CRM/CRMHeader/CRMHeader.component"
import DashboardContainer from "../../container/CRM/ExpertCRM/Dashboard.container"
import HeaderComponent from "../../components/common/Header/Header.component"

const block = "expert-dashboard-page"

const headerData = {
  title: "ОЦЕНКА АРТИСТОВ",
  text: `Вам предстоит определить, какие музыкальные коллективы станут финалистами 
  конкурса New/Open 2024. Оцените, пожалуйста, заявки музыкальных коллективов по 
  следующим критериям: реалистичность проекта, актуальность заявленного проекта для 
  музыкального коллектива на данный момент, качество музыкальных произведений во всей 
  совокупности, уровень эмоционального отклика. Каждую заявку оценят не менее пяти экспертов.`
}

const ExpertDashboardPage = (): JSX.Element => (
  <div className={block}>
    <HeaderComponent />
    <CRMHeaderComponent {...headerData} />
    <DashboardContainer />
  </div>
)

export default ExpertDashboardPage
