import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useWatch, useForm } from 'react-hook-form'

import {
  Dialog,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  TableFooter
} from '@material-ui/core'

import { ReactComponent as Plus } from '../../../../../assets/icons/plus.svg'
import { ReactComponent as Delete } from '../../../../../assets/icons/delete.svg'
import InputInTableComponent from '../../../../common/InputInTable/InputInTable.component'
import Button from '../../../../common/Button/Button.component'
import { BudgetRowModel } from '../../../../../models/ArtistModels/artistForm.model'
import { createNumberMask } from 'text-mask-addons'

import './BudgetFormFields.style.scss'
import {
  getCurrentBudgetTotal,
  getFullPriceBudget,
  getFullPriceBudgetTotal,
  getRequestedBudget,
  getRequestedBudgetTotal
} from '../../../../../utils/budget'

interface Props {
  formInitialValues: BudgetRowModel[]
  isFormReadonly?: boolean
  onCloseModal: () => void
  isOpenModal: boolean
  setBudgetModalFormData?: (data: BudgetRowModel[]) => void
}

const block = 'artist-form-fields'

const BudgetFormFields = (props: Props): JSX.Element => {
  const {
    formInitialValues,
    isFormReadonly,
    isOpenModal,
    onCloseModal,
    setBudgetModalFormData
  } = props

  const [isBudgetMoreThanMax, setIsBudgetMoreThanMax] = useState(false)
  const { control, handleSubmit, errors, setValue, setError } = useForm<{
    budgetForm: BudgetRowModel[]
  }>({
    defaultValues: { budgetForm: formInitialValues },
    shouldFocusError: false
  })

  const budgetFieldArray = useFieldArray({
    control,
    name: 'budgetForm'
  })

  const budgetFormWatch = useWatch({
    name: 'budgetForm',
    control
  })

  useEffect(() => {
    if (getRequestedBudgetTotal(budgetFormWatch) > 300000) {
      setIsBudgetMoreThanMax(true)
    } else {
      setIsBudgetMoreThanMax(false)
    }
  }, [budgetFormWatch])

  const onSubmit = (data: { budgetForm: BudgetRowModel[] }) => {
    setBudgetModalFormData && setBudgetModalFormData(data.budgetForm)
    setValue('budgetForm', [...data.budgetForm])
    onCloseModal()
  }

  return (
    <Dialog
      maxWidth={false}
      open={isOpenModal}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
    >
      <form id="artist-form-budget" onSubmit={handleSubmit(onSubmit)} />
      <React.Fragment>
        <Table aria-label="simple table">
          <TableHead style={{ background: '#21CEB4' }}>
            <TableRow>
              <TableCell align="left" style={{ color: '#fff' }}>
                №
              </TableCell>
              <TableCell align="left" style={{ color: '#fff' }}>
                Наименование
              </TableCell>
              <TableCell align="left" style={{ color: '#fff' }}>
                Цена (за 1 шт.)
              </TableCell>
              <TableCell align="left" style={{ color: '#fff' }}>
                Кол-во
              </TableCell>
              <TableCell align="left" style={{ color: '#fff' }}>
                Стоимость
              </TableCell>
              <TableCell align="left" style={{ color: '#fff' }}>
                Cофинансирование
              </TableCell>
              <TableCell align="left" style={{ color: '#fff' }}>
                Запрашиваемый бюджет
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!!budgetFieldArray.fields.length &&
              budgetFieldArray.fields.map((field, index) => (
                <TableRow key={field.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: '0 16px' }}
                  >
                    {index + 1}
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: 'Это обязательное поле'
                        }
                      }}
                      control={control}
                      defaultValue={field?.name}
                      name={`budgetForm[${index}].name`}
                      render={({ onChange, value, name }): JSX.Element => (
                        <InputInTableComponent
                          onChange={onChange}
                          value={value}
                          name={name}
                          mask={false}
                          tooltip={true}
                          placeholder={'Название'}
                          disabled={isFormReadonly}
                          error={
                            errors.budgetForm &&
                            errors.budgetForm[index]?.name?.message
                          }
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: 'Это обязательное поле'
                        }
                      }}
                      control={control}
                      defaultValue={field?.price}
                      name={`budgetForm[${index}].price`}
                      render={({ onChange, value, name }): JSX.Element => (
                        <InputInTableComponent
                          onChange={onChange}
                          value={value}
                          name={name}
                          type="number"
                          min={0}
                          max={1000000}
                          mask={createNumberMask({
                            prefix: '',
                            suffix: '',
                            thousandsSeparatorSymbol: '',
                            integerLimit: 7
                          })}
                          placeholder={'0 ₽'}
                          disabled={isFormReadonly}
                          error={
                            errors.budgetForm &&
                            errors.budgetForm[index]?.price?.message
                          }
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: 'Это обязательное поле'
                        }
                      }}
                      control={control}
                      defaultValue={field?.count}
                      name={`budgetForm[${index}].count`}
                      render={({ onChange, value, name }): JSX.Element => (
                        <InputInTableComponent
                          onChange={onChange}
                          value={value}
                          name={name}
                          type="number"
                          min={0}
                          max={1000000}
                          mask={createNumberMask({
                            prefix: '',
                            suffix: '',
                            thousandsSeparatorSymbol: '',
                            integerLimit: 7
                          })}
                          placeholder={'0 ед.'}
                          disabled={isFormReadonly}
                          error={
                            errors.budgetForm &&
                            errors.budgetForm[index]?.count?.message
                          }
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <InputInTableComponent
                      value={getFullPriceBudget(budgetFormWatch[index]) || 0}
                      name={`budgetForm[${index}].fullPrice`}
                      mask={createNumberMask({
                        prefix: '',
                        suffix: ' ₽',
                        thousandsSeparatorSymbol: ' ',
                        integerLimit: 7
                      })}
                      placeholder={'0 ₽'}
                      disabled={true}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: 'Это обязательное поле'
                        }
                      }}
                      control={control}
                      defaultValue={field?.currentBudget}
                      name={`budgetForm[${index}].currentBudget`}
                      render={({ onChange, value, name }): JSX.Element => (
                        <InputInTableComponent
                          onChange={onChange}
                          value={value}
                          name={name}
                          type="number"
                          min={0}
                          max={1000000}
                          mask={createNumberMask({
                            prefix: '',
                            suffix: '',
                            thousandsSeparatorSymbol: '',
                            integerLimit: 7
                          })}
                          placeholder={'0 ₽'}
                          disabled={isFormReadonly}
                          error={
                            errors.budgetForm &&
                            errors.budgetForm[index]?.currentBudget?.message
                          }
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <InputInTableComponent
                      value={getRequestedBudget(budgetFormWatch[index])}
                      mask={createNumberMask({
                        prefix: '',
                        suffix: ' ₽',
                        thousandsSeparatorSymbol: ' ',
                        integerLimit: 7
                      })}
                      name={`budgetForm[${index}].requestedBudget`}
                      placeholder={'0 ₽'}
                      disabled={true}
                    />
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: '0 16px' }}
                  >
                    {!isFormReadonly && (
                      <div
                        className={`${block}__remove`}
                        onClick={(): void => {
                          if (budgetFieldArray.fields.length === 1) return
                          return budgetFieldArray.remove(index)
                        }}
                      >
                        <Delete />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                {!isFormReadonly &&
                  (budgetFieldArray.fields.length >= 20 ? (
                    <div
                      style={{
                        color: '#E63C24',
                        fontWeight: 500,
                        fontSize: '14px'
                      }}
                    >
                      Достигнуто максимальное количество строк!
                    </div>
                  ) : (
                    <Button
                      icon={<Plus fill="#ffffff" />}
                      text={'Добавить строку'}
                      background="turquoise"
                      onClick={(): void =>
                        budgetFieldArray.append(
                          new BudgetRowModel(null, null, null, null)
                        )
                      }
                    />
                  ))}
              </TableCell>
              <TableCell padding="none">Итого:</TableCell>
              <TableCell padding="none">
                <InputInTableComponent
                  value={getFullPriceBudgetTotal(budgetFormWatch) || 0}
                  mask={createNumberMask({
                    prefix: '',
                    suffix: ' ₽',
                    thousandsSeparatorSymbol: ' ',
                    integerLimit: 7
                  })}
                  placeholder={'0 ₽'}
                  disabled={true}
                />
              </TableCell>

              <TableCell padding="none">
                <InputInTableComponent
                  value={getCurrentBudgetTotal(budgetFormWatch) || 0}
                  mask={createNumberMask({
                    prefix: '',
                    suffix: ' ₽',
                    thousandsSeparatorSymbol: ' ',
                    integerLimit: 7
                  })}
                  placeholder={'0 ₽'}
                  disabled={true}
                />
              </TableCell>
              <TableCell padding="none">
                <InputInTableComponent
                  value={getRequestedBudgetTotal(budgetFormWatch) || 0}
                  mask={createNumberMask({
                    prefix: '',
                    suffix: ' ₽',
                    thousandsSeparatorSymbol: ' ',
                    integerLimit: 7
                  })}
                  placeholder={'0 ₽'}
                  disabled={true}
                />
              </TableCell>
              <TableCell padding="none"></TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={4}>
                {Object.keys(errors).length !== 0 && (
                  <div
                    style={{
                      color: '#E63C24',
                      fontWeight: 500,
                      fontSize: '14px'
                    }}
                  >
                    Все поля должны быть заполнены
                  </div>
                )}

                {isBudgetMoreThanMax && (
                  <div
                    style={{
                      color: '#E63C24',
                      fontWeight: 500,
                      maxWidth: '360px',
                      fontSize: '14px'
                    }}
                  >
                    Максимальная сумма запрашиваемого гранта 300 000 рублей
                  </div>
                )}
              </TableCell>
              {!isFormReadonly && (
                <TableCell colSpan={4} align="right">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      text={'Сохранить смету'}
                      background="turquoise"
                      onClick={(): void => {
                        handleSubmit(data => onSubmit(data))()
                      }}
                      isDisabled={
                        isBudgetMoreThanMax || Object.keys(errors).length !== 0
                      }
                    />
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </React.Fragment>
    </Dialog>
  )
}

export default BudgetFormFields
