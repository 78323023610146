import React from "react"
import LazyLoad from "react-lazyload"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import classNames from "classnames"
import LoaderComponent from "../../../common/Loader/Loader.component"
import LazyLoadComponent from "../../common/LazyLoad/LazyLoad.component"
import {
  WinnerListType,
  WinnerListItemModel
} from "../../../../models/AdminModels/WinnerList.model"
import "./WinnerList.style.scss"
import CRMReportListItemComponent from "../CrmReportListItem/CrmReportListItem.component"

interface Props {
  tabIndex: number;
  isLoading: boolean;
  winners?: WinnerListItemModel[];
  onSelect: (tabIndex: number) => void;
  onLoadMore: () => void;
  onArtistClick: (id: number, statusStage1: string) => void;
  totalCount?: number;
  shouldLoadMore: boolean;
}

// const testwinners = [
//   {
//     id: 1,
//     artistId: 1111,
//     name: "string",
//     photo: "",
//     statusStage1: "CHECKED",
//     statusStage2: "NEW"
//   },
//   {
//     id: 2,
//     artistId: 222,
//     name: "string",
//     photo: "",
//     statusStage1: "EDITING",
//     statusStage2: "MODERATION"
//   }
// ]

const block = "winners-list"

const tabNames: { [key in WinnerListType]: string } = {
  new: "Требуется модерация",
  checked: "Oдобрено"
}

const WinnerListComponent = (props: Props): JSX.Element => {
  const {
    tabIndex,
    isLoading,
    winners,
    onSelect,
    shouldLoadMore,
    totalCount,
    onLoadMore,
    onArtistClick
  } = props

  const onLoadMoreHandle = (): void => {
    !isLoading && onLoadMore()
  }

  return (
    <div className={`${block} admin-layout-content`}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex: number): void => onSelect(tabIndex)}
      >
        <div className="admin-layout-content__header">
          <div className="page-title page-title--black admin-layout-content__title">
            Отчеты победителей
          </div>
          <TabList>
            {Object.keys(tabNames).map((category, index) => (
              <Tab key={category}>
                {tabIndex === index && (
                  <div className="admin-layout-content__counter">
                    {totalCount || 0}
                  </div>
                )}
                {tabNames[category as WinnerListType]}
              </Tab>
            ))}
          </TabList>
        </div>

        {isLoading && !winners?.length && (
          <div className="admin-layout-content__loading">
            <LoaderComponent width={100} height={100} />
          </div>
        )}

        <div
          className={classNames("admin-layout-content__body", `${block}__body`)}
        >
          {Object.keys(tabNames).map(category => (
            <TabPanel key={category}>
              {winners && winners.length ? (
                <>
                  {winners.map(winner => (
                    <CRMReportListItemComponent
                      key={winner.artistId}
                      {...winner}
                      onClick={(): void =>
                        onArtistClick(winner.artistId, winner.statusStage1)
                      }
                    />
                  ))}
                  {shouldLoadMore && (
                    <LazyLoad height={10} unmountIfInvisible={true}>
                      <LazyLoadComponent
                        isLoading={isLoading}
                        onMount={onLoadMoreHandle}
                      />
                    </LazyLoad>
                  )}
                </>
              ) : (
                !isLoading && (
                  <div className="admin-layout-content__empty">Список пуст</div>
                )
              )}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  )
}

export default WinnerListComponent
