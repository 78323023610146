import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import RegisterFormComponent from '../../components/CRM/RegisterForm/RegisterForm.component'
import { PATH_CRM } from '../../const/routes.const'
import { authContext } from '../../context/auth.context'
import { RegisterFormFields } from '../../models/AuthModels/register.model'
import { registerUser } from '../../services/auth.service'
import { registrationStore } from '../../store/AuthStore/auth.store'

const RegisterFormContainer = (props: RouteComponentProps): JSX.Element => {
  const { setUserRole } = useContext(authContext)
  const onSubmit = (data: RegisterFormFields): void => {
    registerUser(data).then((data) => {
      setUserRole(data.user.role)
      props.history.push(PATH_CRM)
    })
  }

  const onInputChange = (): void => {
    if (registrationStore.requestErrors) {
      registrationStore.clearErrors()
    }
  }

  return (
    <RegisterFormComponent
      onSubmit={onSubmit}
      onInputChange={onInputChange}
      error={registrationStore.requestErrors && registrationStore.requestErrors[0].message}
      isLoading={registrationStore.isLoading}
    />
  )
}

export default withRouter(observer(RegisterFormContainer))