import { action, observable } from "mobx"
import { ArtistListGetResponse, ListArtistModel } from "../../models/AdminModels/ArtistList.model"
import { ApiRequestStore } from "../common/apiRequest.store"

class ArtistListStore extends ApiRequestStore {
  @observable artists?: ListArtistModel[];
  @observable count?: number;
  @observable currentOffset?: number;
  @observable totalCount?: number;

  @action
  setArtistFormsData(data: ArtistListGetResponse): void {
    if (this.artists && data.items) {
      this.artists = [...this.artists, ...data.items]
    } else {
      this.artists = data.items
    }
    this.count = data.count
    this.totalCount = data.totalCount
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  }

  @action
  clearData = (): void => {
    this.artists = undefined
    this.count = undefined
    this.currentOffset = undefined
  }
}

export const artistListStore: ArtistListStore = new ArtistListStore()
