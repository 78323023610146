import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createNumberMask } from 'text-mask-addons'
import Button from '../../../common/Button/Button.component'
import InputComponent from '../../../common/Input/Input.component'

import './EvaluationMusicPerfomanceForm.style.scss'
import { EvaluateMusicPerfomanceForm } from '../../../../models/FestExpertModels/Evaluation.model'
import Modal from '../Modal/Modal.component'

interface Props {
  error?: string
  isLoading?: boolean
  onSubmit: (data: EvaluateMusicPerfomanceForm) => void
  isDisabled?: boolean
  defaultValues?: EvaluateMusicPerfomanceForm
}

const block = 'evaluation-music-perfomance-form'

const EvaluationMusicPerfomanceFormComponent = (props: Props): JSX.Element => {
  const { error, isLoading, onSubmit, isDisabled, defaultValues } = props
  const {
    handleSubmit,
    errors,
    control,
    formState: { isDirty, isValid },
    setValue
  } = useForm<EvaluateMusicPerfomanceForm>({ defaultValues, mode: 'onChange' })

  const [isOpenModal, setIsOpenModal] = React.useState(false)

  const getError = (): string | boolean => {
    const errorFieldType =
      errors.quality?.type ||
      errors.artistry?.type ||
      errors.emotionalResponse?.type

    if (errorFieldType === 'inRange') {
      return 'В некоторых полях оценка не вписывается в допустимый диапазон.'
    }

    if (errorFieldType === 'required') {
      return 'Заполнены не все обязательные поля.'
    }

    return false
  }

  useEffect(() => {
    setValue('quality', defaultValues?.quality)
    setValue('artistry', defaultValues?.artistry)
    setValue('emotionalResponse', defaultValues?.emotionalResponse)
  }, [defaultValues])

  return (
    <div className={block}>
      <div className={`${block}__title`}>
        1. Очная защита проектов – творческая программа
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Качество музыкальных произведений во всей его совокупности:
            оригинальность произведений, исполнительское мастерство,
            композиционное построение выступления и уровень владения
            музыкальными инструментами
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Это обязательное поле'
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return 'Введите число в диапазоне'
                    }
                  }
                }
              }}
              control={control}
              name="quality"
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: '',
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={'0'}
                  error={errors.quality?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Артистичность исполнения (в том числе общая сценическая культура,
            раскрытие художественного образа, уровень взаимодействия с
            аудиторией и артистизм музыкантов)
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Это обязательное поле'
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return 'Введите число в диапазоне'
                    }
                  }
                }
              }}
              control={control}
              name="artistry"
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: '',
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={'0'}
                  error={errors.artistry?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Уровень эмоционального отклика члена экспертного совета на
            представленные произведения
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Это обязательное поле'
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return 'Введите число в диапазоне'
                    }
                  }
                }
              }}
              control={control}
              name="emotionalResponse"
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: '',
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    maxValue: 10,
                    minValue: 0
                  })}
                  placeholder={'0'}
                  error={errors.emotionalResponse?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>

      {!isDisabled && (
        <div className={`${block}__submit`}>
          <Button
            onClick={() => setIsOpenModal(true)}
            text="Отправить"
            background="turquoise"
            isLoading={isLoading}
            isDisabled={isDisabled || !isDirty || !isValid}
          />
        </div>
      )}

      {!isValid && <div className={`${block}__error`}>{getError()}</div>}

      {!!error && <div className={`${block}__error`}>{error}</div>}

      <Modal
        onCloseModal={() => setIsOpenModal(false)}
        isOpenModal={isOpenModal}
        onSubmit={handleSubmit(onSubmit)}
      />
    </div>
  )
}

export default EvaluationMusicPerfomanceFormComponent
