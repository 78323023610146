import { autoserialize, autoserializeAs } from "cerialize"
import { FileUploadResponse } from "../common/fileUpload.model"
import { CommentModel } from "./artistForm.model"

export enum WinnerFormStatus {
  EDITING = "EDITING",
  MODERATION = "MODERATION",
  CHECKED = "CHECKED",
}

// export class ArtistFormSaveRequest {
//   @autoserialize name: string | null;
//   @autoserialize description: string | null;
//   @autoserialize country: string | null;
//   @autoserialize city: string | null;
//   @autoserialize genre: string | null;
//   @autoserializeAs("contact_email") contactEmail: string;
//   @autoserializeAs("contact_phone") contactPhone: string | null;
//   @autoserializeAs("contact_name") contactName: string | null;
//   @autoserializeAs("facebook_url") facebookUrl: string | null;
//   @autoserializeAs("instagram_url") instagramUrl: string | null;
//   @autoserializeAs("youtube_url") youtubeUrl: string | null;
//   @autoserializeAs("vkontakte_url") vkontakteUrl: string | null;
//   @autoserializeAs("tiktok_url") tiktokUrl: string | null;
//   @autoserializeAs("twitter_url") twitterUrl: string | null;
//   @autoserializeAs("music_links") musicLinks: Array<string | null>;
//   @autoserializeAs("project_name") projectName: string | null;
//   @autoserializeAs("project_description") projectDescription: string | null;
//   @autoserializeAs("project_start") projectStart: string | null;
//   @autoserializeAs("project_end") projectEnd: string | null;
//   @autoserializeAs("project_point") projectPoint: string | null;
//   @autoserializeAs("project_relevance") projectRelevance: string | null;
//   @autoserializeAs("project_pr_strategy") projectPrStrategy: string | null;
//   @autoserializeAs("project_implementation_if_epidemic")
//   projectImplementationIfEpidemic: string | null;
//   @autoserializeAs("project_expectations") projectExpectations: string | null;
//   @autoserializeAs("project_audience_expectations")
//   projectAudienceExpectations: string | null;
//   @autoserializeAs("project_budget") projectBudget: number | null;
//   @autoserializeAs("project_budget_sources") projectBudgetSources:
//     | string
//     | null;
//   @autoserializeAs("promo_post_url") promoPostUrl: string | null;
//   @autoserializeAs("playlist_code") playlistCode: string | null;
//   @autoserializeAs("vk_clips_url") vkClipsUrl: string | null;
//   @autoserializeAs("calendar_plan_text") calendarPlanText: string | null;
//   @autoserializeAs(BudgetRowModel, "budget_plan_json")
//   budgetPlanJson: BudgetRowModel[];
//   @autoserializeAs("project_online_audience_expectations")
//   projectOnlineAudienceExpectations: number | null;
//   @autoserializeAs("project_offilne_audience_expectations")
//   projectOffilneAudienceExpectations: number | null;
//   @autoserializeAs("project_media_coverage_expectations")
//   projectMediaCoverageExpectations: number | null;
//   @autoserializeAs("invited_specialists") invitedSpecialists: string | null;
//   @autoserializeAs("project_target_audience") projectTargetAudience:
//     | string
//     | null;

//   constructor(
//     name: string | null,
//     description: string | null,
//     country: string | null,
//     city: string | null,
//     genre: string | null,
//     contactEmail: string,
//     contactPhone: string | null,
//     contactName: string | null,
//     facebookUrl: string | null,
//     instagramUrl: string | null,
//     youtubeUrl: string | null,
//     vkontakteUrl: string | null,
//     tiktokUrl: string | null,
//     twitterUrl: string | null,
//     musicLinks: Array<string | null>,
//     projectName: string | null,
//     projectDescription: string | null,
//     projectStart: string | null,
//     projectEnd: string | null,
//     projectPoint: string | null,
//     projectRelevance: string | null,
//     projectPrStrategy: string | null,
//     projectImplementationIfEpidemic: string | null,
//     projectExpectations: string | null,
//     projectAudienceExpectations: string | null,
//     projectBudget: number | null,
//     projectBudgetSources: string | null,
//     promoPostUrl: string | null,
//     playlistCode: string | null,
//     vkClipsUrl: string | null,
//     calendarPlanText: string | null,
//     budgetPlanJson: BudgetRowModel[],
//     projectOnlineAudienceExpectations: number | null,
//     projectOffilneAudienceExpectations: number | null,
//     projectMediaCoverageExpectations: number | null,
//     invitedSpecialists: string | null,
//     projectTargetAudience: string | null
//   ) {
//     this.name = name
//     this.description = description
//     this.country = country
//     this.city = city
//     this.genre = genre
//     this.contactEmail = contactEmail
//     this.contactPhone = contactPhone
//     this.contactName = contactName
//     this.facebookUrl = facebookUrl
//     this.instagramUrl = instagramUrl
//     this.youtubeUrl = youtubeUrl
//     this.vkontakteUrl = vkontakteUrl
//     this.tiktokUrl = tiktokUrl
//     this.twitterUrl = twitterUrl
//     this.musicLinks = musicLinks
//     this.projectName = projectName
//     this.projectDescription = projectDescription
//     this.projectStart = projectStart
//     this.projectEnd = projectEnd
//     this.projectPoint = projectPoint
//     this.projectRelevance = projectRelevance
//     this.projectPrStrategy = projectPrStrategy
//     this.projectImplementationIfEpidemic = projectImplementationIfEpidemic
//     this.projectExpectations = projectExpectations
//     this.projectAudienceExpectations = projectAudienceExpectations
//     this.projectBudget = projectBudget
//     this.projectBudgetSources = projectBudgetSources
//     this.promoPostUrl = promoPostUrl
//     this.playlistCode = playlistCode
//     this.vkClipsUrl = vkClipsUrl
//     this.calendarPlanText = calendarPlanText
//     this.budgetPlanJson = budgetPlanJson
//     this.projectOnlineAudienceExpectations = projectOnlineAudienceExpectations
//     this.projectOffilneAudienceExpectations =
//       projectOffilneAudienceExpectations
//     this.projectMediaCoverageExpectations = projectMediaCoverageExpectations
//     this.invitedSpecialists = invitedSpecialists
//     this.projectTargetAudience = projectTargetAudience
//   }
// }

export class WinnerFormResponseForm {
  @autoserializeAs("id") id: number;
  @autoserializeAs("artist_id") artistId: number;
  @autoserializeAs("project_leader_name") projectLeaderName: string | null;
  @autoserializeAs("artist_name") artistName: string | null;
  @autoserializeAs("project_name") projectName: string | null;
  @autoserializeAs("contract_number") contractNumber: number;
  @autoserializeAs("contract_date") contractDate: string | null;
  @autoserializeAs("project_start") projectStart: string | null;
  @autoserializeAs("project_end") projectEnd: string | null;
  @autoserializeAs("checkpoint_completion_details")
  checkpointCompletionDetails: string | null;
  @autoserializeAs("description_project_activities")
  descriptionProjectActivities: string | null;
  @autoserializeAs("past_event_overview") pastEventOverview: string | null;
  @autoserializeAs("project_quantitative_qualitative_results")
  projectQuantitativeQualitativeResults: string | null;
  @autoserializeAs("project_in_media_urls") projectInMediaUrls: string[] | null;
  @autoserializeAs("media_files_url") mediaFilesUrl: string | null;
  @autoserializeAs("electronic_materials_versions")
  electronicMaterialsVersions: string | null;
  @autoserializeAs("artist_project_contribution") artistProjectContribution:
    | string
    | null;
  @autoserializeAs("detected_defects") detectedDefects: string | null;
  @autoserializeAs("general_project_results") generalProjectResults:
    | string
    | null;
  @autoserializeAs("primary_reporting_documents") primaryReportingDocuments:
    | string
    | null;
  @autoserializeAs(FileUploadResponse, "financial_statement_form")
  financialStatementForm: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "consent_provide_data")
  consentProvideData: FileUploadResponse | null;

  constructor(
    id: number,
    artistId: number,
    projectLeaderName: string | null,
    artistName: string | null,
    projectName: string | null,
    contractNumber: number,
    contractDate: string | null,
    projectStart: string | null,
    projectEnd: string | null,
    checkpointCompletionDetails: string | null,
    descriptionProjectActivities: string | null, //лишнее?
    pastEventOverview: string | null,
    projectQuantitativeQualitativeResults: string | null,
    projectInMediaUrls: string[] | null,
    mediaFilesUrl: string | null,
    electronicMaterialsVersions: string | null,
    artistProjectContribution: string | null,
    detectedDefects: string | null,
    generalProjectResults: string | null,
    primaryReportingDocuments: string | null,
    financialStatementForm: FileUploadResponse | null,
    consentProvideData: FileUploadResponse | null
  ) {
    this.id = id
    this.artistId = artistId
    this.projectLeaderName = projectLeaderName
    this.artistName = artistName
    this.projectName = projectName
    this.contractNumber = contractNumber
    this.contractDate = contractDate
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.checkpointCompletionDetails = checkpointCompletionDetails
    this.descriptionProjectActivities = descriptionProjectActivities
    this.pastEventOverview = pastEventOverview
    this.projectQuantitativeQualitativeResults =
      projectQuantitativeQualitativeResults
    this.projectInMediaUrls = projectInMediaUrls
    this.mediaFilesUrl = mediaFilesUrl
    this.electronicMaterialsVersions = electronicMaterialsVersions
    this.artistProjectContribution = artistProjectContribution
    this.detectedDefects = detectedDefects
    this.generalProjectResults = generalProjectResults
    this.primaryReportingDocuments = primaryReportingDocuments
    this.financialStatementForm = financialStatementForm
    this.consentProvideData = consentProvideData
  }
}

export class WinnerFormResponseMeta {
  @autoserialize status: WinnerFormStatus;
  @autoserializeAs("updated_at") updatedAt: string | null;
  @autoserializeAs("applied_at") appliedAt: string | null;
  @autoserializeAs("rejected_at") rejectedAt: string | null;
  @autoserializeAs("checked_at") checkedAt: string | null;

  constructor(
    status: WinnerFormStatus,
    updatedAt: string | null,
    appliedAt: string | null,
    rejectedAt: string | null,
    checkedAt: string | null
  ) {
    this.status = status
    this.updatedAt = updatedAt
    this.appliedAt = appliedAt
    this.rejectedAt = rejectedAt
    this.checkedAt = checkedAt
  }
}

export class WinnerFormGetRequest {
  @autoserialize stage: number;

  constructor(stage: number) {
    this.stage = stage
  }
}

export class WinnerFormGetResponse {
  @autoserializeAs(WinnerFormResponseForm) report: WinnerFormResponseForm;
  @autoserializeAs(WinnerFormResponseMeta) meta: WinnerFormResponseMeta;
  @autoserializeAs(CommentModel) comments: CommentModel[];

  constructor(
    report: WinnerFormResponseForm,
    meta: WinnerFormResponseMeta,
    comments: CommentModel[]
  ) {
    this.report = report
    this.meta = meta
    this.comments = comments
  }
}

export class WinnerFormFields {
  @autoserializeAs("id") id: number;
  @autoserializeAs("artist_id") artistId: number;
  @autoserializeAs("project_leader_name") projectLeaderName: string | null;
  @autoserializeAs("artist_name") artistName: string | null;
  @autoserializeAs("project_name") projectName: string | null;
  @autoserializeAs("contract_number") contractNumber: number;
  @autoserializeAs("contract_date") contractDate: string | null;
  @autoserializeAs("project_start") projectStart: string | null;
  @autoserializeAs("project_end") projectEnd: string | null;
  @autoserializeAs("checkpoint_completion_details")
  checkpointCompletionDetails: string | null;
  @autoserializeAs("description_project_activities")
  descriptionProjectActivities: string | null;
  @autoserializeAs("past_event_overview") pastEventOverview: string | null;
  @autoserializeAs("project_quantitative_qualitative_results")
  projectQuantitativeQualitativeResults: string | null;
  @autoserializeAs("project_in_media_urls") projectInMediaUrls: string[] | null;
  @autoserializeAs("media_files_url") mediaFilesUrl: string | null;
  @autoserializeAs("electronic_materials_versions")
  electronicMaterialsVersions: string | null;
  @autoserializeAs("artist_project_contribution") artistProjectContribution:
    | string
    | null;
  @autoserializeAs("detected_defects") detectedDefects: string | null;
  @autoserializeAs("general_project_results") generalProjectResults:
    | string
    | null;
  @autoserializeAs("primary_reporting_documents") primaryReportingDocuments:
    | string
    | null;
  @autoserializeAs(FileUploadResponse, "financial_statement_form")
  financialStatementForm: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "consent_provide_data")
  consentProvideData: FileUploadResponse | null;

  constructor(
    id: number,
    artistId: number,
    projectLeaderName: string | null,
    artistName: string | null,
    projectName: string | null,
    contractNumber: number,
    contractDate: string | null,
    projectStart: string | null,
    projectEnd: string | null,
    checkpointCompletionDetails: string | null,
    descriptionProjectActivities: string | null, //лишнее?
    pastEventOverview: string | null,
    projectQuantitativeQualitativeResults: string | null,
    projectInMediaUrls: string[] | null,
    mediaFilesUrl: string | null,
    electronicMaterialsVersions: string | null,
    artistProjectContribution: string | null,
    detectedDefects: string | null,
    generalProjectResults: string | null,
    primaryReportingDocuments: string | null,
    financialStatementForm: FileUploadResponse | null,
    consentProvideData: FileUploadResponse | null
  ) {
    this.id = id
    this.artistId = artistId
    this.projectLeaderName = projectLeaderName
    this.artistName = artistName
    this.projectName = projectName
    this.contractNumber = contractNumber
    this.contractDate = contractDate
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.checkpointCompletionDetails = checkpointCompletionDetails
    this.descriptionProjectActivities = descriptionProjectActivities
    this.pastEventOverview = pastEventOverview
    this.projectQuantitativeQualitativeResults =
      projectQuantitativeQualitativeResults
    this.projectInMediaUrls = projectInMediaUrls
    this.mediaFilesUrl = mediaFilesUrl
    this.electronicMaterialsVersions = electronicMaterialsVersions
    this.artistProjectContribution = artistProjectContribution
    this.detectedDefects = detectedDefects
    this.generalProjectResults = generalProjectResults
    this.primaryReportingDocuments = primaryReportingDocuments
    this.financialStatementForm = financialStatementForm
    this.consentProvideData = consentProvideData
  }
}

export class WinnerFormSaveRequest {
  @autoserializeAs("project_leader_name") projectLeaderName: string | null;
  @autoserializeAs("artist_name") artistName: string | null;
  @autoserializeAs("project_name") projectName: string | null;
  @autoserializeAs("contract_number") contractNumber: number;
  @autoserializeAs("contract_date") contractDate: string | null;
  @autoserializeAs("project_start") projectStart: string | null;
  @autoserializeAs("project_end") projectEnd: string | null;
  @autoserializeAs("checkpoint_completion_details")
  checkpointCompletionDetails: string | null;
  @autoserializeAs("description_project_activities")
  descriptionProjectActivities: string | null;
  @autoserializeAs("past_event_overview") pastEventOverview: string | null;
  @autoserializeAs("project_quantitative_qualitative_results")
  projectQuantitativeQualitativeResults: string | null;
  @autoserializeAs("project_in_media_urls") projectInMediaUrls: string[] | null;
  @autoserializeAs("media_files_url") mediaFilesUrl: string | null;
  @autoserializeAs("electronic_materials_versions")
  electronicMaterialsVersions: string | null;
  @autoserializeAs("artist_project_contribution") artistProjectContribution:
    | string
    | null;
  @autoserializeAs("detected_defects") detectedDefects: string | null;
  @autoserializeAs("general_project_results") generalProjectResults:
    | string
    | null;
  @autoserializeAs("primary_reporting_documents") primaryReportingDocuments:
    | string
    | null;

  constructor(
    projectLeaderName: string | null,
    artistName: string | null,
    projectName: string | null,
    contractNumber: number,
    contractDate: string | null,
    projectStart: string | null,
    projectEnd: string | null,
    checkpointCompletionDetails: string | null,
    descriptionProjectActivities: string | null,
    pastEventOverview: string | null,
    projectQuantitativeQualitativeResults: string | null,
    projectInMediaUrls: string[] | null,
    mediaFilesUrl: string | null,
    electronicMaterialsVersions: string | null,
    artistProjectContribution: string | null,
    detectedDefects: string | null,
    generalProjectResults: string | null,
    primaryReportingDocuments: string | null
  ) {
    this.projectLeaderName = projectLeaderName
    this.artistName = artistName
    this.projectName = projectName
    this.contractNumber = contractNumber
    this.contractDate = contractDate
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.checkpointCompletionDetails = checkpointCompletionDetails
    this.descriptionProjectActivities = descriptionProjectActivities
    this.pastEventOverview = pastEventOverview
    this.projectQuantitativeQualitativeResults =
      projectQuantitativeQualitativeResults
    this.projectInMediaUrls = projectInMediaUrls
    this.mediaFilesUrl = mediaFilesUrl
    this.electronicMaterialsVersions = electronicMaterialsVersions
    this.artistProjectContribution = artistProjectContribution
    this.detectedDefects = detectedDefects
    this.generalProjectResults = generalProjectResults
    this.primaryReportingDocuments = primaryReportingDocuments
  }
}
