import React, { ReactNode, useEffect, useState } from 'react'
import { UserRole } from '../../const/permissionsRules.const'
import { AuthProvider } from '../../context/auth.context'

interface Props {
  children: ReactNode;
}

const AuthContextProvider = (props: Props): JSX.Element => {
  const [userRole, setUserRoleState] = useState<UserRole | undefined>()

  const getUserRole = (roleString: string): UserRole => {
    switch(roleString) {
      case UserRole.ADMIN:
        return UserRole.ADMIN
      case UserRole.ARTIST:
        return UserRole.ARTIST
      case UserRole.EXPERT:
        return UserRole.EXPERT
      case UserRole.FESTIVAL_EXPERT:
        return UserRole.FESTIVAL_EXPERT
      default: 
        return UserRole.VISITOR
    }
  }

  const setUserRole = (role: UserRole): void => {
    localStorage.setItem('userRole', role)
    setUserRoleState(role)
  }

  useEffect(() => {
    if (localStorage.getItem('authToken')?.length && localStorage.getItem('userRole')?.length) {
      setUserRoleState(getUserRole(localStorage.getItem('userRole') as string))
    } else {
      setUserRoleState(UserRole.VISITOR)
    }
  }, [])

  const logout = (): void => {
    localStorage.removeItem('authToken')
    localStorage.removeItem('userRole')
  }

  const authProviderValue = {
    user: {
      role: userRole
    },
    setUserRole,
    logout
  }

  return (
    <AuthProvider value={authProviderValue}>
      {authProviderValue.user.role && props.children}
    </AuthProvider>
  )
}

export default AuthContextProvider