import { autoserialize, autoserializeAs } from "cerialize"

export class ArtistRatingGetResponse {
  @autoserializeAs("emotional_response") emotionalResponse: number
  @autoserialize quality: number
  @autoserialize reality: number
  @autoserialize relevance: number
  @autoserializeAs("total_rating") totalRating: number
  @autoserialize comments: string[]

  constructor(
    quality: number,
    emotionalResponse: number,
    reality: number,
    relevance: number,
    totalRating: number,
    comments: string[]
  ) {
    this.quality = quality
    this.emotionalResponse = emotionalResponse
    this.reality = reality
    this.relevance = relevance
    this.totalRating = totalRating
    this.comments = comments
  }
}
