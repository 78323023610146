import React from 'react'
import Dropzone, { DropEvent } from 'react-dropzone'
import { ReactComponent as Plus } from '../../../../assets/icons/plus-white.svg'
import { ReactComponent as Remove } from '../../../../assets/icons/delete.svg'
import { ReactComponent as Loader } from '../../../../assets/icons/loading.svg'
import { FileUploadResponse } from '../../../../models/common/fileUpload.model'
import { ReactComponent as Doc } from '../../../../assets/icons/doc.svg'
import { FileUploadLocalState } from '../../../../store/common/fileUpload.store'
import { observer } from 'mobx-react'

import './UploadFile.style.sass'
import classNames from 'classnames'

const block = 'upload-file'

interface PropTypes {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    rejectedFiles: T[],
    event: DropEvent
  ) => void
  uploadedFileState: FileUploadLocalState
  title?: string
  hasTitleError?: boolean
  fileTypes: string[]
  isImage: boolean
  uploadedFile?: FileUploadResponse
  showFileName: boolean
  error: string
  onDelete: (event: React.SyntheticEvent<HTMLElement>) => void
  disabled?: boolean
  titleIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

@observer
export default class UploadFileComponent extends React.Component<PropTypes> {
  onDropzoneClick = (): void => {
    if (this.props.uploadedFileState?.uploadedFileData?.url) {      
      window.open(this.props.uploadedFileState?.uploadedFileData.url, '_blank')
    }

    if (this.props.uploadedFile?.url) {
      window.open(this.props.uploadedFile.url, '_blank')
    }
  }

  render(): JSX.Element {
    const {
      uploadedFileState,
      title,
      fileTypes,
      isImage,
      uploadedFile,
      error,
      onDelete,
      hasTitleError
    } = this.props

    const disabled = this.props.disabled
    // !!this.props.uploadedFileState?.uploadedFileData?.url
    // !!this.props.uploadedFile?.url

    const shouldShowDocument =
      (uploadedFileState?.uploadedFileData?.url || uploadedFile?.url) &&
      !isImage &&
      uploadedFile?.url &&
      uploadedFile?.url.indexOf('.png') < 0 &&
      uploadedFile?.url.indexOf('.jpg') < 0 &&
      uploadedFile?.url.indexOf('.jpeg') < 0

    return (
      <Dropzone
        onDrop={this.props.onDrop}
        accept={fileTypes}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }): JSX.Element => (
          <div
            className={classNames(
              `${block}`,
              shouldShowDocument && `${block}--document`
            )}
            onClick={this.onDropzoneClick}
          >
            {title && (
              <div
                className={classNames(
                  `${block}__title`,
                  hasTitleError && `${block}__title--error`
                )}
              >
                {title}
                {this.props.titleIcon && (
                  <div className={`${block}__title-icon`}>
                    {React.createElement(this.props.titleIcon)}
                  </div>
                )}
              </div>
            )}
            <div
              className={classNames(
                `${block}__dropzone${this.props.disabled ? '--disabled' : ''}`,
                shouldShowDocument && `${block}__dropzone--document`
              )}
              style={
                uploadedFileState?.uploadedFileData?.url && isImage
                  ? {
                      backgroundImage: `url(${
                        uploadedFileState.uploadedFileData.urlSizeM ||
                        uploadedFileState.uploadedFileData.url
                      })`
                    }
                  : uploadedFile?.url && !uploadedFileState?.isLoading
                  ? {
                      backgroundImage: `url(${
                        uploadedFile.urlSizeM || uploadedFile.url
                      })`
                    }
                  : {}
              }
            >
              <div {...getRootProps()}>
                <input 
                {...getInputProps()} 
                disabled={!!this.props.uploadedFileState?.uploadedFileData?.url || !!this.props.uploadedFile?.url} 
                />
                <div
                  className={classNames(
                    `${block}__control`,
                    shouldShowDocument && `${block}__control--document`
                  )}
                >
                  {(uploadedFileState?.uploadedFileData?.url ||
                    (uploadedFile?.url && !this.props.disabled)) && (
                    <>
                      <div
                        className={classNames(
                          `${block}__delete`,
                          shouldShowDocument && `${block}__delete--document`
                        )}
                        onClick={onDelete}
                      >
                        <Remove />
                      </div>
                      <div className={`${block}__download-overlay`}></div>
                    </>
                  )}
                  {!uploadedFileState?.isLoading &&
                    !uploadedFileState?.uploadedFileData?.url &&
                    !uploadedFile?.url && <Plus fill="#000" />}
                  {uploadedFileState?.isLoading && (
                    <div className={`${block}__loader`}>
                      <Loader fill="#21CEB4" />
                    </div>
                  )}
                  {shouldShowDocument && (
                    <div className={`${block}__file`}>
                      <Doc fill="#000" width="15" height="15" />
                      <div
                        className={classNames(
                          `${block}__file-name`,
                          shouldShowDocument && `${block}__file-name--document`
                        )}
                      >
                        {uploadedFileState?.uploadedFileData?.originalName ||
                          uploadedFile?.originalName}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${block}__error`}>{error}</div>
          </div>
        )}
      </Dropzone>
    )
  }
}
