import React, { useState, useEffect, useRef } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { debounce } from "debounce"
import SearchComponent from "../../../components/common/Search/Search.component"

interface Props {
  onSearchChange?: (value: string) => void;
}

const SearchInputContainer = (
  props: RouteComponentProps & Props
): JSX.Element => {
  const { onSearchChange } = props
  const [searchValue, setSearchValue] = useState(
    () => new URLSearchParams(props.location.search).get("name") || ""
  )

  const debouncedSearchChangeHandleRef = useRef(
    onSearchChange && debounce(onSearchChange, 300)
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedSearchChangeHandleRef.current &&
      debouncedSearchChangeHandleRef.current(event.target.value)

    setSearchValue(event.target.value)

    if (event.target.value.trim() === "") {
      props.history.push(`${props.location.pathname}`)
    } else {
      props.history.push(
        `${props.location.pathname}?name=${event.target.value}`
      )
    }
  }

  const onClear = (): void => {
    setSearchValue("")
    props.history.push(`${props.location.pathname}`)

    onSearchChange && onSearchChange("")
  }

  useEffect(() => {
    debouncedSearchChangeHandleRef.current =
      onSearchChange && debounce(onSearchChange, 300)
  })

  return (
    <SearchComponent
      value={searchValue}
      onChange={onChange}
      onClear={onClear}
    />
  )
}

export default withRouter(SearchInputContainer)
