import React, { useEffect, useState } from "react"

import {
  Paper,
  Dialog,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  TableFooter,
  TableContainer
} from "@material-ui/core"

import {
  getCurrentBudgetTotal,
  getFullPriceBudget,
  getFullPriceBudgetTotal,
  getRequestedBudget,
  getRequestedBudgetTotal
} from "../../../../utils/budget"
import { BudgetRowModel } from "../../../../models/ArtistModels/artistForm.model"
type RowsType = {
  name: string | null
  price: number | null
  count: number | null
  fullPrice: number
  currentBudget: number | null
  requestedBudget: number
}
const createData = (
  name: string | null,
  price: number | null,
  count: number | null,
  fullPrice: number,
  currentBudget: number | null,
  requestedBudget: number
): RowsType => ({
  name,
  price,
  count,
  fullPrice,
  currentBudget,
  requestedBudget
})

interface Props {
  formValues: BudgetRowModel[]
  onCloseModal: () => void
  isOpenModal: boolean
}

const BudgetTable = (props: Props): JSX.Element => {
  const { formValues, isOpenModal, onCloseModal } = props
  const [rows, setRows] = useState<RowsType[]>([])

  useEffect(() => {

    if (formValues.length >= 1) {
      const Currentrows: RowsType[] = []
      formValues.forEach((elem: BudgetRowModel) => {
        const name = elem.name
        const price = elem.price
        const count = elem.count
        const currentBudget = elem.currentBudget
        Currentrows.push(
          createData(
            name,
            price,
            count,
            getFullPriceBudget(elem),
            currentBudget,
            getRequestedBudget(elem)
          )
        )
      })
      setRows(Currentrows)
    }
  }, [formValues])

  return (
    <Dialog
      maxWidth={false}
      open={isOpenModal}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
    >
      <TableContainer component={Paper}>
        <Table aria-label="budget table">
          <TableHead style={{ background: "#21CEB4" }}>
            <TableRow>
              <TableCell align="left" style={{ color: "#fff" }}>
                №
              </TableCell>
              <TableCell align="left" style={{ color: "#fff" }}>
                Наименование
              </TableCell>
              <TableCell align="left" style={{ color: "#fff" }}>
                Цена (за 1 шт.)
              </TableCell>
              <TableCell align="left" style={{ color: "#fff" }}>
                Кол-во
              </TableCell>
              <TableCell align="left" style={{ color: "#fff" }}>
                Стоимость
              </TableCell>
              <TableCell align="left" style={{ color: "#fff" }}>
                Cофинансирование
              </TableCell>
              <TableCell align="left" style={{ color: "#fff" }}>
                Запрашиваемый бюджет
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: RowsType, index: number) => (
              <TableRow key={`${row.name}_${index}`}>
                <TableCell component="th" scope="row">
                  {index+1}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.price} ₽</TableCell>
                <TableCell align="center">{row.count}</TableCell>
                <TableCell align="center">{row.fullPrice} ₽</TableCell>
                <TableCell align="center">{row.currentBudget} ₽</TableCell>
                <TableCell align="center">{row.requestedBudget} ₽</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell
                align="center"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Итого:
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "black", fontWeight: "bold" }}
              >
                {getFullPriceBudgetTotal(rows)} ₽
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "black", fontWeight: "bold" }}
              >
                {getCurrentBudgetTotal(rows)} ₽
              </TableCell>
              <TableCell
                align="center"
                style={{ color: "black", fontWeight: "bold" }}
              >
                {getRequestedBudgetTotal(rows)} ₽
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Dialog>
  )
}

export default BudgetTable
