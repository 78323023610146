import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { createNumberMask } from "text-mask-addons"
import { EvaluateForm, EvaluationValuationModel } from "../../../../models/ExpertModels/Evaluation.model"
import Button from "../../../common/Button/Button.component"
import InputComponent from "../../../common/Input/Input.component"
import TextareaComponent from "../../../common/Textarea/Textarea.component"

import "./EvaluationForm.style.scss"

interface Props {
  onSubmit: (data: EvaluateForm) => void;
  formData?: EvaluationValuationModel
  error?: string;
  isLoading?: boolean;
}

const block = "evaluation-form"

const EvaluationFormComponent = (props: Props): JSX.Element => {
  const { error, isLoading, formData, onSubmit } = props
  const { handleSubmit, errors, control, watch, setValue, formState } =
    useForm<EvaluateForm>({defaultValues: formData? {
      comment: formData.comment,
      emotionalResponse: formData.emotionalResponse.toString(),
      quality: formData.quality.toString(),
      reality: formData.reality.toString(),
      relevance: formData.relevance.toString()
    }: undefined})    

  const realityField = watch("reality")
  const qualityField = watch("quality")
  const relevanceField = watch("relevance")
  const emotionalResponseField = watch("emotionalResponse")

  const getSummary = (): number => {
    const reality = !!realityField ? parseInt(realityField) : 0
    const quality = !!qualityField ? parseInt(qualityField) : 0
    const relevance = !!relevanceField ? parseInt(relevanceField) : 0
    const emotionalResponse = !!emotionalResponseField
      ? parseInt(emotionalResponseField)
      : 0

    return reality * 2 + quality * 4 + relevance  + emotionalResponse * 3
  }

  const getError = (): string | boolean => {
    const errorFieldType =
      errors.emotionalResponse?.type ||
      errors.quality?.type ||
      errors.reality?.type ||
      errors.relevance?.type

    if (errorFieldType === "inRange") {
      return "В некоторых полях оценка не вписывается в допустимый диапазон."
    }

    if (errorFieldType === "required") {
      return "Заполнены не все обязательные поля."
    }

    return false
  }

  useEffect(() => {
    if (formData) {
      setValue('reality', formData.reality.toString())
      setValue('quality', formData.quality.toString())
      setValue('relevance', formData.relevance.toString())
      setValue('emotionalResponse', formData.emotionalResponse.toString())
    }
  }, [formData])

  return (
    <div className={block}>
      <div className={`${block}__title`}>ОЦЕНИТЬ КОЛЛЕКТИВ</div>

      <div className={`${block}__subtitle`}>
        Пожалуйста, оцените коллектив по каждому из критериев. В каждой строке
        можно поставить от 0 до 10 баллов. Далее в системе ваши баллы будут
        умножены на соответствующие критериям коэффициенты. <br />
        <ul>
          <li>Реализуемость проекта — коэффициент 2</li>
          <li>Актуальность заявленного проекта — коэффициент 1.</li>
          <li>Качество музыкальных произведений — коэффициент 4.</li>
          <li>Уровень эмоционального отклика — коэффициент 3.</li>
        </ul>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Реализуемость проекта, в том числе логическая связность проекта,
            реалистичность бюджета и календарного плана, соответствие
            запланированных результатов разделам заявки.
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="reality"
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={"0"}
                  error={errors.reality?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Актуальность заявленного проекта для музыкального коллектива на
            данный момент.
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="relevance"
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={"0"}
                  error={errors.relevance?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Качество музыкальных произведений во всей его совокупности:
            оригинальность, произведений, исполнительское мастерство,
            композиционное построение произведений и уровень владения
            музыкальными инструментами.
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="quality"
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    integerLimit: 2
                  })}
                  placeholder={"0"}
                  error={errors.quality?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-title`}>Оцените от 0 до 10 баллов</div>
        <div className={`${block}__input-row`}>
          <div className={`${block}__input-label`}>
            Уровень эмоционального отклика на музыкальные произведения.
          </div>
          <div className={`${block}__input`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  inRange: (value): boolean | string => {
                    const valueInt = parseInt(value)
                    const maxValue = 10
                    const minValue = 0

                    if (valueInt <= maxValue && valueInt >= minValue) {
                      return true
                    } else {
                      return "Введите число в диапазоне"
                    }
                  }
                }
              }}
              control={control}
              name="emotionalResponse"
              render={({ onChange, value, name }): JSX.Element => (
                <InputComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  mask={createNumberMask({
                    prefix: "",
                    includeThousandsSeparator: false,
                    allowDecimal: false,
                    maxValue: 10,
                    minValue: 0
                  })}
                  placeholder={"0"}
                  error={errors.emotionalResponse?.message}
                  isErrorStatic={true}
                  isLabelCentered={true}
                  shouldHideErrorMessage={true}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-row`}>
          <div className={`${block}__textarea`}>
            <Controller
              control={control}
              name="comment"
              render={({ onChange, value, name }): JSX.Element => (
                <TextareaComponent
                  onChange={(event): void => onChange(event.target.value)}
                  value={value}
                  name={name}
                  placeholder={"Комментарий"}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={`${block}__summary`}>
        <div className={`${block}__summary-title`}>
          Итого баллов с коэффициентами:
        </div>
        <div className={`${block}__summary-number`}>{getSummary()}</div>
      </div>

      <div className={`${block}__submit`}>
        <Button
          onClick={handleSubmit(onSubmit)}
          text="Отправить оценку"
          background="turquoise"
          isLoading={isLoading}
        />
      </div>

      {!formState.isValid && (
        <div className={`${block}__error`}>{getError()}</div>
      )}

      {!!error && <div className={`${block}__error`}>{error}</div>}
    </div>
  )
}

export default EvaluationFormComponent
