import { autoserialize, autoserializeAs } from "cerialize"
import {
  EvaluationValuationModel,
  AvailableToEvaluateModel
} from "./Evaluation.model"
import { BudgetRowModel } from "../ArtistModels/artistForm.model"

export class FestExpertEvaluationLeaderModel {
  @autoserialize name: string;
  @autoserializeAs("music_experience") musicExperience: string;

  constructor(
    name: string,
    musicExperience: string
  ) {
    this.name = name
    this.musicExperience = musicExperience
  }
}

export class FestExpertEvaluationFileModel {
  @autoserialize id: number;
  @autoserializeAs("original_name") name: string;
  @autoserialize url: string;

  constructor(id: number, name: string, url: string) {
    this.id = id
    this.name = name
    this.url = url
  }
}

export class FestExpertEvaluationArtistModel {
  @autoserialize id: number;
  @autoserializeAs("form_id") formId: number;
  @autoserializeAs("photo_site") photoSite: string;
  @autoserialize name: string;
  @autoserialize genre: string;
  @autoserialize country: string;
  @autoserialize city: string;
  @autoserialize description: string;
  @autoserializeAs("facebook_url") facebookUrl: string | null;
  @autoserializeAs("instagram_url") instagramUrl: string | null;
  @autoserializeAs("youtube_url") youtubeUrl: string | null;
  @autoserializeAs("vkontakte_url") vkontakteUrl: string | null;
  @autoserializeAs("twitter_url") twitterUrl: string | null;
  @autoserializeAs("tiktok_url") tiktokUrl: string | null;
  @autoserializeAs("music_links") musicLinks: Array<string | null>;
  @autoserializeAs("project_name") projectName: string;
  @autoserializeAs("project_description") projectDescription: string;
  @autoserializeAs("project_relevance") projectRelevance: string;
  @autoserializeAs("project_point") projectPoint: string;
  @autoserializeAs("project_target_audience") projectTargetAudience:
    | string
    | null;
  @autoserializeAs("project_pr_strategy") projectPrStrategy: string;
  @autoserializeAs("project_start") projectStart: string;
  @autoserializeAs("project_end") projectEnd: string;
  @autoserializeAs("calendar_plan_text") calendarPlanText: string | null;
  @autoserializeAs("project_online_audience_expectations")
  projectOnlineAudienceExpectations: number | null;
  @autoserializeAs("project_offilne_audience_expectations")
  projectOffilneAudienceExpectations: number | null;
  @autoserializeAs("project_media_coverage_expectations")
  projectMediaCoverageExpectations: number | null;
  @autoserializeAs("project_expectations") projectExpectations: string;
  @autoserializeAs(BudgetRowModel, "budget_plan_json")
  budgetPlanJson: BudgetRowModel[];
  @autoserializeAs("project_budget_sources") projectBudgetSources: string;
  @autoserializeAs(FestExpertEvaluationLeaderModel)
  leader: FestExpertEvaluationLeaderModel;
  @autoserializeAs(FestExpertEvaluationFileModel) presentation: FestExpertEvaluationFileModel | null;

  constructor(
    id: number,
    formId: number,
    name: string,
    description: string,
    country: string,
    city: string,
    genre: string,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    twitterUrl: string | null,
    tiktokUrl: string | null,
    musicLinks: Array<string | null>,
    projectName: string,
    projectDescription: string,
    projectStart: string,
    projectEnd: string,
    projectPoint: string,
    projectTargetAudience: string | null,
    projectOnlineAudienceExpectations: number | null,
    projectOffilneAudienceExpectations: number | null,
    projectMediaCoverageExpectations: number | null,
    calendarPlanText: string | null,
    projectRelevance: string,
    projectPrStrategy: string,
    projectExpectations: string,
    projectBudgetSources: string,
    leader: FestExpertEvaluationLeaderModel,
    photoSite: string,
    budgetPlanJson: BudgetRowModel[],
    presentation: FestExpertEvaluationFileModel | null
  ) {
    this.id = id
    this.formId = formId
    this.name = name
    this.description = description
    this.country = country
    this.city = city
    this.genre = genre
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.youtubeUrl = youtubeUrl
    this.vkontakteUrl = vkontakteUrl
    this.twitterUrl = twitterUrl
    this.tiktokUrl = tiktokUrl
    this.musicLinks = musicLinks
    this.projectName = projectName
    this.projectDescription = projectDescription
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.projectPoint = projectPoint
    this.projectTargetAudience = projectTargetAudience
    this.projectRelevance = projectRelevance
    this.projectPrStrategy = projectPrStrategy
    this.projectExpectations = projectExpectations
    this.projectBudgetSources = projectBudgetSources
    this.projectOnlineAudienceExpectations = projectOnlineAudienceExpectations
    this.projectOffilneAudienceExpectations =
      projectOffilneAudienceExpectations
    this.projectMediaCoverageExpectations = projectMediaCoverageExpectations
    this.calendarPlanText = calendarPlanText
    this.leader = leader
    this.photoSite = photoSite
    this.budgetPlanJson = budgetPlanJson
    this.presentation = presentation
  }
}

export class FestExpertArtistGetResponse {
  @autoserializeAs(FestExpertEvaluationArtistModel)
  artist: FestExpertEvaluationArtistModel;
  @autoserializeAs(EvaluationValuationModel)
  valuation: EvaluationValuationModel;
  @autoserializeAs(AvailableToEvaluateModel, "available_to_evaluate")
  availableToEvaluate: AvailableToEvaluateModel;

  constructor(
    artist: FestExpertEvaluationArtistModel,
    valuation: EvaluationValuationModel,
    availableToEvaluate: AvailableToEvaluateModel
  ) {
    this.artist = artist
    this.valuation = valuation
    this.availableToEvaluate = availableToEvaluate
  }
}
