/* eslint-disable react/require-default-props */
import React from "react"
import classNames from "classnames"
import MaskedInput from "react-text-mask"

import "./InputInTable.style.scss"
import Tooltip from "@material-ui/core/Tooltip/Tooltip"

const block = "input-in-table-component"

interface Props {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value: string | number;
  placeholder?: string;
  error?: string;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  mask:
    | Array<string | RegExp>
    | false
    | ((value: string) => Array<string | RegExp> | false);
  guide?: boolean;
  disabled?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  type?: string;
  isErrorStatic?: boolean;
  pipe?: (
    conformedValue: string,
    config: any
  ) =>
    | string
    | false
    | {
        value: string;
        indexesOfPipedChars: number[];
      };
  min?: number;
  max?: number;
  tooltip?: boolean;
}

const InputInTableComponent = (props: Props): JSX.Element => {
  const {
    onChange,
    name,
    value,
    placeholder,
    error,
    onBlur,
    mask,
    guide,
    disabled,
    onFocus,
    type,
    pipe,
    min,
    max,
    tooltip = false
  } = props

  return (
    <div className={block}>
      <div
        className={classNames(
          `${block}__input-block`,
          disabled && `${block}__input-block--disabled`,
          error && `${block}__input-block--error`
        )}
      >
        {tooltip ? (
          <Tooltip
            title={value}
            placement="top-start"
            arrow
            disableFocusListener
          >
            <MaskedInput
              name={name}
              onChange={onChange}
              onBlur={e => onBlur && onBlur(e as React.FocusEvent<HTMLInputElement>)}
              onFocus={e => onFocus && onFocus(e as React.FocusEvent<HTMLInputElement>)}
              mask={mask}
              guide={guide}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              className={`${block}__input`}
              type={type || "text"}
              pipe={pipe}
              min={min}
              max={max}
            />
          </Tooltip>
        ) : (
          <MaskedInput
            name={name}
            onChange={onChange}
            onBlur={e => onBlur && onBlur(e as React.FocusEvent<HTMLInputElement>)}
            onFocus={e => onFocus && onFocus(e as React.FocusEvent<HTMLInputElement>)}
            mask={mask}
            guide={guide}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            className={`${block}__input`}
            type={type || "text"}
            pipe={pipe}
            min={min}
            max={max}
          />
        )}
      </div>
    </div>
  )
}

export default InputInTableComponent
