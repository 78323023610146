import { observable, action } from "mobx"
import moment from "moment"
import { ArtistFormStatus } from "../../models/ArtistModels/artistForm.model"
import { ArtistFormNotificationsResponse, NotificationsType } from "../../models/ArtistModels/artistNotifications.model"
import { ApiRequestStore } from "../common/apiRequest.store"

class ArtistFormNotificationsStore extends ApiRequestStore {
  @observable notificationsData?: ArtistFormNotificationsResponse
  
  @action
  setNotificationsData(data: ArtistFormNotificationsResponse): void {
    this.notificationsData = data
  }

  @action
  clearData(): void {
    this.notificationsData = undefined
  }

  @action
  addNotificationOnApply = (): void => {
    if (this.notificationsData) {
      this.notificationsData = {
        form: {
          ...this.notificationsData.form,
          status: ArtistFormStatus.MODERATION
        },
        notifications: [
          {
            type: NotificationsType.SentToModeration,
            text: 'Ваша анкета была отправлена на модерацию.',
            createdAt: moment().toString()
          },
          ...this.notificationsData.notifications
        ]
      }
    }
  }
}

export const artistFormNotificationsStore: ArtistFormNotificationsStore = new ArtistFormNotificationsStore()