/* eslint-disable max-len */
import moment from "moment"
import React from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"

import { ReactComponent as Eye } from "../../../../../assets/icons/eye.svg"
import { ReactComponent as Plus } from "../../../../../assets/icons/plus.svg"
import { ReactComponent as Doc } from "../../../../../assets/icons/doc.svg"
import { ReactComponent as Delete } from "../../../../../assets/icons/delete.svg"
import { ReactComponent as PlusWhite } from "../../../../../assets/icons/plus-white.svg"

import InputComponent from "../../../../common/Input/Input.component"
import InputLabelComponent from "../InputLabel/InputLabel.component"
import { Element } from "react-scroll"
import TextareaComponent from "../../../../common/Textarea/Textarea.component"
import {
  isDateValid,
  isEmailValid,
  isValidURL
} from "../../../../../const/validators.const"
import {
  FileUploadResponse,
  FileUploadType
} from "../../../../../models/common/fileUpload.model"
import UploadFileContainer from "../../../../../container/CRM/common/UploadFile.container"
import Button from "../../../../common/Button/Button.component"
import {
  ArtistFormFields,
  MemberModel,
  BudgetRowModel,
  ArtistFormStatus
} from "../../../../../models/ArtistModels/artistForm.model"
import classNames from "classnames"
import { memberSocial, projectInSocial } from "../data.const"

import "./ArtistFormFields.style.scss"
import GenreRadioList from "../../../../common/GenreRadioList/GenreRadioList.component"
import BudgetFormFields from "../BudgetFormFields/BudgetFormFields.component"
import { UserRole } from "../../../../../const/permissionsRules.const"
import { artistFormGetStore } from "../../../../../store/ArtistStore/artistForm.store"
import { useLocation } from "react-router-dom"
import { PATH_ARTIST_REQUEST_READ } from "../../../../../const/routes.const"
import EmbedContent from "../../../../common/EmbedContent/EmbedContent.component"
import { deleteFileWithUnmount } from "../../../../../services/common/uploadFile.service"
import { formates } from '../../../../../const/fileFormates'

interface Props {
  formInitialValues: ArtistFormFields;
  setFormInitialValues: (value: React.SetStateAction<ArtistFormFields | undefined>) => void
  showValidationErrors?: boolean;
  formId?: number;
  isFormReadonly?: boolean;
  budgetModalFormData?: BudgetRowModel[];
  setBudgetModalFormData?: (data: BudgetRowModel[]) => void;
  userRole?: UserRole;
  isVisibleHints?: boolean;
  metaRef?: React.MutableRefObject<null | HTMLParagraphElement>
}

const block = "artist-form-fields"

const ArtistFormInputFields = (props: Props): JSX.Element => {
  const { watch, getValues, control, setValue, trigger, errors } =
    useFormContext()
  const location = useLocation()

  const {
    formInitialValues,
    showValidationErrors,
    formId,
    isFormReadonly = true,
    setBudgetModalFormData,
    setFormInitialValues,
    budgetModalFormData,
    userRole,
    metaRef,
    isVisibleHints = false
  } = props

  const [focusedInput, setFocusedInput] = React.useState("")

  const [isOpenModal, setIsOpenModal] = React.useState(false)

  const handleClickOpenModal = () => {
    setIsOpenModal(true)
  }
  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "members"
  })
  const musicLinksFieldArray = useFieldArray({
    control,
    name: "musicLinks"
  })
  const photoSocialField = watch("photoSocial")
  const playlistCodeWatch = watch("playlistCode")

  const dateStartField = watch("projectStart")
  const dateEndField = watch("projectEnd")  

  const checkIsFormReadonly = (): boolean => {
    if (location.pathname === PATH_ARTIST_REQUEST_READ) {
      return true
    }
    return !!isFormReadonly
  }

  const isProjectInSocialFilled = (): boolean => {
    const values = getValues()
    const {
      facebookUrl,
      vkontakteUrl,
      instagramUrl,
      tiktokUrl,
      youtubeUrl,
      twitterUrl
    } = values

    return (
      !!facebookUrl ||
      !!vkontakteUrl ||
      !!instagramUrl ||
      !!tiktokUrl ||
      !!youtubeUrl ||
      !!twitterUrl
    )
  }

  const isStartDateBeforeEnd = (date: string): boolean =>
    moment(date, "DD.MM.YYYY").isBefore(moment(dateEndField, "DD.MM.YYYY"))
  const isStartDateAftereEnd = (date: string): boolean =>
    moment(date, "DD.MM.YYYY").isAfter(moment(dateStartField, "DD.MM.YYYY"))

  const isStartDateValid = (date: string): boolean | string => {
    const dateToCompareStart = moment("14.02.2024", "DD.MM.YYYY")
    const dateToCompareEnd = moment("16.06.2024", "DD.MM.YYYY")


    if (moment(date, "DD.MM.YYYY", true).isValid()) {
      if (
        moment(date, "DD.MM.YYYY").isBetween(
          dateToCompareStart,
          dateToCompareEnd
        )
      ) {
        return isStartDateBeforeEnd(date)
          ? true
          : "Дата начала не может быть позже даты окончания или совпадать с ней"
      } else {
        if (moment(date, "DD.MM.YYYY").isSameOrAfter(dateToCompareEnd)) {
          return "Дата начала не позднее 15.06.2024"
        } else {
          return "Дата начала не ранее 15.02.2024"
        }
      }
    } else {
      return "Введите корректную дату"
    }
  }

  const isEndDateValid = (date: string): boolean | string => {
    const dateToCompareStart = moment("14.02.2024", "DD.MM.YYYY")
    const dateToCompareEnd = moment("16.06.2024", "DD.MM.YYYY")

    if (moment(date, "DD.MM.YYYY", true).isValid()) {
      if (
        moment(date, "DD.MM.YYYY").isBetween(
          dateToCompareStart,
          dateToCompareEnd
        )
      ) {
        return isStartDateAftereEnd(date)
          ? true
          : "Дата окончания не может быть раньше даты начала или совпадать с ней"
      } else {
        if (moment(date, "DD.MM.YYYY").isSameOrAfter(dateToCompareEnd)) {
          return "Дата окончания не позднее 15.06.2024"
        } else {
          return "Дата окончания не ранее 15.02.2024"
        }
      }
    } else {
      return "Введите корректную дату"
    }
  }

  const isTShirtSizeValid = (value: string): boolean | string => {
    const sizes = ["xs", "s", "m", "l", "xl"]

    if (sizes.includes(value.toLowerCase())) {
      return true
    } else {
      return "Введите корректный размер (XS / S / M / L / XL)"
    }
  }

  const isMemberSocialFilled = (index: number): boolean => {
    const values = getValues()
    const {
      facebookUrl,
      vkontakteUrl,
      instagramUrl,
      tiktokUrl,
      youtubeUrl,
      twitterUrl
    } = values.members[index]

    return (
      !!facebookUrl ||
      !!vkontakteUrl ||
      !!instagramUrl ||
      !!tiktokUrl ||
      !!youtubeUrl ||
      !!twitterUrl
    )
  }

  const scrollToMetaNote = () => {
    if (metaRef) {
      metaRef.current?.scrollIntoView({ behavior: "smooth", block: 'center' })
    }
  }
  const checkValueByLength = (name: string, length: number) => {
    const value = getValues(`${name}`)

    if (value.length < length - 1) {
      setValue(name, value)
    } else {
      setValue(name, value.substring(0, value.length - 1))
    }
  }

  const renderMembersAgreementsInputs = (): JSX.Element[] => {
    if (formInitialValues.membersAgreements.length <= fields.length - 1) {
      return fields
        .slice(0, fields.length - 1)
        .map((item: any, index: number) => (
          <div className="form__input" key={`member-agreement-${item.id}`}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name={`membersAgreements[${index}]`}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <UploadFileContainer
                    artistId={formId}
                    nameForStore={name}
                    fileTypes={[formates.pdf]}
                    uploadedFile={value || undefined}
                    onFileUpload={(fileData): void => onChange(fileData)}
                    onFileDelete={(): void => {
                      setValue(name, null)
                    }}
                    showFileName={false}
                    disabled={checkIsFormReadonly()}
                    type={FileUploadType.DOCUMENT}
                    fileType={"member_agreement"}
                  />

                  {index === 0 && (
                    <div className="form__input-label">
                      <InputLabelComponent>
                        Каждый участник коллектива должен скачать документ по{" "}
                        <span>
                          <a
                            href="https://docs.google.com/document/d/1nfKigGkINtC7gch3nu3b-hV_Q7fXYUuV/edit?usp=sharing&ouid=105179013765953865759&rtpof=true&sd=true"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ссылке
                          </a>
                        </span>
                        , поставить подпись и загрузить в анкету.
                      </InputLabelComponent>
                    </div>
                  )}
                </Element>
              )}
            />
          </div>
        ))
    } else {
      return formInitialValues.membersAgreements.map((item, index) => (
        <div className="form__input" key={`member-agreement-${item.id}`}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Это обязательное поле"
              }
            }}
            control={control}
            name={`membersAgreements[${index}]`}
            render={({ onChange, value, name }): JSX.Element => (
              <Element name={name}>
                <UploadFileContainer
                  artistId={formId}
                  nameForStore={name}
                  fileTypes={[formates.pdf]}
                  uploadedFile={value || undefined}
                  onFileUpload={(fileData): void => onChange(fileData)}
                  onFileDelete={(): void => {
                    setValue(name, null)
                  }}
                  showFileName={false}
                  disabled={checkIsFormReadonly()}
                  type={FileUploadType.DOCUMENT}
                  fileType={"member_agreement"}
                />

                {index === 0 && (
                  <div className="form__input-label">
                    <InputLabelComponent>
                      Каждый участник коллектива должен скачать документ по{" "}
                      <span>
                        <a
                          href="https://storage.yandexcloud.net/test.newopen/agreement.docx"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ссылке
                        </a>
                      </span>
                      , заполнить, распечатать, поставить подпись, отсканировать
                      и загрузить в анкету в формате pdf.
                    </InputLabelComponent>
                  </div>
                )}
              </Element>
            )}
          />
        </div>
      ))
    }
  }

  const renderMembersAgreementsBlock = (): JSX.Element | void => (
    <div className="form__input-block">
      <div
        className={classNames(
          "form__input-title",
          showValidationErrors &&
          errors.membersAgreements?.length &&
          "form__input-title--error"
        )}
      >
        * Согласия всех участников музыкального коллектива об участии в конкурсе
      </div>
      <div className="row--space-between row--wrap">
        {renderMembersAgreementsInputs()}
      </div>
    </div>
  )

  return (
    <>
      <div className="form__section">
        <h2 className="form__title">К сожалению, в этом году прием новых заявок завершился, но вы можете редактировать анкеты вернувшиеся с модерации </h2>
        <div className="form__title">1. ОСНОВНАЯ ИНФОРМАЦИЯ</div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Название коллектива/имя музыканта (для сольных проектов)
            <div className="form__input-title-icon">
              <Eye fill="#000" />
            </div>
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues?.name}
              control={control}
              name="name"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите текст"}
                    error={errors.name?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Название пишется без кавычек
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Музыкальный жанр
            <div className="form__input-title-icon">
              <Eye fill="#000" />
            </div>
          </div>
          <div className="form__input">
            <div className="form__input-row">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  }
                }}
                defaultValue={formInitialValues.genre}
                control={control}
                name="genre"
                render={({ onChange, value, name }): JSX.Element => (
                  <GenreRadioList
                    name={name}
                    onChange={onChange}
                    value={value}
                    error={errors.genre?.message}
                    disabled={checkIsFormReadonly()}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="form__input-row">
          <div className="form__input-block">
            <div className="form__input-title">* Страна</div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  }
                }}
                defaultValue={formInitialValues.country}
                control={control}
                name="country"
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={"test"}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={false}
                      placeholder={"Введите текст"}
                      error={errors.country?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={checkIsFormReadonly()}
                    />
                  </Element>
                )}
              />
            </div>
          </div>
          <div className="form__input-block">
            <div className="form__input-title">
              * Город
              <div className="form__input-title-icon">
                <Eye fill="#000" />
              </div>
            </div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  }
                }}
                defaultValue={formInitialValues.city}
                control={control}
                name="city"
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={false}
                      placeholder={"Введите текст"}
                      error={errors.city?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={checkIsFormReadonly()}
                    />
                  </Element>
                )}
              />
            </div>
          </div>
        </div>
        <div className="form__input-row">
          <div className="form__input-block">
            <div className="form__input-title">* Email</div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  }
                }}
                defaultValue={formInitialValues.contactEmail}
                control={control}
                name="contactEmail"
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={false}
                      placeholder={"Введите текст"}
                      error={errors.contactEmail?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={true}
                    />
                  </Element>
                )}
              />
            </div>
          </div>
          <div className="form__input-block">
            <div className="form__input-title">* Телефон</div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  }
                }}
                defaultValue={formInitialValues.contactPhone}
                control={control}
                name="contactPhone"
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={[
                        "+",
                        "7",
                        " ",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/
                      ]}
                      placeholder={"Введите телефон"}
                      error={errors.contactPhone?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={checkIsFormReadonly()}
                    />
                  </Element>
                )}
              />
            </div>
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">* Контактное лицо</div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name="contactName"
              defaultValue={formInitialValues.contactName}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"ФИО полностью"}
                    error={errors.contactName?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Описание музыкального коллектива
            <div className="form__input-title-icon">
              <Eye fill="#000" />
            </div>
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 3000,
                  message: "Не более 3000 символов"
                }
              }}
              defaultValue={formInitialValues.description}
              control={control}
              name="description"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 3000)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.description?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 3000 символов
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Музыкальный проект в социальных сетях
            <div className="form__input-title-icon">
              <Eye fill="#000" />
            </div>
          </div>
          <div className="form__input-row">
            {projectInSocial.map((item, index) => (
              <div className="form__input" key={item.name}>
                <Controller
                  rules={{
                    validate: {
                      projectInSocialFilled: (): boolean | string =>
                        isProjectInSocialFilled() ||
                        "Одно из этих полей должно быть заполнено",
                      validUrl: (value): boolean | string =>
                        !!value?.length
                          ? isValidURL(value.trim()) || "Введите корректный URL"
                          : true
                    }
                  }}
                  defaultValue={formInitialValues[item.name]}
                  control={control}
                  name={item.name}
                  render={({ onChange, value, name }): JSX.Element => (
                    <Element name={name}>
                      <InputComponent
                        onChange={(event): void => {
                          onChange(event)
                          trigger([
                            "facebookUrl",
                            "instagramUrl",
                            "vkontakteUrl",
                            "tiktokUrl",
                            "youtubeUrl",
                            "twitterUrl"
                          ])
                        }}
                        value={value}
                        name={name}
                        mask={false}
                        placeholder={item.placeholder}
                        error={errors && errors[item.name]?.message}
                        onBlur={(): void => setFocusedInput("")}
                        onFocus={(): void => setFocusedInput(name)}
                        icon={item.icon}
                        isErrorStatic={true}
                        disabled={checkIsFormReadonly()}
                      />
                      {(item.name === 'facebookUrl' || item.name === 'instagramUrl') && <button className="meta-note" onClick={scrollToMetaNote}>**</button>}
                      {index === 1 && (
                        <div className="form__input-label">
                          <InputLabelComponent
                            isFocused={
                              focusedInput === "facebookUrl" ||
                              focusedInput === "instagramUrl" ||
                              focusedInput === "vkontakteUrl" ||
                              focusedInput === "tiktokUrl" ||
                              focusedInput === "youtubeUrl" ||
                              focusedInput === "twitterUrl"
                            }
                          >
                            Обязательно нужно указать как минимум одну
                            социальную сеть.
                            <br />
                            <span>Пример:</span>{" "}
                            https://vk.com/user_name/
                          </InputLabelComponent>
                        </div>
                      )}
                    </Element>
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Прикрепите ссылки на конкретные треки, которые услышат эксперты
          </div>
          {musicLinksFieldArray.fields.map((item, index) => (
            <div className="form__input" key={item.id}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  },
                  validate: {
                    validUrl: (value): boolean | string =>
                      !!value?.length
                        ? isValidURL(value.trim()) || "Введите корректный URL"
                        : true
                  }
                }}
                control={control}
                defaultValue={formInitialValues.musicLinks[index]}
                name={`musicLinks.${index}`}
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={(event): void => {
                        onChange(event)
                      }}
                      value={value}
                      name={name}
                      mask={false}
                      placeholder={"Ссылка"}
                      error={
                        errors?.musicLinks &&
                        errors?.musicLinks[index] &&
                        errors?.musicLinks[index].message
                      }
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      isErrorStatic={true}
                      disabled={checkIsFormReadonly()}
                    />
                    {index === 0 && (
                      <div className="form__input-label">
                        <InputLabelComponent
                          isFocused={
                            focusedInput === "musicLinks.0" ||
                            focusedInput === "musicLinks.1" ||
                            focusedInput === "musicLinks.2"
                          }
                        >
                          Для прослушивания будет удобно, если вы прикрепите
                          ссылки на стриминговые сервисы. Но прикрепление ссылки
                          на облачный диск тоже допустимо
                        </InputLabelComponent>
                      </div>
                    )}
                  </Element>
                )}
              />
            </div>
          ))}
        </div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Код плейлиста с вашей музыкой
            <div className="form__input-title-icon">
              <Eye fill="#000" />
            </div>
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.playlistCode}
              control={control}
              name="playlistCode"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Введите код"}
                    error={errors.playlistCode?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Вставьте код на плейлист из ВКонтакте или Яндекс.Музыки.
                      Как это правильно сделать смотрите в{" "}
                      <span>
                        <a
                          href="https://drive.google.com/file/d/10qc9WrNnuFX3Cvb081DPkdaKGE0Zkwt9/view"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          инструкции
                        </a>
                      </span>
                      .
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        {<EmbedContent content={playlistCodeWatch} />}

        <div className="form__input-block">
          <div className="form__input-title">
            Вы можете выиграть специальный приз фестиваля от наших партнеров — Клипов ВКонтакте.
            Выложите вертикальный клип с хэштегом #newopen2024 и
            прикрепите ссылку на видео в этом разделе заявки,
            чтобы принять участие в конкурсе.
          </div>
          <div className="form__input">
            <Controller
              defaultValue={formInitialValues.vkClipsUrl}
              control={control}
              name="vkClipsUrl"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Введите ссылку"}
                    error={errors.vkClipsUrl?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                    mask={false}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Вы можете выиграть специальный приз фестиваля от наших
                      партнеров —{" "}
                      <span>
                        <a
                          href="https://vk.com/vkclips"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Клипов ВКонтакте
                        </a>
                      </span>
                      . Выложите вертикальный клип с хэштегом #НовыйТалант и
                      прикрепите ссылку на видео в этом разделе заявки, чтобы
                      принять участие в конкурсе.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
      </div>

      <div className="form__section">
        <div className="form__title">2. ИНФОРМАЦИЯ О ПРОЕКТЕ</div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Название проекта, на реализацию которого запрашивается грант
            <div className="form__input-title-icon">
              <Eye fill="#000" />
            </div>
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name={"projectName"}
              defaultValue={formInitialValues.projectName}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите текст"}
                    error={errors.projectName?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      <span>Пример: </span>“Съемки клипа на песню “Север”
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Краткое описание проекта
            <div className="form__input-title-icon">
              <Eye fill="#000" />
            </div>
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 5000,
                  message: "Не более 5000 символов"
                }
              }}
              defaultValue={formInitialValues.projectDescription}
              control={control}
              name="projectDescription"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 5000)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.projectDescription?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 5000 символов.
                      <br />
                      Напишите идею проекта, в чем заключается его уникальность.
                      Укажите все детали, которые могут быть важны и интересны,
                      чтобы эксперт мог понять суть планируемого проекта и его
                      проработанность.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Актуальность проекта для музыкального коллектива
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 500,
                  message: "Не более 500 символов"
                }
              }}
              defaultValue={formInitialValues.projectRelevance}
              control={control}
              name="projectRelevance"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 500)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Описание"}
                    error={errors.projectRelevance?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 500 символов.
                      <br />
                      Объясните почему для вашей группы важно реализовать именно
                      такой проект в данный момент времени.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">* Цель проекта</div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 300,
                  message: "Не более 300 символов"
                }
              }}
              defaultValue={formInitialValues.projectPoint}
              control={control}
              name="projectPoint"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 300)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Описание"}
                    error={errors.projectPoint?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 300 символов.
                      <br />
                      Укажите результат, которого вы хотите достигнуть при
                      реализации проекта.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">* Целевая аудитория проекта</div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 500,
                  message: "Не более 500 символов"
                }
              }}
              defaultValue={formInitialValues.projectTargetAudience}
              control={control}
              name="projectTargetAudience"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 500)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Описание"}
                    error={errors.projectTargetAudience?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 500 символов.
                      <br />
                      Опишите половозрастные характеристики вашей аудитории,
                      географию и основные ее особенности.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Каналы коммуникации с целевыми группами проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.projectPrStrategy}
              control={control}
              name="projectPrStrategy"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Описание"}
                    error={errors.projectPrStrategy?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Опишите, о каких событиях, каким образом и на каких
                      ресурсах вы будете рассказывать о работе музыкальной
                      группы в рамках реализации вашего проекта. Укажите какую
                      рекламу вы будете использовать и в каком объеме.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-row">
          <div className="form__input-block">
            <div className="form__input-title">
              * Дата начала реализации проекта
            </div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  },
                  validate: {
                    validDate: (value): boolean | string =>
                      isStartDateValid(value)
                  }
                }}
                control={control}
                name={"projectStart"}
                defaultValue={formInitialValues.projectStart}
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={[
                        /[0-3]/,
                        /[0-9]/,
                        ".",
                        /[0-1]/,
                        /[0-9]/,
                        ".",
                        /[1-2]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                      placeholder={"__.__.____"}
                      error={errors.projectStart?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={checkIsFormReadonly()}
                    />
                  </Element>
                )}
              />
            </div>
          </div>
          <div className="form__input-block">
            <div className="form__input-title">* Дата окончания реализации</div>
            <div className="form__input">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Это обязательное поле"
                  },
                  validate: {
                    validDate: (value): boolean | string =>
                      isEndDateValid(value)
                  }
                }}
                control={control}
                name={"projectEnd"}
                defaultValue={formInitialValues.projectEnd}
                render={({ onChange, value, name }): JSX.Element => (
                  <Element name={name}>
                    <InputComponent
                      onChange={onChange}
                      value={value}
                      name={name}
                      mask={[
                        /[0-3]/,
                        /[0-9]/,
                        ".",
                        /[0-1]/,
                        /[0-9]/,
                        ".",
                        /[1-2]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/
                      ]}
                      placeholder={"__.__.____"}
                      error={errors.projectEnd?.message}
                      onBlur={(): void => setFocusedInput("")}
                      onFocus={(): void => setFocusedInput(name)}
                      disabled={checkIsFormReadonly()}
                    />
                    <div className="form__input-label">
                      <InputLabelComponent
                        isFocused={
                          focusedInput === name ||
                          focusedInput === "projectStart"
                        }
                      >
                        Дата начала не ранее 15.02.2024;
                        <br />
                        Дата окончания не поздее 15.06.2024.
                      </InputLabelComponent>
                    </div>
                  </Element>
                )}
              />
            </div>
          </div>
        </div>

        <div className="form__input-block">
          <div
            className={classNames(
              "form__input-title",
              showValidationErrors &&
              errors.calendarPlanText &&
              "form__input-title--error"
            )}
          >
            * Этапы реализации проекта с указанием сроков
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                maxLength: {
                  value: 3000,
                  message: "Не более 3000 символов"
                }
              }}
              control={control}
              name={"calendarPlanText"}
              defaultValue={formInitialValues.calendarPlanText}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={(value) => {
                      onChange(value)
                      checkValueByLength(name, 3000)
                    }}
                    value={value}
                    name={name}
                    placeholder={"Введите текст"}
                    error={errors.calendarPlanText?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Не более 3000 символов.
                      <br />
                      Расскажите о всех этапах реализации своего проекта,
                      охарактеризуйте работу в рамках каждого из них и укажите
                      планируемые результаты.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-title" style={{ marginTop: "50px" }}>
          Ожидаемые количественные результаты
        </div>
        <div
          className="form__input-block"
          style={{
            marginBottom: isVisibleHints ? "210px" : "0px"
          }}
        >
          <div className="form__input-title">
            * Количество участников мероприятий проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={
                formInitialValues?.projectOffilneAudienceExpectations
              }
              control={control}
              name="projectOffilneAudienceExpectations"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите количество"}
                    error={errors.projectOffilneAudienceExpectations?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                    type="number"
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Количество участников, привлеченных непосредственно к
                      реализации проекта (созданного продукта), количество
                      членов команды, количество привлеченных специалистов,
                      количество зрителей концертов/лекций/других мероприятий
                      (оффлайн и онлайн), участников пресс-конференций и т.д.
                      (указанные цифры необходимо будет при сдаче отчетности
                      подтвердить списками, фотографиями и скриншотами).
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div
          className="form__input-block"
          style={{ marginBottom: isVisibleHints ? "120px" : "0px" }}
        >
          <div className="form__input-title">
            * Охват аудитории творческим продуктом проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={
                formInitialValues?.projectOnlineAudienceExpectations
              }
              control={control}
              name="projectOnlineAudienceExpectations"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите количество"}
                    error={errors.projectOnlineAudienceExpectations?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                    type="number"
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Количество прослушиваний треков, просмотров клипов и иных
                      творческих продуктов, созданных в рамках и в сроки проекта
                      и т.д. (указанные цифры необходимо будет подтвердить
                      скриншотами и/или данным статистики стриминговых
                      платформ).
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div
          className="form__input-block"
          style={{ marginBottom: isVisibleHints ? "160px" : "0px" }}
        >
          <div className="form__input-title">* Медиа-охват проекта</div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues?.projectMediaCoverageExpectations}
              control={control}
              name="projectMediaCoverageExpectations"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Введите количество"}
                    error={errors.projectMediaCoverageExpectations?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                    type="number"
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Количество просмотров постов в соц.сетях и публикаций в
                      СМИ, охват аудитории таргетированной рекламой, количество
                      новых подписчиков, присоединившихся в рамках и в сроки
                      проекта и т.д. (указанные цифры необходимо будет
                      подтвердить при сдаче отчетности скриншотами и/или данными
                      статистики по распространению таргетированной рекламы).
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div className="form__input-title">
            * Ожидаемые качественные результаты проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.projectExpectations}
              control={control}
              name="projectExpectations"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Описание"}
                    error={errors.projectExpectations?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      <span>Пример: </span>“Повышение качества звукозаписи за
                      счет приобретения оборудования;
                      <br />
                      Получение приглашений для выступления на фестивале Ural
                      Music Night”.
                      <br />
                      <span>Пример: </span>“Расширение географии аудитории
                      поклонников (г.Тюмень, г.Челябинск, г.Курган)“.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        {budgetModalFormData && (
          <div className="form__input-block">
            <div
              className={classNames(
                "form__input-title",
                showValidationErrors &&
                errors.budgetPlanJson &&
                "form__input-title--error"
              )}
            >
              * Бюджет проекта
            </div>

            <div className="form__input">
              <div
                className={classNames(
                  `${block}__popup${checkIsFormReadonly() ? "--disabled" : ""}`,
                  budgetModalFormData?.length >= 1
                    ? `${block}__popup--document`
                    : ""
                )}
                onClick={handleClickOpenModal}
              >
                {budgetModalFormData?.length >= 1 ? (
                  <div className={`${block}__file`}>
                    <Doc
                      fill="#000"
                      width="15"
                      height="15"
                      name={"budgetPlanJson"}
                    />
                    <div className={`${block}__title`}>
                      Смета проекта (<span>нажмите чтобы изменить</span>)
                    </div>
                  </div>
                ) : (
                  <div className={classNames(`${block}__control`)}>
                    <PlusWhite fill="#000" name={"budgetPlanJson"} />
                  </div>
                )}
              </div>
              <div className="form__input-label">
                <InputLabelComponent>
                  Максимальная сумма запрашиваемого гранта — 300 000 рублей
                </InputLabelComponent>
              </div>
            </div>
            {showValidationErrors && errors.budgetPlanJson && (
              <p className={classNames(`input-component__error`)} style={{marginBottom: '10px'}}>
                {errors.budgetPlanJson.message}
              </p>
            )}
          </div>
        )}

        <Controller
          name="budgetPlanJson"
          control={control}
          defaultValue={null}
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            }
          }} render={({ onChange, name }) => (
            <BudgetFormFields
              key={name}
              formInitialValues={formInitialValues.budgetPlanJson}
              isFormReadonly={checkIsFormReadonly()}
              onCloseModal={onCloseModal}
              isOpenModal={isOpenModal}
              setBudgetModalFormData={(data: BudgetRowModel[]) => {                
                onChange(data)            
                setBudgetModalFormData && setBudgetModalFormData(data)
              }}
            />
          )} />

        <div className="form__input-block">
          <div className="form__input-title">* Источники софинансирования</div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              defaultValue={formInitialValues.projectBudgetSources}
              control={control}
              name="projectBudgetSources"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Описание"}
                    error={errors.projectBudgetSources?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      <span>Пример: </span>“Партнером группы является фотостудия
                      Zoom. Компания предлагает льготные цены на аренду
                      оборудования для нашей группы”.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
      </div>

      <div className="form__section">
        <div className="form__title">3. СОСТАВ КОЛЛЕКТИВА</div>

        {!!fields.length &&
          fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <div className={`${block}__member-title`}>
                {field.isLeader ? (
                  <>
                    <div className="form__subtitle">Руководитель проекта:</div>
                  </>
                ) : (
                  <>
                    <div className="form__subtitle">Участник проекта:</div>
                    {!checkIsFormReadonly() && (
                      <div
                        className={`${block}__remove`}
                        onClick={(): void => {
                          const membersAgreement: File = getValues(
                            `membersAgreements[${index - 1}]`
                          )
                          if (membersAgreement) {

                            const membersAgreementId = getValues(
                              `membersAgreements[${index - 1}]`
                            ).id
                            const membersAgreementType = FileUploadType.DOCUMENT
                            const artistId = formId
                            const role = localStorage.getItem(
                              "userRole"
                            ) as UserRole



                            const fixedFormData = JSON.parse(JSON.stringify(artistFormGetStore.formData))
                            if (fixedFormData && fixedFormData.form) {
                              const fixedFormDataAgreements = [...fixedFormData.form.membersAgreements]
                              fixedFormDataAgreements.splice(index - 1, 1)
                              fixedFormData.form.membersAgreements = fixedFormDataAgreements
                              setFormInitialValues(fixedFormData.form)
                            }

                            deleteFileWithUnmount(
                              membersAgreementId,
                              membersAgreementType,
                              role,
                              artistId
                            ).then(() => {
                              setValue(`membersAgreements[${index - 1}]`, null)
                              remove(index)
                            })
                          } else {
                            setValue(`membersAgreements[${index - 1}]`, null)
                            remove(index)
                          }
                        }}
                      >
                        <Delete />
                      </div>
                    )}
                  </>
                )}
              </div>

              <Controller
                control={control}
                name={`members[${index}].isLeader`}
                render={(): JSX.Element => <div />}
              />

              <div className="form__input-row">
                <div className="form__input-block">
                  <div className="form__input-title">* ФИО</div>
                  <div className="form__input">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Это обязательное поле"
                        }
                      }}
                      control={control}
                      defaultValue={formInitialValues.members[index]?.name}
                      name={`members[${index}].name`}
                      render={({ onChange, value, name }): JSX.Element => (
                        <Element name={name}>
                          <InputComponent
                            onChange={onChange}
                            value={value}
                            name={name}
                            mask={false}
                            placeholder={"ФИО полностью"}
                            error={
                              errors.members &&
                              errors.members[index]?.name?.message
                            }
                            onBlur={(): void => setFocusedInput("")}
                            onFocus={(): void => setFocusedInput(name)}
                            disabled={checkIsFormReadonly()}
                          />
                        </Element>
                      )}
                    />
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__input-title">* Дата рождения</div>
                  <div className="form__input">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Это обязательное поле"
                        },
                        validate: {
                          validDate: (value): boolean | string =>
                            isDateValid(value) || "Введите корректную дату"
                        }
                      }}
                      control={control}
                      name={`members[${index}].birthday`}
                      defaultValue={formInitialValues.members[index]?.birthday}
                      render={({ onChange, value, name }): JSX.Element => (
                        <Element name={name}>
                          <InputComponent
                            onChange={onChange}
                            value={value}
                            name={name}
                            mask={[
                              /[0-3]/,
                              /[0-9]/,
                              ".",
                              /[0-1]/,
                              /[0-9]/,
                              ".",
                              /[1-2]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/
                            ]}
                            placeholder={"__.__.____"}
                            error={
                              errors.members &&
                              errors.members[index]?.birthday?.message
                            }
                            onBlur={(): void => setFocusedInput("")}
                            onFocus={(): void => setFocusedInput(name)}
                            disabled={checkIsFormReadonly()}
                          />
                        </Element>
                      )}
                    />
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__input-title">* Email</div>
                  <div className="form__input">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Это обязательное поле"
                        },
                        validate: {
                          validEmail: (value): boolean | string =>
                            isEmailValid(value) || "Введите корректный Email"
                        }
                      }}
                      control={control}
                      defaultValue={formInitialValues.members[index]?.email}
                      name={`members[${index}].email`}
                      render={({ onChange, value, name }): JSX.Element => (
                        <Element name={name}>
                          <InputComponent
                            onChange={onChange}
                            value={value}
                            name={name}
                            mask={false}
                            placeholder={"Введите текст"}
                            error={
                              errors.members &&
                              errors.members[index]?.email?.message
                            }
                            onBlur={(): void => setFocusedInput("")}
                            onFocus={(): void => setFocusedInput(name)}
                            disabled={checkIsFormReadonly()}
                          />
                        </Element>
                      )}
                    />
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__input-title">* Телефон</div>
                  <div className="form__input">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Это обязательное поле"
                        }
                      }}
                      defaultValue={formInitialValues.members[index]?.phone}
                      control={control}
                      name={`members[${index}].phone`}
                      render={({ onChange, value, name }): JSX.Element => (
                        <Element name={name}>
                          <InputComponent
                            onChange={onChange}
                            value={value}
                            name={name}
                            mask={[
                              "+",
                              "7",
                              " ",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              " ",
                              /\d/,
                              /\d/,
                              " ",
                              /\d/,
                              /\d/
                            ]}
                            placeholder={"Введите телефон"}
                            error={
                              errors.members &&
                              errors.members[index]?.phone?.message
                            }
                            onBlur={(): void => setFocusedInput("")}
                            onFocus={(): void => setFocusedInput(name)}
                            disabled={checkIsFormReadonly()}
                          />
                        </Element>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__input-title">* Роль в группе</div>
                <div className="form__input">
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Это обязательное поле"
                      }
                    }}
                    control={control}
                    name={`members[${index}].role`}
                    defaultValue={formInitialValues.members[index]?.role}
                    render={({ onChange, value, name }): JSX.Element => (
                      <Element name={name}>
                        <InputComponent
                          onChange={onChange}
                          value={value}
                          name={name}
                          mask={false}
                          placeholder={"Введите текст"}
                          error={
                            errors.members &&
                            errors.members[index]?.role?.message
                          }
                          onBlur={(): void => setFocusedInput("")}
                          onFocus={(): void => setFocusedInput(name)}
                          disabled={checkIsFormReadonly()}
                        />
                        <div className="form__input-label">
                          <InputLabelComponent
                            isFocused={focusedInput === name}
                          >
                            <span>Пример: </span>“Гитарист”.
                          </InputLabelComponent>
                        </div>
                      </Element>
                    )}
                  />
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__input-title">* Размер футболки</div>
                <div className="form__input">
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Это обязательное поле"
                      },
                      validate: {
                        valid: (value): string | boolean =>
                          isTShirtSizeValid(value)
                      }
                    }}
                    control={control}
                    defaultValue={formInitialValues.members[index]?.tShirtSize}
                    name={`members[${index}].tShirtSize`}
                    render={({ onChange, value, name }): JSX.Element => (
                      <Element name={name}>
                        <InputComponent
                          onChange={onChange}
                          value={value}
                          name={name}
                          mask={false}
                          placeholder={"XS / S / M / L / XL"}
                          error={
                            errors.members &&
                            errors.members[index]?.tShirtSize?.message
                          }
                          onBlur={(): void => setFocusedInput("")}
                          onFocus={(): void => setFocusedInput(name)}
                          disabled={checkIsFormReadonly()}
                        />
                      </Element>
                    )}
                  />
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__input-title">
                  * Опыт музыкальной деятельности
                </div>
                <div className="form__input">
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Это обязательное поле"
                      }
                    }}
                    control={control}
                    name={`members[${index}].musicExperience`}
                    defaultValue={
                      formInitialValues.members[index]?.musicExperience
                    }
                    render={({ onChange, value, name }): JSX.Element => (
                      <Element name={name}>
                        <TextareaComponent
                          onChange={onChange}
                          value={value}
                          name={name}
                          placeholder={"Описание"}
                          error={
                            errors.members &&
                            errors.members[index]?.musicExperience?.message
                          }
                          onBlur={(): void => setFocusedInput("")}
                          onFocus={(): void => setFocusedInput(name)}
                          disabled={checkIsFormReadonly()}
                        />
                      </Element>
                    )}
                  />
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__input-title">* Социальные сети</div>
                <div className="form__input-row">
                  {memberSocial.map((item, socialIndex) => (
                    <div
                      className="form__input"
                      key={`members[${index}].${item.name}`}
                    >
                      <Controller
                        rules={{
                          validate: {
                            memberSocialFilled: (): boolean | string =>
                              isMemberSocialFilled(index) ||
                              "Одно из этих полей должно быть заполнено",
                            validUrl: (value): boolean | string =>
                              !!value?.length
                                ? isValidURL(value.trim()) ||
                                "Введите корректный URL"
                                : true
                          }
                        }}
                        control={control}
                        defaultValue={
                          formInitialValues.members[index] &&
                          formInitialValues.members[index][item.name]
                        }
                        name={`members[${index}].${item.name}`}
                        render={({ onChange, value, name }): JSX.Element => (
                          <Element name={name}>
                            <InputComponent
                              onChange={(event): void => {
                                onChange(event)
                                trigger([
                                  `members[${index}].facebookUrl`,
                                  `members[${index}].instagramUrl`,
                                  `members[${index}].vkontakteUrl`,
                                  `members[${index}].tiktokUrl`,
                                  `members[${index}].youtubeUrl`,
                                  `members[${index}].twitterUrl`
                                ])
                              }}
                              value={value}
                              name={name}
                              mask={false}
                              placeholder={item.placeholder}
                              error={
                                errors.members &&
                                errors.members[index]?.[item.name]?.message
                              }
                              onBlur={(): void => setFocusedInput("")}
                              onFocus={(): void => setFocusedInput(name)}
                              icon={item.icon}
                              isErrorStatic={true}
                              disabled={checkIsFormReadonly()}
                            />
                            {(item.name === 'facebookUrl' || item.name === 'instagramUrl') && <button className="meta-note" onClick={scrollToMetaNote}>**</button>}
                            {socialIndex === 1 && (
                              <div className="form__input-label">
                                <InputLabelComponent
                                  isFocused={
                                    focusedInput ===
                                    `members[${index}].facebookUrl` ||
                                    focusedInput ===
                                    `members[${index}].instagramUrl` ||
                                    focusedInput ===
                                    `members[${index}].vkontakteUrl` ||
                                    focusedInput ===
                                    `members[${index}].tiktokUrl` ||
                                    focusedInput ===
                                    `members[${index}].youtubeUrl` ||
                                    focusedInput ===
                                    `members[${index}].twitterUrl`
                                  }
                                >
                                  Обязательно нужно указать как минимум одну
                                  социальную сеть.
                                  <br />
                                  <span>Пример:</span>{" "}
                                  https://vk.com/user_name/
                                </InputLabelComponent>
                              </div>
                            )}
                          </Element>
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          ))}

        {!checkIsFormReadonly() && (
          <div className="form__input-row">
            <div className="form__input-block">
              <Button
                icon={<Plus fill="#ffffff" />}
                text={"Добавить участника"}
                onClick={(): void =>
                  append(
                    new MemberModel(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      false,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )
                  )
                }
                background="turquoise"
              />
            </div>
          </div>
        )}

        <div className="form__input-block">
          <div className="form__input-title">Приглашенные специалисты</div>
          <div className="form__input">
            <Controller
              defaultValue={formInitialValues?.invitedSpecialists}
              control={control}
              name="invitedSpecialists"
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <TextareaComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={"Описание"}
                    error={errors.invitedSpecialists?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Если вы планируете привлечь к реализации вашего проекта
                      специалистов не из состава вашей музыкальной группы
                      (smm-специалист, видеооператор, дизайнер и т.д.), укажите
                      здесь информацию о них
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
      </div>

      <div className="form__section">
        <div className="form__title">4. МЕДИАМАТЕРИАЛЫ</div>
        <div className="form__input-block">
          <div className={classNames("form__input-title")}>
            * Фотографии музыкального коллектива
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              defaultValue={formInitialValues.photoSite}
              name={"photoSite"}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <UploadFileContainer
                    artistId={formId}
                    nameForStore="photoSite"
                    title="На сайт"
                    hasTitleError={!!errors.photoSite}
                    fileTypes={[formates.jpeg, formates.png]}
                    uploadedFile={value || undefined}
                    onFileDelete={(): void => {
                      setValue(name, null)
                    }}
                    onFileUpload={(fileData): void => onChange(fileData)}
                    showFileName={false}
                    minWidth={2560}
                    minHeight={2560}
                    disabled={checkIsFormReadonly()}
                    type={FileUploadType.IMAGE}
                    fileType={"photo_site"}
                    titleIcon={Eye}
                  />

                  <div className="form__input-label">
                    <InputLabelComponent>
                      На сайт: квадратная - 2560 х 2560 px;
                      <br />
                      Для соц.сетей и экранов: квадратная - 1000 х 1000 px;
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div
            className={classNames(
              "form__input-title",
              showValidationErrors &&
              !photoSocialField?.filter((item: FileUploadResponse | null) =>
                Boolean(item)
              ).length &&
              "form__input-title--error"
            )}
          >
            Для социальных сетей и экранов
          </div>
          <div className="row row--space-between">
            {formInitialValues.photoSocial.map((item, index) => (
              <div className="form__input" key={`photo-social-${index}`}>
                <Controller
                  rules={{
                    validate: {
                      photoSocialFilled: (): boolean | string =>
                        !!photoSocialField.filter(
                          (item: FileUploadResponse | null) => Boolean(item)
                        ).length || "Загрузите хотя бы одно изображение"
                    }
                  }}
                  defaultValue={formInitialValues.photoSocial[index]}
                  control={control}
                  name={`photoSocial[${index}]`}
                  render={({ onChange, value, name }): JSX.Element => (
                    <Element name={name}>
                      <UploadFileContainer
                        artistId={formId}
                        nameForStore={`photoSocial[${index}]`}
                        fileTypes={[formates.jpeg, formates.png, formates.jpg]}
                        uploadedFile={value || undefined}
                        onFileUpload={(fileData): void => onChange(fileData)}
                        onFileDelete={(): void => {
                          setValue(name, null)
                        }}
                        showFileName={false}
                        minWidth={1000}
                        minHeight={1000}
                        disabled={checkIsFormReadonly()}
                        type={FileUploadType.IMAGE}
                        fileType={"photo_social"}
                      />
                    </Element>
                  )}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="form__input-block">
          <div
            className={classNames(
              "form__input-title",
              showValidationErrors &&
              errors.leaderPassport &&
              "form__input-title--error"
            )}
          >
            * Отсканированная копия паспорта руководителя проекта
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name={"leaderPassport"}
              defaultValue={formInitialValues.leaderPassport}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <UploadFileContainer
                    artistId={formId}
                    nameForStore={name}
                    fileTypes={[formates.pdf, formates.jpeg, formates.jpg, formates.png]}
                    uploadedFile={value || undefined}
                    onFileUpload={(fileData): void => onChange(fileData)}
                    onFileDelete={(): void => {
                      setValue(name, null)
                    }}
                    showFileName={false}
                    disabled={checkIsFormReadonly()}
                    minWidth={100}
                    minHeight={100}
                    type={FileUploadType.DOCUMENT}
                    fileType={"leader_passport"}
                  />

                  <div className="form__input-label">
                    <InputLabelComponent>
                      {" "}
                      Допустимые форматы: <span>Pdf / jpeg / jpg / png</span>
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        {fields.length > 1 && renderMembersAgreementsBlock()}

        <div className="form__input-block">
          <div
            className={classNames(
              "form__input-title",
              showValidationErrors &&
              errors.techRider &&
              "form__input-title--error"
            )}
          >
            * Технический райдер (только .docx, .doc, .pdf, .txt, .rtf)
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                }
              }}
              control={control}
              name={"techRider"}
              defaultValue={formInitialValues.techRider}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <UploadFileContainer
                    artistId={formId}
                    nameForStore={name}
                    fileTypes={[
                      formates.doc,
                      formates.docx,
                      formates.pdf,
                      formates.txt,
                      formates.rtf
                    ]}
                    uploadedFile={value || undefined}
                    onFileUpload={(fileData): void => onChange(fileData)}
                    onFileDelete={(): void => {
                      setValue(name, null)
                    }}
                    showFileName={false}
                    disabled={checkIsFormReadonly()}
                    type={FileUploadType.DOCUMENT}
                    fileType={"tech_rider"}
                  />

                  <div className="form__input-label">
                    <InputLabelComponent>
                      Требования к оснащению сцены для выступления вашего
                      музыкального коллектива.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>

        <div className="form__input-block">
          <div
            className={classNames(
              "form__input-title",
              showValidationErrors &&
              errors.presentation &&
              "form__input-title--error"
            )}
          >
            Презентация коллектива
          </div>
          <div className="form__input">
            <Controller
              control={control}
              name={"presentation"}
              defaultValue={formInitialValues.presentation}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <UploadFileContainer
                    artistId={formId}
                    nameForStore={name}
                    fileTypes={[formates.pdf]}
                    uploadedFile={value || undefined}
                    onFileUpload={(fileData): void => onChange(fileData)}
                    onFileDelete={(): void => {
                      setValue(name, null)
                    }}
                    showFileName={false}
                    disabled={checkIsFormReadonly()}
                    type={FileUploadType.DOCUMENT}
                    fileType={"presentation"}
                  />

                  <div className="form__input-label">
                    <InputLabelComponent>
                      Вы можете прикрепить презентацию вашего коллектива в
                      формате pdf.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
      </div>

      <div className="form__section form__section--last">
        <div className="form__title">5. ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</div>
        <div className="form__input-block">
          <div className="form__input-title">
            * Разместите анонс в социальных сетях с вашими хештегами и хештегом фестиваля #NewOpen2024 и прикрепите ссылку на пост.
          </div>
          <div className="form__input">
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Это обязательное поле"
                },
                validate: {
                  validUrl: (value): boolean | string =>
                    isValidURL(value.trim()) || "Введите корректный URL"
                }
              }}
              control={control}
              name="promoPostUrl"
              defaultValue={formInitialValues.promoPostUrl}
              render={({ onChange, value, name }): JSX.Element => (
                <Element name={name}>
                  <InputComponent
                    onChange={onChange}
                    value={value}
                    name={name}
                    mask={false}
                    placeholder={"Ссылка"}
                    error={errors.promoPostUrl?.message}
                    onBlur={(): void => setFocusedInput("")}
                    onFocus={(): void => setFocusedInput(name)}
                    disabled={checkIsFormReadonly()}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={focusedInput === name}>
                      Разместите анонс в социальных сетях с вашими хештегами и
                      хештегом фестиваля <span>#NewOpen2024</span> и прикрепите
                      ссылку на пост.
                    </InputLabelComponent>
                  </div>
                </Element>
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ArtistFormInputFields
