import { Deserialize, Serialize } from "cerialize"
import { RequestErrorResponse } from "../../models/common/error.model"
import {
  EvaluateRequest,
  EvaluationInfoGetResponse
} from "../../models/ExpertModels/Evaluation.model"
import {
  evaluateRequestStore,
  evaluationInfoGetStore
} from "../../store/ExpertStore/ExpertEvaluation.store"
import { api } from "../../utils/api"
import {
  EvaluateProjectDefenseRequest,
  EvaluateMusicPerfomanceRequest
} from "../../models/FestExpertModels/Evaluation.model"
import {
  evaluateMusicPerfomanceRequestStore,
  evaluateProjectDefenseRequestStore
} from "../../store/FestExpertStore/festExpertArtistInfo.store"

export function evaluateArtistProjectDefense(
  data: EvaluateProjectDefenseRequest,
  id: number
): Promise<void> {
  const serializedData = Serialize(data, EvaluateProjectDefenseRequest)

  evaluateProjectDefenseRequestStore.setIsLoading(true)

  return api
    .post(`/festexpert/artists/${id}/project-defense`, serializedData)
    .then(() => {
      evaluateProjectDefenseRequestStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data

      evaluateProjectDefenseRequestStore.setIsLoading(false)
      evaluateProjectDefenseRequestStore.setErrors(errorResponseData?.errors)

      throw error
    })
}

export function evaluateArtistMusicPerfomance(
  data: EvaluateMusicPerfomanceRequest,
  id: number
): Promise<void> {
  const serializedData = Serialize(data, EvaluateMusicPerfomanceRequest)

  evaluateMusicPerfomanceRequestStore.setIsLoading(true)

  return api
    .post(`/festexpert/artists/${id}/music-perfomance`, serializedData)
    .then(() => {
      evaluateMusicPerfomanceRequestStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data

      evaluateMusicPerfomanceRequestStore.setIsLoading(false)
      evaluateMusicPerfomanceRequestStore.setErrors(errorResponseData?.errors)

      throw error
    })
}
