import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import CRMListItemComponent from "../../../components/CRM/AdminCRM/CrmListItem/CrmListItem.component"
import { PATH_ARTIST_MODERATE, PATH_EVALUATION } from "../../../const/routes.const"
import { ListArtistModel } from "../../../models/AdminModels/ArtistList.model"
import { ArtistFormStatus } from "../../../models/ArtistModels/artistForm.model"
import { artistDataStore } from "../../../store/ArtistStore/artistData.store"

interface Props {
  artistData: ListArtistModel;
}

const statuses: {
  [key in ArtistFormStatus]: {
    title: string;
    color: "red" | "grey" | "yellow" | "green" | "blue" | 'red';
  };
} = {
  NEW: {
    title: "Новая заявка",
    color: "red"
  },
  EDITING: {
    title: "Редактируется артистом",
    color: "grey"
  },
  MODERATION: {
    title: "Требуется модерация",
    color: "yellow"
  },
  APPROVED: {
    title: "Заявка утверждена",
    color: "green"
  },
  CHECKED: {
    title: "Вычитано",
    color: "blue"
  }
}

const ArtistListItemContainer  = (props: Props): JSX.Element => {
  const { artistData } = props
  const location = useLocation()
  const history = useHistory()

  const onClick = (): void => {
    artistDataStore.setFormStatus(artistData.status)

    history.push(
      `${PATH_ARTIST_MODERATE}/${artistData.id}`,
      location.pathname
    )
  }

  return (
    <CRMListItemComponent 
      title={artistData.name}
      image={artistData.photo || undefined}
      statusColor={statuses[artistData.status].color}
      statusText={statuses[artistData.status].title}
      onClick={onClick} 
    />
  )
}

export default ArtistListItemContainer