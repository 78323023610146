import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import EvaluationInfoComponent from '../../../components/CRM/ExpertCRM/EvaluationInfo/EvaluationInfo.component'
import { RequestErrorResponse } from '../../../models/common/error.model'
import { getEvaluationInfo } from '../../../services/expert/expertEvaluation.service'
import { evaluationInfoGetStore } from '../../../store/ExpertStore/ExpertEvaluation.store'

const EvaluationInfoContainer = (props: RouteComponentProps): JSX.Element => {
  const onEffect = (): () => void => {
    getEvaluationInfo().catch((errorResponseData: RequestErrorResponse) => {
      const noArtistError = errorResponseData?.errors.find(error => error.code === 'no-artist')

      // if (!!noArtistError) {
      //   props.history.replace(PATH_EXPERT_DASHBOARD)
      // }
    })

    return (): void => {      
      evaluationInfoGetStore.clearInfo()
    }
  } 

  useEffect(onEffect, [])  

  return (
    <EvaluationInfoComponent 
      data={evaluationInfoGetStore.evaluationInfo}
      isLoading={evaluationInfoGetStore.isLoading}
    />
  )
}

export default withRouter(observer(EvaluationInfoContainer))