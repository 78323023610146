import { autoserialize } from "cerialize"
import { UserModel } from "../common/user.model"



export class RegisterRequest {
  @autoserialize email: string;
  @autoserialize password: string;
  @autoserialize name: string;
  
  constructor(
    email: string,
    password: string,
    name: string
  ) {
    this.email = email
    this.password = password
    this.name = name
  }
}

export interface RegisterFormFields extends RegisterRequest {
  repeatPassword: string;
}

export class RegisterResponse {
  @autoserialize token: string;
  @autoserialize user: UserModel;

  constructor(
    token: string,
    user: UserModel
  ) {
    this.token = token
    this.user = user
  }
}