import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import SideBar from "../SideBar/SideBar.component"

import "./AdminCRMLayout.style.scss"

interface Props {
  children: React.ReactNode;
}

const block = "admin-layout"

const AdminCRMLayout = (props: Props & RouteComponentProps): JSX.Element => (
  <div className={block}>
    <div className={`${block}__body`}>
      <SideBar />
      <div className={`${block}__content`}>{props.children}</div>
    </div>
  </div>
)

export default withRouter(AdminCRMLayout)
