import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isEmailValid } from '../../../const/validators.const'
import { PassRecoveryRequest } from '../../../models/AuthModels/login.model'
import Button from '../../common/Button/Button.component'
import InputComponent from '../../common/Input/Input.component'
import AuthFormLayoutComponent from '../AuthFormLayout/AuthFormLayout.component'

import './PassRecoveryForm.style.scss'

interface Props {
  error?: string;
  isLoading?: boolean;
  onSubmit: (data: PassRecoveryRequest) => void;
  onLoginClick: () => void;
}

const block = 'pass-recovery-form'

const PassRecoveryFormComponent = (props: Props): JSX.Element => {
  const { error, isLoading, onSubmit, onLoginClick } = props

  const {
    handleSubmit,
    errors,
    control
  } = useForm<PassRecoveryRequest>()

  return (
    <AuthFormLayoutComponent 
      title="ВОССТАНОВЛЕНИЕ ПАРОЛЯ" 
      className={block}
      error={error}
    >
      <div className={`${block}__text`}>
        Забыли пароль? Не беда. <br/>
        Мы поможем вам его восстановить!
      </div>
      <div className={'auth-form-layout__input'}>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Это обязательное поле"
            },
            validate: {
              validEmail: (value): boolean | string => isEmailValid(value) || 'Введите корректный Email'
            }
          }}
          defaultValue=""
          control={control} 
          name="email"
          render={({ onChange, value, name }): JSX.Element => (
            <InputComponent
              onChange={(event): void => onChange(event.target.value.trim())}
              value={value.trim()}
              name={name}
              mask={false}
              placeholder={'E-mail'}
              error={errors.email?.message}
              isErrorStatic={true}
            />
          )}
        />
      </div>
      <div className={`auth-form-layout__pass-recovery`} onClick={onLoginClick}>
        Войти
      </div>
      <div className={`auth-form-layout__button`}>
        <Button 
          background="turquoise" 
          onClick={handleSubmit(onSubmit)}
          isDisabled={isLoading}
          text={'Отправить'}
        />
      </div>
    </AuthFormLayoutComponent>
  )
}

export default PassRecoveryFormComponent