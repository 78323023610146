import React from "react"
import ArtistCRMHeaderContainer from "../../../../container/CRM/ArtistCRM/ArtistCRMHeader.container"

import "./ArtistCRMLayout.style.scss"
import HeaderComponent from "../../../common/Header/Header.component"

interface Props {
  children: React.ReactNode;
}

const block = "artist-crm-layout"

export default class ArtistCRMLayout extends React.Component<Props> {
  render(): JSX.Element {
    return (
      <div className={block}>
        <HeaderComponent/>
        <ArtistCRMHeaderContainer />
        <div className={`${block}__body`}>
          <div className="container">{this.props.children}</div>
        </div>
      </div>
    )
  }
}
