import axios from "axios"

const localStorageAuthToken = localStorage.getItem("authToken")

function getApiUrl(): string {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return "https://test.api.newopen.doubletapp.ai/api"
    case "production":
      return "https://api.newopen.su/api"
    case "test":
      return "https://test.api.newopen.doubletapp.ai/api"
    default:
      return "https://api.newopen.su/api"
  }
}

const getSecret = (): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return "UIz6rvFo4zHT9aFi5TShJMcxX1Ke606Fin8clsJRmh3_TGCsbNgGT2kixnrePB7Q"
    case "production":
      return "vAdkGOTQKhXVeYBZ9eZzB3SHoqh0aDMEhKk_bnliDTii29mL9meFUGxBhij-gSjT"
    case "test":
      return "UIz6rvFo4zHT9aFi5TShJMcxX1Ke606Fin8clsJRmh3_TGCsbNgGT2kixnrePB7Q"
    default:
      return "vAdkGOTQKhXVeYBZ9eZzB3SHoqh0aDMEhKk_bnliDTii29mL9meFUGxBhij-gSjT"
  }
}

export let api = axios.create({
  baseURL: getApiUrl(),
  headers: {
    secret: getSecret(),
    Authorization: localStorageAuthToken && `Bearer ${localStorageAuthToken}`
  }
})

export const setToken = (token: string): void => {
  localStorage.setItem("authToken", token)

  api = axios.create({
    baseURL: getApiUrl(),
    headers: {
      secret: getSecret(),
      Authorization: `Bearer ${localStorage.getItem("authToken")}`
    }
  })
}
