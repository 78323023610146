import React, { useState } from "react"
import AuthInfoComponent from "../../components/CRM/AuthInfo/AuthInfo.component"
import AuthLayoutComponent from "../../components/CRM/AuthLayout/AuthLayout.component"
import LoginFormContainer from "./LoginForm.container"
import RegisterFormContainer from "./RegisterForm.container"

const authInfoData = {
  registerStateData: {
    title: "АВТОРИЗАЦИЯ",
    text: "Войдите в свой аккаунт, если вы уже регистрировались на подачу заявки на фестиваль",
    buttonText: "Войти"
  },
  loginStateData: {
    title: "ПОДАТЬ ЗАЯВКУ",
    text: "Зарегистрируйтесь в системе, чтобы подать новую заявку на участие в фестивале New / Open",
    buttonText: "Зарегистрироваться"
  }
}

const AuthLayoutContainer = ({hasRegister}: {hasRegister: boolean}): JSX.Element => {
  const [isRegisterState, setIsRegisterState] = useState(false)

  const toggleRegisterState = (): void => {
    setIsRegisterState(!isRegisterState)
  }

  return (
    <AuthLayoutComponent hasRegister={hasRegister}>
      {isRegisterState && hasRegister && <RegisterFormContainer />}
      {hasRegister && (
        <AuthInfoComponent
          data={
            isRegisterState
              ? authInfoData.registerStateData
              : authInfoData.loginStateData
          }
          onClick={toggleRegisterState}
        />
      )}
      {!isRegisterState && <LoginFormContainer />}
    </AuthLayoutComponent>
  )
}

export default AuthLayoutContainer
