/* eslint-disable no-console */
import React from "react"
import moment from "moment"
import { ReactComponent as Comment } from "../../../../assets/icons/comment.svg"
import { ReactComponent as CommentAvatar } from "../../../../assets/icons/comment-avatar.svg"
import classNames from "classnames"
import {
  CommentModel,
  ArtistFormStatus,
  ArtistFormFields
} from "../../../../models/ArtistModels/artistForm.model"
import Button from "../../../common/Button/Button.component"
import IconWithTextComponent from "../../common/IconWithText/IconWithText.component"

import "./ArtistSidebar.style.scss"
import CommentInputContainer from "../../../../container/CRM/AdminCRM/CommentInput.container"
import { UserRole } from "../../../../const/permissionsRules.const"

interface Props {
  comments?: CommentModel[];
  commentInputTitle: string;
  activeSidebarTab: "comments" | "hints";
  setActiveSidebarTab: (name: "comments" | "hints") => void;
  onComment: () => void;
  isModerateActive: boolean;
  isModerateLoading: boolean;
  isCanComment: boolean;
}

const block = "artist-sidebar"

const ArtistSidebar = (props: Props): JSX.Element => {
  const {
    comments,
    commentInputTitle,
    activeSidebarTab,
    setActiveSidebarTab,
    onComment,
    isModerateActive,
    isModerateLoading,
    isCanComment
  } = props

  return (
    <div className={block}>
      <div className="tabs">
        <div
          className={`tabs__item ${
            activeSidebarTab === "comments" ? "tabs__item--active" : ""
          }`}
          onClick={() => setActiveSidebarTab("comments")}
        >
          Комментарии
        </div>
        <div
          className={`tabs__item ${
            activeSidebarTab === "hints" ? "tabs__item--active" : ""
          }`}
          onClick={() => setActiveSidebarTab("hints")}
        >
          Подсказки
        </div>
      </div>

      <div
        className={classNames(
          "margin--top-m",
          activeSidebarTab === "hints"
            ? `${block}--tab-hints`
            : `${block}__sticky`
        )}
      >
        {comments && !!comments.length && (
          <div className={`${block}__comments-section`}>
            <div className={`${block}__comments-title`}>
              Предыдущие комментарии:
            </div>
            <div className={`${block}__comments`}>
              {comments.map((comment: CommentModel, index: number) => (
                <div
                  className={`${block}__comments-item`}
                  key={`${comment.author}-comment-${index}`}
                >
                  {comment.author === UserRole.ARTIST && (
                    <div className={`${block}__comments-item-header`}>
                      <div className={`icon`}>
                        <CommentAvatar />
                      </div>
                      <div className={`name`}>Вы</div>
                    </div>
                  )}

                  {comment.author === UserRole.ADMIN && (
                    <div className={`${block}__comments-item-header`}>
                      <div className={`icon icon--admin`}>
                        <CommentAvatar />
                      </div>
                      <div className={`name`}>Модератор</div>
                    </div>
                  )}

                  <div className={`${block}__comments-item-text`}>
                    {comment.comment}
                  </div>

                  <div className={`${block}__comments-item-date`}>
                    {moment
                      .utc(comment.createdAt)
                      .utcOffset(-new Date().getTimezoneOffset())
                      .format("DD.MM.YYYY, HH:mm:ss")}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {isCanComment ? (
          <>
            <div className="margin--top-s">
              <IconWithTextComponent icon={Comment} text={commentInputTitle} />
            </div>
            <div className="margin--top-xs">
              <CommentInputContainer />
            </div>
            <div className="margin--top-s">
              <Button
                onClick={onComment}
                text="Оставить комментарий"
                background="turquoise"
                isDisabled={!isModerateActive}
                isLoading={isModerateLoading}
              />
            </div>
          </>
        ) : (
          <div className="margin--top-m" >Отправка комментария недоступна в текущем статусе заявки</div>
        )}
      </div>
    </div>
  )
}

export default ArtistSidebar
