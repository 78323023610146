import classNames from 'classnames'
import React from 'react'
import { ReactComponent as Loader } from "../../../assets/icons/button-loading.svg"

import './Button.style.scss'

interface Props {
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
  text: string;
  isDisabled?: boolean;
  background: "turquoise" | "white" | "red" | "yellow" | "transparent" | "text";
  isCircle?: boolean;
  children?: React.ReactNode;
  icon?: JSX.Element;
  isLoading?: boolean;
}

const block = 'button'

const Button = (props: Props): JSX.Element => {
  const { onClick, text, background, isDisabled, icon, isLoading } = props

  return (
    <div
      className={classNames(
        block,
        `${block}--${background}`,
        isDisabled && `${block}--disabled`,
        isLoading && `${block}--loading`
      )}
      onClick={(event: React.SyntheticEvent<HTMLElement>): void => {
        !isDisabled && !isLoading && onClick(event)
      }}
    >
      {isLoading ? (
        <div className={`${block}__loader`}>
          <Loader width="30" height="30" />
        </div>
      ) : (
        <>
        {icon && (
          <div className={`${block}__icon`}>
            {icon}
          </div>
        )}
        
        {text}
        </>
      )}

    </div>
  )
}

export default Button

