import { observer } from "mobx-react"
import React, { useContext, useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import ArtistFormComponent from "../../../components/CRM/ArtistCRM/ArtistForm/ArtistForm.component"
import { UserRole } from "../../../const/permissionsRules.const"
import {
  PATH_ARTISTS_NEW,
  PATH_ARTIST_NOTIFICATIONS,
  PATH_ARTIST_REQUEST_READ
} from "../../../const/routes.const"
import { authContext } from "../../../context/auth.context"
import {
  ArtistFormAgreeCheckboxName,
  ArtistFormFields,
  ArtistFormSaveRequest,
  ArtistFormStatus,
  MemberModel
} from "../../../models/ArtistModels/artistForm.model"
import {
  applyForm,
  approveArtist,
  checkForm,
  getArtistForm,
  moderateArtist,
  saveArtistForm,
  saveArtistMembers,
  commentByAdmin,
  commentByArtist
} from "../../../services/artist/artistForm.service"
import { formCommentStore } from "../../../store/AdminStore/formComment.store"
import { artistDataStore } from "../../../store/ArtistStore/artistData.store"
import {
  artistFormApplyStore,
  artistFormApproveStore,
  artistFormGetStore,
  artistFormModerateStore,
  artistFormReadStore,
  artistFormSaveStore,
  artistSaveMembersStore
} from "../../../store/ArtistStore/artistForm.store"
import { artistFormNotificationsStore } from "../../../store/ArtistStore/artistFormNotifications.store"
import { checkboxStore } from "../../../store/common/checkboxStore"
import {
  formatDateForClient,
  formatDateForServer
} from "../../../utils/formatDate"

const ArtistFormContainer = (): JSX.Element => {
  const { user } = useContext(authContext)
  const artistId = parseInt(useParams<{ artistId: string }>().artistId)
  const history = useHistory()
  const location = useLocation<{ category: string }>()
  const [activeSidebarTab, setActiveSidebarTab] = useState<
    "comments" | "hints"
  >("comments")
  artistFormReadStore.requestErrors
  const [isFormReadonlyState, setIsFormReadonlyState] =
    useState<boolean>(false)

  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true)
  const getFormInitialValues = (): ArtistFormFields | undefined => {
    const formData = artistFormGetStore.formData

    if (formData) {
      return {
        ...formData?.form,
        members: formData.form.members.length
          ? formData.form.members.map(
            (member): MemberModel => ({
              ...member,
              birthday: formatDateForClient(member.birthday)
            })
          )
          : [
            {
              name: null,
              email: null,
              birthday: null,
              role: null,
              tShirtSize: null,
              phone: null,
              musicExperience: null,
              facebookUrl: null,
              instagramUrl: null,
              vkontakteUrl: null,
              tiktokUrl: null,
              youtubeUrl: null,
              twitterUrl: null,
              isLeader: true
            }
          ],
        photoSocial: [
          formData.form.photoSocial[0] || null,
          formData.form.photoSocial[1] || null,
          formData.form.photoSocial[2] || null,
          formData.form.photoSocial[3] || null
        ],
        musicLinks: [
          formData.form.musicLinks[0] || "",
          formData.form.musicLinks[1] || "",
          formData.form.musicLinks[2] || ""
        ],
        budgetPlanJson: formData.form.budgetPlanJson?.length
          ? formData.form.budgetPlanJson?.map(item => ({
              name: item?.name || null,
              price: item?.price || 0,
              count: item?.count || 0,
              currentBudget: item?.currentBudget || 0
            }))
          : [
              {
                name: null,
                price: null,
                count: null,
                currentBudget: null
          }],
        projectStart: formatDateForClient(formData?.form.projectStart),
        projectEnd: formatDateForClient(formData?.form.projectEnd),
        projectBudget: formData.form.projectBudget || null
      }
    }
  }

  const [formInitialValues, setFormInitialValues] = useState(
    getFormInitialValues()
  )
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const onEffect = (): (() => void) => {
    if (isNaN(artistId) && user.role === UserRole.ADMIN) {
      history.replace(PATH_ARTISTS_NEW)
    }

    user.role &&
      getArtistForm(user.role, artistId).then(() => {
        setFormInitialValues(getFormInitialValues())
      })

    return (): void => {
      artistFormGetStore.clearData()
    }
  }

  useEffect(onEffect, [])

  useEffect(() => {
    if (location.pathname === PATH_ARTIST_REQUEST_READ) {
      setIsFormReadonlyState(true)
    }
  }, [location.pathname])

  const showErrors = (): void => {
    setShowValidationErrors(true)
  }

  const getSavePreparedRequest = (
    data: ArtistFormFields
  ): {
    saveRequest: ArtistFormSaveRequest;
    members: MemberModel[];
  } => {
    const { members, ...formValues } = data

    const preparedRequest = {
      ...formValues,
      projectStart: formatDateForServer(formValues.projectStart),
      projectEnd: formatDateForServer(formValues.projectEnd),
      // TODO: удаленные поля
      projectBudget: 100,
      projectImplementationIfEpidemic: "удаленное поле"
    }

    const membersPreparedRequest = members?.map(
      (member): MemberModel => ({
        ...member,
        birthday: formatDateForServer(member.birthday)
      })
    )

    return {
      saveRequest: preparedRequest,
      members: membersPreparedRequest
    }
  }

  const goBack = (): void => {
    if (location.state?.category) {
      history.push(location.state.category || PATH_ARTISTS_NEW)
    } else {
      history.push(PATH_ARTISTS_NEW)
    }
  }

  const moderate = (data: ArtistFormFields): void => {
    const preparedRequest = getSavePreparedRequest(data)

    user.role &&
      saveArtistForm(preparedRequest.saveRequest, user.role, artistId).then(
        () => {
          saveArtistMembers(
            preparedRequest.members,
            user.role as UserRole,
            artistId
          )
            .then(() => {
              moderateArtist(formCommentStore.comment, artistId)
            })
            .then(() => {
              commentByAdmin(formCommentStore.comment, artistId).then(() => {
                goBack()
              })
            })
        }
      )
  }

  const comment = (): void => {
    if (user.role && user.role === UserRole.ARTIST) {
      commentByArtist(formCommentStore.comment).then(() => {
        formCommentStore.clearComment()
      })
    }
  }

  const approve = (data: ArtistFormFields): void => {
    const preparedRequest = getSavePreparedRequest(data)

    user.role &&
      saveArtistForm(preparedRequest.saveRequest, user.role, artistId).then(
        () => {
          saveArtistMembers(
            preparedRequest.members,
            user.role as UserRole,
            artistId
          ).then(() => {
            approveArtist(artistId).then(() => {
              goBack()
            })
          })
        }
      )
  }

  const onCheckForm = (data: ArtistFormFields): void => {
    artistFormReadStore.clearErrors()
    const preparedRequest = getSavePreparedRequest(data)

    user.role &&
      saveArtistForm(preparedRequest.saveRequest, user.role, artistId).then(
        () => {
          saveArtistMembers(
            preparedRequest.members,
            user.role as UserRole,
            artistId
          ).then(() => {

            checkForm(artistId).then(() => {
              goBack()
            })
          })
        }
      )
  }

  const onApproveApproveFormWithoutCheck = (data: ArtistFormFields): void => {
    artistFormReadStore.clearErrors()
    const preparedRequest = getSavePreparedRequest(data)

    user.role &&
      saveArtistForm(preparedRequest.saveRequest, user.role, artistId).then(
        () => {
          saveArtistMembers(
            preparedRequest.members,
            user.role as UserRole,
            artistId
          )
        }
      ).then(() => {
        goBack()
      })
  }


  const onSubmit = (data: ArtistFormFields, isApply?: boolean): void => {
    const preparedRequest = getSavePreparedRequest(data)

    user.role &&
      saveArtistForm(preparedRequest.saveRequest, user.role, artistId).then(
        () => {
          saveArtistMembers(
            preparedRequest.members,
            user.role as UserRole,
            artistId
          ).then(() => {
            isApply &&
              applyForm().then(() => history.push(PATH_ARTIST_NOTIFICATIONS))
          })
        }
      )
  }

  const getIsRequestAllowed = (): boolean =>
    checkboxStore.getItem(ArtistFormAgreeCheckboxName.accessCheckbox)
      ?.checked &&
    checkboxStore.getItem(ArtistFormAgreeCheckboxName.associationCheckbox)
      ?.checked &&
    checkboxStore.getItem(ArtistFormAgreeCheckboxName.termsCheckbox)?.checked &&
    checkboxStore.getItem(ArtistFormAgreeCheckboxName.licenseCheckbox)?.checked

  useEffect(() => {
    if (artistFormGetStore.isLoading) {
      setIsLoadingForm(true)
    } else {
      setIsLoadingForm(false)
    }
  }, [artistFormGetStore.isLoading])

  return (
    <ArtistFormComponent
      isLoading={isLoadingForm || !formInitialValues}
      isSaving={
        artistFormSaveStore.isLoading || artistSaveMembersStore.isLoading
      }
      formInitialValues={formInitialValues}
      setFormInitialValues={setFormInitialValues}
      onSubmit={onSubmit}
      onModerate={moderate}
      onApprove={approve}
      onComment={comment}
      onCheckForm={onCheckForm}
      onApproveApproveFormWithoutCheck={onApproveApproveFormWithoutCheck}
      showValidationErrors={showValidationErrors}
      showErrors={showErrors}
      isRequestAllowed={getIsRequestAllowed()}
      submittionErrors={
        artistFormApplyStore.requestErrors || artistFormSaveStore.requestErrors
      }
      isModerateActive={!!formCommentStore.comment.length}
      formStatus={artistDataStore.formStatus || ArtistFormStatus.EDITING}
      isFirstEdit={
        !artistFormGetStore.formData?.meta.appliedAt
        // !artistFormNotificationsStore.notificationsData?.notifications.length
      }
      isFormApplyLoading={artistFormApplyStore.isLoading}
      isModerateLoading={artistFormModerateStore.isLoading}
      isApproveLoading={artistFormApproveStore.isLoading}
      isCheckLoading={artistFormReadStore.isLoading}
      checkingErrors={artistFormReadStore.requestErrors}
      userRole={user.role}
      isFormReadonly={
        user.role === UserRole.EXPERT ||
        isFormReadonlyState ||
        (user.role !== UserRole.ADMIN &&
          artistFormGetStore.formData?.meta.status ===
          ArtistFormStatus.CHECKED) 
        //   ||
        // (user.role === UserRole.ARTIST &&
        //   artistFormGetStore.formData?.meta.status ===
        //   ArtistFormStatus.EDITING &&
        //   !artistFormGetStore.formData?.meta.checkedAt)
      }
      comments={artistFormGetStore.formData?.comments}
      formId={artistId}
      activeSidebarTab={activeSidebarTab}
      setActiveSidebarTab={setActiveSidebarTab}
      artistFormMeta={artistFormGetStore.formData?.meta}
    />
  )
}

export default observer(ArtistFormContainer)
