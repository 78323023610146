import React, { useRef, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { DeepMap, FieldError, FormProvider, useForm } from "react-hook-form"
import { scroller } from "react-scroll"
import classNames from "classnames"
import isEqual from "lodash.isequal"

import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow-down-red.svg"
import { ReactComponent as PDFIcon } from "../../../../assets/icons/pdf.svg"
import { ReactComponent as ChatIcon } from "../../../../assets/icons/chat.svg"
import {
  ArtistFormAgreeCheckboxName,
  ArtistFormFields,
  ArtistFormStatus,
  CommentModel,
  ArtistFormResponseMeta,
  BudgetRowModel
} from "../../../../models/ArtistModels/artistForm.model"
import LoaderComponent from "../../../common/Loader/Loader.component"
import Button from "../../../common/Button/Button.component"
import IconLink from "../../../common/IconLink/IconLink.component"
import { RequestError } from "../../../../models/common/error.model"
import ErrorLabelComponent from "../../common/ErrorLabel/ErrorLabel.component"
import CheckboxComponent from "../../../common/Checkbox/Checkbox.component"
import { UserRole } from "../../../../const/permissionsRules.const"
import AdminApproveSidebarComponent from "../../AdminCRM/AdminApproveSidebar"
import ArtistFormInputFields from "./ArtistFormFields/ArtistFormFields.component"
import ArtistSidebarComponent from "../ArtistSidebar/ArtistSidebar.component"

import { PATH_ARTIST_REQUEST_READ } from "../../../../const/routes.const"
import "./ArtistForm.style.scss"

interface Props {
  isLoading?: boolean;
  formInitialValues?: ArtistFormFields;
  onSubmit: (data: ArtistFormFields, isApply?: boolean) => void;
  setFormInitialValues:  (value: React.SetStateAction<ArtistFormFields | undefined>) => void
  isSaving?: boolean;
  isRequestAllowed: boolean;
  showValidationErrors?: boolean;
  isFormReadonly?: boolean;
  isFormReadonlyForce?: boolean;
  showErrors: () => void;
  submittionErrors?: RequestError[];
  onModerate: (values: ArtistFormFields) => void;
  onApprove: (values: ArtistFormFields) => void;
  onCheckForm: (values: ArtistFormFields) => void;
  onApproveApproveFormWithoutCheck: (values: ArtistFormFields) => void
  onComment: () => void;
  isModerateActive: boolean;
  comments?: CommentModel[];
  formStatus: ArtistFormStatus;
  isFirstEdit: boolean;
  isFormApplyLoading: boolean;
  isModerateLoading: boolean;
  isApproveLoading: boolean;
  isCheckLoading: boolean;
  userRole?: UserRole;
  formId?: number;
  activeSidebarTab: "comments" | "hints";
  setActiveSidebarTab: (name: "comments" | "hints") => void;
  artistFormMeta?: ArtistFormResponseMeta;
  checkingErrors?: RequestError[];
}

const block = "artist-form"

const ArtistFormComponent = (props: Props): JSX.Element => {
  const location = useLocation()
  const metaRef = useRef<HTMLParagraphElement | null>(null)

  const [isOpenLicense, setIsOpenLicense] = useState(false)
  const {
    isLoading,
    formInitialValues,
    setFormInitialValues,
    onSubmit,
    isSaving,
    isRequestAllowed,
    showValidationErrors,
    isFormReadonly,
    showErrors,
    submittionErrors,
    onModerate,
    onApprove,
    onCheckForm,
    onApproveApproveFormWithoutCheck,
    onComment,
    isModerateActive,
    comments,
    formStatus,
    isFirstEdit,
    isFormApplyLoading,
    isModerateLoading,
    isApproveLoading,
    isCheckLoading,
    userRole,
    formId,
    activeSidebarTab,
    setActiveSidebarTab,
    artistFormMeta,
    checkingErrors
  } = props

  const form = useForm<ArtistFormFields>({
    defaultValues: formInitialValues,
    shouldFocusError: false
  })

  const [budgetModalFormData, setBudgetModalFormData] = React.useState<
  BudgetRowModel[]
  >(formInitialValues?.budgetPlanJson || [])
  
  useEffect(() => {
    form.setValue("budgetPlanJson", budgetModalFormData)
    form.clearErrors("budgetPlanJson")
  }, [form.setValue, budgetModalFormData])


  useEffect(() => {
    if (formInitialValues?.budgetPlanJson) {

      setBudgetModalFormData(formInitialValues.budgetPlanJson)
    }
  }, [formInitialValues?.budgetPlanJson])


  const lastSubmitValues = useRef<ArtistFormFields>()
  const autosaveTimeout = useRef<number>()

  const autoSaveInterval = (): void => {
    if (
      !isEqual(form.getValues(), lastSubmitValues.current) &&
      form.getValues() &&
      !isSaving
    ) {
      onSubmit(form.getValues(), false)
      lastSubmitValues.current = form.getValues()
    }
    autosaveTimeout.current = window.setTimeout(autoSaveInterval, 1000 * 30)
  }

  const onSaveFormWithBudget = (data: BudgetRowModel[]) => {
    setBudgetModalFormData(data)

    onSubmit({ ...form.getValues(), budgetPlanJson: data }, false)
  }

  useEffect(() => {
    form.reset(formInitialValues)
    lastSubmitValues.current = formInitialValues
    form.register("budgetPlanJson")

    form.getValues().budgetPlanJson &&
      !form.getValues().budgetPlanJson[0].name &&
      form.setError("budgetPlanJson", {
        type: "required",
        message: "Все поля должны быть заполнены"
      })

    if (isFirstEdit && userRole === UserRole.ARTIST) {
      autosaveTimeout.current = window.setTimeout(autoSaveInterval, 1000 * 30)
    }

    return (): void => {
      clearInterval(autosaveTimeout.current)
      autosaveTimeout.current = undefined
    }
  }, [formInitialValues])

  const scrollToFirstError = (
    errors: DeepMap<ArtistFormFields, FieldError>
  ): void => {
    const errorFields = Object.keys(errors)

    if (errorFields.length) {
      if (errorFields[0] === "members") {
        const errorMembersIndex = errors?.members?.findIndex(Boolean)

        if (
          typeof errorMembersIndex === "number" &&
          errorMembersIndex >= 0 &&
          errors.members &&
          errors.members[errorMembersIndex]
        ) {
          const membersErrorNames = Object.keys(
            errors.members[errorMembersIndex] as any
          )

          scroller.scrollTo(
            `members[${errorMembersIndex}].${membersErrorNames[0]}`,
            {
              duration: 800,
              delay: 0,
              smooth: "easeInOutQuart",
              offset: -230
            }
          )
        }

        return
      }

      if (errorFields[0] === "musicLinks") {
        const errorMusicLinksIndex = errors?.musicLinks?.findIndex(Boolean)

        if (
          typeof errorMusicLinksIndex === "number" &&
          errorMusicLinksIndex >= 0 &&
          errors.musicLinks &&
          errors.musicLinks[errorMusicLinksIndex]
        ) {
          scroller.scrollTo(`musicLinks.${errorMusicLinksIndex}`, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -230
          })
        }

        return
      }

      if (errorFields[0] === "membersAgreements") {
        const errorMembersAgreementsIndex =
          errors?.membersAgreements?.findIndex(Boolean)

        if (
          typeof errorMembersAgreementsIndex === "number" &&
          errorMembersAgreementsIndex >= 0 &&
          errors.membersAgreements
        ) {
          scroller.scrollTo(
            `membersAgreements[${errorMembersAgreementsIndex}]`,
            {
              duration: 800,
              delay: 0,
              smooth: "easeInOutQuart",
              offset: -230
            }
          )
        }

        return
      }

      if (errorFields[0] === "photoSocial") {
        const errorPhotoSocialIndex = errors?.photoSocial?.findIndex(Boolean)

        if (
          typeof errorPhotoSocialIndex === "number" &&
          errorPhotoSocialIndex >= 0 &&
          errors.photoSocial
        ) {
          scroller.scrollTo(`photoSocial[${errorPhotoSocialIndex}]`, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -230
          })
        }

        return
      }

      scroller.scrollTo(errorFields[0], {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -230
      })
    }
  }

  const onFormSubmit = (
    submitFunction: (data: ArtistFormFields) => void
  ): void => {
    showErrors()
    form.handleSubmit(
      data => submitFunction(data),
      errors => scrollToFirstError(errors)
    )()
  }

  useEffect(() => {
    if (!artistFormMeta?.appliedAt) {
      setActiveSidebarTab("hints")
    } else {
      setActiveSidebarTab("comments")
    }
  }, [artistFormMeta?.appliedAt])

  return (
    <div
      className={classNames(
        block,
        userRole !== UserRole.ARTIST &&
          formStatus !== ArtistFormStatus.EDITING &&
          `${block}--admin`,
        activeSidebarTab === "comments" && `${block}--comments`
      )}
    >
      <div className="content-container">
        {isLoading || !formInitialValues ? (
          <LoaderComponent width={100} height={100} />
        ) : (
          <>
            {userRole === UserRole.ARTIST && (
              <div className="content-container__icon-links margin--bottom-xs">
                <IconLink
                  icon={PDFIcon}
                  text={"Пример анкеты"}
                  href={
                    // eslint-disable-next-line max-len
                    "https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2Fsc7iCcdxlGIVMhtMsGv2mbjlZVBSDlRz6GlZW3oZ3R%2BQ7ATW%2BUqQH%2Be1aP2KWGWnq%2FJ6bpmRyOJonT3VoXnDag%3D%3D&name=%D0%9F%D1%80%D0%B8%D0%BC%D0%B5%D1%80%20%D0%B7%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%BD%D0%BE%D0%B9%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D0%B8.pdf&nosw=1"
                  }
                />
                <IconLink
                  icon={ChatIcon}
                  text={"Чат"}
                  href={"https://vk.cc/c66EkL"}
                />
              </div>
            )}
            <form
              id="artist-form"
              onSubmit={(): void => onSubmit(form.getValues(), false)}
            />

            <div className="row row--space-between">
              <div className={`${block}__form`}>
                <FormProvider {...form}>
                  <ArtistFormInputFields
                    formInitialValues={formInitialValues}
                    showValidationErrors={showValidationErrors}
                    setFormInitialValues={setFormInitialValues}
                    formId={formId}
                    isFormReadonly={isFormReadonly}
                    budgetModalFormData={budgetModalFormData}
                    setBudgetModalFormData={onSaveFormWithBudget}
                    userRole={userRole}
                    isVisibleHints={activeSidebarTab === "hints"}
                    metaRef={metaRef}
                  />
                </FormProvider>

                {userRole === UserRole.ARTIST &&
                  formStatus !== ArtistFormStatus.CHECKED &&
                  !artistFormMeta?.checkedAt &&
                  !isFormReadonly && (
                    <div className={`${block}__submit-area`}>
                      <div className={`${block}__agree-checkbox`}>
                        <CheckboxComponent
                          name={ArtistFormAgreeCheckboxName.accessCheckbox}
                          defaultIsChecked={true}
                        >
                          Я согласен на предоставление организаторам фестиваля
                          доступа к социальной сети ВКонтакте для рассылки по
                          подписчикам;
                        </CheckboxComponent>
                      </div>

                      <div className={`${block}__agree-checkbox`}>
                        <CheckboxComponent
                          name={ArtistFormAgreeCheckboxName.termsCheckbox}
                          defaultIsChecked={true}
                        >
                          Я осведомлен с{" "}
                          <a
                            // eslint-disable-next-line max-len
                            href="https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2Ff8ueHbJSZI%2F%2BWTar%2BhpNB2byJqa4w9cHKkJOyyTatqH9MtEH1bADXWiqrAVFhVOuq%2FJ6bpmRyOJonT3VoXnDag%3D%3D%3A%2FПоложение%20New%20Open%202024.pdf&name=Положение%20New%20Open%202024.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            правилами и условиями
                          </a>{" "}
                          фестиваля.
                        </CheckboxComponent>
                      </div>

                      <div className={`${block}__agree-checkbox`}>
                        <CheckboxComponent
                          name={ArtistFormAgreeCheckboxName.associationCheckbox}
                          defaultIsChecked={true}
                        >
                          Я подтверждаю соответствие заявки и музыкального
                          коллектива всем{" "}
                          <a
                            href="https://docs.google.com/document/d/1JJjR5XMH7JYsdKQykNBnXbch25cf9BlD/view"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            требованиям
                          </a>{" "}
                          и даю АНО &#34;Ассоциация музыкантов Урала&#34;
                          согласие на обработку и хранение персональных данных
                        </CheckboxComponent>
                      </div>

                      <div className={`${block}__agree-checkbox`}>
                        <CheckboxComponent
                          name={ArtistFormAgreeCheckboxName.licenseCheckbox}
                          defaultIsChecked={true}
                        >
                          Отправляя музыкальные файлы организаторам фестиваля,
                          вы предоставляете организаторам неисключительную
                          лицензию на использование данных произведений
                        </CheckboxComponent>
                      </div>

                      <div
                        className={classNames(
                          `${block}__details`,
                          isOpenLicense && `${block}__details--open`
                        )}
                      >
                        <div
                          className={`${block}__details-title`}
                          onClick={() => setIsOpenLicense(!isOpenLicense)}
                        >
                          <span>{isOpenLicense ? "Скрыть" : "Подробнее"}</span>
                          <ArrowDown />
                        </div>
                        <div className="hidden">
                          Мы будем использовать их для публикации в нашем
                          сообществе и при составлении плейлиста фестиваля.
                          Воспроизведение произведений, запись на электронном
                          носителе в т.ч. запись в память ЭВМ, публичное
                          воспроизведение произведений, то есть любая
                          демонстрация аудио произведений без соблюдения их
                          последовательности, либо с помощью технических средств
                          в месте открытом для свободного посещения. Территория
                          использования - территория всего мира. Срок
                          использования - на протяжении всего срока действия
                          неисключительных прав. Вознаграждение за
                          предоставление лицензии не предоставляется. Автор
                          гарантирует, что это не приведет к нарушению авторских
                          прав или иных прав интеллектуальной собственности
                          третьих лиц
                        </div>
                      </div>

                      <div className={`${block}__submit-button`}>
                        <Button
                          background="turquoise"
                          onClick={(): void => {
                            showErrors()
                            if (form.getValues('budgetPlanJson').some(value => value.name === null)) {
                              form.setError(
                                'budgetPlanJson', 
                                {type: 'required', message: '"Это обязательное поле'}
                              )
                            }

                            form.handleSubmit(
                              data => onSubmit(data, true),
                              errors => scrollToFirstError(errors)
                            )()
                          }}
                          isDisabled={!isRequestAllowed || isFormReadonly}
                          text={"Подать заявку"}
                          isLoading={isSaving || isFormApplyLoading}
                        />
                      </div>

                      <p className="meta-note-paragraph" id="metaNote" ref={metaRef}>
                        ** Корпорация Meta признана в РФ экстремистской организацией.
                        </p>
                    </div>
                  )}

                {userRole === UserRole.ARTIST &&
                  formStatus === ArtistFormStatus.EDITING &&
                  artistFormMeta?.checkedAt &&
                  location.pathname !== PATH_ARTIST_REQUEST_READ && (
                    <div className={`${block}__submit-button`}>
                      <Button
                        background="turquoise"
                        onClick={(): void => {
                          showErrors()
                          form.handleSubmit(
                            data => onSubmit(data, true),
                            errors => scrollToFirstError(errors)
                          )()
                        }}
                        text={"Отправить на повторную модерацию"}
                        isLoading={isSaving || isFormApplyLoading}
                      />
                    </div>
                  )}

                {formStatus === ArtistFormStatus.EDITING &&
                  userRole === UserRole.ADMIN && (
                    <div className={`${block}__submit-area`}>
                      <div className={`${block}__submit-button`}>
                        <Button
                          background="turquoise"
                          onClick={(): void => {
                            showErrors()
                            form.handleSubmit(
                              data => onSubmit(data, true),
                              errors => scrollToFirstError(errors)
                            )()
                          }}
                          text="Одобрить заявку"
                          isLoading={isApproveLoading || isSaving}
                        />
                      </div>
                    </div>
                  )}

                {formStatus === ArtistFormStatus.CHECKED &&
                  userRole === UserRole.ADMIN && (
                    <div className={`${block}__submit-area`}>
                      <div className={`${block}__submit-button`}>
                        <Button
                          background="turquoise"
                          onClick={(): void => {
                            showErrors()
                            form.handleSubmit(
                              data => onSubmit(data, false),
                              errors => scrollToFirstError(errors)
                            )()
                          }}
                          text="Сохранить"
                          isLoading={isApproveLoading || isSaving}
                        />
                      </div>
                    </div>
                  )}

                {submittionErrors &&
                  submittionErrors.length &&
                  submittionErrors.map((error, index: number) => (
                    <ErrorLabelComponent
                      text={error.message}
                      key={`${error.code}-${index}`}
                    />
                  ))}
              </div>

              {
                // userRole !== UserRole.ARTIST && //TODO: узнать есть ли кроме админа у кого-то кнопка
                userRole === UserRole.ADMIN && (
                  // formStatus !== ArtistFormStatus.EDITING &&
                  // !isFormReadonly &&
                  <div className="margin margin--top-xl">
                    <AdminApproveSidebarComponent
                      onModerate={(): void => onModerate(form.getValues())}
                      onApprove={(): void => onFormSubmit(onApprove)}
                      isModerateActive={isModerateActive}
                      isApproveActive={true}
                      comments={comments}
                      onCheckForm={(): void => onFormSubmit(onCheckForm)}
                      onApproveApproveFormWithoutCheck={(): void => onFormSubmit(onApproveApproveFormWithoutCheck)}
                      formStatus={formStatus}
                      formData={form.watch()}
                      commentInputTitle={
                        "Оставьте комментарий для участника фестиваля"
                      }
                      isModerateLoading={isModerateLoading}
                      isApproveLoading={isApproveLoading}
                      isCheckLoading={isCheckLoading}
                      submittionErrors={submittionErrors}
                      checkingErrors={checkingErrors}
                    />
                  </div>
                )
              }

              {userRole === UserRole.ARTIST && (
                <ArtistSidebarComponent
                  comments={comments}
                  commentInputTitle={"Оставьте комментарий для модератора"}
                  activeSidebarTab={activeSidebarTab}
                  setActiveSidebarTab={setActiveSidebarTab}
                  onComment={onComment}
                  isModerateLoading={isModerateLoading}
                  isModerateActive={isModerateActive}
                  isCanComment={
                    formStatus === ArtistFormStatus.EDITING &&
                    !!artistFormMeta?.appliedAt
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ArtistFormComponent
