import { ReactComponent as FB } from "../../../../assets/icons/facebook.svg"
import { ReactComponent as VK } from "../../../../assets/icons/vk.svg"
import { ReactComponent as Instagram } from "../../../../assets/icons/instagram.svg"
import { ReactComponent as Twitter } from "../../../../assets/icons/twitter.svg"
import { ReactComponent as Tiktok } from "../../../../assets/icons/tiktok.svg"
import { ReactComponent as Youtube } from "../../../../assets/icons/youtube.svg"
import { FestExpertEvaluationArtistModel } from "../../../../models/FestExpertModels/ArtistInfo.model"

interface ProjectInSocialDataItem {
  field: keyof FestExpertEvaluationArtistModel;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const projectInSocialData: ProjectInSocialDataItem[] = [
  {
    field: "facebookUrl",
    icon: FB
  },
  {
    field: "instagramUrl",
    icon: Instagram
  },
  {
    field: "vkontakteUrl",
    icon: VK
  },
  {
    field: "twitterUrl",
    icon: Twitter
  },
  {
    field: "tiktokUrl",
    icon: Tiktok
  },
  {
    field: "youtubeUrl",
    icon: Youtube
  }
]
