import { autoserialize, autoserializeAs } from "cerialize"
import { FileUploadResponse } from "../common/fileUpload.model"
import { UserRole } from "../../const/permissionsRules.const"

export enum ArtistFormStatus {
  NEW = "NEW",
  EDITING = "EDITING",
  MODERATION = "MODERATION",
  APPROVED = "APPROVED",
  CHECKED = "CHECKED",
}

export class MemberModel {
  @autoserialize name: string | null;
  @autoserialize birthday: string | null;
  @autoserialize email: string | null;
  @autoserialize role: string | null;
  @autoserialize phone: string | null;
  @autoserializeAs("music_experience") musicExperience: string | null;
  @autoserializeAs("is_leader") isLeader: boolean;
  @autoserializeAs("t_shirt_size") tShirtSize: string | null;
  @autoserializeAs("facebook_url") facebookUrl: string | null;
  @autoserializeAs("instagram_url") instagramUrl: string | null;
  @autoserializeAs("youtube_url") youtubeUrl: string | null;
  @autoserializeAs("vkontakte_url") vkontakteUrl: string | null;
  @autoserializeAs("tiktok_url") tiktokUrl: string | null;
  @autoserializeAs("twitter_url") twitterUrl: string | null;

  constructor(
    name: string | null,
    birthday: string | null,
    email: string | null,
    role: string | null,
    phone: string | null,
    musicExperience: string | null,
    isLeader: boolean,
    tShirtSize: string | null,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    tiktokUrl: string | null,
    twitterUrl: string | null
  ) {
    this.name = name
    this.birthday = birthday
    this.email = email
    this.role = role
    this.phone = phone
    this.musicExperience = musicExperience
    this.isLeader = isLeader
    this.tShirtSize = tShirtSize
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.twitterUrl = twitterUrl
    this.tiktokUrl = tiktokUrl
    this.youtubeUrl = youtubeUrl
  }
}

export class BudgetRowModel {
  @autoserialize name: string | null;
  @autoserialize price: number | null;
  @autoserialize count: number | null;
  @autoserializeAs("current_budget") currentBudget: number | null;

  constructor(
    name: string | null,
    price: number | null,
    count: number | null,
    currentBudget: number | null
  ) {
    this.name = name
    this.price = price
    this.count = count
    this.currentBudget = currentBudget
  }
}

export class MemberModelResponse {
  @autoserialize id: number;
  @autoserialize name: string | null;
  @autoserialize birthday: string | null;
  @autoserialize email: string | null;
  @autoserialize role: string | null;
  @autoserialize phone: string | null;
  @autoserializeAs("music_experience") musicExperience: string | null;
  @autoserializeAs("is_leader") isLeader: boolean;
  @autoserializeAs("t_shirt_size") tShirtSize: string | null;
  @autoserializeAs("facebook_url") facebookUrl: string | null;
  @autoserializeAs("instagram_url") instagramUrl: string | null;
  @autoserializeAs("youtube_url") youtubeUrl: string | null;
  @autoserializeAs("vkontakte_url") vkontakteUrl: string | null;
  @autoserializeAs("tiktok_url") tiktokUrl: string | null;
  @autoserializeAs("twitter_url") twitterUrl: string | null;

  constructor(
    id: number,
    name: string | null,
    birthday: string | null,
    email: string | null,
    role: string | null,
    phone: string | null,
    musicExperience: string | null,
    isLeader: boolean,
    tShirtSize: string | null,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    tiktokUrl: string | null,
    twitterUrl: string | null
  ) {
    this.id = id
    this.name = name
    this.birthday = birthday
    this.email = email
    this.role = role
    this.phone = phone
    this.musicExperience = musicExperience
    this.isLeader = isLeader
    this.tShirtSize = tShirtSize
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.twitterUrl = twitterUrl
    this.tiktokUrl = tiktokUrl
    this.youtubeUrl = youtubeUrl
  }
}

export class CommentModel {
  @autoserialize comment: string;
  @autoserializeAs("created_at") createdAt: string;
  @autoserialize author: UserRole;

  constructor(comment: string, createdAt: string, author: UserRole) {
    this.comment = comment
    this.createdAt = createdAt
    this.author = author
  }
}

export class ArtistFormSaveRequest {
  @autoserialize name: string | null;
  @autoserialize description: string | null;
  @autoserialize country: string | null;
  @autoserialize city: string | null;
  @autoserialize genre: string | null;
  @autoserializeAs("contact_email") contactEmail: string;
  @autoserializeAs("contact_phone") contactPhone: string | null;
  @autoserializeAs("contact_name") contactName: string | null;
  @autoserializeAs("facebook_url") facebookUrl: string | null;
  @autoserializeAs("instagram_url") instagramUrl: string | null;
  @autoserializeAs("youtube_url") youtubeUrl: string | null;
  @autoserializeAs("vkontakte_url") vkontakteUrl: string | null;
  @autoserializeAs("tiktok_url") tiktokUrl: string | null;
  @autoserializeAs("twitter_url") twitterUrl: string | null;
  @autoserializeAs("music_links") musicLinks: Array<string | null>;
  @autoserializeAs("project_name") projectName: string | null;
  @autoserializeAs("project_description") projectDescription: string | null;
  @autoserializeAs("project_start") projectStart: string | null;
  @autoserializeAs("project_end") projectEnd: string | null;
  @autoserializeAs("project_point") projectPoint: string | null;
  @autoserializeAs("project_relevance") projectRelevance: string | null;
  @autoserializeAs("project_pr_strategy") projectPrStrategy: string | null;
  @autoserializeAs("project_implementation_if_epidemic")
  projectImplementationIfEpidemic: string | null;
  @autoserializeAs("project_expectations") projectExpectations: string | null;
  @autoserializeAs("project_audience_expectations")
  projectAudienceExpectations: string | null;
  @autoserializeAs("project_budget") projectBudget: number | null;
  @autoserializeAs("project_budget_sources") projectBudgetSources:
    | string
    | null;
  @autoserializeAs("promo_post_url") promoPostUrl: string | null;
  @autoserializeAs("playlist_code") playlistCode: string | null;
  @autoserializeAs("vk_clips_url") vkClipsUrl: string | null;
  @autoserializeAs("calendar_plan_text") calendarPlanText: string | null;
  @autoserializeAs(BudgetRowModel, "budget_plan_json")
  budgetPlanJson: BudgetRowModel[];
  @autoserializeAs("project_online_audience_expectations")
  projectOnlineAudienceExpectations: number | null;
  @autoserializeAs("project_offilne_audience_expectations")
  projectOffilneAudienceExpectations: number | null;
  @autoserializeAs("project_media_coverage_expectations")
  projectMediaCoverageExpectations: number | null;
  @autoserializeAs("invited_specialists") invitedSpecialists: string | null;
  @autoserializeAs("project_target_audience") projectTargetAudience:
    | string
    | null;

  constructor(
    name: string | null,
    description: string | null,
    country: string | null,
    city: string | null,
    genre: string | null,
    contactEmail: string,
    contactPhone: string | null,
    contactName: string | null,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    tiktokUrl: string | null,
    twitterUrl: string | null,
    musicLinks: Array<string | null>,
    projectName: string | null,
    projectDescription: string | null,
    projectStart: string | null,
    projectEnd: string | null,
    projectPoint: string | null,
    projectRelevance: string | null,
    projectPrStrategy: string | null,
    projectImplementationIfEpidemic: string | null,
    projectExpectations: string | null,
    projectAudienceExpectations: string | null,
    projectBudget: number | null,
    projectBudgetSources: string | null,
    promoPostUrl: string | null,
    playlistCode: string | null,
    vkClipsUrl: string | null,
    calendarPlanText: string | null,
    budgetPlanJson: BudgetRowModel[],
    projectOnlineAudienceExpectations: number | null,
    projectOffilneAudienceExpectations: number | null,
    projectMediaCoverageExpectations: number | null,
    invitedSpecialists: string | null,
    projectTargetAudience: string | null
  ) {
    this.name = name
    this.description = description
    this.country = country
    this.city = city
    this.genre = genre
    this.contactEmail = contactEmail
    this.contactPhone = contactPhone
    this.contactName = contactName
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.youtubeUrl = youtubeUrl
    this.vkontakteUrl = vkontakteUrl
    this.tiktokUrl = tiktokUrl
    this.twitterUrl = twitterUrl
    this.musicLinks = musicLinks
    this.projectName = projectName
    this.projectDescription = projectDescription
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.projectPoint = projectPoint
    this.projectRelevance = projectRelevance
    this.projectPrStrategy = projectPrStrategy
    this.projectImplementationIfEpidemic = projectImplementationIfEpidemic
    this.projectExpectations = projectExpectations
    this.projectAudienceExpectations = projectAudienceExpectations
    this.projectBudget = projectBudget
    this.projectBudgetSources = projectBudgetSources
    this.promoPostUrl = promoPostUrl
    this.playlistCode = playlistCode
    this.vkClipsUrl = vkClipsUrl
    this.calendarPlanText = calendarPlanText
    this.budgetPlanJson = budgetPlanJson
    this.projectOnlineAudienceExpectations = projectOnlineAudienceExpectations
    this.projectOffilneAudienceExpectations =
      projectOffilneAudienceExpectations
    this.projectMediaCoverageExpectations = projectMediaCoverageExpectations
    this.invitedSpecialists = invitedSpecialists
    this.projectTargetAudience = projectTargetAudience
  }
}

export class ArtistFormResponseForm {
  @autoserialize id: number;
  @autoserialize name: string | null;
  @autoserialize description: string | null;
  @autoserialize country: string | null;
  @autoserialize city: string | null;
  @autoserialize genre: string | null;
  @autoserializeAs("contact_email") contactEmail: string;
  @autoserializeAs("contact_phone") contactPhone: string | null;
  @autoserializeAs("contact_name") contactName: string | null;
  @autoserializeAs("facebook_url") facebookUrl: string | null;
  @autoserializeAs("instagram_url") instagramUrl: string | null;
  @autoserializeAs("youtube_url") youtubeUrl: string | null;
  @autoserializeAs("vkontakte_url") vkontakteUrl: string | null;
  @autoserializeAs("tiktok_url") tiktokUrl: string | null;
  @autoserializeAs("twitter_url") twitterUrl: string | null;
  @autoserializeAs("music_links") musicLinks: Array<string | null>;
  @autoserializeAs(MemberModelResponse) members: MemberModelResponse[];
  @autoserializeAs("project_name") projectName: string | null;
  @autoserializeAs("project_description") projectDescription: string | null;
  @autoserializeAs("project_start") projectStart: string | null;
  @autoserializeAs("project_end") projectEnd: string | null;
  @autoserializeAs("project_point") projectPoint: string | null;
  @autoserializeAs("project_relevance") projectRelevance: string | null;
  @autoserializeAs("project_pr_strategy") projectPrStrategy: string | null;
  @autoserializeAs("project_implementation_if_epidemic")
  projectImplementationIfEpidemic: string | null;
  @autoserializeAs("project_expectations") projectExpectations: string | null;
  @autoserializeAs("project_audience_expectations")
  projectAudienceExpectations: string | null;
  @autoserializeAs("project_budget") projectBudget: number | null;
  @autoserializeAs("project_budget_sources") projectBudgetSources:
    | string
    | null;
  @autoserializeAs(FileUploadResponse, "photo_site")
  photoSite: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "photo_social") photoSocial:
    | FileUploadResponse[]
    | [];
  @autoserializeAs(FileUploadResponse, "calendar_plan")
  calendarPlan: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "budget_plan")
  budgetPlan: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "leader_passport")
  leaderPassport: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "members_agreements") membersAgreements:
    | FileUploadResponse[]
    | [];
  @autoserializeAs(FileUploadResponse, "tech_rider")
  techRider: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse) presentation: FileUploadResponse | null;
  @autoserializeAs("promo_post_url") promoPostUrl: string | null;
  @autoserializeAs(BudgetRowModel, "budget_plan_json")
  budgetPlanJson: BudgetRowModel[];
  @autoserializeAs("playlist_code") playlistCode: string | null;
  @autoserializeAs("vk_clips_url") vkClipsUrl: string | null;
  @autoserializeAs("calendar_plan_text") calendarPlanText: string | null;
  @autoserializeAs("project_online_audience_expectations")
  projectOnlineAudienceExpectations: number | null;
  @autoserializeAs("project_offilne_audience_expectations")
  projectOffilneAudienceExpectations: number | null;
  @autoserializeAs("project_media_coverage_expectations")
  projectMediaCoverageExpectations: number | null;
  @autoserializeAs("invited_specialists") invitedSpecialists: string | null;
  @autoserializeAs("project_target_audience") projectTargetAudience:
    | string
    | null;
  @autoserializeAs("is_finalist") isFinalist: boolean | null;
  @autoserializeAs("is_winner") isWinner: boolean | null;

  constructor(
    id: number,
    name: string | null,
    description: string | null,
    country: string | null,
    city: string | null,
    genre: string | null,
    contactEmail: string,
    contactPhone: string | null,
    contactName: string | null,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    tiktokUrl: string | null,
    twitterUrl: string | null,
    musicLinks: Array<string | null>,
    budgetPlanJson: BudgetRowModel[],
    members: MemberModelResponse[],
    projectName: string | null,
    projectDescription: string | null,
    projectStart: string | null,
    projectEnd: string | null,
    projectPoint: string | null,
    projectRelevance: string | null,
    projectPrStrategy: string | null,
    projectImplementationIfEpidemic: string | null,
    projectExpectations: string | null,
    projectAudienceExpectations: string | null,
    projectBudget: number | null,
    projectBudgetSources: string | null,
    photoSite: FileUploadResponse | null,
    photoSocial: FileUploadResponse[] | [],
    calendarPlan: FileUploadResponse | null,
    budgetPlan: FileUploadResponse | null,
    leaderPassport: FileUploadResponse | null,
    membersAgreements: FileUploadResponse[] | [],
    techRider: FileUploadResponse | null,
    presentation: FileUploadResponse | null,
    promoPostUrl: string | null,
    playlistCode: string | null,
    vkClipsUrl: string | null,
    calendarPlanText: string | null,
    projectOnlineAudienceExpectations: number | null,
    projectOffilneAudienceExpectations: number | null,
    projectMediaCoverageExpectations: number | null,
    invitedSpecialists: string | null,
    projectTargetAudience: string | null,
    isFinalist: boolean | null,
    isWinner: boolean | null
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.country = country
    this.city = city
    this.genre = genre
    this.contactEmail = contactEmail
    this.contactPhone = contactPhone
    this.contactName = contactName
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.youtubeUrl = youtubeUrl
    this.vkontakteUrl = vkontakteUrl
    this.tiktokUrl = tiktokUrl
    this.twitterUrl = twitterUrl
    this.musicLinks = musicLinks
    this.budgetPlanJson = budgetPlanJson
    this.members = members
    this.projectName = projectName
    this.projectDescription = projectDescription
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.projectPoint = projectPoint
    this.projectRelevance = projectRelevance
    this.projectPrStrategy = projectPrStrategy
    this.projectImplementationIfEpidemic = projectImplementationIfEpidemic
    this.projectExpectations = projectExpectations
    this.projectAudienceExpectations = projectAudienceExpectations
    this.projectBudget = projectBudget
    this.projectBudgetSources = projectBudgetSources
    this.photoSite = photoSite
    this.photoSocial = photoSocial
    this.calendarPlan = calendarPlan
    this.budgetPlan = budgetPlan
    this.leaderPassport = leaderPassport
    this.membersAgreements = membersAgreements
    this.techRider = techRider
    this.presentation = presentation
    this.promoPostUrl = promoPostUrl
    this.playlistCode = playlistCode
    this.vkClipsUrl = vkClipsUrl
    this.calendarPlanText = calendarPlanText
    this.projectOnlineAudienceExpectations = projectOnlineAudienceExpectations
    this.projectOffilneAudienceExpectations =
      projectOffilneAudienceExpectations
    this.projectMediaCoverageExpectations = projectMediaCoverageExpectations
    this.invitedSpecialists = invitedSpecialists
    this.projectTargetAudience = projectTargetAudience
    this.isFinalist = isFinalist
    this.isWinner = isWinner
  }
}

export class ArtistFormResponseMeta {
  @autoserialize status: ArtistFormStatus;
  @autoserializeAs("updated_at") updatedAt: string | null;
  @autoserializeAs("applied_at") appliedAt: string | null;
  @autoserializeAs("rejected_at") rejectedAt: string | null;
  @autoserializeAs("approved_at") approvedAt: string | null;
  @autoserializeAs("checked_at") checkedAt: string | null;

  constructor(
    status: ArtistFormStatus,
    updatedAt: string | null,
    appliedAt: string | null,
    rejectedAt: string | null,
    approvedAt: string | null,
    checkedAt: string | null
  ) {
    this.status = status
    this.updatedAt = updatedAt
    this.appliedAt = appliedAt
    this.rejectedAt = rejectedAt
    this.approvedAt = approvedAt
    this.checkedAt = checkedAt
  }
}

export class ArtistFormGetResponse {
  @autoserializeAs(ArtistFormResponseForm) form: ArtistFormResponseForm;
  @autoserializeAs(ArtistFormResponseMeta) meta: ArtistFormResponseMeta;
  @autoserializeAs(CommentModel) comments: CommentModel[];

  constructor(
    form: ArtistFormResponseForm,
    meta: ArtistFormResponseMeta,
    comments: CommentModel[]
  ) {
    this.form = form
    this.meta = meta
    this.comments = comments
  }
}

export class ArtistFormFields {
  @autoserialize id: number;
  @autoserialize name: string | null;
  @autoserialize description: string | null;
  @autoserialize country: string | null;
  @autoserialize city: string | null;
  @autoserialize genre: string | null;
  @autoserializeAs("contact_email") contactEmail: string;
  @autoserializeAs("contact_phone") contactPhone: string | null;
  @autoserializeAs("contact_name") contactName: string | null;
  @autoserializeAs("facebook_url") facebookUrl: string | null;
  @autoserializeAs("instagram_url") instagramUrl: string | null;
  @autoserializeAs("youtube_url") youtubeUrl: string | null;
  @autoserializeAs("vkontakte_url") vkontakteUrl: string | null;
  @autoserializeAs("tiktok_url") tiktokUrl: string | null;
  @autoserializeAs("twitter_url") twitterUrl: string | null;
  @autoserializeAs("music_links") musicLinks: Array<string | null>;
  @autoserializeAs("project_name") projectName: string | null;
  @autoserializeAs("project_description") projectDescription: string | null;
  @autoserializeAs("project_start") projectStart: string | null;
  @autoserializeAs("project_end") projectEnd: string | null;
  @autoserializeAs("project_point") projectPoint: string | null;
  @autoserializeAs("project_relevance") projectRelevance: string | null;
  @autoserializeAs("project_pr_strategy") projectPrStrategy: string | null;
  @autoserializeAs("project_expectations") projectExpectations: string | null;
  @autoserializeAs("project_audience_expectations")
  projectAudienceExpectations: string | null;
  @autoserializeAs("project_budget") projectBudget: number | null;
  @autoserializeAs("project_budget_sources") projectBudgetSources:
    | string
    | null;
  @autoserializeAs("promo_post_url") promoPostUrl: string | null;
  @autoserializeAs(MemberModel) members: MemberModel[];
  @autoserializeAs(FileUploadResponse, "photo_site")
  photoSite: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "photo_social")
  photoSocial: Array<FileUploadResponse | null>;
  @autoserializeAs(FileUploadResponse, "leader_passport")
  leaderPassport: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse, "members_agreements")
  membersAgreements: FileUploadResponse[];
  @autoserializeAs(FileUploadResponse, "tech_rider")
  techRider: FileUploadResponse | null;
  @autoserializeAs(FileUploadResponse) presentation: FileUploadResponse | null;
  @autoserializeAs(BudgetRowModel, "budget_plan_json")
  budgetPlanJson: BudgetRowModel[];
  @autoserializeAs("playlist_code") playlistCode: string | null;
  @autoserializeAs("vk_clips_url") vkClipsUrl: string | null;
  @autoserializeAs("calendar_plan_text") calendarPlanText: string | null;
  @autoserializeAs("project_online_audience_expectations")
  projectOnlineAudienceExpectations: number | null;
  @autoserializeAs("project_offilne_audience_expectations")
  projectOffilneAudienceExpectations: number | null;
  @autoserializeAs("project_media_coverage_expectations")
  projectMediaCoverageExpectations: number | null;
  @autoserializeAs("invited_specialists") invitedSpecialists: string | null;
  @autoserializeAs("project_target_audience") projectTargetAudience:
    | string
    | null;
  @autoserializeAs("is_finalist") isFinalist: boolean | null;
  @autoserializeAs("is_winner") isWinner: boolean | null;

  constructor(
    id: number,
    members: MemberModel[],
    photoSite: FileUploadResponse | null,
    photoSocial: Array<FileUploadResponse | null>,
    leaderPassport: FileUploadResponse | null,
    membersAgreements: FileUploadResponse[] | [],
    presentation: FileUploadResponse | null,
    techRider: FileUploadResponse | null,
    name: string | null,
    description: string | null,
    country: string | null,
    city: string | null,
    genre: string | null,
    contactEmail: string,
    contactPhone: string | null,
    contactName: string | null,
    facebookUrl: string | null,
    instagramUrl: string | null,
    youtubeUrl: string | null,
    vkontakteUrl: string | null,
    tiktokUrl: string | null,
    twitterUrl: string | null,
    musicLinks: Array<string | null>,
    budgetPlanJson: BudgetRowModel[],
    projectName: string | null,
    projectDescription: string | null,
    projectStart: string | null,
    projectEnd: string | null,
    projectPoint: string | null,
    projectRelevance: string | null,
    projectPrStrategy: string | null,
    projectExpectations: string | null,
    projectAudienceExpectations: string | null,
    projectBudget: number | null,
    projectBudgetSources: string | null,
    promoPostUrl: string | null,
    playlistCode: string | null,
    vkClipsUrl: string | null,
    calendarPlanText: string | null,
    projectOnlineAudienceExpectations: number | null,
    projectOffilneAudienceExpectations: number | null,
    projectMediaCoverageExpectations: number | null,
    invitedSpecialists: string | null,
    projectTargetAudience: string | null,
    isFinalist: boolean | null,
    isWinner: boolean | null
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.country = country
    this.city = city
    this.genre = genre
    this.contactEmail = contactEmail
    this.contactPhone = contactPhone
    this.contactName = contactName
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.youtubeUrl = youtubeUrl
    this.vkontakteUrl = vkontakteUrl
    this.tiktokUrl = tiktokUrl
    this.twitterUrl = twitterUrl
    this.musicLinks = musicLinks
    this.budgetPlanJson = budgetPlanJson
    this.projectName = projectName
    this.projectDescription = projectDescription
    this.projectStart = projectStart
    this.projectEnd = projectEnd
    this.projectPoint = projectPoint
    this.projectRelevance = projectRelevance
    this.projectPrStrategy = projectPrStrategy
    this.projectExpectations = projectExpectations
    this.projectAudienceExpectations = projectAudienceExpectations
    this.projectBudget = projectBudget
    this.projectBudgetSources = projectBudgetSources
    this.promoPostUrl = promoPostUrl
    this.members = members
    this.photoSite = photoSite
    this.photoSocial = photoSocial
    this.leaderPassport = leaderPassport
    this.membersAgreements = membersAgreements
    this.techRider = techRider
    this.presentation = presentation
    this.playlistCode = playlistCode
    this.vkClipsUrl = vkClipsUrl
    this.calendarPlanText = calendarPlanText
    this.projectOnlineAudienceExpectations = projectOnlineAudienceExpectations
    this.projectOffilneAudienceExpectations =
      projectOffilneAudienceExpectations
    this.projectMediaCoverageExpectations = projectMediaCoverageExpectations
    this.invitedSpecialists = invitedSpecialists
    this.projectTargetAudience = projectTargetAudience
    this.isFinalist = isFinalist
    this.isWinner = isWinner
  }
}

export enum ArtistFormAgreeCheckboxName {
  accessCheckbox = "accessCheckbox",
  termsCheckbox = "termsCheckbox",
  associationCheckbox = "associationCheckbox",
  licenseCheckbox = "licenseCheckbox",
}
