import { autoserialize, autoserializeAs } from "cerialize"

export class EvaluateProjectDefenseRequest {
  @autoserialize reality: number;
  @autoserialize relevance: number;
  @autoserialize realizability: number;
  @autoserialize scale: number;

  constructor(
    reality: number,
    relevance: number,
    realizability: number,
    scale: number
  ) {
    this.reality = reality
    this.relevance = relevance
    this.realizability = realizability
    this.scale = scale
  }
}

export class EvaluateMusicPerfomanceRequest {
  @autoserialize quality: number;
  @autoserialize artistry: number;
  @autoserializeAs("emotional_response") emotionalResponse: number;

  constructor(quality: number, artistry: number, emotionalResponse: number) {
    this.quality = quality
    this.artistry = artistry
    this.emotionalResponse = emotionalResponse
  }
}

export class AvailableToEvaluateModel {
  @autoserializeAs("project_defense")
  projectDefense: boolean;
  @autoserializeAs("music_perfomance")
  musicPerfomance: boolean;

  constructor(projectDefense: boolean, musicPerfomance: boolean) {
    this.projectDefense = projectDefense
    this.musicPerfomance = musicPerfomance
  }
}

export class EvaluationModel {
  @autoserializeAs("project_defense")
  projectDefense: EvaluateProjectDefenseRequest;
  @autoserializeAs("music_perfomance")
  musicPerfomance: EvaluateMusicPerfomanceRequest;

  constructor(
    projectDefense: EvaluateProjectDefenseRequest,
    musicPerfomance: EvaluateMusicPerfomanceRequest
  ) {
    this.projectDefense = projectDefense
    this.musicPerfomance = musicPerfomance
  }
}

export class EvaluateProjectDefenseForm {
  @autoserialize reality: string;
  @autoserialize relevance: string;
  @autoserialize realizability: string;
  @autoserialize scale: string;

  constructor(
    reality: string,
    relevance: string,
    realizability: string,
    scale: string
  ) {
    this.reality = reality
    this.relevance = relevance
    this.realizability = realizability
    this.scale = scale
  }
}

export class EvaluateMusicPerfomanceForm {
  @autoserialize quality: string;
  @autoserialize artistry: string;
  @autoserializeAs("emotional_response") emotionalResponse: string;

  constructor(quality: string, artistry: string, emotionalResponse: string) {
    this.quality = quality
    this.artistry = artistry
    this.emotionalResponse = emotionalResponse
  }
}

export class EvaluationValuationModel {
  @autoserializeAs(EvaluateProjectDefenseForm, "project_defense")
  projectDefense: EvaluateProjectDefenseForm;
  @autoserializeAs(EvaluateMusicPerfomanceForm, "music_perfomance")
  musicPerfomance: EvaluateMusicPerfomanceForm;

  constructor(
    projectDefense: EvaluateProjectDefenseForm,
    musicPerfomance: EvaluateMusicPerfomanceForm
  ) {
    this.projectDefense = projectDefense
    this.musicPerfomance = musicPerfomance
  }
}
