import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { ArtistFormFields } from "../../../../models/ArtistModels/artistForm.model"
import Button from "../../../common/Button/Button.component"
import LoaderComponent from "../../../common/Loader/Loader.component"
import ArtistFormInputFields from "../../ArtistCRM/ArtistForm/ArtistFormFields/ArtistFormFields.component"

import "./FinalistForm.style.scss"

interface Props {
  formInitialValues?: ArtistFormFields;
  setFormInitialValues:  (value: React.SetStateAction<ArtistFormFields | undefined>) => void
  isLoading: boolean;
  artistId: number;
  onConfirm: (isConfirmed: boolean) => void;
  isConfirmLoading?: boolean;
}

const block = "finalist-form"

const FinalistFormComponent = (props: Props): JSX.Element => {
  const {
    formInitialValues,
    isLoading,
    artistId,
    onConfirm,
    setFormInitialValues,
    isConfirmLoading
  } = props
  const form = useForm()

  const onEffect = (): void => {
    form.reset(formInitialValues)
  }

  React.useEffect(onEffect, [formInitialValues])

  return (
    <div className={block}>
      <div className="content-container">
        {isLoading || !formInitialValues ? (
          <LoaderComponent width={100} height={100} />
        ) : (
          <div className="row row--space-between">
            <div className={`${block}__form`}>
              <FormProvider {...form}>
                <ArtistFormInputFields
                  setFormInitialValues={setFormInitialValues}
                  formInitialValues={formInitialValues}
                  formId={artistId}
                  isFormReadonly={true}
                />
              </FormProvider>
            </div>

            <div className={`${block}__moderate-block`}>
              <div className={`${block}__moderate-block-button`}>
                <Button
                  text="Приедет"
                  background="turquoise"
                  onClick={(): void => onConfirm(true)}
                  isLoading={isConfirmLoading}
                />
              </div>
              <div className={`${block}__moderate-block-button`}>
                <Button
                  text="Не приедет"
                  background="red"
                  onClick={(): void => onConfirm(false)}
                  isLoading={isConfirmLoading}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FinalistFormComponent
