import { Deserialize, Serialize } from "cerialize"
import moment from "moment"
import { UserRole } from "../../const/permissionsRules.const"
import {
  ArtistFormGetResponse,
  ArtistFormSaveRequest,
  ArtistFormStatus
} from "../../models/ArtistModels/artistForm.model"
import { RequestErrorResponse } from "../../models/common/error.model"
import { artistDataStore } from "../../store/ArtistStore/artistData.store"
import { artistFormNotificationsStore } from "../../store/ArtistStore/artistFormNotifications.store"
import { api } from "../../utils/api"
import {
  WinnerFormGetResponse,
  WinnerFormGetRequest,
  WinnerFormSaveRequest
} from "../../models/ArtistModels/winnerForm.model"
import {
  winnerFormGetStore,
  winnerFormSaveStore,
  winnerFormApplyStore,
  winnerFormModerateStore,
  winnerFormReadStore
} from "../../store/ArtistStore/winnerForm.store"
import { winnerDataStore } from "../../store/ArtistStore/winnerData.store"

const getWinnerFormUrl = (role: UserRole, artistid?: number): string => {
  switch (role) {
    case UserRole.ARTIST:
      return "/artist/reports"
    case UserRole.ADMIN:
      return `/admin/artists/${artistid}/reports`
    default:
      return `/admin/artists/${artistid}/reports`
  }
}

const getWinnerFormUrlForSend = (role: UserRole, formId?: number): string => {
  switch (role) {
    case UserRole.ARTIST:
      return `/artist/reports/${formId}`
    case UserRole.ADMIN:
      return `/admin/artists/reports/${formId}`
    default:
      return `/admin/artists/reports/${formId}`
  }
}

export function getWinnerForm(
  role: UserRole,
  stage: number,
  id?: number
): Promise<void> {
  const url = getWinnerFormUrl(role, id)
  winnerFormGetStore.setIsLoading(true)
  const serializedData = Serialize({ stage: stage + 1 }, WinnerFormGetRequest) // потому что стало 1,2

  return api
    .get(url, {
      params: serializedData
    })
    .then((res) => {
      const data: WinnerFormGetResponse = Deserialize(
        res.data,
        WinnerFormGetResponse
      )

      winnerFormGetStore.setIsLoading(false)
      winnerFormGetStore.setFormData(data)
      winnerDataStore.setFormStatus(data.meta.status)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerFormGetStore.setIsLoading(false)
      winnerFormGetStore.setErrors(errorResponseData?.errors)
    })
}

export function saveWinnerForm(
  request: WinnerFormSaveRequest,
  role: UserRole,
  formId?: number
): Promise<void> {
  const url = getWinnerFormUrlForSend(role, formId)
  const serializedData = Serialize(request, WinnerFormSaveRequest)

  winnerFormSaveStore.setIsLoading(true)

  return api
    .put(url, serializedData)
    .then(() => {
      winnerFormSaveStore.setIsLoading(false)
      winnerFormSaveStore.setSaveTime(moment().toDate())
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerFormSaveStore.setIsLoading(false)
      winnerFormSaveStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function applyForm(formId: number): Promise<void> {
  winnerFormApplyStore.setIsLoading(true)

  return api
    .post(`/artist/reports/${formId}/apply`)
    .then(() => {
      winnerFormApplyStore.setIsLoading(false)
      artistDataStore.setFormStatus(ArtistFormStatus.MODERATION)
      // artistFormNotificationsStore.addNotificationOnApply()
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerFormApplyStore.setIsLoading(false)
      winnerFormApplyStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function moderateWinner(
  comment: string,
  formId?: number
): Promise<void> {
  winnerFormModerateStore.setIsLoading(true)

  return api
    .post(`/admin/artists/reports/${formId}/reject`, { comment })
    .then(() => {
      winnerFormModerateStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerFormModerateStore.setIsLoading(false)
      winnerFormModerateStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function checkForm(formId: number): Promise<void> {
  winnerFormReadStore.setIsLoading(true)

  return api
    .post(`/admin/artists/reports/${formId}/check`)
    .then(() => {
      winnerFormReadStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerFormReadStore.setIsLoading(false)
      winnerFormReadStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function commentByAdmin(comment: string, id?: number): Promise<void> {
  winnerFormModerateStore.setIsLoading(true)

  return api
    .post(`/admin/artists/${id}/reports/comment`, { comment })
    .then(() => {
      winnerFormModerateStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerFormModerateStore.setIsLoading(false)
      winnerFormModerateStore.setErrors(errorResponseData?.errors)
      throw error
    })
}

export function commentByArtist(comment: string): Promise<void> {
  winnerFormModerateStore.setIsLoading(true)

  return api
    .post("/artist/reports/comment", { comment })
    .then(() => {
      winnerFormModerateStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response?.data
      winnerFormModerateStore.setIsLoading(false)
      winnerFormModerateStore.setErrors(errorResponseData?.errors)
      throw error
    })
}
