import React from "react"
import Router from "./Router"
import { Helmet } from "react-helmet"

function App(): JSX.Element {
  return (
    <div className="app-content-wrapper">
      <Helmet>
        <script
          type="text/javascript"
          src="https://vk.com/js/api/openapi.js?169"
          async
        ></script>
      </Helmet>
      <Router />
    </div>
  )
}

export default App
