import { observable, action } from "mobx"
import { ArtistRatingGetResponse } from "../../models/ArtistModels/artistRating.model"
import { ApiRequestStore } from "../common/apiRequest.store"

class ArtistRatingStore extends ApiRequestStore {
  @observable ratingData?: ArtistRatingGetResponse
  
  @action
  setNotificationsData(data: ArtistRatingGetResponse): void {
    this.ratingData = data
  }

  @action
  clearData(): void {
    this.ratingData = undefined
  }
}

export const artistRatingStore: ArtistRatingStore = new ArtistRatingStore()